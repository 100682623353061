import React, { useState, useEffect } from 'react';
import { Typography, Row, Col, Card, Space, Divider, Button, Table, Tag, Modal, message, Spin } from 'antd';
import { FireOutlined, CreditCardOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { getUserProfile } from '../auth';
import { api } from '../api';
import moment from 'moment-timezone';

const { Title, Text } = Typography;

const SubscriptionManagement = () => {
  const [userProfile, setUserProfile] = useState(null);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [profile, subscriptionResponse, transactionsResponse] = await Promise.all([
        getUserProfile(),
        api.get('/subscriptions/current-subscription'),
        api.get('/subscriptions/transactions')
      ]);
      setUserProfile(profile);
      setCurrentSubscription(subscriptionResponse.data);
      setTransactions(transactionsResponse.data);
    } catch (error) {
      console.error('Failed to fetch data:', error);
      message.error('データの取得に失敗しました');
    } finally {
      setLoading(false);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      await api.post('/subscriptions/cancel-subscription');
      message.success('サブスクリプションをキャンセルしました');
      fetchData();
    } catch (error) {
      console.error('Failed to cancel subscription:', error);
      message.error('サブスクリプションのキャンセルに失敗しました');
    }
    setCancelModalVisible(false);
  };

  const handleChangePaymentMethod = async () => {
    try {
      const response = await api.post('/subscriptions/create-portal-session');
      if (response.data && response.data.url) {
        window.location.href = response.data.url;
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Failed to create portal session:', error);
      message.error('決済方法の変更に失敗しました');
    }
  };

  const planTags = {
    free: { color: 'blue', text: 'フリープラン' },
    standard: { color: 'green', text: 'スタンダードプラン' },
    premium: { color: 'gold', text: 'プレミアムプラン' },
    custom: { color: 'purple', text: 'カスタムプラン' },
  };

  const columns = [
    {
      title: '日付',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => moment(date).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm'),
    },
    {
      title: '金額（税込）',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => `¥${amount.toLocaleString()}`,
    },
    {
      title: 'ステータス',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={status === 'succeeded' ? 'green' : 'red'}>
          {status === 'succeeded' ? '成功' : '失敗'}
        </Tag>
      ),
    },
    {
      title: '内容',
      dataIndex: 'plan_name',
      key: 'plan_name',
      render: (planName, record) => `${planName}（${record.is_new ? '新規' : '継続'}／${record.is_yearly ? '年払い' : '月払い'}）`,
    },
  ];

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <div>
      <Text style={{ margin: '16px 0' }}>ユーザー設定</Text>
      <Title level={1} className='gradient-text' style={{fontSize:'24px'}}>サブスクリプション管理</Title>
      <Divider style={{ margin: '24px 0' }} />
      
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Card title="現在のプラン">
            {currentSubscription && (
              <>
                <Tag color={planTags[currentSubscription.plan]?.color || 'default'}>
                  {planTags[currentSubscription.plan]?.text || '不明なプラン'}
                </Tag>
                <Divider />
                <Text>次回更新日：{moment(currentSubscription.current_period_end).format('YYYY年MM月DD日')}</Text>
                {currentSubscription.status === 'canceling' && (
                  <Text type="warning" style={{display: 'block'}}>（キャンセル予約中）</Text>
                )}
                {currentSubscription.status === 'active' && currentSubscription.plan !== 'free' && (
                  <Button onClick={() => setCancelModalVisible(true)} style={{marginTop: '16px', marginLeft: '24px'}}>
                    キャンセルする
                  </Button>
                )}
              </>
            )}
          </Card>
        </Col>
        <Col span={8}>
          <Card title="チケット枚数">
            {userProfile && currentSubscription && (
              <>
                <Text>
                  チケット残り：<FireOutlined style={{color: '#0072ff'}} /> {userProfile.ticket_count}枚
                </Text>
                <Divider />
                <Text>
                  次回チケット更新：{moment(currentSubscription.current_period_end).format('YYYY年MM月DD日')}
                </Text>
                <br/>
                <Text style={{lineHeight: '1.9' }}>
                  <FireOutlined style={{color: '#0072ff'}} /> {userProfile.ticket_count}枚(現在) →{' '}
                  <FireOutlined style={{color: '#0072ff'}} /> {userProfile.plan?.monthly_tickets || 0}枚(更新後)
                </Text>
              </>
            )}
          </Card>
        </Col>
        <Col span={8}>
          <Card title="登録カード">
            {userProfile && (
              <>
                <Space>
                  <CreditCardOutlined />
                  <Text>xxxx xxxx xxxx {userProfile.stripe_customer?.last4 || '****'}</Text>
                </Space>
                <Button onClick={handleChangePaymentMethod} style={{marginTop: '16px'}}>
                  変更する
                </Button>
              </>
            )}
          </Card>
        </Col>
      </Row>

      <Title level={3} style={{ margin: '32px 0 16px', fontSize: '18px' }}>決済履歴</Title>
      <Table columns={columns} dataSource={transactions} rowKey="id" />

      <Modal
        title="サブスクリプションのキャンセル"
        visible={cancelModalVisible}
        onOk={handleCancelSubscription}
        onCancel={() => setCancelModalVisible(false)}
        okText="キャンセルする"
        cancelText="戻る"
      >
        <p>サブスクリプションをキャンセルすると、次回更新日からフリープランに変更されます。</p>
        <p>現在の保有チケットは次回更新日まで使用できますが、更新されなくなります。</p>
        <p>本当にキャンセルしますか？</p>
      </Modal>
    </div>
  );
};

export default SubscriptionManagement;