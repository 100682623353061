import React, { useState, useEffect } from 'react';
import {
  Table,
  Tag,
  Typography,
  Tooltip,
  Progress,
  Button,
  message,
} from 'antd';
import { SearchOutlined, ExportOutlined } from '@ant-design/icons';
import { api } from '../../api';
import moment from 'moment-timezone';
import { formatDateTime } from '../../utils/dateUtils';

const { Text } = Typography;

const FactCheckHistoryTab = ({ userId }) => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });

  const fetchTasks = async (page = 1, pageSize = 5) => {
    setLoading(true);
    try {
      const response = await api.get(`/fact-check/tasks`, {
        params: {
          user_id: userId,
          skip: (page - 1) * pageSize,
          limit: pageSize,
        },
      });

      if (response.data && response.data.tasks) {
        // タスクデータの整形
        const processedTasks = response.data.tasks.map(task => {
          // タイトルの取得処理
          let title = '';
          try {
            if (task.fact_check_text) {
              const content = JSON.parse(task.fact_check_text);
              const titleBlock = content.blocks.find(
                block => block.type === 'header' && block.data.level === 1,
              );
              title = titleBlock ? titleBlock.data.text : '';
            }
          } catch (e) {
            console.warn('Failed to parse task content:', e);
          }

          return {
            ...task,
            title,
          };
        });

        setTasks(processedTasks);
        setPagination({
          ...pagination,
          current: page,
          pageSize: pageSize,
          total: response.data.total_count || processedTasks.length,
        });
      }
    } catch (error) {
      console.error('Failed to fetch fact check tasks:', error);
      message.error('検証タスクの取得に失敗しました');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, [userId]);

  const handleTableChange = newPagination => {
    fetchTasks(newPagination.current, newPagination.pageSize);
  };

  const getStatusColor = status => {
    switch (status) {
      case 'draft':
        return 'default';
      case 'queued':
        return 'orange';
      case 'processing':
        return 'green';
      case 'completed':
        return 'blue';
      case 'finished':
        return 'purple';
      case 'failed':
        return 'red';
      default:
        return 'default';
    }
  };

  const getStatusText = status => {
    const statusMap = {
      draft: '下書き',
      queued: '予約中',
      processing: '実行中',
      completed: '実行済み',
      finished: '完了',
      failed: '失敗',
    };
    return statusMap[status] || status;
  };

  const getAccuracyColor = accuracy => {
    if (accuracy >= 90) return '#0072FF';
    if (accuracy >= 79) return '#2E8CFF';
    if (accuracy >= 70) return '#52C41A';
    if (accuracy >= 60) return '#73D13D';
    if (accuracy >= 50) return '#FFA940';
    if (accuracy >= 40) return '#FA8C16';
    if (accuracy >= 30) return '#FA541C';
    return '#F5222D';
  };

  const columns = [
    {
      title: 'ステータス',
      dataIndex: 'status',
      key: 'status',
      width: 120,
      render: (value, record) => (
        <>
          <Tag color={getStatusColor(value)}>{getStatusText(value)}</Tag>
          {value === 'processing' && (
            <Progress
              style={{
                display: 'inline-block',
                width: '60px',
                marginLeft: '8px',
              }}
              percent={record.progress || 0}
              size="small"
            />
          )}
        </>
      ),
    },
    {
      title: 'タイトル',
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
    },
    {
      title: '実行日',
      dataIndex: 'execution_date',
      key: 'execution_date',
      width: 160,
      render: (text, record) => formatDateTime(text || record.created_at),
      sorter: (a, b) => {
        const dateA = a.execution_date || a.created_at;
        const dateB = b.execution_date || b.created_at;
        return moment(dateA).diff(moment(dateB));
      },
    },
    {
      title: '正確性',
      dataIndex: 'factuality',
      key: 'factuality',
      width: 80,
      render: (_, record) => {
        // フラグメントから平均正確性を計算
        const fragments = record.fragments || [];
        const validFragments = fragments.filter(
          f => typeof f.factuality === 'number',
        );
        const avgFactuality = validFragments.length
          ? (validFragments.reduce((sum, f) => sum + f.factuality, 0) /
              validFragments.length) *
            100
          : 0;

        return (
          <Tooltip title={`検証項目：${fragments.length}件`}>
            <Progress
              type="circle"
              percent={Math.round(avgFactuality)}
              size={24}
              strokeColor={getAccuracyColor(avgFactuality)}
              format={percent => {
                if (percent === 0)
                  return <Text style={{ fontSize: '10px' }}>-</Text>;
                return (
                  <Text style={{ fontSize: '11px', fontWeight: 'bold' }}>
                    {percent}
                  </Text>
                );
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      render: (_, record) => (
        <Button
          type="text"
          icon={<ExportOutlined />}
          onClick={() =>
            window.open(`/fact-check/tasks/${record.id}`, '_blank')
          }
        />
      ),
    },
  ];

  return (
    <div>
      <Button
        icon={<ExportOutlined />}
        onClick={() => window.open(`/fact-check/tasks/${userId}`, '_blank')}
        style={{ marginBottom: '24px' }}
      >
        検証タスク履歴を開く
      </Button>
      <Table
        columns={columns}
        dataSource={tasks}
        rowKey="id"
        pagination={{
          ...pagination,
          onChange: (page, pageSize) =>
            handleTableChange({ current: page, pageSize }),
          showTotal: (total, range) => `${range[0]}-${range[1]} / ${total}件`,
        }}
        loading={loading}
        size="small"
      />
    </div>
  );
};

export default FactCheckHistoryTab;
