import React, { useState, useEffect } from 'react';
import { Select, Spin, Space, Typography, Avatar, Tooltip } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { api } from '../../api';
import debounce from 'lodash/debounce';

const { Text } = Typography;
const { Option } = Select;

const UserSelector = ({
  value,
  onChange,
  placeholder = 'ユーザーで絞り込み',
  style = {},
}) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  // ユーザー一覧を取得
  const fetchUsers = async (searchValue = '') => {
    try {
      setLoading(true);
      const response = await api.get('/users/', {
        params: { search: searchValue },
      });

      // 条件に合うユーザーだけをフィルタリング
      // 1. last_nameとfirst_name両方nullではない
      // 2. is_activeがtrue
      const filteredUsers = (response.data || []).filter(
        user => user.is_active === true && (user.last_name || user.first_name), // どちらか一方でも値があればOK
      );

      setUsers(filteredUsers);
    } catch (error) {
      console.error('Failed to fetch users:', error);
    } finally {
      setLoading(false);
    }
  };

  // 初回ロード時にユーザー一覧を取得
  useEffect(() => {
    fetchUsers();
  }, []);

  // 検索時のディバウンス処理
  const debouncedSearch = debounce(value => {
    setSearchText(value);
    fetchUsers(value);
  }, 500);

  // オプションをフィルタリング - nullチェックを強化
  const filterOption = (input, option) => {
    if (!input || !option) return false;

    // searchContentプロパティがある場合はそれを使用
    if (option.searchContent) {
      return option.searchContent.toLowerCase().includes(input.toLowerCase());
    }

    // childrenがある場合は再帰的に検索（文字列の場合は直接比較）
    if (option.children) {
      if (typeof option.children === 'string') {
        return option.children.toLowerCase().includes(input.toLowerCase());
      }

      // Reactの要素配列の場合
      if (Array.isArray(option.children)) {
        return option.children.some(
          child =>
            typeof child === 'string' &&
            child.toLowerCase().includes(input.toLowerCase()),
        );
      }

      // Reactの単一要素の場合
      if (typeof option.children === 'object' && option.children.props) {
        const childText = option.children.props.children;
        if (typeof childText === 'string') {
          return childText.toLowerCase().includes(input.toLowerCase());
        }
      }
    }

    // label属性がある場合はそれを使用
    if (option.label) {
      return option.label.toLowerCase().includes(input.toLowerCase());
    }

    // 何も見つからない場合はfalse
    return false;
  };

  return (
    <Select
      showSearch
      value={value}
      placeholder={placeholder}
      style={{ width: 250, ...style }}
      onChange={onChange}
      onSearch={debouncedSearch}
      filterOption={filterOption}
      notFoundContent={loading ? <Spin size="small" /> : null}
      allowClear
      optionLabelProp="label"
    >
      {users.map(user => (
        <Option
          key={user.user_id}
          value={user.user_id}
          label={`${user.last_name || ''} ${user.first_name || ''}`}
          searchContent={`${user.email || ''} ${user.company_name || ''} ${user.last_name || ''} ${user.first_name || ''}`}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              icon={<UserOutlined />}
              size="small"
              style={{ marginRight: 8 }}
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ lineHeight: '1.2' }}>
                {user.last_name || ''} {user.first_name || ''}
              </Text>
              <Text
                type="secondary"
                style={{ fontSize: '12px', lineHeight: '1.2' }}
                ellipsis
              >
                {user.email || ''}
                {user.company_name && ` - ${user.company_name}`}
              </Text>
            </div>
          </div>
        </Option>
      ))}
    </Select>
  );
};

export default UserSelector;
