// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Auth_authContainer__mMQiz {
background-color: #fff;
padding: 48px;
border-radius: 4px;
min-height: 400px;
display: flex;
flex-direction: column;
}

.Auth_authTitle__vrhsP {
text-align: center;
margin-bottom: 24px;
}

.Auth_authSegmented__AfaD4 {
margin-bottom: 24px;
}

.Auth_authContent__Emjb4 {
flex: 1 1;
display: flex;
flex-direction: column;
justify-content: center;
}

.Auth_form__FjcNT {
max-width: 500px;
margin: 0 auto;
}

.Auth_label__YUTJV {
color: #626262;
font-weight: 500;
}

.Auth_input__dsL2n {
border: 2px solid #ededed;
border-radius: 4px;
}

.Auth_input__dsL2n:focus {
border-color: #40a9ff;
box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.Auth_form__FjcNT .ant-form-item-explain {
font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/auth/Auth.module.css"],"names":[],"mappings":"AAAA;AACA,sBAAsB;AACtB,aAAa;AACb,kBAAkB;AAClB,iBAAiB;AACjB,aAAa;AACb,sBAAsB;AACtB;;AAEA;AACA,kBAAkB;AAClB,mBAAmB;AACnB;;AAEA;AACA,mBAAmB;AACnB;;AAEA;AACA,SAAO;AACP,aAAa;AACb,sBAAsB;AACtB,uBAAuB;AACvB;;AAEA;AACA,gBAAgB;AAChB,cAAc;AACd;;AAEA;AACA,cAAc;AACd,gBAAgB;AAChB;;AAEA;AACA,yBAAyB;AACzB,kBAAkB;AAClB;;AAEA;AACA,qBAAqB;AACrB,6CAA6C;AAC7C;;AAEA;AACA,eAAe;AACf","sourcesContent":[".authContainer {\nbackground-color: #fff;\npadding: 48px;\nborder-radius: 4px;\nmin-height: 400px;\ndisplay: flex;\nflex-direction: column;\n}\n\n.authTitle {\ntext-align: center;\nmargin-bottom: 24px;\n}\n\n.authSegmented {\nmargin-bottom: 24px;\n}\n\n.authContent {\nflex: 1;\ndisplay: flex;\nflex-direction: column;\njustify-content: center;\n}\n\n.form {\nmax-width: 500px;\nmargin: 0 auto;\n}\n\n.label {\ncolor: #626262;\nfont-weight: 500;\n}\n\n.input {\nborder: 2px solid #ededed;\nborder-radius: 4px;\n}\n\n.input:focus {\nborder-color: #40a9ff;\nbox-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);\n}\n\n.form :global(.ant-form-item-explain) {\nfont-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"authContainer": `Auth_authContainer__mMQiz`,
	"authTitle": `Auth_authTitle__vrhsP`,
	"authSegmented": `Auth_authSegmented__AfaD4`,
	"authContent": `Auth_authContent__Emjb4`,
	"form": `Auth_form__FjcNT`,
	"label": `Auth_label__YUTJV`,
	"input": `Auth_input__dsL2n`
};
export default ___CSS_LOADER_EXPORT___;
