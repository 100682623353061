import React, { useState, useEffect } from 'react';
import Nestable from 'react-nestable';
import 'react-nestable/dist/styles/index.css';
import { AiOutlineHolder, AiFillCaretRight, AiFillCaretDown, AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai';
import './ArticleDetail.css';

const parseHeadings = (markdownHeadings) => {
  const lines = markdownHeadings.split('\n').filter(line => line.trim() !== '');
  const parsedHeadings = [];
  let currentH2 = null;

  lines.forEach((line) => {
    if (line.startsWith('$$ ')) {
      currentH2 = {
        id: `h2-${parsedHeadings.length}`,
        text: line.slice(3),
        type: 'h2',
        children: [],
      };
      parsedHeadings.push(currentH2);
    } else if (line.startsWith('### ') && currentH2) {
      currentH2.children.push({
        id: `h3-${currentH2.id}-${currentH2.children.length}`,
        text: line.slice(4),
        type: 'h3',
      });
    }
  });
  return parsedHeadings;
};

const StructuredHeadings = ({ headings, onChange, isContentGenerated }) => {
  const [structuredHeadings, setStructuredHeadings] = useState(parseHeadings(headings));

  useEffect(() => {
    setStructuredHeadings(parseHeadings(headings));
  }, [headings]);

  const renderItem = ({ item, collapseIcon, handler }) => {
    return (
      <div className="dd-item" key={item.id}>
        <div className="dd-handle">
          {!isContentGenerated && handler}
          <div className="dd-content">
            <div className={`heading-label heading-label-${item.type}`}>{item.type.toUpperCase()}</div>
            {collapseIcon}
            <div
              className="text"
              contentEditable={!isContentGenerated}
              suppressContentEditableWarning={true}
              onBlur={(e) => {
                if (!isContentGenerated && e.target.textContent !== item.text) {
                  const newStructuredHeadings = structuredHeadings.map((heading) => {
                    if (heading.id === item.id) {
                      return { ...heading, text: e.target.textContent };
                    } else if (heading.children) {
                      return {
                        ...heading,
                        children: heading.children.map((child) => {
                          if (child.id === item.id) {
                            return { ...child, text: e.target.textContent };
                          }
                          return child;
                        }),
                      };
                    }
                    return heading;
                  });
                  setStructuredHeadings(newStructuredHeadings);
                  onChange(newStructuredHeadings);
                }
              }}
            >
              {item.text}
            </div>
            {!isContentGenerated && (
              <button
                className="delete-button"
                onClick={() => {
                  const deleteItem = (headings, id) => {
                    return headings
                      .filter((heading) => heading.id !== id)
                      .map((heading) => {
                        if (heading.children) {
                          return {
                            ...heading,
                            children: deleteItem(heading.children, id),
                          };
                        }
                        return heading;
                      });
                  };

                  const newStructuredHeadings = deleteItem(structuredHeadings, item.id);
                  setStructuredHeadings(newStructuredHeadings);
                  onChange(newStructuredHeadings);
                }}
              >
                <AiOutlineDelete />
              </button>
            )}
          </div>
        </div>
        {!isContentGenerated && item.type === 'h2' && (
          <div className="add-heading-button-wrapper">
            <AddHeadingButton item={item} onAdd={addHeading} />
          </div>
        )}
      </div>
    );
  };

  const Handler = () => (
    <div className="drag-handle">
      <AiOutlineHolder />
    </div>
  );

  const Collapser = ({ isCollapsed }) => (
    <div className="collapse-icon">
      {isCollapsed ? <AiFillCaretRight /> : <AiFillCaretDown />}
    </div>
  );

  const AddHeadingButton = ({ item, onAdd }) => (
    <button className="add-heading-button" onClick={() => onAdd(item)}>
      <AiOutlinePlus />
    </button>
  );

  const addHeading = (item) => {
    const totalHeadings = structuredHeadings.reduce((count, h2) => count + 1 + (h2.children ? h2.children.length : 0), 0);
    const h2Count = structuredHeadings.length;
    const h3Count = item.children ? item.children.length : 0;

    if (totalHeadings >= 25) {
      alert('大見出しと中見出しの合計個数が上限に達しました。これ以上追加できません。');
      return;
    }

    if (item.type === 'h2' && h2Count >= 8) {
      alert('大見出しの個数が上限に達しました。これ以上追加できません。');
      return;
    }

    if (item.type === 'h2' && h3Count >= 10) {
      alert('この大見出し内の中見出しの個数が上限の10に達しました。これ以上追加できません。');
      return;
    }

    const newHeading = {
      id: `${item.type}-${item.id}-${item.children ? item.children.length : 0}`,
      text: '新しい見出し',
      type: item.type === 'h2' ? 'h3' : 'h2',
    };

    const insertHeading = (headings, parentId, heading) => {
      return headings.map((h) => {
        if (h.id === parentId) {
          return {
            ...h,
            children: [...(h.children || []), heading],
          };
        } else if (h.children) {
          return {
            ...h,
            children: insertHeading(h.children, parentId, heading),
          };
        }
        return h;
      });
    };

    const newStructuredHeadings = insertHeading(structuredHeadings, item.id, newHeading);
    setStructuredHeadings(newStructuredHeadings);
    onChange(newStructuredHeadings);
  };

  return (
    <Nestable
      items={structuredHeadings}
      renderItem={renderItem}
      handler={!isContentGenerated ? <Handler /> : null}
      renderCollapseIcon={({ isCollapsed }) => <Collapser isCollapsed={isCollapsed} />}
      onChange={(newStructuredHeadings) => {
        if (!isContentGenerated) {
          const updateItemType = (item, parentItem) => {
            if (!parentItem) {
              item.type = 'h2';
            } else {
              item.type = 'h3';
            }
            if (item.children) {
              item.children.forEach(child => updateItemType(child, item));
            }
            return item;
          };
        
          let updatedStructuredHeadings;
          if (Array.isArray(newStructuredHeadings)) {
            updatedStructuredHeadings = newStructuredHeadings.map(item => updateItemType(item, null));
          } else if (newStructuredHeadings.items) {
            updatedStructuredHeadings = newStructuredHeadings.items.map(item => updateItemType(item, null));
          } else {
            updatedStructuredHeadings = updateItemType(newStructuredHeadings, null);
            updatedStructuredHeadings = [updatedStructuredHeadings];
          }

          setStructuredHeadings(updatedStructuredHeadings);
          onChange(updatedStructuredHeadings);
        }
      }}
      maxDepth={2}
      disabled={isContentGenerated}
    />
  );
};

export default StructuredHeadings;
