// frontend/src/components/SEOArticle/ExportButton.js

import React, { useState } from 'react';
import { Button, Modal, Progress, Space, message, Radio, Tooltip } from 'antd';
import {
  DownloadOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  ExportOutlined,
} from '@ant-design/icons';

const ExportButton = ({ article, userPlan }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [exportFormat, setExportFormat] = useState('excel');
  const [exportProgress, setExportProgress] = useState(0);
  const [exporting, setExporting] = useState(false);

  // エクスポート機能が利用可能なプラン
  const exportAllowedPlans = [
    'premium',
    'pro',
    'custom', // レガシープラン
    'standard_new', // 新プラン
    'premium_new', // 新プラン
  ];

  const canExport = exportAllowedPlans.includes(userPlan);

  const startExport = async () => {
    setExporting(true);
    setExportProgress(0);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/seo-article/export/${article.uuid}?format=${exportFormat}`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Export failed');
      }

      const blob = await response.blob();
      const filename =
        response.headers
          .get('content-disposition')
          ?.split('filename=')[1]
          ?.replace(/["]/g, '') ||
        `export-${article.uuid}.${exportFormat === 'excel' ? 'xlsx' : 'pdf'}`;

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      message.success('エクスポートが完了しました');
      setIsModalVisible(false);
    } catch (error) {
      message.error('エクスポートに失敗しました');
      console.error('Export error:', error);
    } finally {
      setExporting(false);
      setExportProgress(0);
    }
  };

  return (
    <>
      <Tooltip
        title={
          !canExport
            ? 'エクスポート機能はスタンダード以上のプランでご利用いただけます。'
            : ''
        }
      >
        <div style={{ display: 'inline-block' }}>
          <Button
            icon={<DownloadOutlined />}
            onClick={() => setIsModalVisible(true)}
            disabled={!canExport}
          >
            生成結果をエクスポート
          </Button>
        </div>
      </Tooltip>

      <Modal
        title={<Space>エクスポート形式の選択</Space>}
        open={isModalVisible}
        onCancel={() => !exporting && setIsModalVisible(false)}
        footer={[
          <Button
            key="cancel"
            onClick={() => setIsModalVisible(false)}
            disabled={exporting}
          >
            キャンセル
          </Button>,
          <Button
            key="export"
            type="primary"
            onClick={startExport}
            loading={exporting}
            icon={
              exportFormat === 'excel' ? (
                <FileExcelOutlined />
              ) : (
                <FilePdfOutlined />
              )
            }
          >
            {exporting ? 'エクスポート中...' : 'エクスポートする'}
          </Button>,
        ]}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <Radio.Group
            value={exportFormat}
            onChange={e => setExportFormat(e.target.value)}
            style={{ width: '100%' }}
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              <Radio.Button
                value="excel"
                style={{ width: '100%', height: 'auto', padding: '16px' }}
              >
                <Space align="start">
                  <FileExcelOutlined
                    style={{ fontSize: '24px', color: '#217346' }}
                  />
                  <div>
                    <div style={{ fontWeight: 'bold' }}>Excel形式 (.xlsx)</div>
                    <div style={{ fontSize: '12px', color: '#666' }}>
                      タブごとにシートが分かれ、各種分析と構成案までを出力
                      <br />
                    </div>
                  </div>
                </Space>
              </Radio.Button>

              <Radio.Button
                value="pdf"
                style={{ width: '100%', height: 'auto', padding: '16px' }}
              >
                <Space align="start">
                  <FilePdfOutlined
                    style={{ fontSize: '24px', color: '#ff4d4f' }}
                  />
                  <div>
                    <div style={{ fontWeight: 'bold' }}>PDF形式 (.pdf)</div>
                    <div style={{ fontSize: '12px', color: '#666' }}>
                      画面表示と同じレイアウトで、印刷に適した形式で出力
                    </div>
                  </div>
                </Space>
              </Radio.Button>
            </Space>
          </Radio.Group>

          {exporting && (
            <div style={{ marginTop: '20px' }}>
              <Progress
                percent={exportProgress}
                status="active"
                strokeColor={{
                  '0%': '#108ee9',
                  '100%': '#87d068',
                }}
              />
              <div
                style={{ textAlign: 'center', marginTop: '8px', color: '#666' }}
              >
                {exportFormat === 'excel' ? 'Excelファイル' : 'PDFファイル'}
                を生成しています...
              </div>
            </div>
          )}
        </Space>
      </Modal>
    </>
  );
};

export default ExportButton;
