import moment from 'moment-timezone';

export const formatDateTime = (date, options = {}) => {
  const {
    includeSeconds = false,
    timezone = 'Asia/Tokyo',
    format = undefined,
  } = options;

  if (!date) return '';

  const m = moment(date).tz(timezone);

  if (format) {
    return m.format(format);
  }

  return m.format(
    includeSeconds ? 'YYYY年M月D日 HH:mm:ss' : 'YYYY年M月D日 HH:mm',
  );
};

export const formatRelativeTime = (date, timezone = 'Asia/Tokyo') => {
  if (!date) return '';

  const m = moment(date).tz(timezone);
  const now = moment().tz(timezone);
  const diffHours = now.diff(m, 'hours');

  if (diffHours < 24) {
    return m.fromNow(); // ○時間前
  }

  return formatDateTime(date, { timezone });
};

// 実行中タスクの残り時間を計算
export const calculateRemainingTime = (startTime, progress) => {
  if (!startTime || progress >= 100) return null;

  const now = moment();
  const start = moment(startTime);
  const elapsed = now.diff(start, 'seconds');

  if (progress <= 0 || elapsed <= 0) return null;

  // 現在の進捗から完了までの推定時間を計算
  const estimatedTotal = (elapsed * 100) / progress;
  const remaining = estimatedTotal - elapsed;

  if (remaining <= 0) return null;

  // 残り時間を分単位で返す（最小1分）
  return Math.max(1, Math.ceil(remaining / 60));
};
