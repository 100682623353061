import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Form,
  Input,
  Button,
  message,
  Breadcrumb,
  Modal,
  Typography,
  Divider,
  Row,
  Col,
  Table,
  Segmented,
  Badge,
  Card,
  Tag,
  AutoComplete,
  Tabs,
  Alert,
  Progress,
  Spin,
} from 'antd';
import { Tooltip as AntTooltip } from 'antd';
import {
  FireOutlined,
  InfoCircleOutlined,
  FileSearchOutlined,
  AuditOutlined,
  CheckCircleOutlined,
  ThunderboltOutlined,
  QuestionCircleOutlined,
  FilterOutlined,
  SearchOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { getUserProfile } from '../../auth';
import moment from 'moment-timezone';
import { debounce } from 'lodash';
import { trackEvent } from '../../utils/ga4';
import './Generate.css';

const { Title, Paragraph, Text } = Typography;

const SEOArticleGenerator = () => {
  const [form] = Form.useForm();
  const [queueData, setQueueData] = useState({
    running: [],
    queued: [],
    completed: [],
  });
  const [activeTab, setActiveTab] = useState('queued');
  const [selectedQueue, setSelectedQueue] = useState(null);
  const location = useLocation();
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [recommendedKeywords, setRecommendedKeywords] = useState([]);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [existingArticle, setExistingArticle] = useState(null);
  const [simpleAnalysisLoading, setSimpleAnalysisLoading] = useState(false);
  const [simpleAnalysisResult, setSimpleAnalysisResult] = useState(null);
  const [simpleAnalysisVisible, setSimpleAnalysisVisible] = useState(false);
  const [remainingAnalysisCount, setRemainingAnalysisCount] = useState(null);
  const [dailyAnalysisLimit, setDailyAnalysisLimit] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    fetchQueueData();
    fetchRecommendedKeywords();
    fetchRemainingAnalysisCount();
    console.log('初期化時に残り回数を取得しました');
    const timer = setInterval(fetchQueueData, 10000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (location.state && location.state.keyword) {
      form.setFieldsValue({ keyword: location.state.keyword });
    }
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [location.state, form]);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIfMobile();

    window.addEventListener('resize', checkIfMobile);

    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  const fetchQueueData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/seo-article/user-queue`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      console.log('User Queue Data:', response.data);
      setQueueData(response.data);
    } catch (error) {
      console.error('Failed to fetch queue data:', error);
      setQueueData({ running: [], queued: [], completed: [] });
    }
  };

  const fetchRecommendedKeywords = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/seo-article/keywords-recommend`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      );
      setRecommendedKeywords(response.data.keywords || []); // 空の配列をデフォルト値として設定
    } catch (error) {
      console.error('Failed to fetch recommended keywords:', error);
      setRecommendedKeywords([]); // エラー時は空の配列を設定
    }
  };

  const fetchAutoCompleteKeywords = useCallback(
    debounce(async searchText => {
      if (searchText.length < 1) {
        setAutoCompleteOptions([]);
        return;
      }
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/seo-article/autocomplete-keywords?query=${encodeURIComponent(searchText)}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        );
        setAutoCompleteOptions(
          response.data.map(keyword => ({ value: keyword })),
        );
      } catch (error) {
        console.error('Failed to fetch autocomplete keywords:', error);
        setAutoCompleteOptions([]);
      }
    }, 300),
    [],
  );

  const fetchRemainingAnalysisCount = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/seo-article/remaining-analysis-count`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      );
      console.log('残り回数API レスポンスデータ:', response.data);

      if (response.data) {
        setRemainingAnalysisCount(response.data.remaining_count);
        setDailyAnalysisLimit(response.data.daily_limit);
      } else {
        // APIエラー時はデフォルト値を設定
        console.error('残り回数API エラー: データなし');
        // プレミアムプランのデフォルト値を設定
        setRemainingAnalysisCount(10);
        setDailyAnalysisLimit(10);
      }
    } catch (error) {
      console.error('残り簡易分析回数の取得に失敗しました', error);
      // エラー時もデフォルト値を設定
      setRemainingAnalysisCount(10);
      setDailyAnalysisLimit(10);
    }
  };

  const onFinish = async values => {
    try {
      const userProfile = await getUserProfile();

      if (!userProfile.user_id) {
        message.error('ユーザー情報の取得に失敗しました');
        return;
      }

      if (userProfile.ticket_count < 10) {
        message.error('チケットが不足しています。');
        return;
      }

      // 既存の記事をチェック
      const checkResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/seo-article/check-existing`,
        { keyword: values.keyword },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      );

      if (checkResponse.data.exists) {
        Modal.confirm({
          title: '既に同じキーワードで生成しています',
          content: (
            <div>
              <p>
                キーワード：
                <a
                  href={`/seo-article/detail/${checkResponse.data.article.uuid}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {checkResponse.data.article.keyword}
                </a>
              </p>
              <p>
                生成実行日：
                {checkResponse.data.article.execution_date
                  ? new Date(
                      checkResponse.data.article.execution_date,
                    ).toLocaleDateString()
                  : '日付不明'}
              </p>
            </div>
          ),
          okText: '新たに生成する',
          cancelText: 'キャンセル',
          onOk() {
            generateArticle(values);
          },
        });
      } else {
        Modal.confirm({
          title: '新規生成の確認',
          content: (
            <div>
              <p>この操作では各種データと構成案まで生成します。</p>
              <p>
                <strong
                  style={{
                    fontSize: '1.5em',
                    fontWeight: 'bold',
                    background:
                      'linear-gradient(90deg, #0072ff 0%, #00d4ff 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  10
                </strong>
                枚のチケットを消費しますがよろしいですか？
              </p>
            </div>
          ),
          onOk: () => generateArticle(values),
          onCancel: () => {},
        });
      }
    } catch (error) {
      console.error('Failed to check existing article:', error);
      message.error('記事の確認に失敗しました');
    }
  };

  const generateArticle = async values => {
    const userProfile = await getUserProfile();

    if (!userProfile.user_id) {
      message.error('ユーザー情報の取得に失敗しました');
      return;
    }

    try {
      const token = localStorage.getItem('token');

      values.keyword = values.keyword.replace(/　/g, ' ').replace(/\s+$/g, '');

      trackEvent('start_article_generation', {
        keyword: values.keyword,
        user_id: userProfile.user_id,
        ticket_count: userProfile.ticket_count,
      });

      console.log('Sending generate request with:', values);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/seo-article/generate`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      console.log('Generate response:', response);

      if (response.data.success) {
        form.resetFields();
        await fetchQueueData();
        message.success('新規生成を開始しました');
      } else {
        message.error(
          '生成リクエストの送信に失敗しました: ' +
            (response.data.message || '不明なエラー'),
        );
      }
    } catch (error) {
      console.error('Failed to generate article:', error);
      message.error(
        '生成リクエストの送信に失敗しました: ' +
          (error.response?.data?.detail || error.message),
      );
      await fetchQueueData();
    }
  };

  const handleRecommendedKeywordClick = keyword => {
    form.setFieldsValue({ keyword });
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const getBadgeColor = type => {
    switch (type) {
      case 'queued':
        return '#faad14';
      case 'running':
        return '#1890ff';
      case 'completed':
        return '#00d4ff';
      case 'failed':
        return '#f5222d';
      default:
        return '#d9d9d9';
    }
  };

  const handleRegenerate = async record => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/seo-article/regenerate/${record.uuid}`,
        {
          task_type: record.type,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      );

      if (response.status === 200 && response.data.message) {
        message.success(response.data.message);
        await fetchQueueData();
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error('Failed to regenerate task:', error);
      if (error.response && error.response.status === 200) {
        message.success('タスクの再実行が予約されました');
        await fetchQueueData();
      } else {
        message.error(
          'タスクの再実行に失敗しました: ' +
            (error.response?.data?.detail || error.message),
        );
      }
    }
  };

  const renderBadge = (count, type) => {
    if (count === 0) return null;
    return (
      <Badge
        count={count}
        style={{
          backgroundColor: getBadgeColor(type),
          marginLeft: '1px',
          marginTop: '-10px',
          transform: 'scale(0.8)',
        }}
      />
    );
  };

  const handleCancelQueue = async () => {
    if (!selectedQueue || !selectedQueue.id) {
      message.error('キャンセルする予約が選択されていません');
      return;
    }

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/seo-article/cancel-queue/${selectedQueue.id}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      );
      message.success(response.data.message);
      setSelectedQueue(null);
      await fetchQueueData();
    } catch (error) {
      console.error('Failed to cancel queue:', error);
      message.error(
        'キャンセルに失敗しました: ' +
          (error.response?.data?.detail || error.message),
      );
    }
  };

  const showRegenModal = record => {
    Modal.confirm({
      title: 'タスクの再実行',
      content: `「${record.keyword}」のタスクを再実行しますか？ ${getTicketCost(record.type)}枚のチケットが消費されます。`,
      onOk: () => handleRegenerate(record),
    });
  };

  const getTicketCost = type => {
    switch (type) {
      case 'generate':
        return 10;
      default:
        return 0;
    }
  };

  const columns = [
    {
      title:
        activeTab === 'queued'
          ? '予想待ち時間'
          : activeTab === 'running'
            ? '開始時間'
            : '完了時間',
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => {
        if (activeTab === 'queued') {
          return record.estimated_wait_time != null
            ? `${record.estimated_wait_time}分後`
            : '計算中';
        } else if (activeTab === 'completed' && record.status === 'failed') {
          return (
            <span>
              失敗
              {record.type === 'generate' && (
                <Button
                  size="small"
                  onClick={e => {
                    e.stopPropagation();
                    showRegenModal(record);
                  }}
                  style={{ marginLeft: '8px' }}
                >
                  再実行
                </Button>
              )}
            </span>
          );
        } else {
          return text
            ? moment.utc(text).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm')
            : '-';
        }
      },
    },
    {
      title: 'キーワード',
      dataIndex: 'keyword',
      key: 'keyword',
      render: (text, record) =>
        activeTab === 'completed' && record.status !== 'failed' ? (
          <Link to={`/seo-article/detail/${record.uuid}`}>{text}</Link>
        ) : (
          text
        ),
    },
    {
      title: '内容',
      dataIndex: 'type',
      key: 'type',
      render: text => {
        let content = '';
        switch (text) {
          case 'generate':
            content = '記事生成';
            break;
          case 'check_topics':
            content = 'トピックチェック';
            break;
          case 'generate_content':
            content = '本文生成';
            break;
          default:
            content = text;
        }
        return <Tag>{content}</Tag>;
      },
    },
  ];

  const getCompletedCount = () => queueData.completed.length;
  const getFailedCount = () =>
    queueData.completed.filter(job => job.status === 'failed').length;

  const completedCount = getCompletedCount();
  const failedCount = getFailedCount();

  const handleSimpleAnalysis = async () => {
    const keyword = form.getFieldValue('keyword');
    if (!keyword) {
      message.error('キーワードを入力してください');
      return;
    }

    setSimpleAnalysisLoading(true);
    setSimpleAnalysisVisible(true);
    setSimpleAnalysisResult(null);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/seo-article/simple-analysis`,
        { keyword },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      );

      if (response.data.error) {
        message.error(response.data.message);
        setSimpleAnalysisResult(null);
      } else {
        console.log('API response:', response.data); // デバッグ用
        setSimpleAnalysisResult(response.data.result);
        // 残り回数を更新
        setRemainingAnalysisCount(response.data.remaining);
        setDailyAnalysisLimit(response.data.limit);
      }
    } catch (error) {
      console.error('Simple analysis error:', error);
      message.error(
        '分析中にエラーが発生しました。しばらく経ってからお試しください。',
      );
    } finally {
      setSimpleAnalysisLoading(false);
    }
  };

  // 評価スコアの表示用スタイル
  const getScoreColor = score => {
    if (!score) return '#d9d9d9'; // データがない場合
    if (score >= 5) return '#0072ff'; // 高スコア: 青
    if (score >= 4) return '#52c41a'; // 高スコア: 緑
    if (score >= 3) return '#faad14'; // 中スコア: オレンジ
    return '#f5222d'; // 低スコア: 赤
  };

  // 評価スコアの表示コンポーネント
  const ScoreDisplay = ({ label, score, description }) => (
    <AntTooltip title={description}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        <div
          style={{
            width: '160px',
            fontWeight: 'bold',
            fontSize: '13px',
            textAlign: 'right',
          }}
        >
          {label}：
        </div>
        <div
          style={{
            flex: 1,
            marginRight: '10px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {simpleAnalysisLoading ? (
            <div
              style={{
                width: '100%',
                height: '8px',
                backgroundColor: '#f0f0f0',
                borderRadius: '4px',
              }}
            ></div>
          ) : (
            <Progress
              percent={score ? score * 20 : 0}
              strokeColor={getScoreColor(score)}
              showInfo={false}
              size="small"
              style={{ margin: 0, width: '100%' }}
            />
          )}
        </div>
        <div
          style={{
            width: '40px',
            textAlign: 'right',
            fontWeight: 'bold',
            fontSize: '13px',
          }}
        >
          {simpleAnalysisLoading ? '-/-' : score ? `${score}/5` : '-/-'}
        </div>
      </div>
    </AntTooltip>
  );

  // 検索意図の表示コンポーネント
  const IntentDisplay = ({ intent, index }) => {
    if (!intent && !simpleAnalysisLoading) return null;
    return (
      <div style={{ marginBottom: '12px' }}>
        {simpleAnalysisLoading ? (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
            <div
              style={{
                height: '16px',
                width: '80%',
                backgroundColor: '#f0f0f0',
                marginBottom: '6px',
                borderRadius: '4px',
              }}
            ></div>
            <div
              style={{
                height: '14px',
                width: '90%',
                backgroundColor: '#f0f0f0',
                borderRadius: '4px',
              }}
            ></div>
            <div
              style={{
                height: '14px',
                width: '85%',
                backgroundColor: '#f0f0f0',
                borderRadius: '4px',
              }}
            ></div>
          </div>
        ) : (
          <>
            <div
              style={{
                fontWeight: 'bold',
                fontSize: '14px',
                marginBottom: '3px',
              }}
            >
              {index}. {intent.subject}
            </div>
            <div
              style={{ color: '#666', fontSize: '13px', paddingLeft: '14px' }}
            >
              {intent.description}
            </div>
          </>
        )}
      </div>
    );
  };

  // 新規生成を予約するハンドラー
  const handleScheduleGeneration = () => {
    setSimpleAnalysisVisible(false);
    form.setFieldsValue({ keyword: form.getFieldValue('keyword') });
    onFinish(form.getFieldsValue());
  };

  // 見出しスタイル
  const headingStyle = {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#444',
    marginBottom: '12px',
  };

  const renderSimpleAnalysisModal = () => {
    return (
      <Modal
        title={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span>{`「${form.getFieldValue('keyword')}」の簡易分析結果`}</span>
            <span
              style={{
                fontSize: '13px',
                color: '#1890ff',
                fontWeight: 'normal',
                marginRight: '24px',
              }}
            >
              本日の残り:{' '}
              {remainingAnalysisCount !== null ? remainingAnalysisCount : '?'}/
              {dailyAnalysisLimit !== null ? dailyAnalysisLimit : '?'}回
            </span>
          </div>
        }
        open={simpleAnalysisVisible}
        onCancel={() => setSimpleAnalysisVisible(false)}
        footer={
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {!simpleAnalysisLoading && (
              <Button
                key="schedule"
                type="primary"
                onClick={handleScheduleGeneration}
                icon={<FireOutlined />}
                className="submit-button"
                style={{ marginRight: '8px', borderRadius: '50px' }}
              >
                新規生成を予約する
              </Button>
            )}
            <Button
              key="close"
              onClick={() => setSimpleAnalysisVisible(false)}
              style={{ minWidth: '80px', height: '40px' }}
            >
              閉じる
            </Button>
          </div>
        }
        width={700}
        bodyStyle={{
          maxHeight: '64vh',
          overflowY: 'auto',
          overflowX: 'hidden',
          scrollbarWidth: 'thin',
          scrollbarColor: '#e6e6e6 transparent',
          msOverflowStyle: 'none',
          position: 'relative',
        }}
        style={{
          '&::-webkit-scrollbar': {
            width: '6px',
            height: '6px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#e6e6e6',
            borderRadius: '3px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#d9d9d9',
          },
        }}
      >
        {simpleAnalysisLoading && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 10,
              textAlign: 'center',
            }}
          >
            <Spin size="large" />
            <div style={{ marginTop: '10px', color: '#1890ff' }}>
              分析中...このまま10秒ほどお待ちください...
            </div>
          </div>
        )}
        <div
          style={{
            marginBottom: '20px',
            opacity: simpleAnalysisLoading ? 0.5 : 1,
          }}
        >
          <Divider />
          <div style={headingStyle}>評価スコア</div>
          <div
            style={{
              marginBottom: '20px',
              padding: '15px',
              backgroundColor: '#f9f9f9',
              borderRadius: '4px',
            }}
          >
            {simpleAnalysisResult ? (
              <>
                <ScoreDisplay
                  label="検索意図の鮮明性"
                  score={simpleAnalysisResult.intention}
                  description="ユーザーの検索意図がどれだけ明確かを示します。高いほど明確な目的を持った検索キーワードです。"
                />
                <ScoreDisplay
                  label="悩みの深さ"
                  score={simpleAnalysisResult.depth_of_worry}
                  description="ユーザーの抱える問題や悩みの深刻度を表します。高いほど解決への意欲が強いことを示します。"
                />
                <ScoreDisplay
                  label="緊急性"
                  score={simpleAnalysisResult.urgency}
                  description="ユーザーがどれだけ早く解決を求めているかを示します。高いほど即時の解決策を求めていることを示します。"
                />
                <ScoreDisplay
                  label="コンバージョン近接度"
                  score={simpleAnalysisResult.distance_of_conversion}
                  description="ユーザーが購入や申し込みなどのコンバージョンにどれだけ近いかを示します。高いほど成約に近いことを示します。"
                />
                <ScoreDisplay
                  label="分析精度"
                  score={simpleAnalysisResult.certainty}
                  description="AIによる分析の確信度を表します。高いほど分析結果の信頼性が高いことを示します。"
                />
                <ScoreDisplay
                  label="magicss相性"
                  score={simpleAnalysisResult.affinity}
                  description="magicssによる記事作成との相性を表します。高いほどmagicssが質の高い記事を生成できる可能性が高いことを示します。"
                />
              </>
            ) : (
              <>
                <ScoreDisplay
                  label="検索意図の鮮明性"
                  description="ユーザーの検索意図がどれだけ明確かを示します。高いほど明確な目的を持った検索キーワードです。"
                />
                <ScoreDisplay
                  label="悩みの深さ"
                  description="ユーザーの抱える問題や悩みの深刻度を表します。高いほど解決への意欲が強いことを示します。"
                />
                <ScoreDisplay
                  label="緊急性"
                  description="ユーザーがどれだけ早く解決を求めているかを示します。高いほど即時の解決策を求めていることを示します。"
                />
                <ScoreDisplay
                  label="コンバージョン近接度"
                  description="ユーザーが購入や申し込みなどのコンバージョンにどれだけ近いかを示します。高いほど成約に近いことを示します。"
                />
                <ScoreDisplay
                  label="分析精度"
                  description="AIによる分析の確信度を表します。高いほど分析結果の信頼性が高いことを示します。"
                />
                <ScoreDisplay
                  label="magicss相性"
                  description="magicssによる記事作成との相性を表します。高いほどmagicssが質の高い記事を生成できる可能性が高いことを示します。"
                />
              </>
            )}
          </div>

          <Divider />
          <div style={headingStyle}>検索意図分析</div>
          <div
            style={{
              marginBottom: '20px',
              padding: '15px',
              backgroundColor: '#f9f9f9',
              borderRadius: '4px',
            }}
          >
            {simpleAnalysisLoading ? (
              <>
                <IntentDisplay index={1} />
                <IntentDisplay index={2} />
                <IntentDisplay index={3} />
                <IntentDisplay index={4} />
                <IntentDisplay index={5} />
              </>
            ) : simpleAnalysisResult ? (
              <>
                <IntentDisplay
                  intent={simpleAnalysisResult.intent1}
                  index={1}
                />
                <IntentDisplay
                  intent={simpleAnalysisResult.intent2}
                  index={2}
                />
                <IntentDisplay
                  intent={simpleAnalysisResult.intent3}
                  index={3}
                />
                <IntentDisplay
                  intent={simpleAnalysisResult.intent4}
                  index={4}
                />
                <IntentDisplay
                  intent={simpleAnalysisResult.intent5}
                  index={5}
                />
              </>
            ) : (
              <>
                <IntentDisplay index={1} />
                <IntentDisplay index={2} />
                <IntentDisplay index={3} />
              </>
            )}
          </div>

          <Divider />
          <div style={headingStyle}>コンバージョン分析</div>
          <Row gutter={16} style={{ marginBottom: '15px' }}>
            <Col span={12}>
              <AntTooltip title="このキーワードで検索する人が自然な流れでコンバージョンしやすい商品やサービスの案です。">
                <div
                  style={{
                    padding: '15px',
                    backgroundColor: '#f9f9f9',
                    borderRadius: '4px',
                    height: '100%',
                  }}
                >
                  <div
                    style={{
                      fontWeight: 'bold',
                      marginBottom: '5px',
                      fontSize: '13px',
                    }}
                  >
                    主要コンバージョン:
                  </div>
                  {simpleAnalysisLoading ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                      }}
                    >
                      <div
                        style={{
                          height: '14px',
                          width: '90%',
                          backgroundColor: '#f0f0f0',
                          borderRadius: '4px',
                        }}
                      ></div>
                      <div
                        style={{
                          height: '14px',
                          width: '80%',
                          backgroundColor: '#f0f0f0',
                          borderRadius: '4px',
                        }}
                      ></div>
                      <div
                        style={{
                          height: '14px',
                          width: '60%',
                          backgroundColor: '#f0f0f0',
                          borderRadius: '4px',
                        }}
                      ></div>
                    </div>
                  ) : simpleAnalysisResult ? (
                    <div
                      style={{
                        fontSize: '14px',
                        color: '#333',
                        wordBreak: 'break-word',
                      }}
                    >
                      {simpleAnalysisResult.conversion}
                    </div>
                  ) : (
                    <div
                      style={{
                        height: '60px',
                        backgroundColor: '#f0f0f0',
                        borderRadius: '4px',
                      }}
                    ></div>
                  )}
                </div>
              </AntTooltip>
            </Col>
            <Col span={12}>
              <AntTooltip title="主要コンバージョンの他に、訴求次第ではコンバージョンに繋がりそうな商品やサービスの案です。">
                <div
                  style={{
                    padding: '15px',
                    backgroundColor: '#f9f9f9',
                    borderRadius: '4px',
                    height: '100%',
                  }}
                >
                  <div
                    style={{
                      fontWeight: 'bold',
                      marginBottom: '5px',
                      fontSize: '13px',
                    }}
                  >
                    副次的コンバージョン:
                  </div>
                  {simpleAnalysisLoading ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                      }}
                    >
                      <div
                        style={{
                          height: '14px',
                          width: '85%',
                          backgroundColor: '#f0f0f0',
                          borderRadius: '4px',
                        }}
                      ></div>
                      <div
                        style={{
                          height: '14px',
                          width: '75%',
                          backgroundColor: '#f0f0f0',
                          borderRadius: '4px',
                        }}
                      ></div>
                      <div
                        style={{
                          height: '14px',
                          width: '65%',
                          backgroundColor: '#f0f0f0',
                          borderRadius: '4px',
                        }}
                      ></div>
                    </div>
                  ) : simpleAnalysisResult ? (
                    <div
                      style={{
                        fontSize: '14px',
                        color: '#333',
                        wordBreak: 'break-word',
                      }}
                    >
                      {simpleAnalysisResult.sub_conversion}
                    </div>
                  ) : (
                    <div
                      style={{
                        height: '60px',
                        backgroundColor: '#f0f0f0',
                        borderRadius: '4px',
                      }}
                    ></div>
                  )}
                </div>
              </AntTooltip>
            </Col>
          </Row>
        </div>
      </Modal>
    );
  };

  return (
    <>
      <Helmet>
        <title>新規生成 | SEO記事生成モード - magicss</title>
        <meta name="robots" content="noindex" />
        <meta
          property="og:title"
          content={`新規生成 | SEO記事生成モード - magicss`}
        />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/ogp.png`}
        />
        <meta
          property="og:description"
          content={`コンテンツ生成AIツール「magicss」の新規生成ページです。`}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>SEO記事生成</Breadcrumb.Item>
      </Breadcrumb>

      <Title level={1} className="gradient-text" style={{ fontSize: '24px' }}>
        新規生成を予約する
      </Title>
      <div
        style={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: isMobile ? 'flex-start' : 'center',
          marginBottom: '16px',
          gap: isMobile ? '8px' : 0,
        }}
      >
        <Text
          style={{
            color: '#6d8eb7',
            fontSize: isMobile ? '12px' : '14px',
            whiteSpace: isMobile ? 'nowrap' : 'normal',
            overflow: isMobile ? 'hidden' : 'visible',
            textOverflow: isMobile ? 'ellipsis' : 'clip',
            maxWidth: isMobile ? '100%' : 'auto',
          }}
        >
          SEO記事の生成はキーワード入力して予約→順次処理→実行開始から10分程で完了します。
        </Text>
        <Button
          type="link"
          icon={<QuestionCircleOutlined />}
          onClick={() => {
            Modal.info({
              title: 'SEO記事生成モードについて',
              width: 700,
              maskClosable: true,
              content: (
                <div
                  style={{
                    marginTop: '16px',
                    maxHeight: 'calc(80vh - 100px)',
                    overflow: 'auto',
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#e6e6e6 transparent',
                    msOverflowStyle: 'none',
                    '&::-webkit-scrollbar': {
                      width: '6px',
                      height: '6px',
                    },
                    '&::-webkit-scrollbar-track': {
                      background: 'transparent',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: '#e6e6e6',
                      borderRadius: '3px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                      background: '#d9d9d9',
                    },
                  }}
                >
                  <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="概要" key="1">
                      <div style={{ padding: '8px 0' }}>
                        <p>
                          SEO記事生成モードは、指定したキーワードに基づいて検索上位を狙える高品質なSEO記事を自動生成する機能です。
                          <span
                            style={{ color: '#faad14', fontWeight: 'bold' }}
                          >
                            数百回のプロンプト実行と10種類のLLMモデル
                          </span>
                          を駆使してオープンデータの解析や競合分析、情報の咀嚼、キーワード分析から構成案作成を行います。
                        </p>
                        <p>
                          一回目の実行で各種分析と構成案まで完了し、その後構成案を確認、手動で調整し、膨大なソースを元にした本文を生成します。
                        </p>
                        <div
                          style={{
                            textAlign: 'center',
                            margin: '16px 0',
                          }}
                        >
                          <video
                            src="/video/about_seo.mp4"
                            controls
                            autoPlay
                            muted
                            loop
                            style={{
                              width: '100%',
                              maxWidth: '600px',
                              borderRadius: '8px',
                              border: '1px solid #f0f0f0',
                            }}
                          />
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '16px',
                          }}
                        >
                          <div
                            style={{
                              width: '40px',
                              height: '40px',
                              borderRadius: '50%',
                              background: '#f0f0f0',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              marginRight: '12px',
                              fontSize: '20px',
                              color: '#1890ff',
                            }}
                          >
                            <FileSearchOutlined />
                          </div>
                          <div>
                            <div
                              style={{
                                fontWeight: '500',
                                marginBottom: '4px',
                              }}
                            >
                              主な特徴
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '12px',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  background: '#f6f8ff',
                                  padding: '10px',
                                  borderRadius: '8px',
                                  border: '1px solid #e6f0ff',
                                }}
                              >
                                <div
                                  style={{
                                    width: '36px',
                                    height: '36px',
                                    borderRadius: '50%',
                                    background: '#e6f0ff',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginRight: '12px',
                                    color: '#1890ff',
                                  }}
                                >
                                  <FileSearchOutlined
                                    style={{ fontSize: '18px' }}
                                  />
                                </div>
                                <div>
                                  <div
                                    style={{
                                      fontWeight: '500',
                                      fontSize: '14px',
                                      marginBottom: '2px',
                                    }}
                                  >
                                    高度なキーワード分析
                                  </div>
                                  <div
                                    style={{
                                      fontSize: '12px',
                                      color: 'rgba(0, 0, 0, 0.65)',
                                    }}
                                  >
                                    検索意図や競合分析に基づく最適なコンテンツ戦略を提案
                                  </div>
                                </div>
                              </div>

                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  background: '#f6fff8',
                                  padding: '10px',
                                  borderRadius: '8px',
                                  border: '1px solid #e6ffe6',
                                }}
                              >
                                <div
                                  style={{
                                    width: '36px',
                                    height: '36px',
                                    borderRadius: '50%',
                                    background: '#e6ffe6',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginRight: '12px',
                                    color: '#52c41a',
                                  }}
                                >
                                  <AuditOutlined style={{ fontSize: '18px' }} />
                                </div>
                                <div>
                                  <div
                                    style={{
                                      fontWeight: '500',
                                      fontSize: '14px',
                                      marginBottom: '2px',
                                    }}
                                  >
                                    SEO最適化された構成案
                                  </div>
                                  <div
                                    style={{
                                      fontSize: '12px',
                                      color: 'rgba(0, 0, 0, 0.65)',
                                    }}
                                  >
                                    検索上位を狙える見出し構成と必須トピックの提案
                                  </div>
                                </div>
                              </div>

                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  background: '#fff8f6',
                                  padding: '10px',
                                  borderRadius: '8px',
                                  border: '1px solid #ffe6e0',
                                }}
                              >
                                <div
                                  style={{
                                    width: '36px',
                                    height: '36px',
                                    borderRadius: '50%',
                                    background: '#ffe6e0',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginRight: '12px',
                                    color: '#fa541c',
                                  }}
                                >
                                  <CheckCircleOutlined
                                    style={{ fontSize: '18px' }}
                                  />
                                </div>
                                <div>
                                  <div
                                    style={{
                                      fontWeight: '500',
                                      fontSize: '14px',
                                      marginBottom: '2px',
                                    }}
                                  >
                                    ソース付き高品質コンテンツ
                                  </div>
                                  <div
                                    style={{
                                      fontSize: '12px',
                                      color: 'rgba(0, 0, 0, 0.65)',
                                    }}
                                  >
                                    信頼性の高い参考文献付きの本文で読者と検索エンジンから高評価
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tabs.TabPane>

                    <Tabs.TabPane tab="キーワードのコツ" key="2">
                      <div style={{ padding: '8px 0' }}>
                        <p>
                          基本的に、検索意図が明確な2語～4語のキーワードが効果的です。記事の制作コストが下がることで、これまで参入しづらかったロングテールキーワードにも展開できることも利点です。
                        </p>
                        <div
                          style={{
                            background: '#f9f9f9',
                            padding: '16px',
                            borderRadius: '8px',
                            marginBottom: '16px',
                            border: '1px solid #f0f0f0',
                          }}
                        >
                          <div
                            style={{
                              fontWeight: '500',
                              marginBottom: '12px',
                              fontSize: '15px',
                            }}
                          >
                            <FilterOutlined
                              style={{
                                color: '#1890ff',
                                marginRight: '8px',
                              }}
                            />
                            相性の良いキーワード
                          </div>
                          <p>
                            LLMモデルの学習範囲（知識）にない情報でもリアルタイムで調べて構成し、本文を生成できるようになりました。これによりある程度新しい情報にも対応できるようになり、ジャンルの得意・不得意もそれほどなくなりました。
                          </p>
                        </div>

                        <div
                          style={{
                            background: '#fff8f6',
                            padding: '16px',
                            borderRadius: '8px',
                            marginBottom: '16px',
                            border: '1px solid #ffe6e0',
                          }}
                        >
                          <div
                            style={{
                              fontWeight: '500',
                              marginBottom: '8px',
                              fontSize: '15px',
                            }}
                          >
                            <InfoCircleOutlined
                              style={{
                                color: '#fa541c',
                                marginRight: '8px',
                              }}
                            />
                            避けた方が良いキーワード
                          </div>
                          <ul
                            style={{ paddingLeft: '20px', marginBottom: '0' }}
                          >
                            <li>
                              極端に新しい話題や製品でWeb上に情報がほとんどない場合
                            </li>
                            <li>非常にニッチで専門的すぎる内容</li>
                            <li>検索意図が不明確なキーワード</li>
                            <li>
                              主観的な内容が軸になるキーワード（例：○○
                              レビューなど）
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Tabs.TabPane>

                    <Tabs.TabPane tab="使い方" key="3">
                      <div style={{ padding: '8px 0' }}>
                        <h3
                          style={{
                            borderBottom: '1px solid #f0f0f0',
                            paddingBottom: '8px',
                            fontSize: '16px',
                          }}
                        >
                          使い方の流れ
                        </h3>
                        <ol style={{ paddingLeft: '20px' }}>
                          <li style={{ marginBottom: '8px' }}>
                            <strong>キーワード入力</strong>
                            ：ターゲットとするキーワードを入力して生成を予約
                          </li>
                          <li style={{ marginBottom: '8px' }}>
                            <strong>自動分析</strong>
                            ：検索意図や競合分析、関連キーワードの抽出
                          </li>
                          <li style={{ marginBottom: '8px' }}>
                            <strong>構成案確認</strong>
                            ：自動生成された見出し構成と必須トピックを確認・編集
                          </li>
                          <li style={{ marginBottom: '8px' }}>
                            <strong>本文生成</strong>
                            ：構成に基づいた高品質な本文を生成
                          </li>
                          <li style={{ marginBottom: '8px' }}>
                            <strong>編集と活用</strong>
                            ：必要に応じて編集し、HTMLやテキストとしてエクスポート
                          </li>
                        </ol>

                        <h3
                          style={{
                            borderBottom: '1px solid #f0f0f0',
                            paddingBottom: '8px',
                            fontSize: '16px',
                            marginTop: '24px',
                          }}
                        >
                          生成後の活用方法
                        </h3>
                        <div
                          style={{
                            background: '#f6f8ff',
                            padding: '12px',
                            borderRadius: '8px',
                            marginBottom: '16px',
                            border: '1px solid #e6f0ff',
                          }}
                        >
                          <div
                            style={{
                              fontWeight: '500',
                              marginBottom: '8px',
                            }}
                          >
                            <FileSearchOutlined
                              style={{
                                color: '#1890ff',
                                marginRight: '8px',
                              }}
                            />
                            エクスポート機能
                          </div>
                          <div style={{ fontSize: '13px' }}>
                            生成した記事はHTML形式やテキスト形式でエクスポートできます。WordPressなどのCMSへの投稿や、さらなる編集のために活用できます。
                          </div>
                        </div>

                        <Alert
                          message={
                            <span
                              style={{
                                fontWeight: '500',
                                fontSize: '14px',
                              }}
                            >
                              重要な注意点
                            </span>
                          }
                          description="生成された記事はあくまで下書きとしてご活用ください。公開前に内容の正確性を確認し、必要に応じて編集することをお勧めします。"
                          type="warning"
                          showIcon
                          style={{ marginTop: '16px', fontSize: '13px' }}
                        />
                      </div>
                    </Tabs.TabPane>

                    <Tabs.TabPane tab="料金と制限" key="4">
                      <div style={{ padding: '8px 0' }}>
                        <h3
                          style={{
                            borderBottom: '1px solid #f0f0f0',
                            paddingBottom: '8px',
                            fontSize: '16px',
                          }}
                        >
                          チケット消費について
                        </h3>
                        <div
                          style={{
                            background: '#f6f8ff',
                            padding: '16px',
                            borderRadius: '8px',
                            marginBottom: '16px',
                            border: '1px solid #e6f0ff',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginBottom: '12px',
                            }}
                          >
                            <div
                              style={{
                                width: '40px',
                                height: '40px',
                                borderRadius: '50%',
                                background: '#e6f0ff',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: '12px',
                                fontSize: '20px',
                                color: '#1890ff',
                              }}
                            >
                              <FireOutlined />
                            </div>
                            <div>
                              <div
                                style={{
                                  fontWeight: '500',
                                  fontSize: '16px',
                                }}
                              >
                                初期分析・構成案生成：10チケット
                              </div>
                            </div>
                          </div>
                          <ul
                            style={{ paddingLeft: '20px', marginBottom: '0' }}
                          >
                            <li>キーワード分析</li>
                            <li>検索意図の特定</li>
                            <li>競合・市場分析</li>
                            <li>関連キーワード/トピック抽出</li>
                            <li>記事タイトルの生成</li>
                            <li>最適な見出し構成の生成</li>
                          </ul>
                        </div>

                        <div
                          style={{
                            background: '#f6fff8',
                            padding: '16px',
                            borderRadius: '8px',
                            marginBottom: '16px',
                            border: '1px solid #e6ffe6',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginBottom: '12px',
                            }}
                          >
                            <div
                              style={{
                                width: '40px',
                                height: '40px',
                                borderRadius: '50%',
                                background: '#e6ffe6',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: '12px',
                                fontSize: '20px',
                                color: '#52c41a',
                              }}
                            >
                              <FireOutlined />
                            </div>
                            <div>
                              <div
                                style={{
                                  fontWeight: '500',
                                  fontSize: '16px',
                                }}
                              >
                                本文生成：チケット不要
                              </div>
                            </div>
                          </div>
                          <ul
                            style={{ paddingLeft: '20px', marginBottom: '0' }}
                          >
                            <li>構成に基づいた本文の生成</li>
                            <li>SEO最適化された文章</li>
                            <li>参考ソースの引用</li>
                            <li>アイキャッチ画像の生成</li>
                          </ul>
                        </div>

                        <Alert
                          message="キャンセルポリシー"
                          description="生成の実行開始後はキャンセルできません。何らかの理由で処理が失敗した場合は自動的にチケットが返還されます。"
                          type="info"
                          showIcon
                          style={{ marginBottom: '16px', fontSize: '13px' }}
                        />

                        <h3
                          style={{
                            borderBottom: '1px solid #f0f0f0',
                            paddingBottom: '8px',
                            fontSize: '16px',
                            marginTop: '24px',
                          }}
                        >
                          制限事項
                        </h3>
                        <ul style={{ paddingLeft: '20px' }}>
                          <li style={{ marginBottom: '8px' }}>
                            同一キーワードでの再生成は可能ですが、新たにチケットを消費します
                          </li>
                          <li style={{ marginBottom: '8px' }}>
                            生成される記事の文字数は約5,000〜10,000文字です（見出し数により変動）
                          </li>
                          <li style={{ marginBottom: '8px' }}>
                            アイキャッチ画像は自動生成されますが、本文中の画像は別途追加が必要です
                          </li>
                          <li style={{ marginBottom: '8px' }}>
                            極端に専門的な内容や最新の情報については正確性が低下する場合があります
                          </li>
                        </ul>
                      </div>
                    </Tabs.TabPane>
                  </Tabs>
                </div>
              ),
            });
          }}
          style={{
            padding: isMobile ? '0' : undefined,
            fontSize: isMobile ? '12px' : '14px',
            whiteSpace: isMobile ? 'nowrap' : 'normal',
          }}
        >
          SEO記事生成モードについて
        </Button>
      </div>
      <Divider style={{ margin: '24px 0' }} />

      <Row gutter={40} className="generate-content">
        <Col xs={24} lg={14} className="keyword-input-section">
          <Form
            form={form}
            id="keyword-form"
            style={{ padding: '24px 0' }}
            name="keyword-form"
            onFinish={onFinish}
            layout="vertical"
          >
            <Title level={2} style={{ fontSize: '1em', color: '#00d4ff' }}>
              キーワードを入力
              <AntTooltip title="検索者の意図が明確な2～3単語のキーワードがおすすめ！口コミ/レビューなどの主観的内容、Web上に情報が少ない最新商品等は相性△です。">
                <InfoCircleOutlined
                  style={{ marginLeft: '6px', color: 'rgb(0 212 255)' }}
                />
              </AntTooltip>
              ：
            </Title>
            <Row gutter={0}>
              <Col xs={24} lg={24}>
                <Form.Item
                  name="keyword"
                  rules={[
                    { required: true, message: 'キーワードを入力してください' },
                  ]}
                >
                  <AutoComplete
                    options={autoCompleteOptions}
                    onSearch={fetchAutoCompleteKeywords}
                    onSelect={value => form.setFieldsValue({ keyword: value })}
                  >
                    <Input
                      ref={inputRef}
                      placeholder="キーワードを入力してください"
                      className="keyword-input"
                    />
                  </AutoComplete>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={12} lg={12}>
                <Form.Item>
                  <AntTooltip
                    title={
                      <div>
                        <div
                          style={{ marginBottom: '8px', fontWeight: 'bold' }}
                        >
                          キーワードの検索意図や競合状況を素早く分析します
                        </div>
                        <div style={{ marginBottom: '8px' }}>
                          プランごとの1日の利用制限:
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '4px',
                          }}
                        >
                          <div>
                            <span
                              style={{ color: '#1890ff', marginRight: '5px' }}
                            >
                              ●
                            </span>{' '}
                            フリー: 1回
                          </div>
                          <div>
                            <span
                              style={{ color: '#52c41a', marginRight: '5px' }}
                            >
                              ●
                            </span>{' '}
                            スタンダード: 3回
                          </div>
                          <div>
                            <span
                              style={{ color: '#fa8c16', marginRight: '5px' }}
                            >
                              ●
                            </span>{' '}
                            プロ: 5回
                          </div>
                          <div>
                            <span
                              style={{ color: '#f5222d', marginRight: '5px' }}
                            >
                              ●
                            </span>{' '}
                            プレミアム: 10回
                          </div>
                          <div>
                            <span
                              style={{ color: '#722ed1', marginRight: '5px' }}
                            >
                              ●
                            </span>{' '}
                            カスタム: 15回
                          </div>

                          <div style={{ marginTop: '8px', fontWeight: 'bold' }}>
                            ※チケットは消費されません。
                          </div>
                        </div>
                      </div>
                    }
                  >
                    <Button
                      type="default"
                      onClick={handleSimpleAnalysis}
                      className="simple-analysis-button"
                      style={{ width: '100%', position: 'relative' }}
                      loading={simpleAnalysisLoading}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '100%',
                        }}
                      >
                        <SearchOutlined className="analysis-icon" />
                        <span style={{ marginLeft: '8px' }}>
                          {isMobile ? '簡易分析' : '簡易分析する'}
                        </span>
                      </div>
                      <span
                        className="remaining-count"
                        style={{
                          position: 'absolute',
                          right: '8px',
                          top: isMobile ? '6px' : '50%',
                          transform: isMobile ? 'none' : 'translateY(-50%)',
                          fontSize: isMobile ? '9px' : '12px',
                          lineHeight: isMobile ? '1.2' : 'normal',
                          color: '#888',
                          backgroundColor: 'rgba(255, 255, 255, 0.8)',
                          padding: isMobile ? '1px 4px' : '2px 6px',
                          borderRadius: '10px',
                          display: 'flex',
                          flexDirection: isMobile ? 'column' : 'row',
                          alignItems: 'center',
                        }}
                      >
                        {isMobile ? (
                          <>
                            <span>本日</span>
                            <span>
                              {remainingAnalysisCount !== null
                                ? remainingAnalysisCount
                                : 10}
                              /
                              {dailyAnalysisLimit !== null
                                ? dailyAnalysisLimit
                                : 10}
                              回
                            </span>
                          </>
                        ) : (
                          <>
                            本日
                            {remainingAnalysisCount !== null
                              ? remainingAnalysisCount
                              : 10}
                            /
                            {dailyAnalysisLimit !== null
                              ? dailyAnalysisLimit
                              : 10}
                            回
                          </>
                        )}
                      </span>
                    </Button>
                  </AntTooltip>
                </Form.Item>
              </Col>
              <Col xs={12} lg={12}>
                <Form.Item>
                  <AntTooltip
                    title={
                      <div>
                        <div
                          style={{ marginBottom: '8px', fontWeight: 'bold' }}
                        >
                          キーワードに基づいたSEO記事の生成を予約します
                        </div>
                        <div style={{ marginBottom: '8px' }}>
                          以下の処理が自動的に行われます:
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '4px',
                          }}
                        >
                          <div>
                            <span style={{ marginRight: '5px' }}>★</span>{' '}
                            検索結果の多角的な分析
                          </div>
                          <div>
                            <span style={{ marginRight: '5px' }}>★</span>{' '}
                            深い検索意図の特定
                          </div>
                          <div>
                            <span style={{ marginRight: '5px' }}>★</span>{' '}
                            重要語句/トピックの抽出
                          </div>
                          <div>
                            <span style={{ marginRight: '5px' }}>★</span>{' '}
                            最適な見出し構成の生成
                          </div>
                          <div>
                            <span style={{ marginRight: '5px' }}>★</span>{' '}
                            本文生成（別途実行）
                          </div>
                        </div>
                        <div style={{ marginTop: '8px', fontWeight: 'bold' }}>
                          ※一記事あたり10枚のチケットを消費します。
                        </div>
                      </div>
                    }
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="submit-button"
                      loading={loading}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '100%',
                        }}
                      >
                        <FireOutlined className="submit-icon" />
                        <span style={{ marginLeft: '8px' }}>
                          {isMobile ? '生成を予約' : '新規生成を予約する'}
                        </span>
                      </div>
                    </Button>
                  </AntTooltip>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div className="instructions">
            <Paragraph>
              <ul style={{ lineHeight: 2.0 }}>
                <li>
                  各種分析と構成案を自動生成します。本文はそのあとで生成実行できます。
                </li>
                <li>生成は予約され、優先順に自動実行されます。</li>
                <li>実行が始まるとキャンセルはできません。</li>
                <li>
                  膨大な分析/生成処理を行うため、実行～完了までに10分前後の時間を要します。
                </li>
                <li>画面を開いて待っていただく必要はありません。</li>
                <li>
                  何らかの理由で処理が失敗した場合、チケットは返還されます。
                </li>
                <li>キーワードの全角/半角スペースは同一と見なされます。</li>
              </ul>
            </Paragraph>
          </div>
        </Col>
        <Col xs={24} lg={10} className="status-card-section">
          <Card
            title="あなたの生成状況"
            extra={
              <AntTooltip title="ここにあなたの生成予約状況や実行状況がリアルタイム表示されます。">
                <InfoCircleOutlined
                  style={{ marginLeft: '6px', color: 'rgb(0 212 255)' }}
                />
              </AntTooltip>
            }
          >
            <Segmented
              options={[
                {
                  label: (
                    <span>
                      予約中 {renderBadge(queueData.queued.length, 'queued')}
                    </span>
                  ),
                  value: 'queued',
                },
                {
                  label: (
                    <span>
                      実行中 {renderBadge(queueData.running.length, 'running')}
                    </span>
                  ),
                  value: 'running',
                },
                {
                  label: (
                    <span>
                      完了
                      {renderBadge(completedCount - failedCount, 'completed')}
                      {renderBadge(failedCount, 'failed')}
                    </span>
                  ),
                  value: 'completed',
                },
              ]}
              value={activeTab}
              onChange={setActiveTab}
              style={{ marginBottom: '16px', width: '100%' }}
              block
            />
            <Table
              dataSource={queueData[activeTab]}
              columns={columns}
              pagination={false}
              size="small"
              onRow={record => ({
                onClick: () => {
                  if (activeTab === 'queued') {
                    console.log('Selected queue:', record);
                    setSelectedQueue(record);
                  }
                },
              })}
              rowKey={record => record.id}
            />
            <Modal
              title="キューのキャンセル"
              visible={!!selectedQueue}
              onOk={handleCancelQueue}
              onCancel={() => setSelectedQueue(null)}
              okText="キャンセル"
              cancelText="閉じる"
              okButtonProps={{ danger: true }}
            >
              <p>「{selectedQueue?.keyword}」の生成をキャンセルしますか？</p>
              <p>キャンセルすると、消費したチケットが返還されます。</p>
            </Modal>
          </Card>
        </Col>
      </Row>

      {renderSimpleAnalysisModal()}
    </>
  );
};

export default SEOArticleGenerator;
