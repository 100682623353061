import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { api } from '../../api';
import {
  Typography,
  Tabs,
  Table,
  message,
  Checkbox,
  Button,
  Breadcrumb,
  Tooltip,
  Progress,
  Segmented,
  Switch,
  Affix,
  Modal,
  Collapse,
  Divider,
  Spin,
  Skeleton,
  Space,
  Input,
  Dropdown,
  Menu,
  Row,
  Col,
  Grid,
  Badge,
  Tag,
} from 'antd';
// import { Tooltip as Tooltip } from 'antd';
import {
  BulbOutlined,
  RadarChartOutlined,
  ProductOutlined,
  BarsOutlined,
  ContactsOutlined,
  CopyOutlined,
  CheckOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  RightOutlined,
  SettingOutlined,
  LockOutlined,
  CodeOutlined,
  ClockCircleOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import { Radar } from 'react-chartjs-2';
import './ArticleDetail.css';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip as ChartTooltip,
  Legend,
} from 'chart.js';
import StructuredHeadings from './StructuredHeadings';
import 'react-json-pretty/themes/monikai.css';
import JSONPretty from 'react-json-pretty';
import { Helmet } from 'react-helmet';
import moment from 'moment-timezone';
import { trackEvent } from '../../utils/ga4';
import {
  calculateCharacterCount,
  calculateHtmlCharacterCount,
} from '../../utils/calculateCharacterCount';
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  ChartTooltip,
  Legend,
);
import ExportButton from './ExportButton';

const { Title, Paragraph, Text } = Typography;
const { TextArea } = Input;
const { TabPane } = Tabs;
const { confirm } = Modal;
const { Panel } = Collapse;
const { useBreakpoint } = Grid;

const ArticleDetail = () => {
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [topTopics, setTopTopics] = useState([]);
  const [uniqueTopics, setUniqueTopics] = useState([]);
  const [relatedKeywords, setRelatedKeywords] = useState([]);
  const [queryInsightsSummaries, setQueryInsightsSummaries] = useState({});
  const [topicCheckStatus, setTopicCheckStatus] = useState('idle');
  const [isTopicCheckExecuted, setIsTopicCheckExecuted] = useState(false);
  const [contentGenerationStatus, setContentGenerationStatus] =
    useState('idle');
  const { uuid } = useParams();
  const [products, setProducts] = useState([]);
  const [selectedTab, setSelectedTab] = useState('relatedKeywords');
  const [showAllTopics, setShowAllTopics] = useState(true);
  const [lastJobStatus, setLastJobStatus] = useState({
    topicCheck: null,
    contentGeneration: null,
  });
  const [editableTitle, setEditableTitle] = useState('');
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [shareStatus, setShareStatus] = useState('private');
  const [shareUrl, setShareUrl] = useState('');
  const [sharePassword, setSharePassword] = useState('');
  const [userPlan, setUserPlan] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [updatingTopics, setUpdatingTopics] = useState({
    relatedKeywords: false,
    topTopics: false,
    uniqueTopics: false,
  });
  const screens = useBreakpoint();
  const [activeKey, setActiveKey] = useState('1');
  const contentRef = useRef(null);
  const [showCTA, setShowCTA] = useState(false);
  const [showSources, setShowSources] = useState(true); // デフォルトでON（ソースを表示）

  const showConfirm = (action, isRetry = false) => {
    let title = '';
    let content = '';
    let confirmAction = null;
    if (action === 'generateStructure') {
      title = '構成案の生成';
      content = '構成案を生成します。よろしいですか？';
      confirmAction = handleGenerateStructure;
    } else if (action === 'checkTopics') {
      title = 'トピックのチェック';
      content =
        '構成案に含まれている語句やトピックをAIがチェックします。この機能は一度のみ実行できます。実行しますか？';
      confirmAction = () => handleTopicCheck();
    } else if (action === 'generateArticleContent') {
      title = isRetry ? '本文生成の再実行' : '本文の生成';
      content =
        '構成案に基づいて本文を生成します。この機能は一度のみ実行できます。実行しますか？';
      confirmAction = () => handleContentGeneration(isRetry);
    }
    confirm({
      title: title,
      icon: <ExclamationCircleOutlined />,
      content: content,
      onOk() {
        if (confirmAction) {
          confirmAction();
        }
      },
      onCancel() {},
    });
  };

  const fetchArticle = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/seo-article/detail/${uuid}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      );
      setArticle(response.data);
    } catch (error) {
      console.error('Failed to fetch article:', error);
      message.error('記事の取得に失敗しました');
    }
  }, [uuid]);

  useEffect(() => {
    fetchArticle();
  }, [fetchArticle]);

  useEffect(() => {
    const fetchArticleAndStatus = async () => {
      try {
        const token = localStorage.getItem('token');
        console.log('Token:', token);
        const articleResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/seo-article/detail/${uuid}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        console.log('**Article Response:', articleResponse.data);
        setArticle(articleResponse.data);
        setLoading(false);

        if (!isEditingTitle) {
          setEditableTitle(articleResponse.data.title);
        }

        console.log('**Share Status:', articleResponse.data.share_status);
        setShareStatus(
          prevStatus =>
            articleResponse.data.share_status || prevStatus || 'private',
        );
        setShareUrl(prevUrl => {
          if (articleResponse.data.share_status === 'private') {
            return '';
          }
          return articleResponse.data.share_url || prevUrl || '';
        });

        if (articleResponse.data.top_topics) {
          const parsedTopTopics =
            typeof articleResponse.data.top_topics === 'string'
              ? JSON.parse(articleResponse.data.top_topics)
              : articleResponse.data.top_topics;
          setTopTopics(
            parsedTopTopics.filter(
              topic => topic[0] && !topic[0].startsWith('トピック'),
            ),
          );
        }

        if (articleResponse.data.unique_topics) {
          const parsedUniqueTopics =
            typeof articleResponse.data.unique_topics === 'string'
              ? JSON.parse(articleResponse.data.unique_topics)
              : articleResponse.data.unique_topics;
          setUniqueTopics(
            parsedUniqueTopics.filter(
              topic => topic[0] && !topic[0].startsWith('トピック'),
            ),
          );
        }

        if (articleResponse.data.related_keywords) {
          const parsedRelatedKeywords =
            typeof articleResponse.data.related_keywords === 'string'
              ? JSON.parse(articleResponse.data.related_keywords)
              : articleResponse.data.related_keywords;
          setRelatedKeywords(parsedRelatedKeywords);
        }

        if (articleResponse.data.query_insights_summaries) {
          setQueryInsightsSummaries(
            JSON.parse(articleResponse.data.query_insights_summaries),
          );
        }

        const statusResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/seo-article/status/${uuid}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        console.log('Status Response:', statusResponse);
        setTopicCheckStatus(statusResponse.data.topic_check_status);
        setContentGenerationStatus(
          statusResponse.data.content_generation_status,
        );
        setLastJobStatus({
          topicCheck: statusResponse.data.last_topic_check_status,
          contentGeneration: statusResponse.data.last_content_generation_status,
        });
      } catch (error) {
        console.error('Failed to fetch article or status:', error);
        message.error('記事情報の取得に失敗しました');
        setLoading(false);
      }
    };

    const fetchProducts = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/seo-article/products/${uuid}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setProducts(response.data);
      } catch (error) {
        // message.error('Failed to fetch products');
      }
    };

    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/users/me`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        setUserPlan(response.data.plan?.name);
        setUserRole(response.data.role);
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    };

    const checkTopicCheckStatus = () => {
      if (article) {
        const topTopics = JSON.parse(article.top_topics || '[]');
        const uniqueTopics = JSON.parse(article.unique_topics || '[]');
        const relatedKeywords = JSON.parse(article.related_keywords || '[]');

        const isExecuted = [
          ...topTopics,
          ...uniqueTopics,
          ...relatedKeywords,
        ].some(topic => [1, 2, 3].includes(topic[2]));

        setIsTopicCheckExecuted(isExecuted);
      }
    };

    fetchArticleAndStatus();
    fetchProducts();
    fetchUserInfo();
    checkTopicCheckStatus();

    const intervalId = setInterval(() => {
      fetchArticleAndStatus();
      fetchProducts();
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, [uuid, isEditingTitle, shareStatus]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1000) {
        setShowCTA(true);
      } else {
        setShowCTA(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const updateArticle = async data => {
    try {
      if (userPlan === 'free') {
        if (data.title || data.structured_headings) {
          message.warning(
            '編集機能はスタンダード以上のプランでご利用になれます。',
          );
          return;
        }
      }

      const token = localStorage.getItem('token');
      const updatedData = { ...data };
      ['top_topics', 'unique_topics', 'related_keywords'].forEach(key => {
        if (updatedData[key]) {
          if (typeof updatedData[key] === 'string') {
            try {
              updatedData[key] = JSON.parse(updatedData[key]);
            } catch (error) {
              console.error(`Failed to parse ${key}:`, error);
              message.error(`${key}の形式が正しくありません`);
              return;
            }
          }
        }
      });
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/seo-article/detail/${article.uuid}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.status === 200) {
        message.success('データを更新しました');
      } else {
        throw new Error('Update failed');
      }
    } catch (error) {
      console.error('Failed to update article:', error);
      throw error;
    }
  };

  const handleTabChange = key => {
    setActiveKey(key);
    if (contentRef.current && !screens.lg) {
      contentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleTopicCheck = async (isRetry = false) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(
        `${process.env.REACT_APP_API_URL}/seo-article/detail/${uuid}/check_topics`,
        { is_retry: false },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setTopicCheckStatus('queued');
    } catch (error) {
      console.error('Failed to start topic check:', error);
      message.error('トピックチェックの開始に失敗しました');
    }
  };

  const handleContentGeneration = async (isRetry = false) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${process.env.REACT_APP_API_URL}/seo-article/generate_article_content/${uuid}`,
        { is_retry: isRetry },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setContentGenerationStatus('queued');

      trackEvent('start_content_generation', {
        article_id: article.id,
        keyword: article.keyword,
        user_plan: uuid,
      });
      // message.success(isRetry ? '本文生成の再実行を開始しました' : '本文生成を開始しました');
    } catch (error) {
      console.error('Failed to start content generation:', error);
      message.error('本文生成の開始に失敗しました');
    }
  };

  const renderTopicCheckButton = () => {
    const baseButtonStyle = {
      border: 'none',
      transition: 'all 0.3s',
      width: '100%',
      fontWeight: 'bold',
    };

    const activeButtonStyle = {
      ...baseButtonStyle,
      background: 'linear-gradient(90deg, #0072ff 0%, #00d4ff 100%)',
    };

    const disabledButtonStyle = {
      ...baseButtonStyle,
      background: '#f5f5f5',
      color: 'rgba(0, 0, 0, 0.25)',
    };

    if (isTopicCheckExecuted) {
      return (
        <Tooltip title="AIが簡易的に自動チェックします。タイトルに含まれている場合はピンク、大見出しはオレンジ、中見出しは薄いオレンジで色分けされます。">
          <Button disabled block size="large" style={disabledButtonStyle}>
            トピックチェック済み
          </Button>
        </Tooltip>
      );
    }

    if (topicCheckStatus === 'queued' || topicCheckStatus === 'processing') {
      return (
        <Button
          disabled
          block
          size="large"
          style={disabledButtonStyle}
          icon={topicCheckStatus === 'processing' ? <Spin /> : null}
        >
          {topicCheckStatus === 'queued'
            ? 'トピックチェック予約中'
            : 'トピックチェック実行中'}
        </Button>
      );
    }

    const handleClick = () => {
      // フリープランの場合は機能制限
      if (userPlan === 'free') {
        message.warning(
          '含有トピックの自動チェック機能は、ライト以上のプランでご利用になれます。',
        );
      } else {
        showConfirm('checkTopics', false);
      }
    };

    // フリープランの場合はボタンを無効化
    const isPlanRestricted = userPlan === 'free';
    const buttonStyle = isPlanRestricted
      ? disabledButtonStyle
      : activeButtonStyle;

    return (
      <Tooltip
        title={
          isPlanRestricted
            ? '含有トピックの自動チェック機能は、スタンダード以上のプランでご利用になれます。'
            : ''
        }
      >
        <Button
          onClick={handleClick}
          type="primary"
          size="middle"
          style={buttonStyle}
          disabled={isPlanRestricted}
          icon={isPlanRestricted ? <LockOutlined /> : null}
          onMouseEnter={e => {
            if (!isPlanRestricted) {
              e.currentTarget.style.opacity = '0.7';
            }
          }}
          onMouseLeave={e => {
            if (!isPlanRestricted) {
              e.currentTarget.style.opacity = '1.0';
            }
          }}
        >
          含有トピックを自動チェックする
          {!isPlanRestricted && (
            <RightOutlined
              style={{
                marginLeft: 'auto',
                right: '10px',
                position: 'absolute',
                top: '50%',
                transform: 'translate(0, -50%)',
                fontSize: '14px',
              }}
            />
          )}
        </Button>
      </Tooltip>
    );
  };

  const renderContentGenerationButton = () => {
    const buttonStyle = {
      background: 'linear-gradient(90deg, #0072ff 0%, #00d4ff 100%)',
      border: 'none',
      transition: 'all 0.3s',
      width: '100%',
      fontWeight: 'bold',
    };

    if (
      contentGenerationStatus === 'queued' ||
      contentGenerationStatus === 'processing'
    ) {
      return (
        <Button
          disabled
          block
          size="large"
          style={{ fontWeight: 'bold' }}
          icon={<Spin />}
        >
          {contentGenerationStatus === 'queued'
            ? '本文生成を予約中…'
            : '本文を生成しています…'}
        </Button>
      );
    }

    const isRetry = lastJobStatus.contentGeneration === 'failed';
    return (
      <Button
        onClick={() => showConfirm('generateArticleContent', isRetry)}
        style={buttonStyle}
        type="primary"
        size="large"
        onMouseEnter={e => {
          e.currentTarget.style.opacity = '0.7';
        }}
        onMouseLeave={e => {
          e.currentTarget.style.opacity = '1.0';
        }}
      >
        {isRetry ? '本文生成を再実行する' : '記事の本文を生成する'}
        <RightOutlined
          style={{
            marginLeft: 'auto',
            right: '10px',
            position: 'absolute',
            top: '50%',
            transform: 'translate(0, -50%)',
            fontSize: '14px',
          }}
        />
      </Button>
    );
  };

  const handleShareStatusChange = async status => {
    try {
      const data = { status };
      if (status === 'password') {
        data.password = sharePassword;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/seo-article/share/${uuid}`,
        data,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      );

      // APIレスポンスに基づいて状態を更新
      setShareStatus(response.data.status);
      setShareUrl(
        response.data.status === 'private' ? '' : response.data.url || '',
      );

      trackEvent('share_article', {
        article_id: article.id,
        keyword: article.keyword,
        share_status: status,
        user_id: uuid,
      });

      message.success('共有設定を更新しました');
    } catch (error) {
      console.error('Failed to change share status:', error);
      message.error('共有設定の更新に失敗しました');
    }
  };

  const copyShareUrl = () => {
    navigator.clipboard.writeText(shareUrl);
    message.success('URLをコピーしました');
  };

  const getShareMenuItems = () => {
    const items = [
      {
        key: 'private',
        label: (
          <span>
            {shareStatus === 'private' && (
              <CheckOutlined style={{ marginRight: 8 }} />
            )}
            非公開
          </span>
        ),
        onClick: () => handleShareStatusChange('private'),
      },
      {
        key: 'public',
        label: (
          <span>
            {shareStatus === 'public' && (
              <CheckOutlined style={{ marginRight: 8 }} />
            )}
            URLを公開
          </span>
        ),
        onClick: () => handleShareStatusChange('public'),
      },
    ];

    // パスワード付き共有が利用可能なプラン
    const passwordSharingPlans = [
      'pro',
      'premium',
      'custom', // レガシープラン
      'standard_new',
      'premium_new', // 新プラン
    ];

    if (
      userRole === 'admin' ||
      userRole === 'master' ||
      passwordSharingPlans.includes(userPlan)
    ) {
      items.push({
        key: 'password',
        label: (
          <span>
            {shareStatus === 'password' && (
              <CheckOutlined style={{ marginRight: 8 }} />
            )}
            パスワード付きで公開
          </span>
        ),
        onClick: () => {
          Modal.confirm({
            title: 'パスワードを設定',
            content: (
              <div
                style={{
                  border: '2px solid #f5f5f5',
                  backgroundColor: '#f5f5f5',
                  transition: 'all 0.3s',
                  borderRadius: '8px', // 角丸を追加
                }}
                onMouseEnter={e => {
                  e.currentTarget.style.backgroundColor = '#eee';
                }}
                onMouseLeave={e => {
                  e.currentTarget.style.backgroundColor = '#f5f5f5';
                }}
                onFocus={e => {
                  e.currentTarget.style.border = '2px solid #0072ff';
                }}
                onBlur={e => {
                  e.currentTarget.style.border = '2px solid #f5f5f5';
                }}
              >
                <Input.Password
                  placeholder="パスワードを入力"
                  onChange={e => setSharePassword(e.target.value)}
                />
              </div>
            ),
            onOk: () => handleShareStatusChange('password'),
          });
        },
      });
    } else {
      items.push({
        key: 'password',
        label: (
          <Tooltip title="パスワード付きの公開はスタンダード以上のプランでご利用になれます。">
            <span style={{ color: '#d9d9d9' }}>パスワード付きで公開</span>
          </Tooltip>
        ),
        disabled: true,
      });
    }

    return items;
  };

  const shareMenu = {
    items: getShareMenuItems(),
  };

  const getShareButtonText = status => {
    switch (status) {
      case 'public':
        return 'URLを公開中';
      case 'password':
        return 'パスワード付きで公開中';
      default:
        return '非公開';
    }
  };

  const getShareTooltip = status => {
    switch (status) {
      case 'public':
        return 'この生成結果ページは公開されています。クリックして設定を変更できます。';
      case 'password':
        return 'この生成結果ページはパスワード付きで公開されています。クリックして設定を変更できます。';
      default:
        return 'この生成結果ページは非公開です。クリックして共有設定を変更できます。';
    }
  };

  const MeetingCTA = () => {
    const style = {
      position: 'fixed',
      bottom: '5%',
      left: '2%',
      width: screens.lg ? 'auto' : '100%',
      zIndex: 9999,
      transition: 'opacity 0.8s',
      opacity: showCTA ? 1 : 0,
      pointerEvents: showCTA ? 'auto' : 'none',
    };

    if (!screens.lg) {
      style.top = '0';
      style.bottom = 'auto';
      style.right = '0';
      style.left = '0';
    }

    return (
      <div id="cta_button" style={style}>
        ＼記事の編集や入稿も承ります／
        <Button
          type="primary"
          href={`/reserve-mtg${uuid ? `?magicss_uuid=${uuid}` : ''}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            width: '100%',
            height: '100%',
            whiteSpace: 'normal',
            lineHeight: '1.2',
          }}
        >
          無料ミーティングを予約する
        </Button>
      </div>
    );
  };

  if (loading) {
    return <Spin>Loading...</Spin>;
  }

  if (!article) {
    return <Typography.Title level={3}>記事が見つかりません</Typography.Title>;
  }

  const searchIntents = article.search_intents
    ? JSON.parse(article.search_intents)
    : [];
  const readerGoals = article.reader_goals
    ? JSON.parse(article.reader_goals)
    : { content_goal: '', best_result: '' };
  const searchPersona = article.search_persona || '';

  const queryInsightsData = {
    labels: [
      '専門性',
      'コンバージョン',
      '緊急性',
      'ローカリゼーション',
      'ニーズの多様性',
      'ビジュアライゼーション',
      '公式度と信頼性',
      '情報の最新性',
      'UGCやレビュー・体験談',
    ],
    datasets: [
      {
        label: 'Query Insights',
        data: [
          article.expertise_and_depth,
          article.purchase_motivation_and_conversion,
          article.urgency_and_cta,
          article.localization_and_regional_adaptation,
          article.diversity_of_user_needs,
          article.visualization,
          article.source_formality_and_reliability,
          article.freshness_of_information,
          article.ugc_utilization_and_impact,
        ],
        backgroundColor: 'rgba(0,212,255,0.1)',
        borderColor: 'rgb(0 212 255)',
        borderWidth: 2,
      },
    ],
  };

  const queryInsightsDescriptions = [
    {
      category: '専門性',
      description: '上位サイトがどの程度専門的で深い情報を提供しているか',
    },
    {
      category: 'コンバージョン',
      description:
        '上位サイトがどの程度購買意欲を喚起し、コンバージョンを促進しているか',
    },
    {
      category: '緊急性',
      description:
        '検索者が緊急を要し、また上位サイトが即座の行動を促しているか',
    },
    {
      category: 'ローカリゼーション',
      description: '国や地域に根付いたローカルな情報である度合い',
    },
    {
      category: 'ニーズの多様性',
      description: 'どの程度、多様なユーザーニーズを内容したキーワードであるか',
    },
    {
      category: 'ビジュアライゼーション',
      description: '上位サイトがどの程度ビジュアルコンテンツを提供しているか',
    },
    {
      category: '公式度と信頼性',
      description: '上位サイトの情報源がどの程度公式で信頼できるか',
    },
    {
      category: '情報の最新性',
      description: '上位サイトがどの程度最新の情報を提供しているか',
    },
    {
      category: 'UGCやレビュー・体験談',
      description:
        '上位サイトがどの程度ユーザー生成コンテンツを活用し、影響力があるか',
    },
  ];

  const categoryColors = {
    '企業/ブランド': { backgroundColor: '#e6f7ff', color: '#1890ff' },
    '商品/サービス': { backgroundColor: '#fff2e8', color: '#fa8c16' },
    '機能/プラン': { backgroundColor: '#f9f0ff', color: '#7b61ff' },
    ウェブサイト: { backgroundColor: '#f8f9cd', color: '#b5b01f' },
    '店舗/施設': { backgroundColor: '#f0fff1', color: '#33b65e' },
    '書籍/コンテンツ': { backgroundColor: '#f3e4ee', color: '#c76fbf' },
    公的機関: { backgroundColor: '#fffbe6', color: '#faad14' },
    その他: { backgroundColor: '#f0f0f0', color: '#8c8c8c' },
  };

  const productsColumns = [
    {
      title: '関連スコア',
      dataIndex: 'article_data',
      key: 'relevance',
      render: (article_data, record) => {
        const relevance = article_data[article.id]?.relevance || 0;
        return (
          <Progress
            percent={relevance * 20} // relevanceは1-5のスケールなので、100%に合わせるために20を掛ける
            steps={5}
            size={7}
            strokeColor={{
              '0%': '#108ee9',
              '100%': '#87d068',
            }}
            format={() => null}
          />
        );
      },
      width: 120,
    },
    {
      title: 'プロダクト名',
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: 'カテゴリー',
      dataIndex: 'category',
      key: 'category',
      render: category => (
        <span
          style={{
            display: 'inline-block',
            padding: '2px 8px',
            borderRadius: '4px',
            backgroundColor:
              categoryColors[category]?.backgroundColor || '#f0f0f0',
            color: categoryColors[category]?.color || '#000',
            fontSize: '12px',
            width: '100px',
            textAlign: 'center ',
          }}
        >
          {category}
        </span>
      ),
      width: 180,
    },
    {
      title: '説明文',
      dataIndex: 'article_data',
      key: 'description',
      render: (article_data, record) =>
        article_data[article.id]?.description || '',
      width: 240,
    },
    // {
    //   title: '出現数 / 公式サイト数',
    //   key: 'count',
    //   render: (_, record) => {
    //     const articleData = record.article_data[article.id] || {};
    //     return (
    //       <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
    //         {articleData.count || 0} / {articleData.official_count || 0}
    //       </span>
    //     );
    //   },
    // },
  ];

  const handleRelatedKeywordCheck = async keyword => {
    // 即時にUIを更新
    const updatedRelatedKeywords = relatedKeywords.map(item => {
      if (item[0] === keyword) {
        return [item[0], item[1], item[2] === 0 ? 5 : 0];
      }
      return item;
    });
    setRelatedKeywords(updatedRelatedKeywords);

    // 短いローディング状態を表示
    setUpdatingTopics(prev => ({ ...prev, relatedKeywords: true }));

    // 少し遅延してローディングを消す（例: 500ms後）
    setTimeout(() => {
      setUpdatingTopics(prev => ({ ...prev, relatedKeywords: false }));
    }, 500);

    // バックグラウンドで更新処理を継続
    updateArticle({ related_keywords: updatedRelatedKeywords })
      .then(() => {
        // 成功時の処理（静かに状態を同期）
        setRelatedKeywords(updatedRelatedKeywords);
      })
      .catch(error => {
        console.error('Failed to update related keywords:', error);
        // エラー時は元の状態に戻す
        setRelatedKeywords(relatedKeywords);
        message.error('関連キーワードの更新に失敗しました');
      });
  };

  const handleTopTopicCheck = async topic => {
    // 即時にUIを更新
    const updatedTopTopics = topTopics.map(item => {
      if (item[0] === topic) {
        return [item[0], item[1], item[2] === 0 ? 5 : 0];
      }
      return item;
    });
    setTopTopics(updatedTopTopics);

    // 短いローディング状態を表示
    setUpdatingTopics(prev => ({ ...prev, topTopics: true }));

    // 少し遅延してローディングを消す（例: 500ms後）
    setTimeout(() => {
      setUpdatingTopics(prev => ({ ...prev, topTopics: false }));
    }, 500);

    // バックグラウンドで更新処理を継続
    updateArticle({ top_topics: updatedTopTopics })
      .then(() => {
        // 成功時の処理（静かに状態を同期）
        setTopTopics(updatedTopTopics);
      })
      .catch(error => {
        console.error('Failed to update top topics:', error);
        // エラー時は元の状態に戻す
        setTopTopics(topTopics);
        message.error('主要トピックの更新に失敗しました');
      });
  };

  const handleUniqueTopicCheck = async topic => {
    // 即時にUIを更新
    const updatedUniqueTopics = uniqueTopics.map(item => {
      if (item[0] === topic) {
        return [item[0], item[1], item[2] === 0 ? 5 : 0];
      }
      return item;
    });
    setUniqueTopics(updatedUniqueTopics);

    // 短いローディング状態を表示
    setUpdatingTopics(prev => ({ ...prev, uniqueTopics: true }));

    // 少し遅延してローディングを消す（例: 500ms後）
    setTimeout(() => {
      setUpdatingTopics(prev => ({ ...prev, uniqueTopics: false }));
    }, 500);

    // バックグラウンドで更新処理を継続
    updateArticle({ unique_topics: updatedUniqueTopics })
      .then(() => {
        // 成功時の処理（静かに状態を同期）
        setUniqueTopics(updatedUniqueTopics);
      })
      .catch(error => {
        console.error('Failed to update unique topics:', error);
        // エラー時は元の状態に戻す
        setUniqueTopics(uniqueTopics);
        message.error('独自トピックの更新に失敗しました');
      });
  };

  const renderCheckbox = checked => {
    const checkboxColor =
      checked === 1
        ? 'pink'
        : checked === 2
          ? 'red'
          : checked === 3
            ? 'orange'
            : checked === 5
              ? 'blue'
              : 'default';
    return (
      <Checkbox
        checked={checked !== 0}
        disabled={true}
        className={`custom-checkbox ${checkboxColor}`}
      />
    );
  };

  const renderTopicTable = (type, data) => (
    <Table
      columns={[
        {
          title: '',
          dataIndex: 'checked',
          key: 'check',
          render: (_, record) => renderCheckbox(record[2]),
          width: 30,
          fixed: 'left',
        },
        {
          title: type === 'relatedKeywords' ? 'キーワード' : 'トピック',
          dataIndex: 'keyword',
          key: 'keyword',
          render: (_, record) => <span>{record[0]}</span>,
          width: 'calc(100% - 130px)',
        },
        {
          title:
            type === 'relatedKeywords'
              ? '出現割合'
              : type === 'topTopics'
                ? '頻出度'
                : '独自性',
          dataIndex: 'count',
          key: 'count',
          render: (_, record) => (
            <Progress
              percent={record[1] * 20}
              steps={5}
              size={7}
              strokeColor={{
                '0%': '#108ee9',
                '100%': '#87d068',
              }}
              format={() => null}
            />
          ),
          width: 100,
        },
      ]}
      dataSource={data}
      pagination={false}
      scroll={{ y: '60vh' }}
      className={`topic-table ${type}-table`}
      loading={updatingTopics[type]}
    />
  );

  const handleGenerateStructure = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(
        `${process.env.REACT_APP_API_URL}/seo-article/detail/${article.uuid}`,
        { status: '更新中' },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      await axios.post(
        `${process.env.REACT_APP_API_URL}/seo-article/generate_structure/${article.uuid}`,
        null,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      await axios.put(
        `${process.env.REACT_APP_API_URL}/seo-article/detail/${article.uuid}`,
        { status: '生成完了' },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    } catch (error) {
      console.error('Failed to generate structure:', error);
      message.error('構造の生成に失敗しました');
    }
  };

  const updateArticleStructure = async markdownStructure => {
    if (userPlan === 'free') {
      message.warning(
        '構成案の編集機能はライト以上のプランでご利用になれます。',
      );
      return;
    }

    // デバッグ用にデータ型を確認
    console.log('Received markdownStructure type:', typeof markdownStructure);
    console.log('Received markdownStructure value:', markdownStructure);

    // 配列やオブジェクトが来た場合はreturnして処理を中断
    if (typeof markdownStructure !== 'string') {
      console.error(
        'Invalid markdownStructure type:',
        typeof markdownStructure,
      );
      return;
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/seo-article/detail/${article.uuid}`,
        {
          structured_headings: markdownStructure,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.status === 200) {
        message.success('構成案を更新しました');
        await fetchArticle();
      }
    } catch (error) {
      console.error('Failed to update article structure:', error);
      message.error('構成案の更新に失敗しました');
      await fetchArticle();
    }
  };

  const handleCopyStructure = () => {
    if (article.structured_headings) {
      const formattedStructure = article.structured_headings
        .split('\n')
        .map(line => {
          if (line.startsWith('$$')) {
            return `<h2>${line.slice(2).trim()}</h2>`;
          } else if (line.startsWith('###')) {
            return `<h3>${line.slice(3).trim()}</h3>`;
          }
          return line;
        })
        .join('\n');

      navigator.clipboard
        .writeText(formattedStructure)
        .then(() => {
          message.success('構成案をコピーしました！');
        })
        .catch(error => {
          console.error('Failed to copy structure:', error);
          message.error('構成案のコピーに失敗しました');
        });
    }
  };

  const renderStructuredHeadings = headings => {
    if (!headings) return null;
    const lines = headings.split('\n');
    let h2Count = 0;
    let h3Count = 0;

    return (
      <ul className="table-of-contents">
        {lines.map((line, index) => {
          if (line.startsWith('$$')) {
            h2Count++;
            h3Count = 0;
            const headingText = line.slice(2).trim();
            const headingId = `content-h2-${h2Count}`;
            return (
              <li key={index} className="toc-parent">
                <a
                  href={`#${headingId}`}
                  onClick={e => {
                    e.preventDefault();
                    scrollToHeading(headingId);
                  }}
                >
                  {headingText}
                </a>
              </li>
            );
          } else if (line.startsWith('###')) {
            h3Count++;
            const headingText = line.slice(3).trim();
            const headingId = `content-h2-${h2Count}-h3-${h3Count}`;
            return (
              <li key={index}>
                <a
                  href={`#${headingId}`}
                  onClick={e => {
                    e.preventDefault();
                    scrollToHeading(headingId);
                  }}
                >
                  {headingText}
                </a>
              </li>
            );
          }
          return null;
        })}
      </ul>
    );
  };

  const scrollToHeading = headingId => {
    const element = document.getElementById(headingId);
    if (element) {
      const offset = 0; // ヘッダーの高さなどに応じて調整
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const getFaviconUrl = url => {
    try {
      // URLをパースしてドメイン部分を取得
      const domain = new URL(url).hostname;
      // Google FaviconサービスまたはDuckDuckGoのサービスを使用
      return `https://www.google.com/s2/favicons?domain=${domain}&sz=32`;
    } catch (error) {
      // URLのパースに失敗した場合はデフォルトのファビコンを返す
      return `${process.env.PUBLIC_URL}/favicon.ico`;
    }
  };

  // ドメイン名を取得するヘルパー関数
  const getDomainName = url => {
    try {
      const domain = new URL(url).hostname;
      // www.を削除
      return domain.replace(/^www\./i, '');
    } catch (error) {
      return url;
    }
  };

  const copyHtml = () => {
    if (!article || !article.content) return;

    let formattedHtml = article.content;

    // ソース参照を非表示にする場合は、コピー時にも[n]とそれに関連するタグを削除
    if (!showSources) {
      // 数字の参照[n]を削除
      formattedHtml = formattedHtml.replace(/\[\d+\]/g, '');

      // 引用に関連する<sup>タグを削除
      formattedHtml = formattedHtml.replace(/<sup[^>]*>.*?<\/sup>/g, '');

      // 引用参照に使われる<a>タグで特定のクラスや属性を持つものを削除
      formattedHtml = formattedHtml.replace(
        /<a[^>]*class="citation-ref"[^>]*>.*?<\/a>/g,
        '',
      );
      formattedHtml = formattedHtml.replace(
        /<a[^>]*data-citation[^>]*>.*?<\/a>/g,
        '',
      );
    }

    formattedHtml = formattedHtml.replace(/></g, '>\n<');

    // 参考文献がある場合はHTMLに追加（ソース表示がONの場合のみ）
    if (article.citations && article.citations.length > 0 && showSources) {
      formattedHtml +=
        '\n\n<h2 id="references">参考文献</h2>\n<ul class="references-list">\n';
      article.citations.forEach(citation => {
        formattedHtml += `  <li><a href="${citation.url}" target="_blank" rel="noopener noreferrer">[${citation.id}] ${getDomainName(citation.url)}</a></li>\n`;
      });
      formattedHtml += '</ul>';
    }

    navigator.clipboard
      .writeText(formattedHtml)
      .then(() => {
        message.success('HTMLをコピーしました');
      })
      .catch(error => {
        message.error('HTMLのコピーに失敗しました');
        console.error('Failed to copy HTML: ', error);
      });
  };

  const copyText = () => {
    if (!article || !article.content) return;

    const tempDiv = document.createElement('div');

    // タイトルを追加
    const titleElement = document.createElement('h1');
    titleElement.textContent = article.title;
    tempDiv.appendChild(titleElement);

    // アイキャッチ画像を追加（もし存在する場合）
    if (article.eyecatch_image_url) {
      const imgPlaceholder = document.createElement('p');
      imgPlaceholder.textContent = `[画像: ${process.env.REACT_APP_API_URL}${article.eyecatch_image_url}]`;
      tempDiv.appendChild(imgPlaceholder);
    }

    // 本文を追加（ソース参照を非表示にする場合は[n]と関連タグを削除）
    let contentHtml = article.content;
    if (!showSources) {
      // 数字の参照[n]を削除
      contentHtml = contentHtml.replace(/\[\d+\]/g, '');

      // 引用に関連する<sup>タグを削除
      contentHtml = contentHtml.replace(/<sup[^>]*>.*?<\/sup>/g, '');

      // 引用参照に使われる<a>タグで特定のクラスや属性を持つものを削除
      contentHtml = contentHtml.replace(
        /<a[^>]*class="citation-ref"[^>]*>.*?<\/a>/g,
        '',
      );
      contentHtml = contentHtml.replace(
        /<a[^>]*data-citation[^>]*>.*?<\/a>/g,
        '',
      );
    }
    tempDiv.innerHTML += contentHtml;

    // 参考文献を追加（もし存在する場合かつソース表示がONの場合）
    if (article.citations && article.citations.length > 0 && showSources) {
      const referencesHeading = document.createElement('h2');
      referencesHeading.textContent = '参考文献';
      tempDiv.appendChild(referencesHeading);

      const referencesList = document.createElement('ul');
      article.citations.forEach(citation => {
        const listItem = document.createElement('li');
        listItem.textContent = `[${citation.id}] ${citation.url}`;
        referencesList.appendChild(listItem);
      });
      tempDiv.appendChild(referencesList);
    }

    // テーブル後に空行を追加
    const tables = tempDiv.getElementsByTagName('table');
    for (let i = 0; i < tables.length; i++) {
      const br = document.createElement('br');
      tables[i].parentNode.insertBefore(br, tables[i].nextSibling);
    }

    // スタイルを適用
    const style = document.createElement('style');
    style.textContent = `
      h1, h2, h3, h4 { font-weight: bold !important; }
      table { border-collapse: collapse !important; width: 100% !important; margin-bottom: 16px !important; }
      th, td { border: 1px solid black !important; padding: 4px !important; }
      img { max-width: 100% !important; height: auto !important; margin: 10px 0 !important; }
    `;
    tempDiv.appendChild(style);

    // コピー用の一時的な要素を作成
    const hiddenElement = document.createElement('div');
    hiddenElement.innerHTML = tempDiv.innerHTML;
    hiddenElement.style.position = 'fixed';
    hiddenElement.style.pointerEvents = 'none';
    hiddenElement.style.opacity = '0';

    // ボディに追加
    document.body.appendChild(hiddenElement);

    // 選択範囲を作成
    const range = document.createRange();
    range.selectNode(hiddenElement);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);

    try {
      // 選択した内容をクリップボードにコピー
      document.execCommand('copy');
      message.success('Googleドキュメント用にコピーしました');
    } catch (err) {
      message.error('コピーに失敗しました');
      console.error('Failed to copy text: ', err);
    }

    // 選択を解除し、一時的な要素を削除
    window.getSelection().removeAllRanges();
    document.body.removeChild(hiddenElement);
  };

  const ArticleContent = ({ article }) => {
    if (!article || !article.content) return null;

    const addIdsToHeadings = htmlContent => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, 'text/html');

      let h2Count = 0;
      let h3Count = 0;

      doc.querySelectorAll('h2, h3, h4').forEach(heading => {
        if (heading.tagName === 'H2') {
          h2Count++;
          h3Count = 0;
          heading.id = `content-h2-${h2Count}`;
        } else if (heading.tagName === 'H3') {
          h3Count++;
          heading.id = `content-h2-${h2Count}-h3-${h3Count}`;
        } else if (heading.tagName === 'H4') {
          heading.id = `content-h2-${h2Count}-h3-${h3Count}-h4-${heading.textContent.replace(/\s+/g, '-').toLowerCase()}`;
        }
      });

      return doc.body.innerHTML;
    };

    // ソース参照[n]を非表示にする処理
    const processContent = content => {
      if (!showSources) {
        return content.replace(/\[\d+\]/g, '');
      }
      return content;
    };

    const renderCitations = () => {
      console.log('article:', article);
      // citationsがないか空の場合は何も表示しない
      if (!article.citations || article.citations.length === 0) {
        return null;
      }

      // 本文内で参照されている引用IDを抽出
      const extractReferencedCitations = content => {
        try {
          const tempDiv = document.createElement('div');
          tempDiv.innerHTML = content;
          const textContent = tempDiv.textContent || tempDiv.innerText || '';
          const matches = textContent.match(/\[(\d+)\]/g) || [];
          const ids = matches
            .map(m => {
              const id = parseInt(m.replace(/[[\]]/g, ''), 10);
              return id;
            })
            .filter(id => !isNaN(id));
          const uniqueIds = [...new Set(ids)];
          return uniqueIds;
        } catch (error) {
          console.error('Error extracting citations:', error);
          return [];
        }
      };

      const referencedIds = article.content
        ? extractReferencedCitations(article.content)
        : [];

      console.log('Referenced IDs:', referencedIds);
      console.log('Referenced IDs count:', referencedIds.length);

      return (
        <div className="article-citations">
          <h2 id="references">
            参考文献
            <Tooltip title="本文作成時に参考にしたウェブページです。番号は記事内の[n]と対応しています。青色表示されているのは本文中で直接引用されているサイトです。">
              <InfoCircleOutlined
                style={{
                  marginLeft: '6px',
                  fontSize: '14px',
                  color: 'rgba(0, 0, 0, 0.45)',
                }}
              />
            </Tooltip>
          </h2>
          <div className="citations-list">
            {article.citations.map((citation, index) => {
              const citationId = parseInt(String(citation.id).trim(), 10);
              const isReferenced = referencedIds.includes(citationId);

              console.log(`Citation ${citationId}: referenced=${isReferenced}`);

              return (
                <div key={index} className="citation-item">
                  <div className="citation-favicon">
                    <img
                      src={getFaviconUrl(citation.url)}
                      alt=""
                      onError={e => {
                        e.target.onerror = null;
                        e.target.src = `${process.env.PUBLIC_URL}/favicon.ico`;
                      }}
                    />
                  </div>
                  <div className="citation-content">
                    <a
                      href={citation.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="citation-link"
                    >
                      <span className="citation-number">[{citation.id}]</span>
                      <span
                        className="citation-domain"
                        style={{ color: isReferenced ? '#1890ff' : '#8c8c8c' }}
                      >
                        {getDomainName(citation.url)}
                      </span>
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    };

    const parser = new DOMParser();
    const doc = parser.parseFromString(article.content, 'text/html');
    const firstH2 = doc.querySelector('h2');
    const introduction = firstH2
      ? article.content.substring(0, article.content.indexOf(firstH2.outerHTML))
      : '';
    const mainContent = firstH2
      ? article.content.substring(article.content.indexOf(firstH2.outerHTML))
      : article.content;

    return (
      <div className="article-content">
        <div
          className="html-content"
          dangerouslySetInnerHTML={{
            __html: addIdsToHeadings(processContent(introduction)),
          }}
        />
        <div className="table-of-contents">
          <div className="toc-title">この記事の目次</div>
          {renderStructuredHeadings(article.structured_headings)}
        </div>
        <div
          className="html-content"
          dangerouslySetInnerHTML={{
            __html: addIdsToHeadings(processContent(mainContent)),
          }}
        />
        {renderCitations()}
      </div>
    );
  };

  const fixedColumn30 = {
    width: 30,
    paddingLeft: 8,
    paddingRight: 8,
  };
  const fixedColumn50 = {
    width: 50,
    paddingLeft: 8,
    paddingRight: 8,
  };
  const fixedColumn240 = {
    width: 240,
    paddingLeft: 16,
    paddingRight: 16,
  };

  const tabItems = [
    {
      key: '1',
      icon: <RadarChartOutlined />,
      title: '上位サイトの傾向',
      tooltip:
        '検索上位表示サイトの共通点や特徴を9つの観点から分析し、レーダーチャートで可視化します。記事作成の方向性を決める重要な指標です。',
    },
    {
      key: '2',
      icon: <ContactsOutlined />,
      title: '検索者の分析',
      tooltip:
        'キーワードを検索するユーザーの意図や関心事、悩みなど深層心理を分析します。検索者が求めている情報を把握してターゲット層に合った記事を作成できます。',
    },
    {
      key: '3',
      icon: <BulbOutlined />,
      title: 'トピック分析',
      tooltip:
        '上位表示サイトで頻出するトピックや特有のキーワードを抽出し、記事に盛り込むべき内容を特定します。SEO上重要な語句が一目でわかります。',
    },
    {
      key: '4',
      icon: <ProductOutlined />,
      title: 'プロダクト分析',
      tooltip:
        '検索結果で言及されている商品やサービスを抽出した結果です。製品レビューや比較記事を作成する際に参考になる情報を提供します。',
    },
    {
      key: '5',
      icon: <BarsOutlined />,
      title: '構成案の生成',
      tooltip:
        '分析結果に基づいて最適な記事構成を作成します。見出し構造や含めるべきトピックを提案し、SEO効果の高い記事のフレームワークを提供します。',
    },
    {
      key: '6',
      icon: <EditOutlined />,
      title: '本文の生成',
      tooltip:
        '構成案に沿って専門性、正確性、独自性の高い本文を生成します。SEO効果と読者の満足度を両立した高品質な記事を作成します。',
      badge:
        article &&
        (!article.content || article.content === '') &&
        contentGenerationStatus !== 'processing',
      processingBadge: contentGenerationStatus === 'processing',
    },
  ];

  const MobileTabMenu = ({ activeKey, onChange, items }) => {
    return (
      <Menu
        mode="horizontal"
        selectedKeys={[activeKey]}
        onClick={({ key }) => onChange(key)}
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          display: 'flex',
          justifyContent: 'space-around',
          background: '#fff',
          borderTop: '1px solid #f0f0f0',
          zIndex: 999,
          width: '100vw',
        }}
        className="mobileMenu"
      >
        {items.map(item => (
          <Menu.Item key={item.key} icon={item.icon}>
            {item.badge && (
              <Badge
                color="red"
                style={{ position: 'absolute', right: '10px', top: '10px' }}
              />
            )}
            {item.processingBadge && (
              <Badge
                icon={<ClockCircleOutlined style={{ fontSize: '10px' }} />}
                style={{ position: 'absolute', right: '10px', top: '10px' }}
              />
            )}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const renderTabContent = key => {
    const item = tabItems.find(item => item.key === key);
    return (
      <div ref={contentRef}>
        {!screens.lg && (
          <Title
            level={3}
            style={{ marginBottom: '20px' }}
            className="mobile_tabTitle"
          >
            {item.title}
          </Title>
        )}
        {key === '1' && (
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              {screens.lg && (
                <Radar
                  data={{
                    labels: queryInsightsData.labels,
                    datasets: queryInsightsData.datasets,
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                    scales: {
                      r: {
                        min: 0,
                        max: 5,
                        ticks: {
                          stepSize: 1,
                          font: {
                            size: 12,
                          },
                        },
                        pointLabels: {
                          font: {
                            size: 12,
                          },
                        },
                      },
                    },
                  }}
                />
              )}
            </Col>
            <Col xs={24} lg={12} className="queryInsight">
              <Title
                level={3}
                style={{ fontSize: '14px', color: 'rgb(0 212 255)' }}
              >
                上位サイトの傾向
                <Tooltip title="上位サイトの特徴を分析し、9つの観点からAIがスコア化した独自の指標です。">
                  <InfoCircleOutlined
                    style={{ marginLeft: '6px', color: 'rgb(0 212 255)' }}
                  />
                </Tooltip>
                ：
              </Title>
              <Collapse
                defaultActiveKey={['0']}
                accordion
                expandIconPosition="left"
                bordered={false}
              >
                {queryInsightsDescriptions.map((item, index) => (
                  <Panel
                    header={
                      <div
                        style={{
                          display: 'grid',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%',
                          gridTemplateColumns: '5fr 4fr',
                        }}
                      >
                        <span className="qi_categoryname">{item.category}</span>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '10px',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Progress
                            percent={
                              queryInsightsData.datasets[0].data[index] * 20
                            }
                            steps={5}
                            size="small"
                            strokeColor={{
                              '0%': '#108ee9',
                              '100%': '#87d068',
                            }}
                            style={{ width: '28px', marginRight: '10px' }}
                            format={() => ''} // この行を追加してn%を非表示にします
                          />
                          <span style={{ marginRight: '10px' }}>
                            <span
                              style={{
                                color:
                                  queryInsightsData.datasets[0].data[index] >=
                                  4.0
                                    ? '#0072ff'
                                    : '#00d4ff',
                              }}
                            >
                              {queryInsightsData.datasets[0].data[
                                index
                              ].toFixed(1)}
                            </span>
                            <span style={{ color: '#999' }}> / 5.0</span>
                          </span>
                          <Tooltip title={item.description}>
                            <InfoCircleOutlined
                              style={{ color: 'rgba(0, 0, 0, 0.45)' }}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    }
                    key={index}
                    style={{
                      borderBottom:
                        index === queryInsightsDescriptions.length - 1
                          ? 'none'
                          : '1px solid #f0f0f0',
                      transition: 'background-color 0.3s',
                    }}
                  >
                    <p>{queryInsightsSummaries[item.category] || ''}</p>
                  </Panel>
                ))}
              </Collapse>
            </Col>
          </Row>
        )}
        {key === '2' && (
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Title
                level={3}
                style={{ fontSize: '14px', color: 'rgb(0 212 255)' }}
              >
                分析サマリー
                <Tooltip title="検索結果やオープンデータを多角的に分析し、検索者が知りたい可能性が高い情報を要約したデータです。">
                  <InfoCircleOutlined
                    style={{ marginLeft: '6px', color: 'rgb(0 212 255)' }}
                  />
                </Tooltip>
                ：
              </Title>
              {article && article.summarized_headings && (
                <div
                  style={{
                    border: '1px solid rgba(0, 212, 255, 0.3)',
                    padding: '20px',
                    borderRadius: '8px',
                    marginBottom: '24px',
                    background:
                      'linear-gradient(135deg, rgba(0, 212, 255, 0.02) 0%, rgba(0, 114, 255, 0.02) 100%)',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
                  }}
                >
                  <Paragraph
                    style={{
                      whiteSpace: 'pre-wrap',
                      background:
                        'linear-gradient(135deg, rgb(15, 170, 201) 0%, rgb(38, 126, 233) 100%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      backgroundClip: 'text',
                      color: 'transparent',
                      fontSize: '15px',
                      lineHeight: '1.8',
                      fontWeight: '500',
                    }}
                  >
                    {article.summarized_headings}
                  </Paragraph>
                </div>
              )}
            </Col>
            <Col xs={24} lg={12}>
              <Title
                level={3}
                style={{ fontSize: '14px', color: 'rgb(0 212 255)' }}
              >
                検索意図
                <Tooltip title="キーワードに内包される検索者の知りたいことをAIが分析したデータです。">
                  <InfoCircleOutlined
                    style={{ marginLeft: '6px', color: 'rgb(0 212 255)' }}
                  />
                </Tooltip>
                ：
              </Title>
              {article && article.search_intents && (
                <Table
                  bordered
                  dataSource={searchIntents.map((intent, index) => ({
                    key: index,
                    index: index + 1,
                    intent: intent.replace(/"/g, ''),
                  }))}
                  columns={[
                    {
                      dataIndex: 'index',
                      key: 'index',
                      render: (text, record, index) => ({
                        children: text,
                        props: {
                          style: {
                            backgroundColor: '#fafafa',
                            padding: '16px',
                            fontWeight: 'bold',
                          },
                        },
                      }),
                    },
                    {
                      dataIndex: 'intent',
                      key: 'intent',
                    },
                  ]}
                  showHeader={false}
                  pagination={false}
                />
              )}
              <Title
                level={3}
                style={{
                  fontSize: '14px',
                  marginTop: '36px',
                  color: 'rgb(0 212 255)',
                }}
              >
                読者のゴール
                <Tooltip title="検索行動の後で、読者がどのような状態になりたいかをAIが分析したデータです。">
                  <InfoCircleOutlined
                    style={{ marginLeft: '6px', color: 'rgb(0 212 255)' }}
                  />
                </Tooltip>
                ：
              </Title>
              {article && article.reader_goals && (
                <Table
                  bordered
                  dataSource={[
                    {
                      key: 'content_goal',
                      label: 'コンテンツのゴール',
                      value: readerGoals.content_goal,
                    },
                    {
                      key: 'best_result',
                      label: '最高の結果',
                      value: readerGoals.best_result,
                    },
                  ]}
                  columns={[
                    {
                      dataIndex: 'label',
                      key: 'label',
                      render: (text, record, index) => ({
                        children: text,
                        props: {
                          style: {
                            backgroundColor: '#fafafa',
                            padding: '16px',
                            fontWeight: 'bold',
                          },
                        },
                      }),
                    },
                    {
                      dataIndex: 'value',
                      key: 'value',
                    },
                  ]}
                  showHeader={false}
                  pagination={false}
                />
              )}
              <Title
                level={3}
                style={{
                  fontSize: '14px',
                  marginTop: '36px',
                  color: 'rgb(0 212 255)',
                }}
              >
                検索者のペルソナ
                <Tooltip title="AIが多角的に分析した、中央値的な検索者のペルソナです。">
                  <InfoCircleOutlined
                    style={{ marginLeft: '6px', color: 'rgb(0 212 255)' }}
                  />
                </Tooltip>
                ：
              </Title>
              {article && article.search_persona && (
                <Table
                  bordered
                  dataSource={searchPersona
                    .split('\n')
                    .map((line, index) => {
                      const [key, value] = line.split(':');
                      return key && value
                        ? { key: index, label: key.trim(), value: value.trim() }
                        : null;
                    })
                    .filter(Boolean)}
                  columns={[
                    {
                      dataIndex: 'label',
                      key: 'label',
                      width: 160,
                      render: (text, record, index) => ({
                        children: text,
                        props: {
                          style: {
                            backgroundColor: '#fafafa',
                            padding: '16px',
                            fontWeight: 'bold',
                          },
                        },
                      }),
                    },
                    {
                      dataIndex: 'value',
                      key: 'value',
                    },
                  ]}
                  showHeader={false}
                  pagination={false}
                />
              )}
            </Col>
          </Row>
        )}
        {key === '3' && (
          <Row gutter={[24, 36]}>
            <Col xs={24} xl={8}>
              <Title
                level={3}
                style={{ fontSize: '14px', color: 'rgb(0 212 255)' }}
              >
                重要語句
                <Tooltip title="上位サイトで頻出している重要な語句です。一般的に用いられるサジェストワードや共起語と比べ、ある意味ではよりリアルなデータと言えます。">
                  <InfoCircleOutlined
                    style={{ marginLeft: '6px', color: 'rgb(0 212 255)' }}
                  />
                </Tooltip>
                ：
              </Title>
              {renderTopicTable('relatedKeywords', relatedKeywords)}
            </Col>
            <Col xs={24} xl={8}>
              <Title
                level={3}
                style={{ fontSize: '14px', color: 'rgb(0 212 255)' }}
              >
                上位サイトの主要トピック
                <Tooltip title="上位サイトのコンテンツをAIが分析し、頻出しているトピックを整理したデータです。">
                  <InfoCircleOutlined
                    style={{ marginLeft: '6px', color: 'rgb(0 212 255)' }}
                  />
                </Tooltip>
                ：
              </Title>
              {renderTopicTable('topTopics', topTopics)}
            </Col>
            <Col xs={24} xl={8}>
              <Title
                level={3}
                style={{ fontSize: '14px', color: 'rgb(0 212 255)' }}
              >
                独自性を出しやすいトピック
                <Tooltip title="上位サイトではあまり扱っていないが、一定のニーズがある可能性が高いトピックのアイデアです。">
                  <InfoCircleOutlined
                    style={{ marginLeft: '6px', color: 'rgb(0 212 255)' }}
                  />
                </Tooltip>
                ：
              </Title>
              {renderTopicTable('uniqueTopics', uniqueTopics)}
            </Col>
          </Row>
        )}
        {key === '4' && (
          <>
            <Title
              level={3}
              style={{ fontSize: '14px', color: 'rgb(0 212 255)' }}
            >
              上位サイトで紹介されているプロダクト
              <Tooltip title="上位サイトで紹介されている商品やサービス、機関などをAIが分析したデータです。">
                <InfoCircleOutlined
                  style={{ marginLeft: '6px', color: 'rgb(0 212 255)' }}
                />
              </Tooltip>
              ：
            </Title>
            {products && products.length > 0 && (
              <Table
                dataSource={products.sort(
                  (a, b) =>
                    (b.article_data[article.id]?.presence || 0) -
                    (a.article_data[article.id]?.presence || 0),
                )}
                columns={productsColumns}
                pagination={false}
                scroll={{ x: 'max-content' }}
              />
            )}
          </>
        )}
        {key === '5' && (
          <Row gutter={[24, 40]}>
            <Col xs={24} lg={14}>
              <Title
                level={3}
                style={{ fontSize: '14px', color: 'rgb(0 212 255)' }}
              >
                AIタイトル
                <Tooltip title="分析したあらゆる情報を元に、AIが生成した記事のタイトル候補です。">
                  <InfoCircleOutlined
                    style={{ marginLeft: '6px', color: 'rgb(0 212 255)' }}
                  />
                </Tooltip>
                ：
              </Title>
              <Title level={2} style={{ margin: '8px 0 36px' }}>
                <TextArea
                  value={editableTitle}
                  onChange={e => setEditableTitle(e.target.value)}
                  onFocus={() => setIsEditingTitle(true)}
                  onBlur={async () => {
                    setIsEditingTitle(false);
                    if (editableTitle !== article.title) {
                      try {
                        await updateArticle({ title: editableTitle });
                        setArticle({ ...article, title: editableTitle });
                      } catch (error) {
                        setEditableTitle(article.title);
                      }
                    }
                  }}
                  autoSize={{ minRows: 1, maxRows: 3 }}
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    border: 'none',
                    padding: '0',
                    margin: '0',
                    width: '100%',
                    resize: 'none',
                  }}
                />
              </Title>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '4px',
                }}
              >
                <Title
                  level={3}
                  style={{ fontSize: '14px', color: 'rgb(0 212 255)' }}
                >
                  AI構成案
                  <Tooltip title="あらゆる情報を元にAIが生成した記事の構成案です。並び替えや編集が可能です。">
                    <InfoCircleOutlined
                      style={{ marginLeft: '6px', color: 'rgb(0 212 255)' }}
                    />
                  </Tooltip>
                  ：
                </Title>
                <Button
                  onClick={handleCopyStructure}
                  style={{ fontSize: '12px', color: '#108ee9' }}
                >
                  <CopyOutlined style={{ fontSize: '16px' }} />
                  &nbsp;コピー
                </Button>
              </div>
              {article.structured_headings && (
                <StructuredHeadings
                  headings={article.structured_headings}
                  onChange={updateArticleStructure}
                  isContentGenerated={
                    article.content !== null && article.content !== ''
                  }
                />
              )}
            </Col>
            <Col xs={24} lg={10}>
              <Affix offsetTop={24}>
                <div
                  className="content-check-table-wrap"
                  style={{ marginBottom: '16px' }}
                >
                  <Row
                    gutter={16}
                    style={{
                      justifyContent: 'space-between',
                      marginBottom: '8px',
                    }}
                  >
                    <Col xs={12}>
                      <Title
                        level={3}
                        style={{ fontSize: '14px', color: 'rgb(0 212 255)' }}
                      >
                        <Tooltip title="構成案に語句やトピックが含まれているか、チェックしてメモできます。">
                          構成案のチェック
                          <InfoCircleOutlined
                            style={{
                              marginLeft: '6px',
                              color: 'rgb(0 212 255)',
                            }}
                          />
                          ：
                        </Tooltip>
                      </Title>
                    </Col>
                    <Col xs={12} style={{ textAlign: 'right' }}>
                      <Switch
                        checked={showAllTopics}
                        onChange={checked => setShowAllTopics(checked)}
                        size="small"
                      />
                      <span style={{ fontSize: '12px', marginLeft: '4px' }}>
                        すべて表示する
                      </span>
                    </Col>
                  </Row>
                  <Segmented
                    options={[
                      {
                        label: '重要語句',
                        value: 'relatedKeywords',
                      },
                      {
                        label: '主要トピック',
                        value: 'topTopics',
                      },
                      {
                        label: '独自トピック',
                        value: 'uniqueTopics',
                      },
                    ]}
                    value={selectedTab}
                    onChange={setSelectedTab}
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: '4px',
                    }}
                    block
                  />
                  {selectedTab === 'relatedKeywords' && (
                    <Table
                      columns={[
                        {
                          title: 'チェック',
                          dataIndex: 'checked',
                          key: 'check',
                          width: '10%',
                          render: (_, record) => {
                            const keyword = record[0];
                            const checkState = record[2];
                            const checkboxColor =
                              checkState === 1
                                ? 'pink'
                                : checkState === 2
                                  ? 'red'
                                  : checkState === 3
                                    ? 'orange'
                                    : 'default';
                            return (
                              <Checkbox
                                checked={checkState !== 0}
                                onChange={() =>
                                  handleRelatedKeywordCheck(keyword)
                                }
                                className={`custom-checkbox ${checkboxColor}`}
                              />
                            );
                          },
                          ...fixedColumn50,
                        },
                        {
                          title: 'キーワード',
                          dataIndex: 'keyword',
                          key: 'keyword',
                          width: '75%',
                          render: (_, [keyword, frequency, checked]) => (
                            <span
                              style={{
                                color: checked === 0 ? 'inherit' : '#ccc',
                              }}
                            >
                              {keyword}
                            </span>
                          ),
                        },
                        {
                          title: '重要度',
                          dataIndex: 'importance',
                          key: 'importance',
                          width: '15%',
                          render: (_, [keyword, frequency]) => (
                            <Progress
                              percent={frequency * 20}
                              steps={5}
                              size={7}
                              strokeColor={{
                                '0%': '#108ee9',
                                '100%': '#87d068',
                              }}
                              format={() => null}
                            />
                          ),
                        },
                      ]}
                      dataSource={
                        showAllTopics
                          ? relatedKeywords
                          : relatedKeywords.filter(
                              ([_, __, checked]) =>
                                checked === 0 || checked === 5,
                            )
                      }
                      pagination={false}
                      showHeader={false}
                      size="small"
                      className="content-check-table"
                    />
                  )}
                  {selectedTab === 'topTopics' && (
                    <Table
                      columns={[
                        {
                          title: 'チェック',
                          dataIndex: 'checked',
                          key: 'check',
                          width: '10%',
                          render: (_, record) => {
                            const topic = record[0];
                            const checkState = record[2];
                            const checkboxColor =
                              checkState === 1
                                ? 'pink'
                                : checkState === 2
                                  ? 'red'
                                  : checkState === 3
                                    ? 'orange'
                                    : 'default';
                            return (
                              <Checkbox
                                checked={checkState !== 0}
                                onChange={() => handleTopTopicCheck(topic)}
                                className={`custom-checkbox ${checkboxColor}`}
                              />
                            );
                          },
                          ...fixedColumn50,
                        },
                        {
                          title: 'トピック',
                          dataIndex: 'topic',
                          key: 'topic',
                          width: '75%',
                          render: (_, [keyword, frequency, checked]) => (
                            <span
                              style={{
                                color: checked === 0 ? 'inherit' : '#ccc',
                              }}
                            >
                              {keyword}
                            </span>
                          ),
                        },
                        {
                          title: '重要度',
                          dataIndex: 'importance',
                          key: 'importance',
                          width: '15%',
                          render: (_, [keyword, frequency]) => (
                            <Progress
                              percent={frequency * 20}
                              steps={5}
                              size={7}
                              strokeColor={{
                                '0%': '#108ee9',
                                '100%': '#87d068',
                              }}
                              format={() => null}
                            />
                          ),
                        },
                      ]}
                      dataSource={
                        showAllTopics
                          ? topTopics
                          : topTopics.filter(
                              ([_, __, checked]) =>
                                checked === 0 || checked === 5,
                            )
                      }
                      pagination={false}
                      showHeader={false}
                      size="small"
                      className="content-check-table"
                    />
                  )}
                  {selectedTab === 'uniqueTopics' && (
                    <Table
                      columns={[
                        {
                          title: 'チェック',
                          dataIndex: 'checked',
                          key: 'check',
                          width: '10%',
                          render: (_, record) => {
                            const topic = record[0];
                            const checkState = record[2];
                            const checkboxColor =
                              checkState === 1
                                ? 'pink'
                                : checkState === 2
                                  ? 'red'
                                  : checkState === 3
                                    ? 'orange'
                                    : 'default';
                            return (
                              <Checkbox
                                checked={checkState !== 0}
                                onChange={() => handleUniqueTopicCheck(topic)}
                                className={`custom-checkbox ${checkboxColor}`}
                              />
                            );
                          },
                          ...fixedColumn50,
                        },
                        {
                          title: 'トピック',
                          dataIndex: 'topic',
                          key: 'topic',
                          width: '75%',
                          render: (_, [keyword, uniqueness, checked]) => (
                            <span
                              style={{
                                color: checked === 0 ? 'inherit' : '#ccc',
                              }}
                            >
                              {keyword}
                            </span>
                          ),
                        },
                        {
                          title: '独自性',
                          dataIndex: 'uniqueness',
                          key: 'uniqueness',
                          width: '15%',
                          render: (_, [keyword, uniqueness]) => (
                            <Progress
                              percent={uniqueness * 20}
                              steps={5}
                              size={7}
                              strokeColor={{
                                '0%': '#108ee9',
                                '100%': '#87d068',
                              }}
                              format={() => null}
                            />
                          ),
                        },
                      ]}
                      dataSource={
                        showAllTopics
                          ? uniqueTopics
                          : uniqueTopics.filter(
                              ([_, __, checked]) =>
                                checked === 0 || checked === 5,
                            )
                      }
                      pagination={false}
                      showHeader={false}
                      size="small"
                      className="content-check-table"
                    />
                  )}
                </div>
                {article.structured_headings && renderTopicCheckButton()}
              </Affix>
            </Col>
          </Row>
        )}
        {key === '6' && (
          <>
            <Row
              gutter={[40, 40]}
              style={{ justifyContent: 'center', margin: '0px' }}
            >
              <Col
                xs={24}
                lg={24}
                className="article_wrap"
                style={{ width: '100%', padding: '0px' }}
              >
                {article.content ? (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        gap: '16px',
                        marginBottom: '20px',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>
                        生成された文字数：
                        <span style={{ fontSize: '24px', fontWeight: 'bold' }}>
                          {article.content
                            ? calculateHtmlCharacterCount(
                                article.content,
                              ).toLocaleString()
                            : '0'}
                        </span>
                        文字
                      </div>
                      <Tooltip title="各文の末尾にある[n]形式のソース参照を表示/非表示にします。">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Switch
                            checked={showSources}
                            onChange={setShowSources}
                            style={{ marginRight: '8px' }}
                          />
                          <span>各センテンスのソースを表示</span>
                        </div>
                      </Tooltip>
                    </div>
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gap: '12px',
                        marginBottom: '20px',
                      }}
                    >
                      <Tooltip title="記事のHTMLコードをコピーします。ウェブサイトに貼り付ける際に便利です。">
                        <Button
                          onClick={copyHtml}
                          icon={<CodeOutlined />}
                          size="middle"
                          style={{ height: '36px' }}
                        >
                          HTMLをコピー
                        </Button>
                      </Tooltip>
                      <Tooltip title="記事をGoogleドキュメントに適した形式でコピーします。書式が保持されます。">
                        <Button
                          onClick={copyText}
                          icon={<CopyOutlined />}
                          size="middle"
                          style={{ height: '36px' }}
                        >
                          Google Docs用にコピー
                        </Button>
                      </Tooltip>
                    </div>
                    <Divider />
                    <div className="article_title">{article.title}</div>
                    {article.eyecatch_image_url && (
                      <img
                        src={`${process.env.REACT_APP_API_URL}${article.eyecatch_image_url}`}
                        alt="Article eyecatch"
                        style={{
                          maxWidth: '100%',
                          height: 'auto',
                          marginBottom: '20px',
                        }}
                      />
                    )}
                    <div>
                      <ArticleContent article={article} />
                    </div>
                    {/* {article.t_subheading_outlines && (
                      <div>
                        <h3>Subheading Outlines:</h3>
                        <JSONPretty data={article.t_subheading_outlines} />
                      </div>
                    )}
                    {article.t_section_leads && (
                      <div>
                        <h3>Section Leads:</h3>
                        <JSONPretty data={article.t_section_leads} />
                      </div>
                    )}
                    {article.t_section_content && (
                      <div>
                        <h3>Section Content:</h3>
                        <JSONPretty data={article.t_section_content} />
                      </div>
                    )}
                    {article.t_refined_section_content && (
                      <div>
                        <h3>Refined Section Content:</h3>
                        <JSONPretty data={article.t_refined_section_content} />
                      </div>
                    )}
                    {article.t_article_lead && (
                      <div>
                        <h3>Article Lead:</h3>
                        <JSONPretty data={article.t_article_lead} />
                      </div>
                    )} */}
                  </>
                ) : (
                  <>
                    <div className="article_title">{article.title}</div>
                    <Skeleton
                      loading={true}
                      active
                      paragraph={{
                        rows: 4,
                        width: ['100%', '100%', '100%', '100%'],
                      }}
                      title={{ width: '20%' }}
                      style={{ marginBottom: '36px' }}
                    >
                      <div style={{ marginBottom: '20px' }}>
                        <Skeleton.Paragraph
                          style={{ width: 200 }}
                          active
                          size="small"
                        />
                        <br />
                        <Skeleton.Paragraph
                          style={{ width: 150, marginTop: 16 }}
                          active
                          size="small"
                        />
                      </div>
                    </Skeleton>
                    <div
                      className="content-structure-wrap"
                      style={{ marginBottom: '24px' }}
                    >
                      <div className="table-of-contents">
                        <div className="toc-title">この記事の目次</div>
                        {article &&
                          article.structured_headings &&
                          renderStructuredHeadings(article.structured_headings)}
                      </div>
                    </div>
                    {article && article.structured_headings ? (
                      <Title
                        level={3}
                        style={{
                          fontSize: '14px',
                          color: 'rgb(0 212 255)',
                          marginBottom: '16px',
                        }}
                      >
                        想定文字数：
                        <span style={{ fontSize: '24px', fontWeight: 'bold' }}>
                          {(() => {
                            const headings =
                              article.structured_headings.split('\n');
                            const h2Count = headings.filter(line =>
                              line.startsWith('$$'),
                            ).length;
                            const h3Count = headings.filter(line =>
                              line.startsWith('###'),
                            ).length;

                            // 見出しの文字数を計算
                            const headingsText = headings
                              .filter(
                                line =>
                                  line.startsWith('$$') ||
                                  line.startsWith('###'),
                              )
                              .map(line => {
                                if (line.startsWith('$$')) {
                                  return line.substring(2).trim();
                                } else if (line.startsWith('###')) {
                                  return line.substring(3).trim();
                                }
                                return '';
                              })
                              .join('');

                            const headingsLength = headingsText.length;

                            // 新しい計算式: (中見出し数×400) + (大見出し数 × 200) + 150 + 見出しの文字数
                            const estimatedCharCount =
                              h3Count * 400 +
                              h2Count * 200 +
                              150 +
                              headingsLength;

                            return estimatedCharCount.toLocaleString();
                          })()}
                        </span>
                        文字
                      </Title>
                    ) : (
                      <Title
                        level={3}
                        style={{
                          fontSize: '14px',
                          color: 'rgb(0 212 255)',
                          marginBottom: '16px',
                        }}
                      >
                        想定文字数：計算不可
                      </Title>
                    )}
                    <Paragraph>
                      <ul style={{ lineHeight: 2.0 }}>
                        <li>本文生成のやり直しはできません。</li>
                        <li>
                          上記のタイトルや構成案に沿ってAIが本文を生成します。
                        </li>
                        <li>文字数は目安となります。</li>
                        <li>記事の叩きとして利用することを推奨しています。</li>
                      </ul>
                    </Paragraph>
                    {renderContentGenerationButton()}
                  </>
                )}
              </Col>
            </Row>
            <MeetingCTA />
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>{`「${article?.keyword || ''}」の生成結果 | SEO記事生成モード - magicss`}</title>
        <meta name="robots" content="noindex" />
        <meta
          property="og:title"
          content={`「${article?.keyword || ''}」の生成結果 | SEO記事生成モード - magicss`}
        />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={
            article?.eyecatch_image_url
              ? `${process.env.REACT_APP_API_URL}${article.eyecatch_image_url}`
              : `${process.env.PUBLIC_URL}/img/ogp.png`
          }
        />
        <meta
          property="og:description"
          content={`「${article?.keyword || ''}」のSEO記事生成結果です。`}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div id="a_detail">
        <Row gutter={24}>
          <Col xs={24} lg={14}>
            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>
                <Link to="/seo-article">SEO記事生成</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/seo-article/list">生成結果一覧</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="hide-on-mobile">
                「{article?.keyword}」の生成結果
              </Breadcrumb.Item>
            </Breadcrumb>
            <Title
              level={1}
              className="gradient-text"
              style={{ fontSize: '24px', lineHeight: '1.6' }}
            >
              「{article.keyword}」の生成結果
            </Title>
            <Text
              type="secondary"
              style={{ display: 'block', marginBottom: '24px' }}
            >
              生成完了日:{' '}
              {moment
                .utc(article.completed_at)
                .tz('Asia/Tokyo')
                .format('YYYY/MM/DD HH:mm')}
            </Text>
          </Col>
          <Col xs={24} lg={10} className="share_setting">
            <Space direction="vertical" align="end" size="small" gap="8px">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '8px',
                }}
              >
                <Text type="secondary" className="hide-on-mobile">
                  共有設定：
                </Text>
                <Tooltip title={getShareTooltip(shareStatus)}>
                  <Dropdown
                    menu={{ items: getShareMenuItems() }}
                    trigger={['click']}
                  >
                    <Button>
                      {getShareButtonText(shareStatus)}
                      <Divider type="vertical" />
                      <SettingOutlined />
                    </Button>
                  </Dropdown>
                </Tooltip>
              </div>
              {shareStatus !== 'private' && (
                <Input.Group
                  compact
                  className="share_settings"
                  style={{ display: 'flex' }}
                >
                  <Input
                    style={{ flex: 1, minWidth: '300px' }}
                    value={shareUrl}
                    readOnly
                  />
                  <Button icon={<CopyOutlined />} onClick={copyShareUrl}>
                    コピー
                  </Button>
                </Input.Group>
              )}
              <ExportButton article={article} userPlan={userPlan} />
            </Space>
          </Col>
        </Row>
        <Divider style={{ margin: '24px 0' }} />
        <div style={{ paddingBottom: screens.lg ? 0 : '60px' }}>
          {screens.lg ? (
            <Tabs
              activeKey={activeKey}
              onChange={handleTabChange}
              type="card"
              size="middle"
              style={{ width: '100%' }}
            >
              {tabItems.map(item => (
                <TabPane
                  tab={
                    <Tooltip title={item.tooltip}>
                      <span>
                        {item.icon}
                        <span style={{ marginLeft: '8px' }}>{item.title}</span>
                        {item.badge && (
                          <Badge color="red" style={{ marginLeft: '5px' }} />
                        )}
                        {item.processingBadge && (
                          <Badge
                            icon={
                              <ClockCircleOutlined
                                style={{ fontSize: '10px' }}
                              />
                            }
                            style={{ marginLeft: '5px' }}
                          />
                        )}
                      </span>
                    </Tooltip>
                  }
                  key={item.key}
                >
                  {renderTabContent(item.key)}
                </TabPane>
              ))}
            </Tabs>
          ) : (
            <>
              {renderTabContent(activeKey)}
              <MobileTabMenu
                activeKey={activeKey}
                onChange={handleTabChange}
                items={tabItems}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ArticleDetail;
