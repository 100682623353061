// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#u_detail input,
#u_detail .ant-select-selector,
#u_detail .ant-input-number,
#u_detail .ant-picker,
#u_detail textarea {
    border-color: #fff!important;
}
.u_detail_modal .ant-modal-content {
    height: 80vh;
    padding: 0;
}
.u_detail_modal .ant-modal-body {
    padding: 40px;
}
.u_detail_modal .ant-tabs-content-holder {
    height: calc(80vh - 150px);
    overflow-y: auto;
    scroll-behavior: smooth;
    scrollbar-width: thin;
    scrollbar-color: #e7e7e7 #f5f5f5;
}
.u_detail_modal .ant-tabs-content {
    padding: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/UserManagement/UserDetail.css"],"names":[],"mappings":"AAAA;;;;;IAKI,4BAA4B;AAChC;AACA;IACI,YAAY;IACZ,UAAU;AACd;AACA;IACI,aAAa;AACjB;AACA;IACI,0BAA0B;IAC1B,gBAAgB;IAChB,uBAAuB;IACvB,qBAAqB;IACrB,gCAAgC;AACpC;AACA;IACI,aAAa;AACjB","sourcesContent":["#u_detail input,\n#u_detail .ant-select-selector,\n#u_detail .ant-input-number,\n#u_detail .ant-picker,\n#u_detail textarea {\n    border-color: #fff!important;\n}\n.u_detail_modal .ant-modal-content {\n    height: 80vh;\n    padding: 0;\n}\n.u_detail_modal .ant-modal-body {\n    padding: 40px;\n}\n.u_detail_modal .ant-tabs-content-holder {\n    height: calc(80vh - 150px);\n    overflow-y: auto;\n    scroll-behavior: smooth;\n    scrollbar-width: thin;\n    scrollbar-color: #e7e7e7 #f5f5f5;\n}\n.u_detail_modal .ant-tabs-content {\n    padding: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
