import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, message, Row, Col, Typography, Segmented } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { registerEmail as registerEmailAuth } from '../../auth';
import styles from './Auth.module.css';
import '../../index.css'
import LoginForm from './LoginForm';
import RegisterEmailForm from './RegisterEmail';

const { Title } = Typography;

const Auth = () => {
  const [authType, setAuthType] = useState('login');
  const navigate = useNavigate();

  const handleLogin = () => {
    message.success('ログインに成功しました');
    navigate('/');
  };

  const handleRegisterEmail = async (values) => {
    try {
      await registerEmailAuth(values.email);
      message.success('認証URLを送信しました。メールをご確認ください。');
      return true; // 成功時にtrueを返す
    } catch (error) {
      if (error.response && error.response.status === 400) {
        message.error(error.response.data.detail);
      } else {
        message.error('認証URLの送信に失敗しました');
      }
      return false; // 失敗時にfalseを返す
    }
  };

  return (
    <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
      <Col xs={22} sm={18} md={12} lg={8}>
        <div className={styles.authContainer} style={{boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)'}}>
          <div className='logo outside'>
            magicss
          </div>
          <Segmented
            options={[
              { label: 'ログイン', value: 'login' },
              { label: '新規登録', value: 'register' },
            ]}
            value={authType}
            onChange={setAuthType}
            block
            size="large"
            className={styles.authSegmented}
          />
          <div className={styles.authContent}>
            {authType === 'login' ? (
              <LoginForm onFinish={handleLogin} />
            ) : (
              <RegisterEmailForm onFinish={handleRegisterEmail} />
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Auth;