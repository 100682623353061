import React, { useState, useEffect } from 'react';
import {
  Typography,
  Row,
  Col,
  Button,
  Card,
  Space,
  Divider,
  Modal,
  Switch,
  message,
  Tag,
  Checkbox,
  Input,
} from 'antd';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  FireOutlined,
  RightOutlined,
  InfoCircleOutlined,
  CrownOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { getUserProfile } from '../auth';
import { Helmet } from 'react-helmet';
import { api, getCurrentSubscription } from '../api';
import { trackEvent } from '../utils/ga4';
import moment from 'moment';

const { Title, Text, Paragraph } = Typography;

const PlanSettings = () => {
  const [allPlans, setAllPlans] = useState([]); // すべてのプラン（API応答そのまま）
  const [userPlan, setUserPlan] = useState(null); // ユーザーの現在のプラン
  const [isYearly, setIsYearly] = useState(false); // デフォルトを月払いに変更
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [nextRenewalDate, setNextRenewalDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [newPlanPrice, setNewPlanPrice] = useState(0);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [ticketChange, setTicketChange] = useState(0);
  const [couponCode, setCouponCode] = useState('');
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [isCouponApplying, setIsCouponApplying] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isUserLegacyPlan, setIsUserLegacyPlan] = useState(false);

  // 表示用プラン
  const [standardPlans, setStandardPlans] = useState([]); // 新プラン（ライト/スタンダード/プレミアム）
  const [freePlan, setFreePlan] = useState(null); // フリープラン
  const [customPlan, setCustomPlan] = useState(null); // カスタムプラン

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      console.log('データ取得開始');
      await fetchPlans();
      await fetchUserProfile();
      await fetchCurrentSubscription();
      console.log('データ取得完了');
    };
    fetchData();
  }, []);

  const fetchPlans = async () => {
    try {
      const response = await api.get('/plans/');
      console.log('APIからのプラン一覧:', response.data);

      // 静的なカスタムプラン
      const customPlanData = {
        id: 'custom',
        name: 'custom',
        display_name: 'カスタムプラン',
        type: 'custom',
        monthly_tickets: '????',
        description: {
          seo_can: [
            'プレミアムプランと同等の全機能を利用可能',
            'チケット数のカスタマイズ',
            '請求書払い対応',
            '導入サポートの優遇',
            '専任のサポート担当者',
          ],
          seo_cant: [],
        },
      };

      // すべてのプランを保持
      setAllPlans(response.data);

      // 名前に基づいてレガシープランか判定する関数
      const isLegacyPlan = plan => {
        return !(
          plan.name === 'lite' ||
          plan.name === 'standard_new' ||
          plan.name === 'premium_new' ||
          plan.name === 'free_new'
        );
      };

      // 中段：新プラン（ライト/スタンダード/プレミアム）のみ
      const newPlans = response.data
        .filter(
          plan =>
            !isLegacyPlan(plan) &&
            plan.type !== 'free' &&
            plan.type !== 'custom',
        )
        .sort((a, b) => a.priority - b.priority);

      console.log('新プラン（中段）:', newPlans);
      setStandardPlans(newPlans);

      // 下段：フリープラン
      const freePlanData = response.data.find(plan => plan.type === 'free');
      console.log('フリープラン（下段）:', freePlanData);
      setFreePlan(freePlanData);

      // カスタムプラン
      const customPlanFromAPI = response.data.find(
        plan => plan.type === 'custom',
      );
      const finalCustomPlan = customPlanFromAPI || customPlanData;
      console.log('カスタムプラン（下段）:', finalCustomPlan);
      setCustomPlan(finalCustomPlan);
    } catch (error) {
      console.error('Failed to fetch plans:', error);
      message.error('プランの取得に失敗しました');
    }
  };

  const fetchUserProfile = async () => {
    try {
      const profile = await getUserProfile();
      setUserProfile(profile);
      setUserPlan(profile.plan);

      // ユーザーがレガシープランを使用しているか確認
      // 名前に基づいてレガシープランか判定
      if (profile.plan) {
        const planName = profile.plan.name;
        const isLegacy = !(
          planName === 'lite' ||
          planName === 'standard_new' ||
          planName === 'premium_new' ||
          planName === 'free_new'
        );

        if (
          isLegacy &&
          (planName === 'standard' ||
            planName === 'pro' ||
            planName === 'premium')
        ) {
          console.log(
            'ユーザーはレガシープランを使用しています:',
            profile.plan,
          );
          setIsUserLegacyPlan(true);

          // レガシー表示用にplan.is_legacyプロパティを追加
          profile.plan.is_legacy = true;
          setUserPlan(profile.plan);
        }
      }
    } catch (error) {
      console.error('Failed to fetch user profile:', error);
      message.error('ユーザー情報の取得に失敗しました');
    }
  };

  const fetchCurrentSubscription = async () => {
    try {
      const subscription = await getCurrentSubscription();
      setCurrentSubscription(subscription);
    } catch (error) {
      console.error('Failed to fetch current subscription:', error);
      message.error('現在のサブスクリプション情報の取得に失敗しました');
    }
  };

  const handlePlanChange = async planId => {
    setIsLoading(true);
    // customプランの場合は文字列'custom'が渡されるため、特別に処理
    const plan =
      planId === 'custom' ? customPlan : allPlans.find(p => p.id === planId);

    if (!plan) {
      console.error('プランが見つかりません', planId);
      message.error('プランが見つかりません');
      setIsLoading(false);
      return;
    }

    setSelectedPlan(plan);

    trackEvent('view_plan_details', {
      user_id: userProfile.user_id,
      current_plan: userProfile.plan.type,
      viewed_plan: plan.type,
    });

    // カスタムプランの場合は問い合わせに誘導
    if (plan.type === 'custom') {
      setIsLoading(false);
      handleCustomPlanInquiry();
      return;
    }

    try {
      const response = await api.post('/subscriptions/calculate-plan-change', {
        new_plan_id: planId,
        is_yearly: isYearly,
      });

      setNewPlanPrice(response.data.new_plan_price);
      setPaymentAmount(response.data.payment_amount);
      setNextRenewalDate(moment(response.data.next_renewal_date));
      setTicketChange(response.data.ticket_change);
      setConfirmModalVisible(true);
    } catch (error) {
      console.error('Failed to fetch prorated amount:', error);
      message.error('プラン変更の計算に失敗しました');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmPlanChange = async () => {
    setIsLoading(true);
    try {
      const response = await api.post('/subscriptions/change-plan', {
        new_plan_id: selectedPlan.id,
        is_yearly: isYearly,
        coupon_code: appliedCoupon ? appliedCoupon.code : null,
      });

      if (response.data.status === 'checkout') {
        window.location.href = response.data.checkout_url;
      } else if (response.data.status === 'success') {
        message.success(response.data.message);
        if (selectedPlan.type === 'free') {
          setUserProfile(prevProfile => ({
            ...prevProfile,
            plan_id: response.data.new_plan_id,
            ticket_count: response.data.ticket_count,
          }));
        } else {
          await fetchUserProfile();
        }
        trackEvent('complete_plan_change', {
          user_id: userProfile.user_id,
          from_plan: userProfile.plan.type,
          to_plan: selectedPlan.type,
          is_yearly: isYearly,
        });
        await fetchCurrentSubscription();
        setConfirmModalVisible(false);
      }
    } catch (error) {
      console.error('Failed to change plan:', error);
      message.error(
        'プラン変更に失敗しました: ' +
          (error.response?.data?.detail || error.message),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleCustomPlanInquiry = () => {
    // 問い合わせページへの遷移先を変更
    const magicssUuid = userProfile?.user_id || '';
    window.location.href = `${process.env.REACT_APP_SITE_URL}/reserve-mtg?magicss_uuid=${magicssUuid}`;
  };

  const handleCouponApply = async () => {
    if (!couponCode.trim()) {
      message.warning('クーポンコードを入力してください');
      return;
    }

    setIsCouponApplying(true);
    try {
      const response = await api.post('/subscriptions/validate-coupon', {
        code: couponCode,
        is_yearly: isYearly,
      });
      if (response.data.is_monthly_only && isYearly) {
        message.warning('このクーポンは月払いのみ適用可能です');
        setAppliedCoupon(null);
      } else {
        setAppliedCoupon(response.data);
        message.success('クーポンが適用されました');
        await fetchPlans();
      }
    } catch (error) {
      console.error('Coupon application error:', error);
      if (error.response && error.response.data) {
        message.error(error.response.data.detail || '無効なクーポンコードです');
      } else {
        message.error('クーポンの適用に失敗しました');
      }
      setAppliedCoupon(null);
    } finally {
      setIsCouponApplying(false);
    }
  };

  useEffect(() => {
    if (appliedCoupon && appliedCoupon.is_monthly_only && isYearly) {
      setAppliedCoupon(null);
      message.warning('月払い専用クーポンが削除されました');
    }
  }, [isYearly]);

  const handleCouponRemove = () => {
    setAppliedCoupon(null);
    setCouponCode('');
    message.success('クーポンが削除されました');
    // プランの価格を再計算
    fetchPlans();
  };

  const calculateDiscountedPrice = price => {
    if (appliedCoupon) {
      const discountAmount = price * (appliedCoupon.discount_rate / 100);
      return price - discountAmount;
    }
    return price;
  };

  const planTags = {
    free: { color: 'blue', text: 'フリープラン' },
    standard: { color: 'green', text: 'スタンダードプラン' },
    pro: { color: 'orange', text: 'プロプラン' },
    premium: { color: 'red', text: 'プレミアムプラン' },
    custom: { color: 'purple', text: 'カスタムプラン' },
    lite: { color: 'cyan', text: 'ライトプラン' },
  };

  const renderPlanCard = plan => {
    // カスタムプランの特別処理
    if (!plan) return null;

    // 名前に基づいてレガシープランか判定
    const isLegacyPlan =
      plan.is_legacy ||
      (plan.name &&
        !(
          plan.name === 'lite' ||
          plan.name === 'standard_new' ||
          plan.name === 'premium_new' ||
          plan.name === 'free_new'
        ) &&
        (plan.name === 'standard' ||
          plan.name === 'pro' ||
          plan.name === 'premium'));

    const yearlyPrice = calculateDiscountedPrice(plan.yearly_price || 0);
    const monthlyPrice = calculateDiscountedPrice(plan.monthly_price || 0);
    const displayPrice = isYearly ? yearlyPrice / 12 : monthlyPrice;
    const isCurrentPlan = userPlan && userPlan.id === plan.id;
    const isCustomPlan = plan.type === 'custom';

    // 表示名の横にLegacyを追加
    const displayName = isLegacyPlan
      ? `${plan.display_name}（Legacy）`
      : plan.display_name;

    // カードを3列表示に変更（4列から3列へ）
    const colSpan = { xs: 24, sm: 12, md: 8 };

    return (
      <Col
        {...colSpan}
        key={plan.id}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Card
          title={
            <>
              {isLegacyPlan && isCurrentPlan && (
                <div
                  style={{
                    fontSize: '12px',
                    color: '#666',
                    marginBottom: '4px',
                  }}
                >
                  現在入っているプラン
                </div>
              )}
              {displayName}
            </>
          }
          style={{
            textAlign: 'center',
            width: '100%',
            borderTop: '3px solid #1890ff',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              marginBottom: '16px',
              height: '80px',
            }}
          >
            {plan.type !== 'free' && !isCustomPlan && (
              <>
                {!appliedCoupon && (
                  <>
                    <Text type="secondary" style={{ fontSize: '16px' }}>
                      ¥
                    </Text>
                    <Text strong style={{ fontSize: '24px', margin: '0 4px' }}>
                      {Math.floor(displayPrice * 1.1).toLocaleString()}
                    </Text>
                    <Text type="secondary" style={{ fontSize: '12px' }}>
                      (税込) / 月あたり
                    </Text>
                  </>
                )}
                {appliedCoupon && (
                  <>
                    <Text type="secondary" style={{ fontSize: '16px' }}>
                      ¥
                    </Text>
                    <Text
                      strong
                      style={{
                        fontSize: '24px',
                        margin: '0 4px',
                        color: '#0072ff',
                      }}
                    >
                      {Math.floor(displayPrice * 1.1).toLocaleString()}
                    </Text>
                    <Text type="secondary" style={{ fontSize: '12px' }}>
                      (税込) / 初月
                    </Text>
                    <br />
                    <Text type="secondary" style={{ fontSize: '12px' }}>
                      次回更新以降: ¥
                      {Math.floor(plan.monthly_price * 1.1).toLocaleString()}{' '}
                      (税込)
                    </Text>
                  </>
                )}
                <br />
                {isYearly && (
                  <>
                    <Text
                      type="secondary"
                      style={{
                        fontSize: '12px',
                        textDecoration: 'line-through',
                      }}
                    >
                      ¥{Math.floor(monthlyPrice * 1.1).toLocaleString()} / 月
                    </Text>
                    <br />
                    <Text type="secondary" style={{ fontSize: '12px' }}>
                      年間: ¥{Math.floor(yearlyPrice * 1.1).toLocaleString()}{' '}
                      (税込)
                    </Text>
                  </>
                )}
              </>
            )}
            {plan.type === 'free' && (
              <>
                <Text type="secondary" style={{ fontSize: '16px' }}>
                  ¥
                </Text>
                <Text strong style={{ fontSize: '24px' }}>
                  0
                </Text>
              </>
            )}
            {isCustomPlan && (
              <Text strong style={{ fontSize: '24px' }}>
                ASK
              </Text>
            )}
          </div>
          <Space
            direction="vertical"
            size="middle"
            style={{ width: '100%', textAlign: 'center' }}
          >
            <Text style={{ fontSize: '12px' }}>
              <FireOutlined
                style={{
                  color: '#0072ff',
                  marginRight: '4px',
                  fontSize: '18px',
                }}
              />
              毎月のチケット付与数：
              <span
                style={{
                  fontWeight: 'bold',
                  fontSize: '18px',
                  color: '#0072ff',
                }}
              >
                {isCustomPlan ? 'ASK' : plan.monthly_tickets}{' '}
              </span>
              {!isCustomPlan && '枚'}
            </Text>
          </Space>
          <Divider style={{ margin: '16px 0' }} />
          {!isLegacyPlan && (
            <Space
              direction="vertical"
              size="middle"
              style={{ width: '100%', textAlign: 'left' }}
            >
              <Text style={{ fontWeight: 'bold', color: '#00d4ff' }}>
                SEO記事生成モード：
              </Text>
              {plan.description.seo_can &&
                plan.description.seo_can.map((data, index) => (
                  <Text key={index}>
                    <CheckCircleFilled
                      style={{ color: '#00d4ff', marginRight: '8px' }}
                    />
                    {data}
                  </Text>
                ))}
              {plan.description.seo_cant &&
                plan.description.seo_cant.map((data, index) => (
                  <Text key={index} type="secondary">
                    <CloseCircleFilled
                      style={{ color: '#ff4d4f', marginRight: '8px' }}
                    />
                    {data}
                  </Text>
                ))}
            </Space>
          )}
          <Button
            loading={isLoading}
            type="primary"
            size="large"
            block
            style={{
              marginTop: '24px',
              ...(isCurrentPlan || (userPlan && userPlan.type === 'custom')
                ? {
                    backgroundColor: '#f5f5f5',
                    color: '#999',
                    cursor: 'not-allowed',
                    fontWeight: 'bold',
                    border: 'none',
                  }
                : {
                    background:
                      'linear-gradient(90deg, #0072ff 0%, #00d4ff 100%)',
                    border: 'none',
                    transition: 'all 0.3s',
                    fontWeight: 'bold',
                  }),
            }}
            onMouseEnter={e => {
              if (!isCurrentPlan) {
                e.currentTarget.style.opacity = '0.7';
              }
            }}
            onMouseLeave={e => {
              if (!isCurrentPlan) {
                e.currentTarget.style.opacity = '1.0';
              }
            }}
            onClick={() => handlePlanChange(plan.id)}
            disabled={
              isCurrentPlan ||
              (userPlan && userPlan.type === 'custom') ||
              isLoading
            }
          >
            {isCurrentPlan
              ? '現在のプラン'
              : userPlan && userPlan.type === 'custom'
                ? '変更できません'
                : isCustomPlan
                  ? 'お問い合わせ'
                  : 'このプランに変更する'}
            <RightOutlined
              style={{
                marginLeft: 'auto',
                right: '10px',
                position: 'absolute',
                top: '50%',
                transform: 'translate(0, -50%)',
                fontSize: '14px',
              }}
            />
          </Button>
        </Card>
      </Col>
    );
  };

  // フリープランとカスタムプランを表示するための関数
  const renderBottomPlans = () => {
    return (
      <Row gutter={[16, 24]} justify="center">
        {freePlan && renderPlanCard(freePlan)}
        {customPlan && renderPlanCard(customPlan)}
      </Row>
    );
  };

  return (
    <>
      <Helmet>
        <title>プラン変更 | magicss</title>
        <meta name="robots" content="noindex" />
        <meta property="og:title" content={`プラン変更 | magicss`} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/ogp.png`}
        />
        <meta
          property="og:description"
          content={`コンテンツ生成AIツール「magicss」のプラン変更ページです。`}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div>
        <Text style={{ margin: '16px 0' }}>ユーザー設定</Text>
        <Title level={1} className="gradient-text" style={{ fontSize: '24px' }}>
          ご利用プランの変更
        </Title>
        <Text style={{ color: '#6d8eb7' }}>
          このページでプランを即時変更できます。請求書払いをご希望の法人様、料金やチケットのカスタマイズをご希望される方はお気軽にお問い合わせください。
        </Text>
        <Divider style={{ margin: '24px 0' }} />
        <Row gutter={[16, 8]} style={{ marginBottom: '24px' }}>
          <Col xs={24} lg={4}>
            <Switch
              checked={isYearly}
              onChange={checked => setIsYearly(checked)}
              checkedChildren="年払い"
              unCheckedChildren="月払い"
            />
          </Col>
          <Col
            xs={24}
            lg={{ span: 12, offset: 8 }}
            style={{ textAlign: 'right' }}
          >
            <Input
              placeholder="クーポンコード"
              value={couponCode}
              onChange={e => setCouponCode(e.target.value)}
              style={{ width: '200px', marginRight: '10px' }}
              disabled={appliedCoupon !== null}
            />
            {appliedCoupon ? (
              <Button onClick={handleCouponRemove}>削除</Button>
            ) : (
              <Button onClick={handleCouponApply} loading={isCouponApplying}>
                {isCouponApplying ? '適用中' : '適用'}
              </Button>
            )}
          </Col>
        </Row>

        {/* 1. 上段：現在のプラン（レガシープラン加入中ユーザーのみ） */}
        {isUserLegacyPlan && userPlan && (
          <Row
            gutter={[16, 24]}
            justify="center"
            style={{ marginBottom: '32px' }}
          >
            {renderPlanCard(userPlan)}
          </Row>
        )}

        {/* 2. 中段：新プラン（ライト/スタンダード/プレミアム） */}
        {standardPlans.length > 0 && (
          <Row
            gutter={[16, 24]}
            justify="center"
            style={{ marginBottom: '32px' }}
          >
            {standardPlans
              .sort((a, b) => {
                // ライト→スタンダード→プレミアムの順に表示
                const order = { lite: 1, standard_new: 2, premium_new: 3 };
                return (order[a.name] || 99) - (order[b.name] || 99);
              })
              .map(plan => renderPlanCard(plan))}
          </Row>
        )}

        {/* 3. 下段：フリープラン/カスタムプラン */}
        {(freePlan || customPlan) && renderBottomPlans()}

        <Modal
          title={<Title level={3}>プラン変更の確認</Title>}
          visible={confirmModalVisible}
          onOk={confirmPlanChange}
          onCancel={() => setConfirmModalVisible(false)}
          okText="プラン変更に進む"
          cancelText="キャンセル"
          width={800}
          okButtonProps={{ disabled: !isConfirmed }}
        >
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <Card>
              <Row gutter={[16, 16]}>
                <Col xs={24} lg={8}>
                  <Text strong>現在のプラン:</Text>
                  <br />
                  <Tag
                    color={userPlan ? planTags[userPlan.type].color : 'default'}
                  >
                    {userPlan
                      ? userPlan.is_legacy
                        ? `${userPlan.display_name}（Legacy）`
                        : userPlan.display_name
                      : 'プラン未設定'}
                  </Tag>
                </Col>
                <Col xs={24} lg={8}>
                  <Text strong>新しいプラン:</Text>
                  <br />
                  <Tag
                    color={
                      selectedPlan
                        ? planTags[selectedPlan.type].color
                        : 'default'
                    }
                  >
                    {selectedPlan ? selectedPlan.display_name : 'プラン未選択'}
                  </Tag>
                </Col>
                <Col xs={24} lg={8}>
                  <Text strong>次回更新日:</Text>
                  <br />
                  <Text>
                    {nextRenewalDate && nextRenewalDate.isValid()
                      ? nextRenewalDate.format('YYYY年MM月DD日')
                      : '未定'}
                  </Text>
                </Col>
              </Row>
            </Card>
            <Card>
              <Row gutter={[16, 16]}>
                <Col xs={24} lg={12}>
                  <Text strong>チケット変更:</Text>
                  <br />
                  <Text>
                    {userProfile ? `${userProfile.ticket_count}枚` : '0枚'} →{' '}
                    <Text strong style={{ color: '#0072ff' }}>
                      {userProfile && selectedPlan
                        ? `${userProfile.ticket_count + selectedPlan.monthly_tickets}枚`
                        : '0枚'}
                    </Text>{' '}
                    {userProfile && selectedPlan && (
                      <Text type="success">
                        (+{selectedPlan.monthly_tickets}枚)
                      </Text>
                    )}
                  </Text>
                </Col>
                <Col xs={24} lg={12}>
                  <Text strong>毎月の付与チケット数:</Text>
                  <br />
                  <Text>
                    {userPlan ? userPlan.monthly_tickets : 0}枚 →{' '}
                    <Text strong style={{ color: '#0072ff' }}>
                      {selectedPlan ? selectedPlan.monthly_tickets : 0}枚
                    </Text>
                  </Text>
                </Col>
              </Row>
            </Card>
            {selectedPlan && selectedPlan.type !== 'free' && (
              <Card>
                <Space
                  direction="vertical"
                  size="small"
                  style={{ width: '100%' }}
                >
                  <Row justify="space-between">
                    <Col>
                      <Text strong>今回のお支払い:</Text>
                    </Col>
                    <Col>
                      <Text
                        strong
                        style={{ fontSize: '18px', color: '#1890ff' }}
                      >
                        ¥
                        {(
                          paymentAmount -
                          (appliedCoupon
                            ? (paymentAmount * appliedCoupon.discount_rate) /
                              100
                            : 0)
                        ).toLocaleString()}
                      </Text>
                    </Col>
                  </Row>
                  {appliedCoupon && (
                    <Text type="secondary">
                      クーポン割引: ¥
                      {(
                        (paymentAmount * appliedCoupon.discount_rate) /
                        100
                      ).toLocaleString()}
                      （{appliedCoupon.discount_rate}% 割引）
                    </Text>
                  )}
                  <Text type="secondary">
                    次回更新日からの{isYearly ? '年額' : '月額'}料金: ¥
                    {(
                      (isYearly
                        ? selectedPlan.yearly_price
                        : selectedPlan.monthly_price) * 1.1
                    ).toLocaleString()}
                    （税込）
                  </Text>
                </Space>
              </Card>
            )}
            {selectedPlan && selectedPlan.type === 'free' && (
              <Card>
                <Text>
                  フリープランに変更すると、次回更新日以降は料金が発生せず、チケットも補充されなくなります。
                </Text>
              </Card>
            )}
          </Space>
          <Divider />
          <Paragraph>
            <InfoCircleOutlined
              style={{ marginRight: '8px', color: '#1890ff' }}
            />
            プラン変更に関する注意事項:
          </Paragraph>
          <ul>
            <li>
              プラン変更時のチケット付与は加算方式です（上限なしで繰り越し）
            </li>
            <li>
              フリープランへの変更を除き、チケット枚数やプランは即時反映されます
            </li>
            <li>
              更新時に決済が失敗すると強制的にフリープランに戻り、チケットは付与されません
            </li>
            <li>未使用チケットの返金は受け付けておりません</li>
            <li>チケットの有効期限は最後の消費/更新から90日間です</li>
            <li>カスタムプランをご希望の場合は、お問い合わせください</li>
          </ul>
          <Divider />
          <Checkbox
            checked={isConfirmed}
            onChange={e => setIsConfirmed(e.target.checked)}
          >
            注意事項を確認しました
          </Checkbox>
        </Modal>
      </div>
    </>
  );
};

export default PlanSettings;
