import React, { useState, useEffect, useRef } from 'react';
import {
  Radio,
  Button,
  Form,
  Input,
  Steps,
  Typography,
  Breadcrumb,
  Select,
  message,
  Row,
  Col,
  Divider,
  Grid,
  Affix,
  Skeleton,
  Spin,
  Upload,
  Tooltip,
  List,
  Modal,
  Space,
  Alert,
  Tabs,
  Table,
} from 'antd';
import {
  ThunderboltOutlined,
  FireOutlined,
  AuditOutlined,
  CaretRightOutlined,
  InboxOutlined,
  FileOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  RightOutlined,
  LeftOutlined,
  SaveOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
  SafetyOutlined,
  FileSearchOutlined,
  FilterOutlined,
  CheckCircleOutlined,
  TableOutlined,
  LoadingOutlined,
  FileTextOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import Editor from './Editor';
import TableOfContents from './TableOfContents';
import axios from 'axios';
import {
  Link,
  useNavigate,
  useLocation,
  useParams,
  useBeforeUnload,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getUserProfile } from '../../auth';
import { parseHtml } from './Editor';
import './FactCheckNew.css';
import { calculateCharacterCount } from '../../utils/calculateCharacterCount';
const { Title, Text } = Typography;
const { Step } = Steps;
const { useBreakpoint } = Grid;

const FactCheckNew = () => {
  const [activeTab, setActiveTab] = useState('from-seo');
  const [editorContent, setEditorContent] = useState(null);
  const [url, setUrl] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [articleOptions, setArticleOptions] = useState([]);
  const [selectedArticleId, setSelectedArticleId] = useState(null);
  const [selectedArticleContent, setSelectedArticleContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentEditorData, setCurrentEditorData] = useState(null);
  const [displayEditorData, setDisplayEditorData] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [isUrlLoading, setIsUrlLoading] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedContent, setUploadedContent] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [totalCharCount, setTotalCharCount] = useState(0);
  const [isDirty, setIsDirty] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const initialContentRef = useRef(null);
  const [isSaving, setIsSaving] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const MAX_CHAR_COUNT = 15000;

  const navigate = useNavigate();
  const { userRole, draft_id } = useParams();
  const screens = useBreakpoint();

  // モーダルの状態を管理するための新しい状態
  const [modalData, setModalData] = useState({ charCount: 0, ticketCost: 0 });

  useEffect(() => {
    fetchArticles();
    if (draft_id) {
      handleDraftLoad(draft_id);
    }
  }, [draft_id]);

  useEffect(() => {
    // beforeunloadイベントのハンドラ
    const handleBeforeUnload = event => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue =
          '変更内容が保存されていません。このページを離れてもよろしいですか？';
        return event.returnValue;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  // React Router用のブロック処理
  useBeforeUnload(
    isDirty
      ? {
          when: true,
          message:
            '変更内容が保存されていません。このページを離れてもよろしいですか？',
        }
      : undefined,
  );

  // タスク初期化とドラフトデータ読み込み
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profile = await getUserProfile();
        setUserProfile(profile);
      } catch (error) {
        console.error('Failed to fetch user profile:', error);
        message.error('ユーザー情報の取得に失敗しました');
      }
    };

    fetchUserProfile();
  }, []);

  const resetEditorState = () => {
    setEditorContent(null);
    setCurrentEditorData(null);
    setSelectedArticleContent(null);
    setSelectedArticleId(null);
    setUrl('');
    setUploadedContent(null);
    setUploadedFile(null);
    setTaskId(null);
    setIsDirty(false);
  };

  const handleStepChange = current => {
    if (current === 0 && draft_id) {
      return; // 下書き編集時はソース選択に戻れないようにする
    }
    setCurrentStep(current);
  };

  const handleTabChange = e => {
    if (isDirty) {
      Modal.confirm({
        title: '変更内容が保存されていません',
        content:
          'ソースを切り替えると、現在の編集内容が失われます。よろしいですか？',
        okText: 'はい',
        cancelText: 'いいえ',
        onOk() {
          setActiveTab(e.target.value);
          resetEditorState();
        },
      });
    } else {
      setActiveTab(e.target.value);
      resetEditorState();
    }
  };

  const handleArticleSelect = async value => {
    if (isDirty) {
      Modal.confirm({
        title: '変更内容が保存されていません',
        content:
          '別の記事を選択すると、現在の編集内容が失われます。よろしいですか？',
        okText: 'はい',
        cancelText: 'いいえ',
        onOk() {
          performArticleSelect(value);
        },
      });
    } else {
      performArticleSelect(value);
    }
  };

  const fetchArticles = async () => {
    try {
      const profile = await getUserProfile();
      setUserProfile(profile);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/seo-article/list`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          params: {
            per_page: 100,
            status: '本文完了',
            ...(userRole !== 'admin' &&
              userRole !== 'master' && { user_id: profile.user_id }),
          },
        },
      );
      setArticleOptions(
        response.data.articles.map(article => ({
          label: article.keyword,
          value: article.uuid,
          id: article.id,
        })),
      );
    } catch (error) {
      console.error('Failed to fetch articles', error);
      message.error('記事の取得に失敗しました');
    }
  };

  const performArticleSelect = async value => {
    try {
      setIsLoading(true);
      resetEditorState();

      // 記事情報の取得のみ
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/seo-article/detail/${value}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );

      const articleId = response.data.id;
      const articleUuid = value; // UUID保持
      setSelectedArticleId(articleId);
      const articleContent = response.data.content;

      if (!articleContent) {
        throw new Error('記事のコンテンツが見つかりません');
      }

      const blocks = parseHtml(articleContent, {
        article: {
          title: response.data.title,
        },
      });

      const editorData = {
        time: Date.now(),
        blocks: blocks,
        version: '2.28.2',
      };

      // 状態の保持のみ
      setSelectedArticleContent(articleContent);
      setEditorContent(editorData);
      setCurrentEditorData(editorData);
      setDisplayEditorData(editorData);
    } catch (error) {
      console.error('Error fetching article:', error);
      message.error('記事の取得に失敗しました');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDraftSave = async () => {
    if (!editorContent) {
      message.error('コンテンツがありません');
      return;
    }

    if (!userProfile?.user_id) {
      message.error('ユーザー情報が取得できません');
      return;
    }

    setIsSaving(true);

    try {
      // 下書きデータの組み立て
      const draftData = {
        user_id: userProfile.user_id,
        fact_check_text: JSON.stringify(editorContent),
        status: 'draft',
        progress: 0,
        draft_data: {
          currentStep,
          activeTab,
          selectedArticleId,
          url,
        },
      };

      let response;
      if (taskId) {
        // PUT /fact-check/tasks/:taskId
        response = await axios.put(
          `${process.env.REACT_APP_API_URL}/fact-check/tasks/${taskId}`,
          draftData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
          },
        );
      } else {
        // POST /fact-check/tasks (新規作成)
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/fact-check/tasks`,
          draftData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
          },
        );
        // 新規作成後にIDを保持
        if (response.data?.id) {
          setTaskId(response.data.id);
        }
      }

      // 保存したコンテンツを現在のコンテンツとして設定
      setCurrentEditorData(editorContent);
      setIsDirty(false);
      message.success('下書きを保存しました');
    } catch (error) {
      console.error('Draft save error:', error);
      message.error('下書きの保存に失敗しました');
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (editorContent && isInitialLoad) {
      setIsInitialLoad(false);
    }
  }, [editorContent]);

  const handleEditorChange = content => {
    // モーダル表示中は更新をスキップ
    if (confirmModalVisible) return;

    // if (isInitialLoad) return;

    if (content && content.blocks) {
      setEditorContent(content);
      setDisplayEditorData(content);

      // 現在のコンテンツと最後に保存したコンテンツを比較
      const currentContentStr = JSON.stringify(content.blocks);
      const lastSavedContentStr = currentEditorData
        ? JSON.stringify(currentEditorData.blocks)
        : null;

      // 保存済みのコンテンツとの差分を検知
      if (lastSavedContentStr === null) {
        setIsDirty(true);
      } else {
        setIsDirty(currentContentStr !== lastSavedContentStr);
      }

      // 文字数をカウント
      const charCount = calculateCharacterCount(content);

      setTotalCharCount(charCount);
    }
  };

  const handleUrlChange = e => {
    setUrl(e.target.value);
  };

  const handleUrlSubmit = async () => {
    if (!url) {
      message.error('URLを入力してください');
      return;
    }

    setIsUrlLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/fact-check/scrape-url`,
        { url },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );

      if (response.data && response.data.blocks) {
        setEditorContent(response.data);
        setCurrentEditorData(response.data);
        setDisplayEditorData(response.data);

        // 下書きとして自動保存
        const draftData = {
          user_id: userProfile.user_id,
          fact_check_text: JSON.stringify(response.data),
          status: 'draft',
          progress: 0,
          draft_data: {
            currentStep,
            activeTab,
            selectedArticleId: null,
            url,
          },
        };

        const saveResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/fact-check/tasks`,
          draftData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
          },
        );

        if (saveResponse.data?.id) {
          // 保存成功時は編集画面に遷移
          navigate(`/fact-check/new/${saveResponse.data.id}`);
          message.success('コンテンツの取得と下書きの保存に成功しました');
        } else {
          setTaskId(null);
          message.error('下書きの保存に失敗しました');
        }
      } else {
        message.error('コンテンツの取得に失敗しました');
      }
    } catch (error) {
      console.error('Failed to scrape URL:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
      }
      message.error('コンテンツの取得に失敗しました');
    } finally {
      setIsUrlLoading(false);
    }
  };

  const calculateTicketCost = charCount => {
    const charLimits = [2000, 3500, 5000, 6500, 8000, 9500, 11000];
    const ticketCosts = [4, 6, 8, 10, 12, 14, 16];

    // 文字数に応じてチケット枚数を決定
    for (let i = 0; i < charLimits.length; i++) {
      if (charCount <= charLimits[i]) {
        return ticketCosts[i];
      }
    }

    // 全ての制限を超えた場合は最大値を使用
    return ticketCosts[ticketCosts.length - 1];
  };

  const handleNext = async () => {
    if (currentStep === 0) {
      if (activeTab === 'from-seo' && !selectedArticleId) {
        message.error('SEO記事を選択してください');
        return;
      }

      if (activeTab === 'from-seo' && selectedArticleId) {
        try {
          // 下書きとして保存
          const draftData = {
            user_id: userProfile.user_id,
            fact_check_text: JSON.stringify(editorContent),
            status: 'draft',
            progress: 0,
            draft_data: {
              currentStep,
              activeTab,
              selectedArticleId,
              selectedArticleUuid: articleOptions.find(
                opt => opt.id === selectedArticleId,
              )?.value,
              url: '',
            },
          };

          const saveResponse = await axios.post(
            `${process.env.REACT_APP_API_URL}/fact-check/tasks`,
            draftData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
              },
            },
          );

          if (saveResponse.data?.id) {
            message.success('原稿を読み込み、下書きとして保存しました');
            navigate(`/fact-check/new/${saveResponse.data.id}`);
          } else {
            message.error('下書きの保存に失敗しました');
          }
          return;
        } catch (error) {
          console.error('Error saving draft:', error);
          message.error('下書きの保存に失敗しました');
          return;
        }
      }

      if (activeTab === 'from-url') {
        handleUrlSubmit();
        return;
      }
      if (activeTab === 'from-file') {
        if (!uploadedContent) {
          message.error('ファイルをアップロードしてください');
          return;
        }
        handleFileProcess();
        return;
      }
      if (activeTab === 'direct-input') {
        setCurrentStep(currentStep + 1);
        return;
      }
    } else if (currentStep === 1) {
      // エディタ編集時
      if (!editorContent) {
        message.error('コンテンツがありません');
        return;
      }

      if (totalCharCount > MAX_CHAR_COUNT) {
        message.error(
          `文字数が制限（${MAX_CHAR_COUNT.toLocaleString()}文字）を超えています`,
        );
        return;
      }

      // モーダルを開く時に一度だけデータを設定
      setModalData({
        charCount: totalCharCount,
        ticketCost: calculateTicketCost(totalCharCount),
      });
      setConfirmModalVisible(true);
    }
  };

  const handlePrev = () => {
    if (currentStep === 1 && draft_id) {
      return; // 下書き編集時はソース選択に戻れないようにする
    }
    setCurrentStep(currentStep - 1);
  };

  const handleConfirmReservation = async () => {
    setConfirmModalVisible(false);
    await handleSubmit(); // 既存の予約処理を実行
  };

  const handleSubmit = async () => {
    if (!userProfile?.user_id) {
      message.error('ユーザー情報が取得できません。');
      return;
    }
    if (!editorContent) {
      message.error('原稿の内容がありません。');
      return;
    }
    if (totalCharCount > MAX_CHAR_COUNT) {
      message.error(
        `文字数が${MAX_CHAR_COUNT.toLocaleString()}を超えています。`,
      );
      return;
    }

    // チケット枚数を計算
    const calculatedTicketCost = calculateTicketCost(totalCharCount);

    const taskData = {
      fact_check_text: JSON.stringify(editorContent),
      status: 'queued',
      draft_data: {
        activeTab,
        selectedArticleId,
        url,
      },
      article_id: selectedArticleId || null,
      original_url: url || null,
      frontend_ticket_cost: calculatedTicketCost, // フロントエンドで計算したチケット枚数を追加
    };

    if (draft_id) {
      taskData.id = draft_id;
    }

    try {
      let response;
      if (draft_id) {
        // PUTパターン(重複作成回避)
        response = await axios.put(
          `${process.env.REACT_APP_API_URL}/fact-check/tasks/${draft_id}`,
          taskData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
          },
        );
      } else {
        // 新規タスクのPOST
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/fact-check/tasks`,
          taskData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
          },
        );
      }

      if (response.data) {
        message.success('ファクトチェックを予約しました');
        navigate(`/fact-check/result/${response.data.id}`);
      }
    } catch (error) {
      console.error('タスク作成エラー:', error);
      message.error('タスクの作成に失敗しました');
    }
  };

  const handleFileUpload = async file => {
    if (!file) {
      message.error('ファイルを選択してください');
      return;
    }

    console.log('アップロードするファイル:', file.name, file.type);

    const allowedTypes = [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
      'text/plain',
    ];

    if (!allowedTypes.includes(file.type)) {
      message.error(
        'サポートされているファイル形式は.docx、.pdf、.txtのみです',
      );
      return;
    }

    setIsFileUploading(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      console.log('ファイルアップロード開始');
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/fact-check/upload`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      console.log('サーバーレスポンス:', response.data);

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      if (response.data.uploaded_content) {
        setUploadedContent(response.data.uploaded_content);
        setUploadedFile(file);
        message.success('ファイルのアップロードに成功しました');
      } else {
        console.error(
          'レスポンスにコンテンツが含まれていません:',
          response.data,
        );
        throw new Error('ファイルの処理に失敗しました');
      }
    } catch (error) {
      console.error('ファイルアップロードエラー:', error);
      console.error('エラーレスポンス:', error.response?.data);
      message.error(error.message || 'ファイルのアップロードに失敗しました');
      setUploadedContent(null);
      setUploadedFile(null);
    } finally {
      setIsFileUploading(false);
    }
  };

  const handleFileProcess = async () => {
    if (!uploadedContent) {
      message.error('ファイルをアップロードしてください');
      return;
    }

    setIsProcessing(true);
    try {
      const editorData = {
        time: Date.now(),
        blocks: uploadedContent,
        version: '2.28.2',
      };

      // 下書きとして自動保存
      const draftData = {
        user_id: userProfile.user_id,
        fact_check_text: JSON.stringify(editorData),
        status: 'draft',
        progress: 0,
        draft_data: {
          currentStep,
          activeTab,
          selectedArticleId: null,
          url: '',
        },
      };

      const saveResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/fact-check/tasks`,
        draftData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
      );

      if (saveResponse.data?.id) {
        // 保存成功時は編集画面に遷移
        navigate(`/fact-check/new/${saveResponse.data.id}`);
        setTaskId(saveResponse.data.id);
        setEditorContent(editorData);
        setCurrentEditorData(editorData);
        setDisplayEditorData(editorData);
        setIsDirty(false);
        message.success('ファイルの読み込みと下書きの保存が完了しました');
      } else {
        message.error('下書きの保存に失敗しました');
      }
    } catch (error) {
      console.error('ファイル処理エラー:', error);
      message.error('ファイルの処理に失敗しました');
    } finally {
      setIsProcessing(false);
    }
  };

  const renderEditorSection = () => {
    return (
      <Row gutter={[24, 24]}>
        <Col
          xs={24}
          xl={16}
          style={{
            position: 'relative',
            padding: screens.md ? '0 0 0 36px' : '0 0 0 16px',
            marginBottom: screens.md ? 0 : '20px',
          }}
        >
          <Editor
            onChange={handleEditorChange}
            initialData={currentEditorData}
            onReady={handleEditorReady}
            config={{
              holder: 'editorjs',
              tools: {
                header: {
                  class: Header,
                  config: {
                    levels: [2, 3],
                    defaultLevel: 2,
                  },
                },
              },
              minHeight: 0,
              placeholder: '本文を入力',
              inlineToolbar: ['bold', 'italic'],
            }}
          />
        </Col>
        {screens.xl && (
          <Col xl={8}>
            <Affix offsetTop={24}>
              <TableOfContents
                editorData={displayEditorData}
                loading={isLoading}
              />
            </Affix>
          </Col>
        )}
      </Row>
    );
  };

  const ConfirmReservationModal = React.memo(
    ({ visible, onOk, onCancel, modalData }) => {
      // 文字数に基づいて該当する行を特定
      const getRowHighlight = charCount => {
        const charLimits = [2000, 3500, 5000, 6500, 8000, 9500, 11000];
        for (let i = 0; i < charLimits.length; i++) {
          if (charCount <= charLimits[i]) {
            return i;
          }
        }
        return charLimits.length - 1;
      };

      const highlightIndex = getRowHighlight(modalData.charCount || 0);

      // 文字数に応じた最大検証項目数
      const statementLimits = [
        { chars: 2500, count: 40 },
        { chars: 5000, count: 60 },
        { chars: 7500, count: 80 },
        { chars: 10000, count: 100 },
        { chars: 12500, count: 120 },
        { chars: 15000, count: 150 },
      ];

      // 該当する最大検証項目数
      const maxStatements =
        highlightIndex < statementLimits.length
          ? statementLimits[highlightIndex].count
          : statementLimits[statementLimits.length - 1].count;

      // テーブル表示状態の管理
      const [tableVisible, setTableVisible] = useState(false);

      return (
        <Modal
          title={
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <CheckCircleOutlined
                style={{ color: '#52c41a', fontSize: '20px' }}
              />
              <span>ファクトチェックの予約確認</span>
            </div>
          }
          open={visible}
          onOk={onOk}
          onCancel={onCancel}
          okText="ファクトチェックを予約する"
          cancelText="キャンセル"
          width={screens.md ? 600 : '95%'}
          okButtonProps={{
            style: {
              background: '#1890ff',
              borderColor: '#1890ff',
              fontSize: screens.md ? '14px' : '12px',
            },
            size: screens.md ? 'middle' : 'small',
          }}
          cancelButtonProps={{
            size: screens.md ? 'middle' : 'small',
            style: { fontSize: screens.md ? '14px' : '12px' },
          }}
          bodyStyle={{ padding: screens.md ? '24px' : '16px' }}
        >
          <div style={{ marginBottom: screens.md ? '24px' : '16px' }}>
            <div
              style={{
                borderRadius: '8px',
                marginBottom: screens.md ? '20px' : '16px',
              }}
            >
              <Row gutter={[16, 16]} align="middle">
                <Col span={12}>
                  <div
                    style={{
                      fontSize: screens.md ? '15px' : '13px',
                      color: '#333',
                    }}
                  >
                    原稿の文字数
                  </div>
                  <div
                    style={{
                      fontSize: screens.md ? '24px' : '18px',
                      fontWeight: 'bold',
                      color: '#1890ff',
                      marginTop: '4px',
                    }}
                  >
                    {(modalData.charCount || 0).toLocaleString()}{' '}
                    <span style={{ fontSize: screens.md ? '16px' : '13px' }}>
                      文字
                    </span>
                  </div>
                </Col>
                <Col span={12}>
                  <div
                    style={{
                      fontSize: screens.md ? '15px' : '13px',
                      color: '#333',
                    }}
                  >
                    消費チケット
                  </div>
                  <div
                    style={{
                      fontSize: screens.md ? '24px' : '18px',
                      fontWeight: 'bold',
                      color: '#1890ff',
                      marginTop: '4px',
                    }}
                  >
                    {modalData.ticketCost || 0}{' '}
                    <span style={{ fontSize: screens.md ? '16px' : '13px' }}>
                      枚
                    </span>
                  </div>
                </Col>
              </Row>
            </div>

            <Divider />

            <div style={{ marginBottom: screens.md ? '20px' : '16px' }}>
              <div
                style={{
                  fontSize: screens.md ? '14px' : '13px',
                  fontWeight: 'bold',
                  marginBottom: screens.md ? '12px' : '8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                >
                  <TableOutlined />
                  <span>文字数ごとのチケット消費と検証項目数</span>
                </div>
                <Button
                  type="link"
                  onClick={() => setTableVisible(!tableVisible)}
                  style={{
                    fontSize: screens.md ? '14px' : '12px',
                    padding: '0',
                  }}
                >
                  {tableVisible ? 'テーブルを隠す' : 'テーブルを表示'}
                </Button>
              </div>

              {tableVisible && (
                <Table
                  size="small"
                  pagination={false}
                  scroll={screens.md ? undefined : { x: 300 }}
                  dataSource={[
                    {
                      chars: 2000,
                      tickets: 4,
                      count: 25,
                      highlight: highlightIndex === 0,
                    },
                    {
                      chars: 3500,
                      tickets: 6,
                      count: 40,
                      highlight: highlightIndex === 1,
                    },
                    {
                      chars: 5000,
                      tickets: 8,
                      count: 55,
                      highlight: highlightIndex === 2,
                    },
                    {
                      chars: 6500,
                      tickets: 10,
                      count: 70,
                      highlight: highlightIndex === 3,
                    },
                    {
                      chars: 8000,
                      tickets: 12,
                      count: 85,
                      highlight: highlightIndex === 4,
                    },
                    {
                      chars: 9500,
                      tickets: 14,
                      count: 100,
                      highlight: highlightIndex === 5,
                    },
                    {
                      chars: 11000,
                      tickets: 16,
                      count: 115,
                      highlight: highlightIndex === 6,
                    },
                  ]}
                  columns={[
                    {
                      title: '文字数',
                      dataIndex: 'chars',
                      key: 'chars',
                      render: (text, record) => (
                        <span
                          style={{
                            fontWeight: record.highlight ? 'bold' : 'normal',
                            color: record.highlight ? '#1890ff' : 'inherit',
                            fontSize: screens.md ? 'inherit' : '12px',
                          }}
                        >
                          〜{text.toLocaleString()}
                        </span>
                      ),
                    },
                    {
                      title: '最大検証項目数',
                      dataIndex: 'count',
                      key: 'count',
                      render: (text, record) => (
                        <span
                          style={{
                            fontWeight: record.highlight ? 'bold' : 'normal',
                            color: record.highlight ? '#1890ff' : 'inherit',
                            fontSize: screens.md ? 'inherit' : '12px',
                          }}
                        >
                          {text}項目
                        </span>
                      ),
                    },
                    {
                      title: 'チケット',
                      dataIndex: 'tickets',
                      key: 'tickets',
                      render: (text, record) => (
                        <span
                          style={{
                            fontWeight: record.highlight ? 'bold' : 'normal',
                            color: record.highlight ? '#1890ff' : 'inherit',
                            fontSize: screens.md ? 'inherit' : '12px',
                          }}
                        >
                          {text}枚
                        </span>
                      ),
                    },
                  ]}
                  rowClassName={record =>
                    record.highlight ? 'highlighted-row' : ''
                  }
                />
              )}
            </div>

            <div style={{ marginBottom: '20px' }}>
              <Alert
                type="info"
                icon={<InfoCircleOutlined />}
                message={
                  <div
                    style={{
                      fontWeight: 'bold',
                      fontSize: screens.md ? '14px' : '12px',
                    }}
                  >
                    ファクトチェック処理について
                  </div>
                }
                description={
                  <div style={{ marginTop: '8px' }}>
                    <ul
                      style={{
                        padding: '0 0 0 20px',
                        margin: '0',
                        fontSize: screens.md ? '13px' : '12px',
                      }}
                    >
                      <li>
                        処理時間の目安：<b>約5〜10分</b>
                        （文字数や内容により変動）
                      </li>
                      <li>
                        最大検証項目数：<b>{maxStatements}項目</b>
                        （実際の項目数はAIが検証が必要と判断した内容により変動します）
                      </li>
                      <li>
                        ログアウトしても処理は継続され、完了後に結果を確認できます
                      </li>
                    </ul>
                  </div>
                }
                style={{ border: '1px solid #91caff', borderRadius: '8px' }}
              />
            </div>

            <Alert
              type="warning"
              showIcon
              message={
                <span style={{ fontSize: screens.md ? '13px' : '12px' }}>
                  予約後、チケットを消費して検証を開始します。実行中の中断はできません。
                </span>
              }
              style={{ border: '1px solid #ffe58f', borderRadius: '8px' }}
            />
          </div>
        </Modal>
      );
    },
    (prevProps, nextProps) => {
      // モーダルの表示状態が変わった時のみ再レンダリング
      return (
        prevProps.visible === nextProps.visible &&
        prevProps.modalData === nextProps.modalData
      );
    },
  );

  const radioStyle = {
    display: 'inline-block',
    width: screens.md ? '25%' : '50%',
    textAlign: 'center',
    height: screens.md ? '40px' : '36px',
    lineHeight: screens.md ? '38px' : '34px', // 行の高さを調整
    fontSize: screens.md ? '14px' : '12px', // フォントサイズを明示的に指定
    padding: '0 8px', // パディングを追加して文字が見えるようにする
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: screens.md ? 0 : '8px',
  };

  const buttonWithIconStyle = {
    position: 'relative',
    paddingRight: '32px',
    whiteSpace: 'nowrap',
    fontSize: screens.md ? '14px' : '12px',
    height: screens.md ? undefined : '32px',
  };

  const buttonWithLeftIconStyle = {
    position: 'relative',
    paddingLeft: '32px',
    paddingRight: '16px',
    whiteSpace: 'nowrap',
    fontSize: screens.md ? '14px' : '12px',
    height: screens.md ? undefined : '32px',
  };

  const buttonIconStyle = {
    position: 'absolute',
    right: '16px',
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: '14px',
  };

  const buttonLeftIconStyle = {
    position: 'absolute',
    left: '16px',
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: '14px',
  };

  const renderCharCount = () => {
    const isWithinLimit = totalCharCount <= MAX_CHAR_COUNT;
    const countDisplay = (
      <span>
        <Text
          strong
          style={{
            fontSize: screens.md ? '20px' : '16px',
            marginRight: '4px',
            color: isWithinLimit ? '#00d4ff' : '#d53d45',
          }}
        >
          {totalCharCount}
        </Text>
        文字
      </span>
    );

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: screens.md ? '16px' : '10px',
          marginTop: screens.md ? '16px' : '12px',
          marginBottom: screens.md ? '16px' : '12px',
        }}
      >
        <Divider style={{ margin: screens.md ? '16px 0' : '10px 0' }} />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {isWithinLimit ? (
            <CheckCircleFilled
              style={{
                fontSize: screens.md ? '24px' : '20px',
                color: '#00d4ff',
                marginRight: '8px',
              }}
            />
          ) : (
            <Tooltip title={`最大${MAX_CHAR_COUNT}文字です`}>
              <CloseCircleFilled
                style={{
                  fontSize: screens.md ? '24px' : '20px',
                  color: '#d53d45',
                  marginRight: '8px',
                }}
              />
            </Tooltip>
          )}
          {isWithinLimit ? (
            countDisplay
          ) : (
            <Tooltip title={`最大${MAX_CHAR_COUNT}文字です`}>
              {countDisplay}
            </Tooltip>
          )}
        </div>
      </div>
    );
  };

  const isNextButtonDisabled = () => {
    if (isUrlLoading || isProcessing || isFileUploading) return true;

    if (currentStep === 1) {
      return totalCharCount > MAX_CHAR_COUNT;
    }

    if (currentStep === 0) {
      switch (activeTab) {
        case 'from-seo':
          return !selectedArticleId;
        case 'from-url':
          return !url.trim();
        case 'from-file':
          return !uploadedFile;
        case 'direct-input':
          return false;
        default:
          return true;
      }
    }

    return false;
  };

  // 下書きを読み込む
  const handleDraftLoad = async taskId => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/fact-check/tasks/${taskId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );

      const task = response.data;
      if (task.status === 'draft' && task.fact_check_text) {
        try {
          const content = JSON.parse(task.fact_check_text);
          console.log('Parsed content:', content);

          // 初期データを設定
          initialContentRef.current = content;

          // ドラフトデータの復元
          if (task.draft_data) {
            const draftData =
              typeof task.draft_data === 'string'
                ? JSON.parse(task.draft_data)
                : task.draft_data;

            setActiveTab(draftData.activeTab || 'from-seo');
            // selectedArticleUuidを使用するように修正
            if (draftData.selectedArticleUuid) {
              setSelectedArticleId(draftData.selectedArticleId);
              // handleArticleSelectは不要 - 記事の再取得は不要
            } else if (draftData.url) {
              setUrl(draftData.url);
            }
          }

          setEditorContent(content);
          setCurrentEditorData(content);
          setDisplayEditorData(content);

          // 文字数をカウント
          const charCount = calculateCharacterCount(content);
          setTotalCharCount(charCount);
          setTaskId(task.id);
          setCurrentStep(1); // 編集ステップに移動
          setIsDirty(false); // 初期状態は必ずfalse

          // message.success('下書きを読み込みました');
        } catch (e) {
          console.error('Failed to parse draft content:', e);
          message.error('下書きの解析に失敗しました');
        }
      } else {
        console.error('Invalid draft data:', task);
        message.error('下書きデータが不正です');
      }
    } catch (error) {
      console.error('Failed to load draft:', error);
      message.error('下書きの読み込みに失敗しました');
    }
  };

  const renderSaveButton = () => (
    <Button
      icon={<SaveOutlined />}
      onClick={handleDraftSave}
      loading={isSaving}
      disabled={!isDirty}
      style={{
        width: '100%',
        marginTop: screens.md ? '24px' : '16px',
        marginBottom: screens.md ? '16px' : '12px',
        fontSize: screens.md ? '14px' : '12px',
      }}
      size={screens.md ? 'middle' : 'small'}
    >
      {isDirty ? '下書きを保存' : '下書き保存済み'}
    </Button>
  );

  // エディタの準備完了時の処理
  const handleEditorReady = () => {
    setIsLoading(false);
    // 初期状態では変更なしとする
    setIsDirty(false);
  };

  // taskIdの変更を監視
  useEffect(() => {}, [taskId]);

  // エディタの変更状態をデバッグするために追加
  useEffect(() => {}, [isDirty]);

  return (
    <>
      <div style={{ margin: '0 auto', padding: screens.md ? 0 : '0 10px' }}>
        <Helmet>
          <title>ファクトチェックの新規予約 - magicss</title>
          <meta name="robots" content="noindex" />
          <meta
            property="og:title"
            content={`ファクトチェックの新規予約 - magicss`}
          />
          <meta property="og:type" content="article" />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:image"
            content={`${process.env.PUBLIC_URL}/img/ogp.png`}
          />
          <meta
            property="og:description"
            content={`コンテンツ生成AIツール「magicss」のファクトチェックタスク作成ページです。`}
          />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>

        <Row>
          <Col span={24}>
            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>ファクトチェック</Breadcrumb.Item>
            </Breadcrumb>
            <Title
              level={1}
              style={{ fontSize: screens.md ? '24px' : '20px' }}
              className="gradient-text"
            >
              新規タスクを予約する
            </Title>
            <div
              style={{
                display: 'flex',
                flexDirection: screens.md ? 'row' : 'column',
                justifyContent: 'space-between',
                alignItems: screens.md ? 'center' : 'flex-start',
                marginBottom: '16px',
                gap: screens.md ? 0 : '8px',
              }}
            >
              <Text
                style={{
                  color: '#6d8eb7',
                  fontSize: screens.md ? '14px' : '12px',
                }}
              >
                検証したい記事、またはテキストを選択してください
              </Text>
              <Button
                type="link"
                icon={<QuestionCircleOutlined />}
                onClick={() => {
                  Modal.info({
                    title: 'ファクトチェックモードについて',
                    width: 900,
                    maskClosable: true,
                    content: (
                      <div
                        style={{
                          marginTop: '16px',
                          maxHeight: 'calc(80vh - 100px)',
                          overflow: 'auto',
                          scrollbarWidth: 'thin',
                          scrollbarColor: '#e6e6e6 transparent',
                          msOverflowStyle: 'none',
                          '&::-webkit-scrollbar': {
                            width: '6px',
                            height: '6px',
                          },
                          '&::-webkit-scrollbar-track': {
                            background: 'transparent',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            background: '#e6e6e6',
                            borderRadius: '3px',
                          },
                          '&::-webkit-scrollbar-thumb:hover': {
                            background: '#d9d9d9',
                          },
                        }}
                      >
                        <Tabs defaultActiveKey="1">
                          <Tabs.TabPane tab="概要" key="1">
                            <div style={{ padding: '8px 0' }}>
                              <p>
                                ファクトチェックモードは、記事やテキスト内の事実確認が必要な記述を自動的に抽出し、AIが検証を行うサービスです。検証結果に基づいて記事の信頼性を高めることができます。
                              </p>
                              <div
                                style={{
                                  textAlign: 'center',
                                  margin: '16px 0',
                                }}
                              >
                                <video
                                  src="/video/about_fact.mp4"
                                  controls
                                  autoPlay
                                  muted
                                  loop
                                  style={{
                                    width: '100%',
                                    maxWidth: '600px',
                                    borderRadius: '8px',
                                    border: '1px solid #f0f0f0',
                                  }}
                                />
                                <div
                                  style={{
                                    fontSize: '12px',
                                    color: '#999',
                                    marginTop: '8px',
                                  }}
                                >
                                  ファクトチェックの基本的な流れ
                                </div>
                              </div>

                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginBottom: '16px',
                                }}
                              >
                                <div
                                  style={{
                                    width: '40px',
                                    height: '40px',
                                    borderRadius: '50%',
                                    background: '#f0f0f0',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginRight: '12px',
                                    fontSize: '20px',
                                    color: '#1890ff',
                                  }}
                                >
                                  <FileSearchOutlined />
                                </div>
                                <div>
                                  <div
                                    style={{
                                      fontWeight: '500',
                                      marginBottom: '4px',
                                    }}
                                  >
                                    主な特徴
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: '12px',
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        background: '#f6f8ff',
                                        padding: '10px',
                                        borderRadius: '8px',
                                        border: '1px solid #e6f0ff',
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: '36px',
                                          height: '36px',
                                          borderRadius: '50%',
                                          background: '#e6f0ff',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          marginRight: '12px',
                                          color: '#1890ff',
                                        }}
                                      >
                                        <FileSearchOutlined
                                          style={{ fontSize: '18px' }}
                                        />
                                      </div>
                                      <div>
                                        <div
                                          style={{
                                            fontWeight: '500',
                                            fontSize: '14px',
                                            marginBottom: '2px',
                                          }}
                                        >
                                          AIによる自動検証項目抽出
                                        </div>
                                        <div
                                          style={{
                                            fontSize: '12px',
                                            color: 'rgba(0, 0, 0, 0.65)',
                                          }}
                                        >
                                          テキスト内の事実確認が必要な記述を自動的に特定
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        background: '#f6fff8',
                                        padding: '10px',
                                        borderRadius: '8px',
                                        border: '1px solid #e6ffe6',
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: '36px',
                                          height: '36px',
                                          borderRadius: '50%',
                                          background: '#e6ffe6',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          marginRight: '12px',
                                          color: '#52c41a',
                                        }}
                                      >
                                        <AuditOutlined
                                          style={{ fontSize: '18px' }}
                                        />
                                      </div>
                                      <div>
                                        <div
                                          style={{
                                            fontWeight: '500',
                                            fontSize: '14px',
                                            marginBottom: '2px',
                                          }}
                                        >
                                          複数のソースを用いたクロスチェック
                                        </div>
                                        <div
                                          style={{
                                            fontSize: '12px',
                                            color: 'rgba(0, 0, 0, 0.65)',
                                          }}
                                        >
                                          信頼性の高い複数のソースを参照して検証
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        background: '#fff8f6',
                                        padding: '10px',
                                        borderRadius: '8px',
                                        border: '1px solid #ffe6e0',
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: '36px',
                                          height: '36px',
                                          borderRadius: '50%',
                                          background: '#ffe6e0',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          marginRight: '12px',
                                          color: '#fa541c',
                                        }}
                                      >
                                        <CheckCircleOutlined
                                          style={{ fontSize: '18px' }}
                                        />
                                      </div>
                                      <div>
                                        <div
                                          style={{
                                            fontWeight: '500',
                                            fontSize: '14px',
                                            marginBottom: '2px',
                                          }}
                                        >
                                          検証結果の視覚的表示
                                        </div>
                                        <div
                                          style={{
                                            fontSize: '12px',
                                            color: 'rgba(0, 0, 0, 0.65)',
                                          }}
                                        >
                                          スコアとカラーコードで信頼性を直感的に把握
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tabs.TabPane>

                          <Tabs.TabPane tab="使い方" key="2">
                            <div style={{ padding: '8px 0' }}>
                              <h3
                                style={{
                                  borderBottom: '1px solid #f0f0f0',
                                  paddingBottom: '8px',
                                  fontSize: '16px',
                                }}
                              >
                                使い方の流れ
                              </h3>
                              <ol style={{ paddingLeft: '20px' }}>
                                <li style={{ marginBottom: '8px' }}>
                                  <strong>原稿インポート</strong>
                                  ：SEO記事、URL、ファイル、または直接入力から検証したいテキストを選択
                                </li>
                                <li style={{ marginBottom: '8px' }}>
                                  <strong>原稿の調整</strong>
                                  ：必要に応じてエディタでテキストを編集
                                </li>
                                <li style={{ marginBottom: '8px' }}>
                                  <strong>チェック実行</strong>
                                  ：AIが自動的に検証項目を抽出し、事実確認を実行
                                </li>
                                <li style={{ marginBottom: '8px' }}>
                                  <strong>結果の確認</strong>
                                  ：検証結果を確認し、必要に応じて修正
                                </li>
                              </ol>

                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'flex-start',
                                  marginBottom: '16px',
                                  padding: '12px',
                                  background: '#f9f9f9',
                                  borderRadius: '8px',
                                  border: '1px solid #f0f0f0',
                                }}
                              >
                                <div
                                  style={{
                                    width: '32px',
                                    height: '32px',
                                    borderRadius: '50%',
                                    background: '#e6f7ff',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginRight: '12px',
                                    fontSize: '16px',
                                    color: '#1890ff',
                                    flexShrink: 0,
                                    marginTop: '4px',
                                  }}
                                >
                                  <FilterOutlined />
                                </div>
                                <div>
                                  <div
                                    style={{
                                      fontWeight: '500',
                                      marginBottom: '4px',
                                    }}
                                  >
                                    検証結果の見方
                                  </div>
                                  <div
                                    style={{
                                      fontSize: '13px',
                                      marginBottom: '8px',
                                    }}
                                  >
                                    検証結果は事実性スコア（0〜10）で表示されます。スコアが高いほど信頼性が高いことを示します。また、各項目には参考情報源へのリンクが表示されます。
                                  </div>
                                </div>
                              </div>

                              <h3
                                style={{
                                  borderBottom: '1px solid #f0f0f0',
                                  paddingBottom: '8px',
                                  fontSize: '16px',
                                  marginTop: '24px',
                                }}
                              >
                                検証タスクの管理
                              </h3>
                              <div style={{ marginBottom: '16px' }}>
                                <div style={{ fontSize: '13px' }}>
                                  <ul
                                    style={{ paddingLeft: '20px', margin: '0' }}
                                  >
                                    <li style={{ marginBottom: '6px' }}>
                                      <strong>進行中のタスク</strong>
                                      ：「ファクトチェック」メニューから「タスク一覧」を選択すると、進行中や完了済みのタスクを確認できます。
                                    </li>
                                    <li style={{ marginBottom: '6px' }}>
                                      <strong>下書き保存</strong>
                                      ：編集中のタスクは「下書きを保存」ボタンで一時保存できます。後で続きから編集可能です。
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </Tabs.TabPane>

                          <Tabs.TabPane tab="チケットルール" key="3">
                            <div style={{ padding: '8px 0' }}>
                              <div
                                style={{
                                  background: '#f9f9f9',
                                  padding: '12px',
                                  borderRadius: '8px',
                                  marginBottom: '16px',
                                  border: '1px solid #f0f0f0',
                                }}
                              >
                                <table
                                  style={{
                                    width: '100%',
                                    borderCollapse: 'collapse',
                                    fontSize: '13px',
                                  }}
                                >
                                  <thead>
                                    <tr
                                      style={{
                                        borderBottom: '1px solid #f0f0f0',
                                      }}
                                    >
                                      <th
                                        style={{
                                          padding: '8px',
                                          textAlign: 'left',
                                        }}
                                      >
                                        文字数
                                      </th>
                                      <th
                                        style={{
                                          padding: '8px',
                                          textAlign: 'center',
                                        }}
                                      >
                                        最大検証項目数
                                      </th>
                                      <th
                                        style={{
                                          padding: '8px',
                                          textAlign: 'right',
                                        }}
                                      >
                                        チケット枚数
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      style={{
                                        borderBottom: '1px solid #f0f0f0',
                                      }}
                                    >
                                      <td style={{ padding: '8px' }}>
                                        2,000文字以下
                                      </td>
                                      <td
                                        style={{
                                          padding: '8px',
                                          textAlign: 'center',
                                        }}
                                      >
                                        25
                                      </td>
                                      <td
                                        style={{
                                          padding: '8px',
                                          textAlign: 'right',
                                        }}
                                      >
                                        4枚
                                      </td>
                                    </tr>
                                    <tr
                                      style={{
                                        borderBottom: '1px solid #f0f0f0',
                                      }}
                                    >
                                      <td style={{ padding: '8px' }}>
                                        2,001〜3,500文字
                                      </td>
                                      <td
                                        style={{
                                          padding: '8px',
                                          textAlign: 'center',
                                        }}
                                      >
                                        40
                                      </td>
                                      <td
                                        style={{
                                          padding: '8px',
                                          textAlign: 'right',
                                        }}
                                      >
                                        6枚
                                      </td>
                                    </tr>
                                    <tr
                                      style={{
                                        borderBottom: '1px solid #f0f0f0',
                                      }}
                                    >
                                      <td style={{ padding: '8px' }}>
                                        3,501〜5,000文字
                                      </td>
                                      <td
                                        style={{
                                          padding: '8px',
                                          textAlign: 'center',
                                        }}
                                      >
                                        55
                                      </td>
                                      <td
                                        style={{
                                          padding: '8px',
                                          textAlign: 'right',
                                        }}
                                      >
                                        8枚
                                      </td>
                                    </tr>
                                    <tr
                                      style={{
                                        borderBottom: '1px solid #f0f0f0',
                                      }}
                                    >
                                      <td style={{ padding: '8px' }}>
                                        5,001〜6,500文字
                                      </td>
                                      <td
                                        style={{
                                          padding: '8px',
                                          textAlign: 'center',
                                        }}
                                      >
                                        70
                                      </td>
                                      <td
                                        style={{
                                          padding: '8px',
                                          textAlign: 'right',
                                        }}
                                      >
                                        10枚
                                      </td>
                                    </tr>
                                    <tr
                                      style={{
                                        borderBottom: '1px solid #f0f0f0',
                                      }}
                                    >
                                      <td style={{ padding: '8px' }}>
                                        6,501〜8,000文字
                                      </td>
                                      <td
                                        style={{
                                          padding: '8px',
                                          textAlign: 'center',
                                        }}
                                      >
                                        85
                                      </td>
                                      <td
                                        style={{
                                          padding: '8px',
                                          textAlign: 'right',
                                        }}
                                      >
                                        12枚
                                      </td>
                                    </tr>
                                    <tr
                                      style={{
                                        borderBottom: '1px solid #f0f0f0',
                                      }}
                                    >
                                      <td style={{ padding: '8px' }}>
                                        8,001〜9,500文字
                                      </td>
                                      <td
                                        style={{
                                          padding: '8px',
                                          textAlign: 'center',
                                        }}
                                      >
                                        100
                                      </td>
                                      <td
                                        style={{
                                          padding: '8px',
                                          textAlign: 'right',
                                        }}
                                      >
                                        14枚
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ padding: '8px' }}>
                                        9,501〜11,000文字
                                      </td>
                                      <td
                                        style={{
                                          padding: '8px',
                                          textAlign: 'center',
                                        }}
                                      >
                                        115
                                      </td>
                                      <td
                                        style={{
                                          padding: '8px',
                                          textAlign: 'right',
                                        }}
                                      >
                                        16枚
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div
                                  style={{
                                    fontSize: '12px',
                                    color: '#999',
                                    marginTop: '8px',
                                  }}
                                >
                                  ※
                                  検証項目数はあくまで最大値であり、実際の検証項目数は原稿の内容によって変動します
                                </div>
                              </div>

                              <Alert
                                message={
                                  <span
                                    style={{
                                      fontSize: '13px',
                                      fontWeight: '500',
                                    }}
                                  >
                                    チケット消費のタイミング
                                  </span>
                                }
                                description="チケットは「チェック実行に進む」ボタンを押して予約を確定した時点で消費されます。システムエラーなどで検証が失敗した場合は自動的にチケットが返還されます。"
                                type="info"
                                showIcon
                                style={{
                                  marginBottom: '16px',
                                  fontSize: '13px',
                                }}
                              />
                            </div>
                          </Tabs.TabPane>

                          <Tabs.TabPane tab="注意事項" key="4">
                            <div style={{ padding: '8px 0' }}>
                              <p>
                                AIによる検証は事実検証作業を自動化する補助ツールですが、完璧ではありません。特に専門的な内容や最新の情報については、結果を参考にしつつ、ご自身でも確認することをお勧めします。
                              </p>

                              <div
                                style={{
                                  background: '#f9f9f9',
                                  padding: '12px',
                                  borderRadius: '8px',
                                  marginBottom: '16px',
                                  border: '1px solid #f0f0f0',
                                }}
                              >
                                <div
                                  style={{
                                    marginBottom: '8px',
                                    fontWeight: '500',
                                    fontSize: '13px',
                                  }}
                                >
                                  ご利用にあたっての注意点：
                                </div>
                                <ul
                                  style={{
                                    paddingLeft: '20px',
                                    margin: '0',
                                  }}
                                >
                                  <li>
                                    <strong>著作権の尊重</strong>
                                    ：URLやファイルからの抽出は、自社コンテンツや許諾を得たものに限定してください
                                  </li>
                                  <li>
                                    <strong>検証結果の確認</strong>
                                    ：AIによる検証結果は必ず人間の目でも確認してください
                                  </li>
                                  <li>
                                    <strong>専門分野の扱い</strong>
                                    ：医療・法律・金融など専門性の高い分野では特に慎重に判断してください
                                  </li>
                                  <li>
                                    <strong>最新情報の確認</strong>
                                    ：最新の出来事については、公式情報源で再確認することをお勧めします
                                  </li>
                                </ul>
                              </div>

                              <Alert
                                message={
                                  <span
                                    style={{
                                      fontSize: '13px',
                                      fontWeight: '500',
                                    }}
                                  >
                                    重要な注意点
                                  </span>
                                }
                                description="ファクトチェックの結果はあくまで補助ツールであり、100%の精度を保証するものではありません。重要な意思決定や法的・医療的なアドバイスに用いる場合、最終的には人間の目でも必ずご確認ください。"
                                type="warning"
                                showIcon
                                style={{ marginTop: '16px', fontSize: '13px' }}
                              />
                            </div>
                          </Tabs.TabPane>
                        </Tabs>
                      </div>
                    ),
                  });
                }}
                style={{ padding: screens.md ? undefined : '0' }}
              >
                ファクトチェックモードについて
              </Button>
            </div>
            <Divider
              style={{ margin: screens.md ? '16px 0 64px' : '12px 0 32px' }}
            />
          </Col>
        </Row>

        <Row gutter={[screens.md ? 24 : 12, screens.md ? 24 : 16]}>
          <Col xs={24} xl={4}>
            {screens.md ? (
              <Affix offsetTop={24}>
                <Steps
                  current={currentStep}
                  direction={screens.xl ? 'vertical' : 'horizontal'}
                  size={screens.md ? 'small' : 'small'}
                  onChange={handleStepChange}
                  items={[
                    {
                      title: screens.md ? '原稿インポート' : 'インポート',
                      disabled: draft_id !== undefined,
                    },
                    {
                      title: screens.md ? '原稿の調整' : '調整',
                      disabled: currentStep !== 1 && currentStep !== 2,
                    },
                  ]}
                  style={{ padding: screens.md ? 0 : '0 10px' }}
                />
                {currentStep === 1 && (
                  <>
                    {renderCharCount()}
                    {renderSaveButton()}
                    <Button
                      type="primary"
                      onClick={handleNext}
                      style={{
                        width: '100%',
                        position: 'relative',
                        paddingRight: '32px',
                        fontSize: screens.md ? '14px' : '13px',
                      }}
                      disabled={totalCharCount > MAX_CHAR_COUNT}
                    >
                      チェック実行に進む
                      <RightOutlined
                        style={{
                          position: 'absolute',
                          right: '16px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                        }}
                      />
                    </Button>
                  </>
                )}
              </Affix>
            ) : (
              <div>
                <Steps
                  current={currentStep}
                  direction="horizontal"
                  size="small"
                  onChange={handleStepChange}
                  items={[
                    {
                      title: 'インポート',
                      disabled: draft_id !== undefined,
                    },
                    {
                      title: '調整',
                      disabled: currentStep !== 1 && currentStep !== 2,
                    },
                  ]}
                  style={{ padding: '0 10px' }}
                />
                {currentStep === 1 && (
                  <>
                    {renderCharCount()}
                    {renderSaveButton()}
                    <Button
                      type="primary"
                      onClick={handleNext}
                      style={{
                        width: '100%',
                        position: 'relative',
                        paddingRight: '32px',
                        fontSize: '13px',
                      }}
                      disabled={totalCharCount > MAX_CHAR_COUNT}
                    >
                      チェック実行に進む
                      <RightOutlined
                        style={{
                          position: 'absolute',
                          right: '16px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                        }}
                      />
                    </Button>
                  </>
                )}
              </div>
            )}
          </Col>

          <Col xs={24} xl={20}>
            <div style={{ width: '100%' }}>
              {currentStep === 0 && (
                <Form>
                  <Form.Item>
                    <Radio.Group
                      onChange={handleTabChange}
                      value={activeTab}
                      style={{
                        display: 'flex',
                        width: '100%',
                        marginBottom: '16px',
                        flexWrap: 'wrap',
                      }}
                      buttonStyle="solid"
                      className={screens.md ? '' : 'sp-radio-tabs'}
                    >
                      <Radio.Button
                        value="direct-input"
                        style={
                          screens.md
                            ? radioStyle
                            : { width: 'calc(50% - 4px)', margin: '0 0 8px 0' }
                        }
                      >
                        <Tooltip title="エディタに直接テキストを入力して検証します。自分で作成したコンテンツの検証に最適です。">
                          {screens.md ? '原稿を直接入力' : '直接入力'}
                        </Tooltip>
                      </Radio.Button>
                      <Radio.Button
                        value="from-seo"
                        style={
                          screens.md
                            ? radioStyle
                            : { width: 'calc(50% - 4px)', margin: '0 0 8px 0' }
                        }
                      >
                        <Tooltip title="magicssで生成したSEO記事を検証します。記事の信頼性を高めるために使用してください。">
                          {screens.md ? '生成したSEO記事' : 'SEO記事'}
                        </Tooltip>
                      </Radio.Button>
                      <Radio.Button
                        value="from-url"
                        style={
                          screens.md
                            ? radioStyle
                            : { width: 'calc(50% - 4px)', margin: '0 0 8px 0' }
                        }
                      >
                        <Tooltip title="URLからコンテンツを抽出して検証します。自社サイトや許諾を得たサイトのみ使用してください。著作権に十分ご注意ください。">
                          {screens.md ? 'URLから抽出' : 'URLから'}
                        </Tooltip>
                      </Radio.Button>
                      <Radio.Button
                        value="from-file"
                        style={
                          screens.md
                            ? radioStyle
                            : { width: 'calc(50% - 4px)', margin: '0 0 8px 0' }
                        }
                      >
                        <Tooltip title="アップロードしたファイルから内容を抽出します。補助的な機能として使用し、著作権のあるコンテンツには使用しないでください。">
                          {screens.md ? 'ファイルから抽出' : 'ファイル'}
                        </Tooltip>
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  {activeTab === 'from-seo' && (
                    <>
                      <Text
                        style={{
                          display: 'block',
                          marginBottom: '8px',
                          color: '#999',
                          fontSize: screens.md ? '14px' : '12px',
                        }}
                      >
                        生成した記事を選択：
                      </Text>
                      <Form.Item>
                        <Select
                          showSearch
                          placeholder="記事を選択"
                          onChange={handleArticleSelect}
                          filterOption={(input, option) =>
                            (option?.label ?? '')
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={articleOptions}
                          className="mode-selector-toggle"
                          size={screens.md ? 'middle' : 'middle'}
                        />
                      </Form.Item>
                    </>
                  )}
                  {activeTab === 'from-url' && (
                    <>
                      <Text
                        style={{
                          display: 'block',
                          marginBottom: '8px',
                          color: '#999',
                          fontSize: screens.md ? '14px' : '12px',
                        }}
                      >
                        URLを入力：
                      </Text>
                      <Form.Item>
                        <Input
                          type="url"
                          value={url}
                          onChange={handleUrlChange}
                          placeholder="https://magicss.ai"
                          disabled={isUrlLoading}
                          className="url-input"
                          size={screens.md ? 'middle' : 'small'}
                        />
                      </Form.Item>
                      {isUrlLoading && (
                        <div style={{ textAlign: 'center', margin: '20px 0' }}>
                          <Spin tip="URLからテキストを抽出中..." />
                        </div>
                      )}
                    </>
                  )}
                  {activeTab === 'direct-input' && (
                    <>
                      <Text
                        style={{
                          display: 'block',
                          marginBottom: '24px',
                        }}
                      >
                        次の画面でブロックエディタが表示されます。
                        <br />
                        HTML、プレーンテキストどちらでもペーストできます。
                      </Text>
                    </>
                  )}
                  {activeTab === 'from-file' && (
                    <>
                      <Text
                        style={{
                          display: 'block',
                          marginBottom: '8px',
                          color: '#999',
                        }}
                      >
                        ファイルをアップロード：
                      </Text>
                      <Form.Item>
                        <Upload.Dragger
                          name="file"
                          accept=".docx,.pdf,.txt"
                          showUploadList={false}
                          customRequest={({ file }) => handleFileUpload(file)}
                          disabled={isFileUploading}
                        >
                          {uploadedFile ? (
                            <div style={{ padding: '20px' }}>
                              <p style={{ margin: 0 }}>
                                <FileOutlined style={{ marginRight: '8px' }} />
                                {uploadedFile.name}
                              </p>
                              <Text
                                type="secondary"
                                style={{ fontSize: '12px' }}
                              >
                                クリックまたはドラッグ＆ドロップで再アップロード
                              </Text>
                            </div>
                          ) : (
                            <>
                              <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                              </p>
                              <p className="ant-upload-text">
                                クリックまたはドラッグ＆ドロップでファイルをアップロード
                              </p>
                              <p className="ant-upload-hint">
                                サポートされているファイル形式: .docx, .pdf,
                                .txt
                              </p>
                            </>
                          )}
                        </Upload.Dragger>
                      </Form.Item>
                      {isFileUploading && (
                        <div style={{ textAlign: 'center', margin: '20px 0' }}>
                          <Spin tip="ファイルをアップロード中..." />
                        </div>
                      )}
                      {isProcessing && (
                        <div style={{ textAlign: 'center', margin: '20px 0' }}>
                          <Spin tip="ファイルを処理中..." />
                        </div>
                      )}
                    </>
                  )}
                </Form>
              )}

              {currentStep === 1 && renderEditorSection()}

              <Row
                justify="center"
                style={{ marginTop: screens.md ? '36px' : '24px' }}
              >
                <Col>
                  {currentStep > 0 && (
                    <Button
                      onClick={handlePrev}
                      style={{
                        ...buttonWithLeftIconStyle,
                        marginRight: '10px',
                      }}
                      disabled={currentStep === 1 && draft_id}
                      size={screens.md ? 'middle' : 'small'}
                    >
                      <LeftOutlined style={buttonLeftIconStyle} />
                      {currentStep === 1
                        ? screens.md
                          ? 'ソース選択に戻る'
                          : '選択に戻る'
                        : screens.md
                          ? '原稿の調整に戻る'
                          : '調整に戻る'}
                    </Button>
                  )}
                  {currentStep < 2 && (
                    <Button
                      type="primary"
                      onClick={handleNext}
                      style={{
                        ...buttonWithIconStyle,
                        width: screens.md ? '300px' : '100%',
                        maxWidth: '300px',
                      }}
                      loading={isUrlLoading || isProcessing}
                      disabled={isNextButtonDisabled()}
                      size={screens.md ? 'middle' : 'small'}
                    >
                      {currentStep === 0
                        ? screens.md
                          ? '原稿調整に進む'
                          : '調整に進む'
                        : screens.md
                          ? 'チェック実行に進む'
                          : '実行に進む'}
                      <RightOutlined style={buttonIconStyle} />
                    </Button>
                  )}
                </Col>
              </Row>

              {currentStep === 0 && (
                <Row
                  justify="center"
                  style={{ marginTop: screens.md ? '16px' : '12px' }}
                >
                  <Col xs={24} md={16} lg={12}>
                    <Alert
                      message={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <InfoCircleOutlined
                            style={{ marginRight: '8px', color: '#1890ff' }}
                          />
                          <span
                            style={{
                              fontWeight: '700',
                              fontSize: screens.md ? '14px' : '12px',
                            }}
                          >
                            この段階ではまだチケットは消費されません
                          </span>
                        </div>
                      }
                      description={
                        <div style={{ fontSize: screens.md ? '13px' : '12px' }}>
                          チケットの消費は「チェック実行に進む」ボタンを押して予約を確定した時点で行われます。原稿を読み込むと下書きとして保存され、タスク一覧ページから編集を再開できます。
                        </div>
                      }
                      type="info"
                      showIcon={false}
                      style={{
                        background: '#f0f7ff',
                        border: '1px solid #d6e8fc',
                        borderRadius: '4px',
                        padding: screens.md ? undefined : '12px',
                      }}
                    />
                  </Col>
                </Row>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <ConfirmReservationModal
        visible={confirmModalVisible}
        onOk={handleConfirmReservation}
        onCancel={() => setConfirmModalVisible(false)}
        modalData={modalData}
      />
    </>
  );
};

export default FactCheckNew;
