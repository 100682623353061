import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  Input,
  Button,
  Space,
  Breadcrumb,
  Tag,
  Badge,
  Checkbox,
  message,
  Divider,
  Typography,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Link } from 'react-router-dom';
import UserDetail from './UserDetail';
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet';

const { Title, Text, Paragraph } = Typography;

const UserList = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [userDetailVisible, setUserDetailVisible] = useState(false);
  const [showPendingOnly, setShowPendingOnly] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
  });
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    // ResizeObserverエラー抑制のためのグローバル設定
    const originalError = window.console.error;
    window.console.error = (...args) => {
      if (
        args[0] &&
        typeof args[0] === 'string' &&
        args[0].includes('ResizeObserver')
      ) {
        // ResizeObserverに関連するエラーを無視
        return;
      }
      return originalError.apply(window.console, args);
    };

    // コンポーネントアンマウント時にオリジナルの関数を復元
    return () => {
      window.console.error = originalError;
    };
  }, []);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            limit: 500, // バックエンドに送信する制限値を増加
            skip: 0,
          },
        },
      );

      if (!response.data || !Array.isArray(response.data)) {
        console.error('Invalid response format:', response.data);
        message.error('サーバーからの応答形式が不正です');
        setData([]);
        setFilteredData([]);
        return;
      }

      // 各フィルター条件ごとの除外数をカウント
      const notActive = response.data.filter(
        user => !user.is_active && user.is_active !== 'True',
      ).length;
      const noLastName = response.data.filter(user => !user.last_name).length;
      const noFirstName = response.data.filter(user => !user.first_name).length;

      // フィルタリングを段階的に行い、各段階での結果をログ出力
      const activeUsers = response.data.filter(
        user => user.is_active === true || user.is_active === 'True',
      );

      const usersWithLastName = activeUsers.filter(user => !!user.last_name);

      const usersWithBothNames = usersWithLastName.filter(
        user => !!user.first_name,
      );

      // 改善したフィルタリングロジック
      const formattedData = response.data
        .filter(user => {
          // 各ユーザーのフィルタリング条件を詳細にチェック
          const isActive = user.is_active === true || user.is_active === 'True';
          const hasLastName = !!user.last_name;
          const hasFirstName = !!user.first_name;

          return isActive && hasLastName && hasFirstName;
        })
        .map(user => ({
          ...user,
          // 確実に正しい型に変換
          is_active: user.is_active === true || user.is_active === 'True',
          registered_at: moment(user.registered_at).tz('Asia/Tokyo'),
          last_login_at: user.last_login_at
            ? moment(user.last_login_at).tz('Asia/Tokyo')
            : null,
          has_pending_custom_plan:
            user.has_pending_custom_plan === true ||
            user.has_pending_custom_plan === 'True',
          is_canceling:
            user.is_canceling === true || user.is_canceling === 'True',
          subscription: user.subscription
            ? {
                ...user.subscription,
                next_renewal_date: user.subscription.next_renewal_date
                  ? moment(user.subscription.next_renewal_date)
                  : null,
                current_period_end: user.subscription.current_period_end
                  ? moment(user.subscription.current_period_end)
                  : null,
              }
            : null,
        }));

      setData(formattedData);
      setFilteredData(formattedData); // 初期状態では全データをセット

      setPagination(prev => ({
        ...prev,
        total: formattedData.length,
      }));
    } catch (error) {
      console.error('Failed to fetch users:', error);
      message.error('ユーザー情報の取得に失敗しました');
      setData([]);
      setFilteredData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const filtered = data.filter(user => {
      const searchLower = searchText.toLowerCase();
      const matches =
        (user.email && user.email.toLowerCase().includes(searchLower)) ||
        (user.company_name &&
          user.company_name.toLowerCase().includes(searchLower)) ||
        (user.department_name &&
          user.department_name.toLowerCase().includes(searchLower)) ||
        (user.last_name &&
          user.last_name.toLowerCase().includes(searchLower)) ||
        (user.first_name &&
          user.first_name.toLowerCase().includes(searchLower)) ||
        (user.last_name_kana &&
          user.last_name_kana.toLowerCase().includes(searchLower)) ||
        (user.first_name_kana &&
          user.first_name_kana.toLowerCase().includes(searchLower)) ||
        (user.last_name &&
          user.first_name &&
          `${user.last_name} ${user.first_name}`
            .toLowerCase()
            .includes(searchLower)) ||
        (user.last_name_kana &&
          user.first_name_kana &&
          `${user.last_name_kana} ${user.first_name_kana}`
            .toLowerCase()
            .includes(searchLower));

      return matches;
    });

    setFilteredData(filtered);
    setPagination(prev => ({
      ...prev,
      current: 1,
      total: filtered.length,
    }));
  }, [searchText, data]);

  const showUserDetail = userId => {
    setSelectedUserId(userId);
    setUserDetailVisible(true);
  };

  const handleUserDetailCancel = () => {
    setSelectedUserId(null);
    setUserDetailVisible(false);
  };

  const handleUserUpdate = () => {
    fetchUsers();
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
  });

  const planTags = {
    free: { color: 'blue', text: 'フリープラン' },
    standard: { color: 'green', text: 'スタンダードプラン' },
    pro: { color: 'orange', text: 'プロプラン' },
    premium: { color: 'gold', text: 'プレミアムプラン' },
    custom: { color: 'purple', text: 'カスタムプラン' },
  };

  const columns = [
    {
      title: '会社名/部署名',
      key: 'company',
      render: (_, record) => (
        <>
          <div>{record.company_name}</div>
          <div style={{ fontSize: '12px', color: '#888' }}>
            {record.department_name}
          </div>
        </>
      ),
      ...getColumnSearchProps('company_name'),
    },
    {
      title: '氏名',
      key: 'name',
      render: (_, record) => (
        <>
          <div>
            {record.last_name} {record.first_name}
          </div>
          <div style={{ fontSize: '12px', color: '#888' }}>
            {record.last_name_kana} {record.first_name_kana}
          </div>
        </>
      ),
      ...getColumnSearchProps('last_name'),
    },
    {
      title: 'プラン',
      dataIndex: 'plan',
      key: 'plan',
      render: (plan, record) => {
        const planInfo = plan
          ? planTags[plan.type] || planTags.custom
          : planTags.free;
        return (
          <>
            <Badge dot={record.has_pending_custom_plan}>
              <Tag color={planInfo.color}>
                {plan?.display_name || planInfo.text}
              </Tag>
            </Badge>
            {record.is_canceling && (
              <div
                style={{ fontSize: '11px', color: '#ff4d4f', marginTop: '4px' }}
              >
                自動キャンセル
              </div>
            )}
          </>
        );
      },
      filters: [
        { text: 'フリープラン', value: 'free' },
        { text: 'スタンダードプラン', value: 'standard' },
        { text: 'プロプラン', value: 'pro' },
        { text: 'プレミアムプラン', value: 'premium' },
        { text: 'カスタムプラン', value: 'custom' },
        { text: '承認待ち', value: 'pending' },
        { text: 'キャンセル予約中', value: 'canceling' },
      ],
      onFilter: (value, record) => {
        if (value === 'pending') {
          return record.has_pending_custom_plan;
        }
        if (value === 'canceling') {
          return record.is_canceling;
        }
        return (
          record.plan?.type === value || (!record.plan && value === 'free')
        );
      },
    },
    {
      title: '次回更新日',
      dataIndex: 'subscription',
      key: 'next_renewal_date',
      render: subscription => {
        if (!subscription) return '-';
        const nextRenewalDate =
          subscription.next_renewal_date || subscription.current_period_end;
        if (!nextRenewalDate) return '-';

        const formattedDate = nextRenewalDate.format('YYYY/MM/DD');

        if (subscription.cancel_at_period_end) {
          return <Text type="danger">{formattedDate}</Text>;
        }

        return formattedDate;
      },
      sorter: (a, b) => {
        const dateA = a.subscription
          ? a.subscription.next_renewal_date ||
            a.subscription.current_period_end
          : null;
        const dateB = b.subscription
          ? b.subscription.next_renewal_date ||
            b.subscription.current_period_end
          : null;
        if (!dateA) return 1;
        if (!dateB) return -1;
        return moment(dateA).diff(moment(dateB));
      },
    },
    {
      title: 'チケット枚数',
      dataIndex: 'ticket_count',
      key: 'ticket_count',
      sorter: (a, b) => a.ticket_count - b.ticket_count,
    },
    {
      title: '登録日',
      dataIndex: 'registered_at',
      key: 'registered_at',
      render: text => text.format('YYYY/MM/DD HH:mm'),
      sorter: (a, b) => a.registered_at.diff(b.registered_at),
    },
    {
      title: '最終ログイン',
      dataIndex: 'last_login_at',
      key: 'last_login_at',
      render: text => (text ? text.format('YYYY/MM/DD HH:mm') : '-'),
      sorter: (a, b) => {
        if (!a.last_login_at) return 1;
        if (!b.last_login_at) return -1;
        return a.last_login_at.diff(b.last_login_at);
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>ユーザー管理 | magicss</title>
        <meta name="robots" content="noindex" />
        <meta property="og:title" content={`ユーザー管理 | magicss`} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/ogp.png`}
        />
        <meta
          property="og:description"
          content={`コンテンツ生成AIツール「magicss」のユーザー管理ページです。`}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>管理者機能</Breadcrumb.Item>
      </Breadcrumb>
      <Title level={1} className="gradient-text" style={{ fontSize: '24px' }}>
        ユーザー管理
      </Title>
      <Divider style={{ margin: '24px 0' }} />
      <Space
        style={{
          marginBottom: 16,
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Checkbox
          checked={showPendingOnly}
          onChange={e => setShowPendingOnly(e.target.checked)}
        >
          カスタムプラン承認待ちユーザーのみ表示
        </Checkbox>
        <Input.Search
          placeholder="メールアドレス、会社名、氏名、カタカナで検索"
          allowClear
          enterButton
          onSearch={value => setSearchText(value)}
          onChange={e => setSearchText(e.target.value)}
          style={{ width: 300 }}
        />
      </Space>
      <Table
        columns={columns}
        dataSource={
          showPendingOnly
            ? filteredData.filter(user => user.has_pending_custom_plan)
            : filteredData
        }
        rowKey="id"
        onRow={record => ({
          onClick: () => showUserDetail(record.user_id),
          style: { cursor: 'pointer' },
        })}
        scroll={{ x: 'max-content' }}
        pagination={{
          ...pagination,
          onChange: (page, pageSize) => {
            setPagination({
              ...pagination,
              current: page,
              pageSize: pageSize,
            });
          },
          showTotal: (total, range) => `${range[0]}-${range[1]} / ${total}件`,
        }}
        loading={loading}
      />
      <UserDetail
        visible={userDetailVisible}
        onCancel={handleUserDetailCancel}
        userId={selectedUserId}
        onUpdate={handleUserUpdate}
      />
    </>
  );
};

export default UserList;
