import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
  Link,
  Flex,
} from 'react-router-dom';
import {
  Layout,
  Menu,
  message,
  Spin,
  Divider,
  Grid,
  Drawer,
  Dropdown,
  Row,
  Col,
  Tooltip,
  Space,
} from 'antd';
import { Helmet } from 'react-helmet';
import {
  FormOutlined,
  FireOutlined,
  DatabaseOutlined,
  UserOutlined,
  TeamOutlined,
  RocketOutlined,
  RobotOutlined,
  ClusterOutlined,
  BulbOutlined,
  MenuOutlined,
  CaretDownOutlined,
  ExportOutlined,
  DashboardOutlined,
  AccountBookOutlined,
  AuditOutlined,
  LockOutlined,
} from '@ant-design/icons';
import './main.css';
import './components/FactCheck/editor-custom.css';
import SEOArticleDashboard from './components/SEOArticle/Dashboard';
import SEOArticleGenerator from './components/SEOArticle/Generate';
import SEOArticleList from './components/SEOArticle/ArticleList';
import SEOArticleDetail from './components/SEOArticle/ArticleDetail';
import SharedArticle from './components/SEOArticle/SharedArticle';
import UserSettings from './components/UserSettings';
import PlanSettings from './components/PlanSettings';
import Auth from './components/auth/Auth';
import RegisterProfile from './components/auth/RegisterProfile';
import ResetPassword from './components/auth/ResetPassword';
import RegisterEmail from './components/auth/RegisterEmail';
import { api, setAuthToken, recordActivity } from './api';
import AuthenticatedMenu from './components/AuthenticatedMenu';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import { getUserRole, getUserProfile } from './auth';
import UserList from './components/UserManagement/UserList';
import AdminDashboard from './components/AdminDashboard';
import AdminTransactions from './components/AdminTransactions';
import ConfirmEmail from './components/ConfirmEmail';
import RecommendedKeywords from './components/SEOArticle/RecommendedKeywords';
import SubscriptionSuccess from './components/SubscriptionSuccess';
import SubscriptionManagement from './components/SubscriptionManagement';
import SubscriptionCanceled from './components/SubscriptionCanceled';
import UpgradeBanner from './components/UpgradeBanner';
import UpgradeModal from './components/UpgradeModal';
import TermsOfService from './components/StaticPages/TermsOfService';
import PrivacyPolicy from './components/StaticPages/PrivacyPolicy';
import ContactUs from './components/StaticPages/ContactUs';
import ReserveMtg from './components/StaticPages/ReserveMtg';
import AgreementManager from './components/AgreementManager';
import { setUserProperties } from './utils/ga4';
import FactCheckList from './components/FactCheck/FactCheckList';
import FactCheckDetail from './components/FactCheck/FactCheckDetail';
import FactCheckNew from './components/FactCheck/FactCheckNew';

const { Header, Content, Sider, Footer } = Layout;
const { useBreakpoint } = Grid;

const menuOverlayStyle = {
  subMenuItemSelectedStyle: {
    backgroundColor: '#1890ff',
    color: 'white',
  },
};

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState('user');
  const [userProfile, setUserProfile] = useState(null);
  const [ticketCount, setTicketCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const screens = useBreakpoint();

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const getSelectedKey = pathname => {
    if (pathname === '/seo-article') return '/seo-article';
    if (pathname.startsWith('/seo-article/list')) return '/seo-article/list';
    if (pathname.startsWith('/seo-article/detail')) return '/seo-article/list';
    if (pathname.startsWith('/seo-article/observe_task'))
      return '/seo-article/observe_task';
    if (pathname.startsWith('/seo-article/keywords-recommend'))
      return '/seo-article/keywords-recommend';
    if (pathname === '/fact-check') return '/fact-check';
    if (pathname.startsWith('/fact-check/new')) return '/fact-check';
    if (pathname.startsWith('/fact-check/tasks')) return '/fact-check/tasks';
    if (pathname.startsWith('/fact-check/result')) return '/fact-check/result';
    if (pathname.startsWith('/admin/dashboard')) return '/admin/dashboard';
    if (pathname.startsWith('/admin/user-management'))
      return '/admin/user-management';
    if (pathname.startsWith('/admin/transactions'))
      return '/admin/transactions';
    if (pathname.startsWith('/plan')) return '/plan';
    if (pathname.startsWith('/settings')) return '/settings';
    if (pathname.startsWith('/subscription-'))
      return '/subscription-management';
    return '';
  };

  useEffect(() => {
    const checkAuth = async () => {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      if (token) {
        setAuthToken(token);
        try {
          const role = await getUserRole();
          setUserRole(role);
          setIsAuthenticated(true);

          try {
            const profile = await getUserProfile();
            setUserProfile(profile);

            if (profile) {
              setUserProperties(profile.user_id, {
                role: profile.role,
                plan: profile.plan?.name,
                company: profile.company_name,
                ticket_count: profile.ticket_count,
                fact_check_access: profile.early_access?.fact_check || false,
              });
            }
          } catch (error) {
            console.error('ユーザー情報の取得に失敗しました:', error);
            message.error('ユーザー情報の取得に失敗しました。');
          }
        } catch (error) {
          localStorage.removeItem('token');
          setIsAuthenticated(false);
          message.error('認証に失敗しました。再度ログインしてください。');
          if (!location.pathname.startsWith('/shared')) {
            redirectToLogin(location);
          }
        }
      } else {
        setIsAuthenticated(false);
        const staticPages = [
          '/terms-of-service',
          '/privacy-policy',
          '/contact-us',
          'reserve-mtg',
          '/login',
          '/register-email',
          '/register-profile',
          '/confirm-email',
          '/reset-password',
          '/shared',
        ];
        if (!staticPages.some(page => location.pathname.startsWith(page))) {
          redirectToLogin(location);
        }
      }
      setIsLoading(false);
    };

    checkAuth();
  }, [location.pathname, location.search]);

  useEffect(() => {
    // アプリケーションが開かれたときにアクティビティを記録
    recordActivity('login');

    // 定期的にアクティビティを記録（例：15分ごと）
    const intervalId = setInterval(
      () => {
        recordActivity('login');
      },
      30 * 60 * 1000,
    );

    return () => clearInterval(intervalId);
  }, []);

  const redirectToLogin = (location, isLogout = false) => {
    const currentParams = new URLSearchParams(location.search);
    const storedParams = JSON.parse(localStorage.getItem('utmParams') || '{}');

    // 現在のURLパラメータを優先し、ない場合はストレージのものを使用
    const combinedParams = new URLSearchParams({
      ...storedParams,
      ...Object.fromEntries(currentParams),
    });

    const utmKeys = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_content',
      'ref',
    ];
    const filteredParams = new URLSearchParams();

    // UTMパラメータのみを抽出
    for (const key of utmKeys) {
      const value = combinedParams.get(key);
      if (value) {
        filteredParams.append(key, value);
      }
    }

    const redirectUrl = `/login${filteredParams.toString() ? `?${filteredParams.toString()}` : ''}`;
    navigate(redirectUrl, { replace: true });
  };

  useEffect(() => {
    if (isAuthenticated && userProfile) {
      const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
      // const wsUrl = `${wsProtocol}//${window.location.hostname}:8000/ws/${userProfile.user_id}`;
      // const wsUrl = `${wsProtocol}//test-api.magicss.ai/ws/${userProfile.user_id}`;
      const wsUrl = `${wsProtocol}//api.magicss.ai/ws/${userProfile.user_id}`;

      const ws = new WebSocket(wsUrl);

      ws.onopen = () => {
        console.log('WebSocket connection established');
        const token = localStorage.getItem('token');
        ws.send(token);
      };

      ws.onmessage = event => {
        const data = JSON.parse(event.data);
        console.log('[DEBUG-WS] メッセージ受信:', data);

        // チケット数の更新
        if (data.ticket_count !== undefined) {
          setTicketCount(data.ticket_count);
        }

        // メッセージ通知
        if (data.message) {
          message.success(data.message);
        }

        // タスク関連のメッセージ処理
        if (data.type) {
          // フラグメントメッセージの場合、必要なIDを設定
          if (data.type === 'new_fragment' && data.fragment) {
            // フラグメントIDを確実に設定
            data.fragment.id = `${data.task_id}-${data.fragment.order_index}`;

            // 配列の初期化を確実に行う
            if (!Array.isArray(data.fragment.reference_info)) {
              data.fragment.reference_info = [];
            }
            if (!Array.isArray(data.fragment.deep_check_sources)) {
              data.fragment.deep_check_sources = [];
            }
          }

          // window.factCheckWebSocketMessageが存在する場合のみ呼び出し
          if (typeof window.factCheckWebSocketMessage === 'function') {
            try {
              window.factCheckWebSocketMessage(data);
            } catch (error) {
              console.error('[DEBUG-WS] メッセージ処理エラー:', error);
            }
          }
        }
      };

      ws.onerror = error => {
        console.error('WebSocket error:', error);
      };

      ws.onclose = () => {
        console.log('WebSocket connection closed');
      };

      return () => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.close();
        }
      };
    }
  }, [isAuthenticated, userProfile]);

  useEffect(() => {
    if (isAuthenticated && userProfile) {
      const shouldShowUpgradePrompt =
        userProfile.plan.type === 'free' && userProfile.ticket_count === 0;
      const isNotPlanPage = location.pathname !== '/plan';
      if (shouldShowUpgradePrompt && isNotPlanPage) {
        const lastPromptDate = localStorage.getItem('lastUpgradePromptDate');
        const today = new Date().toDateString();
        if (lastPromptDate !== today) {
          setShowUpgradeModal(true);
          localStorage.setItem('lastUpgradePromptDate', today);
        }
      }
    }
  }, [isAuthenticated, userProfile, location.pathname]);

  useEffect(() => {
    const recordActivity = async () => {
      if (isAuthenticated) {
        try {
          await api.post('/users/record-activity', { activity_type: 'login' });
        } catch (error) {
          console.error('Failed to record login activity:', error);
        }
      }
    };

    recordActivity();
  }, [isAuthenticated]);

  const handleCloseUpgradeModal = () => {
    setShowUpgradeModal(false);
  };

  const modeMenu = (
    <Menu>
      <Menu.SubMenu
        key="seo-article"
        title="SEO記事生成"
        style={
          location.pathname.startsWith('/seo-article')
            ? { backgroundColor: '#e6f7ff', color: '#1890ff' }
            : {}
        }
        className={
          location.pathname.startsWith('/seo-article')
            ? 'current-menu-item'
            : ''
        }
        onTitleClick={() => navigate('/seo-article')}
        popupClassName="submenu-popup"
      >
        <Menu.Item
          key="seo-article-new"
          onClick={() => navigate('/seo-article')}
          style={
            location.pathname === '/seo-article'
              ? { backgroundColor: '#e6f7ff', color: '#1890ff' }
              : {}
          }
          className={
            location.pathname === '/seo-article' ? 'current-menu-item' : ''
          }
        >
          新規生成
        </Menu.Item>
        <Menu.Item
          key="seo-article-list"
          onClick={() => navigate('/seo-article/list')}
          style={
            location.pathname.startsWith('/seo-article/list') ||
            location.pathname.startsWith('/seo-article/detail')
              ? { backgroundColor: '#e6f7ff', color: '#1890ff' }
              : {}
          }
          className={
            location.pathname.startsWith('/seo-article/list') ||
            location.pathname.startsWith('/seo-article/detail')
              ? 'current-menu-item'
              : ''
          }
        >
          生成結果一覧
        </Menu.Item>
        {(userRole === 'master' || userRole === 'admin') && (
          <Menu.Item
            key="seo-article-observe"
            onClick={() => navigate('/seo-article/observe_task')}
            style={
              location.pathname.startsWith('/seo-article/observe_task')
                ? { backgroundColor: '#e6f7ff', color: '#1890ff' }
                : {}
            }
            className={
              location.pathname.startsWith('/seo-article/observe_task')
                ? 'current-menu-item'
                : ''
            }
          >
            タスク監視
          </Menu.Item>
        )}
      </Menu.SubMenu>
      {userProfile?.early_access?.fact_check ? (
        <Menu.SubMenu
          key="fact-check"
          title="ファクトチェック"
          style={
            location.pathname.startsWith('/fact-check')
              ? { backgroundColor: '#e6f7ff', color: '#1890ff' }
              : {}
          }
          className={
            location.pathname.startsWith('/fact-check')
              ? 'current-menu-item'
              : ''
          }
          onTitleClick={() => navigate('/fact-check')}
          popupClassName="submenu-popup"
        >
          <Menu.Item
            key="fact-check-new"
            onClick={() => navigate('/fact-check')}
            style={
              location.pathname === '/fact-check' ||
              location.pathname.startsWith('/fact-check/new')
                ? { backgroundColor: '#e6f7ff', color: '#1890ff' }
                : {}
            }
            className={
              location.pathname === '/fact-check' ||
              location.pathname.startsWith('/fact-check/new')
                ? 'current-menu-item'
                : ''
            }
          >
            新規タスク予約
          </Menu.Item>
          <Menu.Item
            key="fact-check-tasks"
            onClick={() => navigate('/fact-check/tasks')}
            style={
              location.pathname.startsWith('/fact-check/tasks') ||
              location.pathname.startsWith('/fact-check/result')
                ? { backgroundColor: '#e6f7ff', color: '#1890ff' }
                : {}
            }
            className={
              location.pathname.startsWith('/fact-check/tasks') ||
              location.pathname.startsWith('/fact-check/result')
                ? 'current-menu-item'
                : ''
            }
          >
            タスク一覧
          </Menu.Item>
        </Menu.SubMenu>
      ) : (
        <Menu.Item key="fact-check" disabled>
          <Tooltip title="一部のユーザー様にのみ試験提供中です。ご希望の場合はお問い合わせください。">
            <Space>
              <LockOutlined />
              ファクトチェック
            </Space>
          </Tooltip>
        </Menu.Item>
      )}
      <Menu.SubMenu
        key="user-settings"
        title="ユーザー設定"
        style={
          ['/settings', '/plan', '/subscription-management'].some(
            path => location.pathname === path,
          )
            ? { backgroundColor: '#e6f7ff', color: '#1890ff' }
            : {}
        }
        className={
          ['/settings', '/plan', '/subscription-management'].some(
            path => location.pathname === path,
          )
            ? 'current-menu-item'
            : ''
        }
        onTitleClick={() => navigate('/subscription-management')}
        popupClassName="submenu-popup"
      >
        <Menu.Item
          key="subscription-management"
          onClick={() => navigate('/subscription-management')}
          style={
            location.pathname === '/subscription-management' ||
            location.pathname === '/subscription-success' ||
            location.pathname === '/subscription-canceled'
              ? { backgroundColor: '#e6f7ff', color: '#1890ff' }
              : {}
          }
          className={
            location.pathname === '/subscription-management' ||
            location.pathname === '/subscription-success' ||
            location.pathname === '/subscription-canceled'
              ? 'current-menu-item'
              : ''
          }
        >
          サブスクリプション管理
        </Menu.Item>
        <Menu.Item
          key="plan-settings"
          onClick={() => navigate('/plan')}
          style={
            location.pathname === '/plan'
              ? { backgroundColor: '#e6f7ff', color: '#1890ff' }
              : {}
          }
          className={location.pathname === '/plan' ? 'current-menu-item' : ''}
        >
          プラン変更
        </Menu.Item>
        <Menu.Item
          key="user-settings"
          onClick={() => navigate('/settings')}
          style={
            location.pathname === '/settings'
              ? { backgroundColor: '#e6f7ff', color: '#1890ff' }
              : {}
          }
          className={
            location.pathname === '/settings' ? 'current-menu-item' : ''
          }
        >
          登録情報変更
        </Menu.Item>
      </Menu.SubMenu>
      {(userRole === 'admin' || userRole === 'master') && (
        <Menu.SubMenu
          key="admin-menu"
          title="管理者用メニュー"
          style={
            location.pathname.startsWith('/admin')
              ? { backgroundColor: '#e6f7ff', color: '#1890ff' }
              : {}
          }
          className={
            location.pathname.startsWith('/admin') ? 'current-menu-item' : ''
          }
          onTitleClick={() => navigate('/admin/dashboard')}
          popupClassName="submenu-popup"
        >
          <Menu.Item
            key="admin-dashboard"
            onClick={() => navigate('/admin/dashboard')}
            style={
              location.pathname === '/admin/dashboard'
                ? { backgroundColor: '#e6f7ff', color: '#1890ff' }
                : {}
            }
            className={
              location.pathname === '/admin/dashboard'
                ? 'current-menu-item'
                : ''
            }
          >
            ダッシュボード
          </Menu.Item>
          <Menu.Item
            key="admin-user-management"
            onClick={() => navigate('/admin/user-management')}
            style={
              location.pathname === '/admin/user-management'
                ? { backgroundColor: '#e6f7ff', color: '#1890ff' }
                : {}
            }
            className={
              location.pathname === '/admin/user-management'
                ? 'current-menu-item'
                : ''
            }
          >
            ユーザー管理
          </Menu.Item>
          <Menu.Item
            key="admin-transactions"
            onClick={() => navigate('/admin/transactions')}
            style={
              location.pathname === '/admin/transactions'
                ? { backgroundColor: '#e6f7ff', color: '#1890ff' }
                : {}
            }
            className={
              location.pathname === '/admin/transactions'
                ? 'current-menu-item'
                : ''
            }
          >
            トランザクション一覧
          </Menu.Item>
        </Menu.SubMenu>
      )}
    </Menu>
  );

  const renderSidebar = () => {
    const selectedMenu = getSelectedKey(location.pathname);
    let sidebarContent;

    const createSidebarContent = (title, menuItems) => (
      <>
        <div className="logo side">
          <span>Ignite creativity with..</span>magicss
        </div>
        <div className="mode-selector">
          <Dropdown overlay={modeMenu} trigger={['hover']}>
            <div className="mode-selector-toggle">
              {title} <CaretDownOutlined />
            </div>
          </Dropdown>
        </div>
        <Menu
          mode="inline"
          selectedKeys={[selectedMenu]}
          style={{ height: '100%', borderRight: 0, paddingTop: '24px' }}
        >
          {menuItems}
        </Menu>
      </>
    );

    if (selectedMenu.startsWith('/seo-article')) {
      sidebarContent = createSidebarContent(
        <>
          <RobotOutlined style={{ marginRight: '4px' }} />
          SEO記事生成
        </>,
        <>
          <Menu.Item key="/seo-article" icon={<FormOutlined />}>
            <Link to="/seo-article">新規生成</Link>
          </Menu.Item>
          <Menu.Item key="/seo-article/list" icon={<DatabaseOutlined />}>
            <Link to="/seo-article/list">生成結果一覧</Link>
          </Menu.Item>
          {(userRole === 'master' || userRole === 'admin') && (
            <>
              <Divider style={{ margin: '16px 0' }} />
              <Menu.Item
                key="/seo-article/observe_task"
                icon={<ClusterOutlined />}
              >
                <Link to="/seo-article/observe_task">タスク監視</Link>
              </Menu.Item>
            </>
          )}
        </>,
      );
    } else if (selectedMenu.startsWith('/fact-check')) {
      sidebarContent = createSidebarContent(
        <>
          <AuditOutlined style={{ marginRight: '4px' }} />
          ファクトチェック
        </>,
        <>
          <Menu.Item key="/fact-check" icon={<FormOutlined />}>
            <Link to="/fact-check">新規タスク予約</Link>
          </Menu.Item>
          <Menu.Item key="/fact-check/tasks" icon={<DatabaseOutlined />}>
            <Link to="/fact-check/tasks">タスク一覧</Link>
          </Menu.Item>
        </>,
      );
    } else if (
      selectedMenu === '/settings' ||
      selectedMenu === '/plan' ||
      selectedMenu === '/subscription-management'
    ) {
      sidebarContent = createSidebarContent(
        'ユーザー設定',
        <>
          <Menu.Item
            key="/subscription-management"
            icon={<AccountBookOutlined />}
          >
            <Link to="/subscription-management">サブスクリプション管理</Link>
          </Menu.Item>
          <Menu.Item key="/plan" icon={<RocketOutlined />}>
            <Link to="/plan">プラン変更</Link>
          </Menu.Item>
          <Menu.Item key="/settings" icon={<UserOutlined />}>
            <Link to="/settings">登録情報変更</Link>
          </Menu.Item>
        </>,
      );
    } else if (
      (selectedMenu === '/admin/user-management' ||
        selectedMenu === '/admin/transactions' ||
        selectedMenu === '/admin/dashboard') &&
      (userRole === 'admin' || userRole === 'master')
    ) {
      sidebarContent = createSidebarContent(
        '管理者メニュー',
        <>
          <Menu.Item key="/admin/dashboard" icon={<DashboardOutlined />}>
            <Link to="/admin/dashboard">ダッシュボード</Link>
          </Menu.Item>
          <Menu.Item key="/admin/user-management" icon={<TeamOutlined />}>
            <Link to="/admin/user-management">ユーザー管理</Link>
          </Menu.Item>
          <Menu.Item key="/admin/transactions" icon={<DatabaseOutlined />}>
            <Link to="/admin/transactions">トランザクション一覧</Link>
          </Menu.Item>
        </>,
      );
    }

    const sidebarProps = {
      width: 250,
      style: {
        height: '100vh',
        background: '#fff',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        paddingTop: '80px',
      },
    };

    return screens.xl ? (
      <Sider {...sidebarProps}>{sidebarContent}</Sider>
    ) : (
      <Drawer
        placement="left"
        closable={false}
        onClose={toggleSidebar}
        visible={sidebarVisible}
        bodyStyle={{ padding: 0 }}
        width={250}
      >
        <div className="drawer-menu-content">{sidebarContent}</div>
        <div className="drawer-ticket-count">
          <FireOutlined />
          <strong>{ticketCount} 枚</strong>
        </div>
      </Drawer>
    );
  };

  const renderAuthenticatedRoutes = () => (
    <Layout hasSider style={{ minHeight: '100vh' }}>
      {renderSidebar()}
      <Layout
        style={{ marginLeft: screens.xl ? 250 : 0, transition: 'margin 0.2s' }}
      >
        {userProfile && (
          <Header
            style={{
              background: '#fff',
              padding: screens.xl ? 0 : '0 16px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {!screens.xl && (
              <MenuOutlined className="menu-icon" onClick={toggleSidebar} />
            )}
            <AuthenticatedMenu
              userProfile={userProfile}
              userRole={userRole}
              ticketCount={ticketCount}
              isMobile={!screens.xl}
            />
          </Header>
        )}
        <Content
          style={{
            background: '#fff',
            padding: screens.xl ? '40px 40px 80px' : '16px',
            margin: screens.xl ? '24px 24px 0' : '16px 0 0',
            minHeight: 280,
          }}
        >
          {userProfile &&
            userProfile.plan.type === 'free' &&
            userProfile.ticket_count === 0 &&
            location.pathname !== '/plan' && <UpgradeBanner />}
          <Routes>
            <Route path="/seo-article" element={<SEOArticleGenerator />} />
            <Route
              path="/seo-article/observe_task"
              element={<SEOArticleDashboard />}
            />
            <Route
              path="/seo-article/list"
              element={<SEOArticleList userRole={userRole} />}
            />
            <Route
              path="/seo-article/list/:userId"
              element={<SEOArticleList userRole={userRole} />}
            />
            <Route
              path="/seo-article/detail/:uuid"
              element={<SEOArticleDetail />}
            />
            <Route
              path="/seo-article/keywords-recommend"
              element={<RecommendedKeywords />}
            />
            <Route
              path="/fact-check"
              element={
                userProfile?.early_access?.fact_check ? (
                  <FactCheckNew />
                ) : (
                  <Navigate to="/seo-article" replace />
                )
              }
            />
            <Route
              path="/fact-check/tasks"
              element={
                userProfile?.early_access?.fact_check ? (
                  <FactCheckList />
                ) : (
                  <Navigate to="/seo-article" replace />
                )
              }
            />
            <Route
              path="/fact-check/tasks/:userId"
              element={
                userProfile?.early_access?.fact_check ? (
                  <FactCheckList />
                ) : (
                  <Navigate to="/seo-article" replace />
                )
              }
            />
            <Route
              path="/fact-check/result/:task_id"
              element={
                userProfile?.early_access?.fact_check ? (
                  <FactCheckDetail />
                ) : (
                  <Navigate to="/seo-article" replace />
                )
              }
            />
            <Route
              path="/fact-check/new"
              element={
                userProfile?.early_access?.fact_check ? (
                  <FactCheckNew />
                ) : (
                  <Navigate to="/seo-article" replace />
                )
              }
            />
            <Route
              path="/fact-check/new/:draft_id"
              element={
                userProfile?.early_access?.fact_check ? (
                  <FactCheckNew />
                ) : (
                  <Navigate to="/seo-article" replace />
                )
              }
            />
            <Route path="/settings" element={<UserSettings />} />
            <Route path="/plan" element={<PlanSettings />} />
            <Route
              path="/subscription-success"
              element={
                <AuthenticatedRoute>
                  <SubscriptionSuccess />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="/subscription-management"
              element={
                <AuthenticatedRoute>
                  <SubscriptionManagement />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="/admin/dashboard"
              element={
                isAuthenticated &&
                (userRole === 'admin' || userRole === 'master') ? (
                  <AdminDashboard />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/admin/user-management"
              element={
                userRole === 'admin' || userRole === 'master' ? (
                  <UserList />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/admin/transactions"
              element={
                userRole === 'admin' || userRole === 'master' ? (
                  <AdminTransactions />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/subscription-canceled"
              element={<SubscriptionCanceled />}
            />
            <Route
              path="/settings/confirm-email/:token"
              element={<ConfirmEmail />}
            />
            <Route path="*" element={<Navigate to="/seo-article" />} />
          </Routes>
          {showUpgradeModal && location.pathname !== '/plan' && (
            <UpgradeModal
              visible={showUpgradeModal}
              onClose={handleCloseUpgradeModal}
            />
          )}
        </Content>
        <Footer id="footer">
          <Row gutter={[16, 16]} justify={'space-between'}>
            <Col xs={24} xl={24} xxl={16}>
              <Row className="f_menu">
                <Col xs={24} md={12} lg={5}>
                  <a href="https://magicss.ai" target="_blank">
                    magicssサービスサイト <ExportOutlined />
                  </a>
                </Col>
                <Col xs={24} md={12} lg={5}>
                  <a href="https://magicss.notion.site/" target="_blank">
                    使い方ガイド <ExportOutlined />
                  </a>
                </Col>
                <Col xs={24} md={12} lg={5}>
                  <a href="/terms-of-service" target="_blank">
                    サービス利用規約
                  </a>
                </Col>
                <Col xs={24} md={12} lg={5}>
                  <a href="/privacy-policy" target="_blank">
                    プライバシーポリシー
                  </a>
                </Col>
                <Col xs={24} md={12} lg={4}>
                  <a
                    href={`/contact-us${userProfile ? `?magicss_uuid=${userProfile.user_id}` : ''}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    お問い合わせ <ExportOutlined />
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col
              xs={24}
              lg={24}
              xl={24}
              xxl={24}
              style={{ textAlign: 'center' }}
            >
              © 2024{' '}
              <a href="https://oneword.co.jp" target="_blank">
                ONEWORD Inc.
              </a>
            </Col>
          </Row>
        </Footer>
      </Layout>
    </Layout>
  );

  const renderUnauthenticatedRoutes = () => (
    <Layout className="gate_wrap">
      <Routes>
        <Route path="/login" element={<Auth />} />
        <Route path="/register-email" element={<RegisterEmail />} />
        <Route path="/register-profile/:token" element={<RegisterProfile />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/confirm-email/:token" element={<ConfirmEmail />} />
        <Route
          path="*"
          element={
            <Navigate to="/login" replace state={{ from: location.pathname }} />
          }
        />
      </Routes>
    </Layout>
  );

  const renderContent = () => {
    if (isLoading) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
          }}
        >
          <Spin size="large" />
        </div>
      );
    }

    return (
      <Routes>
        <Route path="/shared/:uuid" element={<SharedArticle />} />
        <Route
          path="/terms-of-service"
          element={
            <TermsOfService
              isAuthenticated={isAuthenticated}
              userProfile={userProfile}
            />
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <PrivacyPolicy
              isAuthenticated={isAuthenticated}
              userProfile={userProfile}
            />
          }
        />
        <Route
          path="/contact-us"
          element={
            <ContactUs
              isAuthenticated={isAuthenticated}
              userProfile={userProfile}
            />
          }
        />
        <Route
          path="/reserve-mtg"
          element={
            <ReserveMtg
              isAuthenticated={isAuthenticated}
              userProfile={userProfile}
            />
          }
        />
        {isAuthenticated ? (
          <Route
            path="/*"
            element={
              <AgreementManager>{renderAuthenticatedRoutes()}</AgreementManager>
            }
          />
        ) : (
          <Route path="/*" element={renderUnauthenticatedRoutes()} />
        )}
      </Routes>
    );
  };

  return (
    <>
      <Helmet>
        <title>magicss</title>
        <meta name="robots" content="noindex" />
        <meta property="og:title" content={`magicss`} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/ogp.png`}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      {renderContent()}
    </>
  );
};

export default App;
