import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { getUserProfile } from '../auth';
import { message } from 'antd';

const AuthenticatedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await getUserProfile();
      } catch (error) {
        setIsAuthenticated(false);
        message.error('ログイン状態が切れました。再度ログインしてください。');
      }
    };

    checkAuth();
  }, []);

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default AuthenticatedRoute;