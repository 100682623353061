import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { message, Spin } from 'antd';
import axios from 'axios';

const ConfirmEmail = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/confirm-email/${token}`);
        console.log('Email confirmation response:', response.data);
        message.success(response.data.message);
        navigate(`/register-profile/${token}`);
      } catch (error) {
        console.error('Error confirming email:', error);
        message.error('メールアドレスの確認に失敗しました。');
        navigate('/register-email');
      } finally {
        setIsLoading(false);
      }
    };

    confirmEmail();
  }, [token, navigate]);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  return null;
};

export default ConfirmEmail;