import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import {
  Layout,
  Typography,
  Button,
  List,
  Spin,
  Progress,
  Breadcrumb,
  Card,
  Alert,
  Space,
  Tag,
  Tooltip,
  Row,
  Col,
  Grid,
  Statistic,
  message,
  Modal,
  Divider,
  Collapse,
  Affix,
  Switch,
  Checkbox,
  Select,
  Skeleton,
  Drawer,
  Descriptions,
  Tabs,
  notification,
} from 'antd';
import {
  RightOutlined,
  ThunderboltOutlined,
  LoadingOutlined,
  ExportOutlined,
  SaveOutlined,
  CheckOutlined,
  CloseOutlined,
  FileWordOutlined,
  LinkOutlined,
  CopyOutlined,
  IssuesCloseOutlined,
  QuestionCircleOutlined,
  UndoOutlined,
  InfoCircleOutlined,
  FileSearchOutlined,
  SortAscendingOutlined,
  CheckCircleOutlined,
  FilterOutlined,
  CheckSquareOutlined,
  SafetyOutlined,
} from '@ant-design/icons';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { PanelGroup, Panel, PanelResizeHandle } from 'react-resizable-panels';
import Editor from './Editor';
import TableOfContents from './TableOfContents';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { getUserProfile } from '../../auth';
import './FactCheckDetail.css';
import moment from 'moment-timezone';
import ReactDOM from 'react-dom';
import { calculateCharacterCount } from '../../utils/calculateCharacterCount';
import { formatDateTime, formatRelativeTime } from '../../utils/dateUtils';

// momentのデフォルトタイムゾーンを日本時間に設定
moment.tz.setDefault('Asia/Tokyo');

const { Title, Text, Paragraph } = Typography;
const { useBreakpoint } = Grid;

// タイプライター効果コンポーネント
const TypewriterText = ({
  text,
  speed = 40,
  onComplete,
  dangerouslySetInnerHTML = false,
}) => {
  const [displayText, setDisplayText] = useState('');
  const [isComplete, setIsComplete] = useState(false);
  const timerRef = useRef(null);
  const hasCalledOnCompleteRef = useRef(false);
  const startTimeRef = useRef(Date.now());
  const textLengthRef = useRef(0);
  const textRef = useRef(text); // テキスト参照を追加

  // HTMLからテキストを抽出
  const getPlainTextFromHTML = useCallback(htmlText => {
    try {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = htmlText;
      return tempDiv.textContent || tempDiv.innerText || '';
    } catch (e) {
      console.error('[ERROR-TYPEWRITER] HTMLテキスト処理エラー:', e);
      return '';
    }
  }, []);

  // タイプライター処理のリセット
  const resetTypewriter = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    hasCalledOnCompleteRef.current = false;
    setDisplayText('');
    setIsComplete(false);
    startTimeRef.current = Date.now();
  }, []);

  // 完了コールバックの安全な呼び出し
  const safeCallOnComplete = useCallback(() => {
    if (!hasCalledOnCompleteRef.current && onComplete) {
      hasCalledOnCompleteRef.current = true;
      console.log(
        `[DEBUG-TYPEWRITER] 完了コールバック呼び出し (表示時間: ${Date.now() - startTimeRef.current}ms, 文字数: ${textLengthRef.current})`,
      );
      onComplete();
    }
  }, [onComplete]);

  // テキストが変更されたら更新
  useEffect(() => {
    textRef.current = text || '';
  }, [text]);

  // タイプライターアニメーション実行
  useEffect(() => {
    // 入力テキストが変更されたらリセット
    resetTypewriter();

    // 処理するテキストを取得
    const plainText = dangerouslySetInnerHTML
      ? getPlainTextFromHTML(textRef.current)
      : textRef.current || '';

    textLengthRef.current = plainText.length;

    // テキストがない場合は即座に完了
    if (!plainText || plainText.length === 0) {
      console.log('[DEBUG-TYPEWRITER] 空テキスト - 即時完了');
      setIsComplete(true);
      safeCallOnComplete();
      return;
    }

    // 処理開始ログ
    console.log(
      `[DEBUG-TYPEWRITER] タイプライター開始: "${plainText.substring(0, 20)}..." (${plainText.length}文字)`,
    );

    // 短いテキストは高速表示、長いテキストはさらに高速化
    let actualSpeed = speed;
    if (plainText.length > 200) {
      actualSpeed = Math.max(5, speed / 4); // 非常に長いテキスト
    } else if (plainText.length > 100) {
      actualSpeed = Math.max(10, speed / 2); // 長いテキスト
    }

    // インデックスとタイマー
    let index = 0;

    // タイプライター処理関数
    const typeNextChar = () => {
      if (index < plainText.length) {
        setDisplayText(plainText.substring(0, index + 1));
        index++;

        // 次の文字表示をスケジュール
        timerRef.current = setTimeout(typeNextChar, actualSpeed);
      } else {
        // タイプライター完了
        setIsComplete(true);
        timerRef.current = null;

        // 即座に完了コールバックを呼び出す（安全なディレイを保持）
        setTimeout(() => {
          console.log(
            `[DEBUG-TYPEWRITER] ${textLengthRef.current}文字のタイプライター完了、コールバック呼び出し`,
          );
          safeCallOnComplete();
        }, 50);
      }
    };

    // 処理開始
    timerRef.current = setTimeout(typeNextChar, 50);

    // クリーンアップ関数
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      // unmount時に完了していなければコールバック呼び出し
      if (!isComplete) {
        safeCallOnComplete();
      }
    };
  }, [
    textRef.current,
    speed,
    dangerouslySetInnerHTML,
    getPlainTextFromHTML,
    safeCallOnComplete,
    resetTypewriter,
    isComplete,
  ]);

  // 表示用のHTMLまたはテキスト
  return dangerouslySetInnerHTML ? (
    <div dangerouslySetInnerHTML={{ __html: displayText }} />
  ) : (
    <>{displayText}</>
  );
};

// アニメーション付き数値表示コンポーネント
const AnimatedNumber = ({ value, duration = 1000, formatter = val => val }) => {
  const [displayValue, setDisplayValue] = useState(0);
  const startTimeRef = useRef(null);
  const startValueRef = useRef(0);
  const frameRef = useRef(null);

  useEffect(() => {
    startValueRef.current = displayValue;
    startTimeRef.current = performance.now();

    const animate = timestamp => {
      const elapsed = timestamp - startTimeRef.current;
      if (elapsed < duration) {
        const nextValue =
          startValueRef.current +
          ((value - startValueRef.current) * elapsed) / duration;
        setDisplayValue(nextValue);
        frameRef.current = requestAnimationFrame(animate);
      } else {
        setDisplayValue(value);
      }
    };

    frameRef.current = requestAnimationFrame(animate);

    return () => {
      if (frameRef.current) {
        cancelAnimationFrame(frameRef.current);
      }
    };
  }, [value, duration]);

  return <span>{formatter(Math.round(displayValue))}</span>;
};

// スケルトンローディングコンポーネント
const FragmentSkeleton = ({ count = 1 }) => {
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <div key={index} className="fragment-card skeleton">
          <div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
                position: 'relative',
              }}
            >
              {/* 円グラフのスケルトン */}
              <div
                style={{
                  width: '24px',
                  height: '24px',
                  borderRadius: '50%',
                  background: '#f0f0f0',
                  flexShrink: 0,
                }}
              />
              {/* テキストのスケルトン */}
              <div style={{ flex: 1 }}>
                <Skeleton
                  active
                  paragraph={{
                    rows: 1,
                    width: '100%',
                  }}
                  title={false}
                  style={{
                    height: '16px', // 実際のテキストの高さに合わせる
                    marginBottom: 0,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

// 正確性に基づく色分けロジック
const getAccuracyColor = accuracy => {
  if (accuracy >= 90) return '#0072FF'; // 濃い青
  if (accuracy >= 80) return '#2E8CFF'; // 青
  if (accuracy >= 70) return '#52C41A'; // 濃い緑
  if (accuracy >= 60) return '#73D13D'; // 緑
  if (accuracy >= 50) return '#FFA940'; // 黄色
  if (accuracy >= 40) return '#FA8C16'; // オレンジ
  if (accuracy >= 30) return '#FA541C'; // 薄い赤
  return '#F5222D'; // 赤
};

// 正確性に基づくテキスト色のロジック
const getAccuracyTextColor = accuracy => {
  if (accuracy >= 90) return '#FFFFFF'; // 白
  if (accuracy >= 80) return '#FFFFFF'; // 白
  if (accuracy >= 70) return '#FFFFFF'; // 白
  if (accuracy >= 60) return '#FFFFFF'; // 白
  if (accuracy >= 50) return '#FFFFFF'; // 白
  if (accuracy >= 40) return '#FFFFFF'; // 白
  if (accuracy >= 30) return '#FFFFFF'; // 白
  return '#FFFFFF'; // 白
};

// リンクを青色に変更する関数
const formatReasonWithLinks = text => {
  if (!text) return '';

  // まずURLを検出して青色のリンクに変換
  let formattedText = text.replace(
    /(https?:\/\/[^\s]+)/g,
    '<a href="$1" target="_blank" rel="noopener noreferrer" style="color: #1890ff; text-decoration: underline;">$1</a>',
  );

  // 次に[n]形式の参照を青色のスパンに変換
  // クリックしたら直接URLを開くように修正
  formattedText = formattedText.replace(
    /\[(\d+)\]/g,
    '<span style="color: #1890ff; cursor: pointer; font-weight: 500;" class="reference-link-number" data-ref-number="$1" onclick="window.openReferenceUrl($1)">[<span>$1</span>]</span>',
  );

  return formattedText;
};

const FactCheckDetail = () => {
  const { task_id } = useParams();
  const navigate = useNavigate();
  const screens = useBreakpoint();

  /** 状態管理 */
  const [task, setTask] = useState(null);
  const [editorContent, setEditorContent] = useState(null);
  const [fragments, setFragments] = useState([]);
  const [displayedFragments, setDisplayedFragments] = useState([]);
  const [pendingFragments, setPendingFragments] = useState([]); // 保留中のフラグメント
  const [fragmentQueue, setFragmentQueue] = useState([]);
  const [isTypingInProgress, setIsTypingInProgress] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState({
    total: 0,
    verified: 0,
    accuracy: 0,
    sourceCount: 0,
  });
  const [userProfile, setUserProfile] = useState(null);
  const [editorReady, setEditorReady] = useState(false);
  const [expandedFragments, setExpandedFragments] = useState(new Set());
  const [minScrollPosition, setMinScrollPosition] = useState(88);
  const [showControls, setShowControls] = useState(true);
  const [updatingFragmentId, setUpdatingFragmentId] = useState(null);

  const [drawerState, setDrawerState] = useState({
    visible: false,
    fragment: null,
  });
  const [deepCheckLoading, setDeepCheckLoading] = useState(false);
  const [showTypewriter, setShowTypewriter] = useState({});
  const [autoScroll, setAutoScroll] = useState(false); // デフォルトをfalseに変更
  const ticking = useRef(false);

  const [affixVisible, setAffixVisible] = useState(false);
  const [updatingFragments, setUpdatingFragments] = useState([]);

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedFragment, setSelectedFragment] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // ドロワーの状態を追加

  // スクロール位置を監視して、フッター付近でカードの最大高さを調整する
  const [cardMaxHeight, setCardMaxHeight] = useState('calc(100vh - 100px)');
  const lastMaxHeightRef = useRef('calc(100vh - 100px)'); // 前回のmaxHeightを保持するref
  // スケルトンカード表示のオーバーライドフラグ
  const [forceHideSkeleton, setForceHideSkeleton] = useState(false);

  useEffect(() => {
    // タスクが完了状態の場合はスケルトンカードを非表示に
    if (task?.status === 'completed' || task?.status === 'finished') {
      setForceHideSkeleton(true);
    } else {
      setForceHideSkeleton(false);
    }
  }, [task?.status]);

  useEffect(() => {
    // フッター要素の参照を保持
    let footerElement = null;

    const handleScroll = () => {
      // ワンカラム表示時（スマホ等）はAffixを適用しない
      if (!screens.md) {
        setAffixVisible(false);
        return;
      }

      // 常にAffixを表示
      setAffixVisible(true);

      // スクロールイベントの発火頻度を抑制する
      if (!ticking.current) {
        // 次の描画フレームで実行するようスケジュール
        window.requestAnimationFrame(() => {
          // フッター要素がない場合は取得（初回のみ）
          if (!footerElement) {
            footerElement = document.querySelector('footer');
          }

          const windowHeight = window.innerHeight;
          const scrollPosition = window.scrollY + windowHeight;

          // フッター位置を計算（キャッシュした要素を使用）
          const footerPosition = footerElement
            ? footerElement.offsetTop
            : document.body.scrollHeight - 100;

          const footerHeight = 149; // フッターの高さ

          // スクロール位置がフッター付近まで来たら
          if (scrollPosition > footerPosition - footerHeight) {
            // フッターがどれだけ表示されているかを計算（整数値に丸める）
            const visibleFooterHeight = Math.min(
              footerHeight,
              Math.ceil(scrollPosition - (footerPosition - footerHeight)),
            );

            // カードの最大高さを調整（計算を最適化）
            const newMaxHeight = `calc(100vh - 100px - ${visibleFooterHeight}px)`;

            // 前回と異なる場合のみ更新（無駄な再レンダリングを防止）
            if (lastMaxHeightRef.current !== newMaxHeight) {
              lastMaxHeightRef.current = newMaxHeight;
              setCardMaxHeight(newMaxHeight);
            }
          } else if (lastMaxHeightRef.current !== 'calc(100vh - 100px)') {
            // 通常の最大高さ（変更がある場合のみ更新）
            lastMaxHeightRef.current = 'calc(100vh - 100px)';
            setCardMaxHeight('calc(100vh - 100px)');
          }

          ticking.current = false;
        });

        ticking.current = true;
      }
    };

    // パッシブイベントリスナーとして登録（スクロールパフォーマンス向上）
    window.addEventListener('scroll', handleScroll, { passive: true });

    // 初期表示時にも実行
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, [screens.md]); // スクリーンサイズ変更時にも再評価

  // 進捗バーとスケルトンの表示条件を判定するヘルパー関数
  const isTaskInProgress = useCallback(task => {
    if (!task) return false;
    return task.status === 'queued' || task.status === 'processing';
  }, []);

  // 事実性スコアに基づいて色を返す関数
  const getFactualityColor = useCallback(factuality => {
    if (factuality === null || factuality === undefined) return '#d9d9d9';
    const score = Math.round(factuality * 100);
    if (score >= 90) return '#52c41a'; // 緑
    if (score >= 70) return '#1890ff'; // 青
    if (score >= 50) return '#faad14'; // 黄色
    if (score >= 30) return '#fa8c16'; // オレンジ
    return '#f5222d'; // 赤
  }, []);

  // フラグメントカードクリック時の処理
  const handleFragmentClick = useCallback(fragment => {
    setSelectedFragment(fragment);
    setIsDrawerOpen(true); // ドロワーを開く
    setDrawerVisible(true);
  }, []);

  const [animatedStats, setAnimatedStats] = useState({
    total: 0,
    verified: 0,
    accuracy: 0,
    avgReliability: 0,
  });

  // フィルターと並び替えの状態管理
  const [showIncomplete, setShowIncomplete] = useState(false);
  const [sortOrder, setSortOrder] = useState('default');

  const editorRef = useRef(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [editorInstance, setEditorInstance] = useState(null);
  const animatedCardIdsRef = useRef(new Set());
  const [initialFragments, setInitialFragments] = useState([]);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [remainingFragments, setRemainingFragments] = useState([]);
  const isTypingInProgressRef = useRef(false); // 即時に参照できるref
  const messageQueueRef = useRef([]); // WebSocketメッセージキュー
  const safeDispatchWebSocketMessageRef = useRef(null);
  const displayedFragmentsRef = useRef([]); // 表示されているフラグメントの参照

  // WebSocketメッセージのカスタムイベント名を定義
  const WS_MESSAGE_EVENT = 'factCheckWebSocketMessage';

  // アニメーション制御用の参照
  const animationSourceRef = useRef('initial'); // 'initial', 'websocket', 'none'

  // 統計情報計算
  const calculateStats = useCallback(
    fragmentList => {
      if (!fragmentList || fragmentList.length === 0) {
        return {
          total: 0,
          verified: 0,
          accuracy: 0,
          sourceCount: 0,
          completed: 0, // 手動で完了済みにしたフラグメントの数
        };
      }

      // 検証完了しているフラグメントのみをフィルタリング
      // タスクが進行中の場合は、タスクの進捗率とフラグメントの順番も考慮する
      const totalFragments = fragmentList.length;
      const verifiedFragments = fragmentList.filter(frag => {
        const isFactualitySet =
          frag.factuality !== undefined && frag.factuality !== null;

        // タスクが進行中でない場合、またはタスクの進捗に応じて検証済みとみなす
        return (
          isFactualitySet &&
          (!isTaskInProgress(task) ||
            (task &&
              task.progress &&
              totalFragments > 0 &&
              frag.order_index <
                Math.floor((task.progress * totalFragments) / 100)))
        );
      });

      // 手動で完了済みにしたフラグメントの数をカウント
      const completedFragments = fragmentList.filter(
        frag => frag.result === true,
      );
      const completedCount = completedFragments.length;

      // 検証完了フラグメントの数と総フラグメント数
      let total = fragmentList.length;
      let verifiedCount = verifiedFragments.length;

      // 検証完了フラグメントのみで平均factualityを計算
      let sumFactuality = verifiedFragments.reduce(
        (sum, frag) => sum + (frag.factuality || 0),
        0,
      );

      // ソース数のカウント（検証完了フラグメントのみ）
      let totalSourceCount = verifiedFragments.reduce((count, frag) => {
        // reference_infoのソースをカウント
        const refCount = Array.isArray(frag.reference_info)
          ? frag.reference_info.length
          : 0;

        // deep_check_sourcesのソースをカウント
        const deepCheckCount = Array.isArray(frag.deep_check_sources)
          ? frag.deep_check_sources.length
          : 0;

        return count + refCount + deepCheckCount;
      }, 0);

      // factualityの平均を計算（検証完了フラグメントがある場合のみ）
      const avgFactuality =
        verifiedCount > 0 ? sumFactuality / verifiedCount : 0;
      const accuracy = Math.round(avgFactuality * 100);

      console.log('[DEBUG-STATS] 計算結果:', {
        total,
        verified: verifiedCount,
        accuracy,
        sourceCount: totalSourceCount,
      });

      return {
        total,
        verified: verifiedCount,
        accuracy,
        sourceCount: totalSourceCount,
        completed: completedCount, // 手動で完了済みにしたフラグメントの数を追加
      };
    },
    [task],
  );

  // 完了スイッチのローディング状態を管理
  const [switchLoading, setSwitchLoading] = useState(false);

  // タスクの状態が変わったときに、スケルトン表示を更新
  useEffect(() => {
    if (task && task.status === 'completed') {
      // タスクが完了状態の場合、スケルトン表示を停止
      setIsInitialLoading(false);

      // 表示されているフラグメントがない場合は、fragmentsから全て表示
      if (displayedFragments.length === 0 && fragments.length > 0) {
        setDisplayedFragments(
          fragments.map(f => ({
            ...f,
            isTyping: false,
            isComplete: true,
            isInteractive: true,
          })),
        );
      }

      // 残りのフラグメントをクリア
      setRemainingFragments([]);
    }
  }, [task, fragments, displayedFragments.length]);

  // フラグメントの変更を監視して統計情報を更新
  useEffect(() => {
    if (!fragments.length) {
      setStats({
        total: 0,
        verified: 0,
        accuracy: 0,
        sourceCount: 0,
      });
      setAnimatedStats({
        total: 0,
        verified: 0,
        accuracy: 0,
        sourceCount: 0,
      });
      return;
    }

    const newStats = calculateStats(fragments);
    console.log('[DEBUG-STATS] 統計更新:', {
      source: animationSourceRef.current,
      stats: newStats,
    });

    // 常にstatsは更新
    setStats(newStats);

    // アニメーション用の統計情報の更新（WebSocketメッセージ受信時のみ）
    if (
      animationSourceRef.current === 'initial' ||
      animationSourceRef.current === 'websocket' ||
      animationSourceRef.current === 'fragment_complete'
    ) {
      setAnimatedStats(newStats);
    }

    // ソースをリセット
    animationSourceRef.current = 'none';
  }, [fragments]);

  // フラグメントが検証済みかどうかを判定するユーティリティ関数
  const isFragmentVerified = (fragment, task, totalFragments) => {
    // nullとundefinedのチェック
    if (fragment.factuality === null || fragment.factuality === undefined) {
      return false;
    }

    // reasonやreference_infoが存在するかチェック（検証の痕跡）
    const hasReason = fragment.reason && fragment.reason.trim().length > 0;
    const hasReferences =
      fragment.reference_info &&
      Array.isArray(fragment.reference_info) &&
      fragment.reference_info.length > 0;

    // タスクが進行中の場合は追加チェック
    if (isTaskInProgress(task)) {
      // 進捗率に応じたインデックスチェック（オプション）
      if (totalFragments > 0 && task?.progress) {
        const verifiedIndex = Math.floor(
          (task.progress * totalFragments) / 100,
        );
        if (fragment.order_index >= verifiedIndex) {
          return false; // 進捗率を超えたインデックスは未検証
        }
      }
    }

    return true;
  };

  // ドロワーを開く処理
  const handleDrawerOpen = useCallback(fragment => {
    if (!fragment) return;
    setDrawerState(prev => ({
      visible: true,
      fragment: { ...fragment },
    }));

    // フラグメントとブロックのハイライトを設定
    const fragmentCard = document.querySelector(
      `.fragment-card[data-fragment-id="${fragment.id}"]`,
    );
    if (fragmentCard) {
      fragmentCard.classList.add('selected');
    }

    if (fragment.block_position?.ids?.length) {
      fragment.block_position.ids.forEach(id => {
        const block = editorRef.current?.blocks.getById(id);
        if (block) {
          const holder = block.holder;
          holder.classList.add('block-highlight');

          const sentences = holder.querySelectorAll('[data-sentence-id]');
          sentences.forEach(sentence => {
            sentence.classList.add('sentence-highlight');
          });
        }
      });
    }
  }, []);

  const handleFragmentSelect = useCallback(
    async fragmentIdOrObject => {
      // fragmentIdOrObjectがオブジェクトかどうかをチェック
      let fragment;
      if (typeof fragmentIdOrObject === 'object') {
        // すでにフラグメントオブジェクトが渡された場合
        fragment = fragmentIdOrObject;
      } else {
        // fragmentIdからfragmentオブジェクトを取得
        fragment = fragments.find(f => f.id === fragmentIdOrObject);
        if (!fragment) {
          console.error(`Fragment with ID ${fragmentIdOrObject} not found`);
          return;
        }
      }

      handleDrawerOpen(fragment);

      document
        .querySelectorAll('.sentence-highlight, .block-highlight')
        .forEach(el => {
          el.classList.remove('sentence-highlight', 'block-highlight');
        });

      if (fragment.block_position?.ids?.length) {
        fragment.block_position.ids.forEach(id => {
          const block = editorRef.current?.blocks.getById(id);
          if (block) {
            const holder = block.holder;
            holder.classList.add('block-highlight');

            const sentences = holder.querySelectorAll('[data-sentence-id]');
            sentences.forEach(sentence => {
              sentence.classList.add('sentence-highlight');
            });

            if (id === fragment.block_position.ids[0]) {
              const editorContainer =
                document.querySelector('.editor-container');
              if (editorContainer && holder) {
                const holderTop = holder.offsetTop;
                editorContainer.scrollTo({
                  top:
                    holderTop -
                    editorContainer.clientHeight / 2 +
                    holder.clientHeight / 2,
                  behavior: 'smooth',
                });
              }
            }
          }
        });
      }
    },
    [handleDrawerOpen, fragments],
  );

  // ホバー処理を追加
  const handleHeaderHover = useCallback(
    (isHovering, blockIds, shouldScroll = false) => {
      // 処理中はホバー効果を無効化
      if (isTaskInProgress(task)) return;

      // 既存のハイライトをクリア
      document.querySelectorAll('.block-hover-highlight').forEach(el => {
        el.classList.remove('block-hover-highlight');
      });

      if (isHovering && blockIds?.length) {
        const firstBlockId = blockIds[0];
        const block = editorRef.current?.blocks.getById(firstBlockId);

        if (block) {
          const holder = block.holder;
          holder.classList.add('block-hover-highlight');

          // 自動スクロールが有効で、shouldScrollがtrueの場合のみスクロール実行
          if (autoScroll && shouldScroll) {
            // エディタのスクロール位置を計算
            const rect = holder.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const centerPosition =
              rect.top +
              window.pageYOffset -
              windowHeight / 2 +
              rect.height / 2;
            const targetPosition = Math.max(minScrollPosition, centerPosition);

            // スムーズスクロール
            window.scrollTo({
              top: targetPosition,
              behavior: 'smooth',
            });
          }
        }
      }
    },
    [task, editorRef, minScrollPosition, autoScroll],
  );

  const updateFragmentNumberStyle = useCallback(
    fragment => {
      if (!editorRef.current || !fragment.block_position?.ids) return;

      const isVerified =
        fragment.factuality !== undefined && fragment.factuality !== null;
      const reliability = isVerified ? fragment.factuality * 100 : 0;
      const reliabilityColor = getAccuracyColor(reliability);

      fragment.block_position.ids.forEach(blockId => {
        try {
          const block = editorRef.current?.blocks.getById(blockId);
          if (!block) return;

          const holder = block.holder;
          if (!holder) return;

          const blockNumbers = holder.querySelector('.block-numbers');
          if (!blockNumbers) return;

          // フラグメントに対応する番号バッジを取得
          const fragmentNumber = blockNumbers.children[fragment.order_index];
          if (!fragmentNumber) return;

          // すでに Sonar 検証済みかどうかを確認
          if (fragment.deep_check_status === 'completed') {
            fragmentNumber.classList.add('deep-checked');
          } else {
            fragmentNumber.classList.remove('deep-checked');
          }

          // 検証済みの場合、アクティブ化する
          if (isVerified) {
            // まず unverified クラスを削除
            fragmentNumber.classList.remove('unverified');

            // スタイルを適用
            if (fragment.deep_check_status === 'completed') {
              fragmentNumber.style.background =
                'linear-gradient(45deg, #722ed1, #eb2f96)';
            } else {
              fragmentNumber.style.backgroundColor = reliabilityColor;
            }

            fragmentNumber.style.color = 'white';
            fragmentNumber.style.cursor = 'pointer';

            // このフラグメントが完了済みかどうかに基づく追加スタイル
            if (fragment.result) {
              fragmentNumber.style.opacity = '0.3';
              // チェックマークアイコンが既にある場合は追加しない
              if (!fragmentNumber.querySelector('.block-number-check')) {
                const checkIcon = document.createElement('div');
                checkIcon.className = 'block-number-check';
                checkIcon.innerHTML = `<svg viewBox="64 64 896 896" focusable="false" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true" stroke="currentColor" stroke-width="50"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg>`;
                fragmentNumber.appendChild(checkIcon);
              }
            } else {
              fragmentNumber.style.opacity = '1';
              // チェックマークアイコンがあれば削除
              const checkIcon = fragmentNumber.querySelector(
                '.block-number-check',
              );
              if (checkIcon) {
                checkIcon.remove();
              }
            }

            // イベントリスナーの関数を定義
            const fragmentClickHandler = e => {
              e.stopPropagation();
              handleFragmentSelect(fragment);
            };
            const fragmentMouseEnterHandler = () =>
              handleHeaderHover(true, fragment.block_position?.ids, false);
            const fragmentMouseLeaveHandler = () => handleHeaderHover(false);

            // 既存のイベントリスナーを削除
            const oldClone = fragmentNumber.cloneNode(true);
            const newElement = oldClone.cloneNode(true);
            fragmentNumber.parentNode.replaceChild(newElement, fragmentNumber);

            // 新しいイベントリスナーを設定
            newElement.addEventListener('click', fragmentClickHandler);
            newElement.addEventListener(
              'mouseenter',
              fragmentMouseEnterHandler,
            );
            newElement.addEventListener(
              'mouseleave',
              fragmentMouseLeaveHandler,
            );

            // データ属性を追加してフラグメントIDを関連付け
            newElement.setAttribute('data-fragment-id', fragment.id);
          }
        } catch (error) {
          console.error(`ブロック番号の更新エラー: ${error.message}`);
        }
      });
    },
    [editorRef, handleFragmentSelect, handleHeaderHover],
  );

  // メッセージキューの処理関数
  const processMessageQueue = useCallback(() => {
    // キューが空なら処理終了
    if (messageQueueRef.current.length === 0) {
      console.log('[キュー] 処理完了 - キューが空です');
      return;
    }

    // タイプ中なら処理待機
    if (isTypingInProgressRef.current) {
      console.log(
        `[キュー] タイプ中のため処理待機中: キュー長=${messageQueueRef.current.length}`,
      );
      return;
    }

    // 次のメッセージを処理
    console.log(
      `[キュー] 次のメッセージ処理開始: キュー長=${messageQueueRef.current.length}`,
    );
    const nextMessage = messageQueueRef.current.shift();

    if (nextMessage && safeDispatchWebSocketMessageRef.current) {
      // 新フラグメントの場合はフラグメント情報も表示
      if (nextMessage.type === 'new_fragment') {
        const fragmentId = `${nextMessage.fragment.task_id}-${nextMessage.fragment.order_index}`;
        console.log(`[キュー] フラグメント処理: ID=${fragmentId}`);
      }

      // メッセージディスパッチ
      safeDispatchWebSocketMessageRef.current(nextMessage);
    } else {
      console.log('[キュー] 処理対象メッセージなし');
    }
  }, []);

  /** タスク開始メッセージ */
  const handleStartMessage = useCallback(data => {
    console.log('[DEBUG-WS] タスク開始:', data);

    // 既存のフラグメントをクリア
    setFragments([]);
    setDisplayedFragments([]);
    setFragmentQueue([]);

    // タスク状態の更新
    setTask(prev => ({
      ...prev,
      status: 'processing',
      progress: 0,
    }));

    setShowControls(false);
    message.info('ファクトチェックを開始しました');
  }, []);

  const handleFragmentsCreate = useCallback(data => {
    if (!Array.isArray(data.fragments)) {
      console.error('Invalid fragments data:', data);
      return;
    }

    // 初期フラグメント情報を保存するが、表示はしない
    const initialFragments = data.fragments.map(fragment => ({
      ...fragment,
      id: fragment.task_id + '-' + fragment.order_index,
      factuality: undefined,
      result: undefined,
      reason: null,
      reference_info: [],
    }));

    // fragments用の配列は作成する
    setFragments(initialFragments);
    setInitialFragments(initialFragments);

    // 表示用の配列は空のままにする
    setDisplayedFragments([]);

    // キューも空にする
    setFragmentQueue([]);

    // スケルトンローディングの状態を継続
    setIsInitialLoading(true);
    setIsTypingInProgress(false);

    console.log('[DEBUG-WS] フラグメント一覧生成完了: 統計情報のみ更新');

    // タスクステータスの更新
    setTask(prev => ({
      ...prev,
      status: 'processing',
    }));
  }, []);

  // フラグメント更新の内部処理
  const handleFragmentUpdateInternal = useCallback(
    (fragmentId, msg) => {
      // 新しいフラグメントの場合
      if (fragmentId === undefined && msg.fragment) {
        const isVerified = msg.fragment.factuality !== undefined;
        const newFragmentId = `${msg.fragment.task_id}-${msg.fragment.order_index}`;
        console.log(
          `[DEBUG-FRAGMENT] 新フラグメント処理: ID=${newFragmentId}, verified=${isVerified}, キュー長=${messageQueueRef.current.length}`,
        );

        // フラグメントの詳細をデバッグ出力
        console.log(`[DEBUG-FRAGMENT] フラグメント詳細:`, {
          fragment_text: msg.fragment.fragment_text,
          factuality: msg.fragment.factuality,
          order_index: msg.fragment.order_index,
        });

        // タスク進捗の更新（progressフィールドがある場合）
        if (msg.progress !== undefined) {
          console.log(`[DEBUG-FRAGMENT] タスク進捗更新: ${msg.progress}%`);
          setTask(prev => ({
            ...prev,
            progress: Math.min(100, Math.max(0, msg.progress)),
            status: prev.status === 'queued' ? 'processing' : prev.status,
          }));
        }

        // すでに表示されているか確認
        const isAlreadyDisplayed = displayedFragmentsRef.current.some(
          f => f.id === newFragmentId,
        );
        if (isAlreadyDisplayed) {
          console.log(
            `[DEBUG-FRAGMENT] スキップ: フラグメント ${newFragmentId} はすでに表示されています`,
          );
          processMessageQueue(); // 次のメッセージ処理を続行
          return;
        }

        // fragments配列も更新
        setFragments(prev => {
          // 既に存在するフラグメントを探す
          const existingIndex = prev.findIndex(
            f =>
              f.task_id === msg.fragment.task_id &&
              f.order_index === msg.fragment.order_index,
          );

          if (existingIndex >= 0) {
            // 既存のフラグメントを更新
            const updatedFragments = [...prev];
            updatedFragments[existingIndex] = {
              ...updatedFragments[existingIndex],
              ...msg.fragment,
              id: newFragmentId,
            };
            return updatedFragments;
          } else {
            // 新しいフラグメントを追加
            return [...prev, { ...msg.fragment, id: newFragmentId }];
          }
        });

        // 検証済みの処理
        if (isVerified) {
          // デバッグログ
          console.log(
            `[DEBUG-FRAGMENT] 検証済みフラグメント表示開始: ID=${newFragmentId}, text="${msg.fragment.fragment_text?.substring(0, 30) || 'テキストなし'}..."`,
          );

          // テキストが空や無効な場合のチェック
          if (
            !msg.fragment.fragment_text ||
            msg.fragment.fragment_text.trim() === ''
          ) {
            console.log(
              `[DEBUG-FRAGMENT] ⚠️ フラグメントテキストが空または無効 - デフォルトテキストを使用します`,
            );

            // フラグメントテキストがない場合はデフォルトテキストを設定
            const defaultText = `検証項目 #${msg.fragment.order_index + 1}`;

            // タイピング状態を更新
            isTypingInProgressRef.current = true;
            setIsTypingInProgress(true);

            // フラグメントを表示リストに追加（デフォルトテキスト付き）
            setDisplayedFragments(prev => {
              // 同じIDが既に存在する場合は追加しない
              if (prev.some(f => f.id === newFragmentId)) {
                console.log(
                  `[DEBUG-FRAGMENT] 重複フラグメント: ${newFragmentId} - 追加をスキップ`,
                );
                return prev;
              }

              // 新しいフラグメントを追加
              const updatedFragments = [
                ...prev,
                {
                  ...msg.fragment,
                  fragment_text: defaultText, // デフォルトテキストを設定
                  id: newFragmentId,
                  isTyping: true,
                  isComplete: false,
                },
              ];
              displayedFragmentsRef.current = updatedFragments;
              return updatedFragments;
            });

            return;
          }

          // タイピング状態を更新
          isTypingInProgressRef.current = true;
          setIsTypingInProgress(true);

          // フラグメントを表示リストに追加
          setDisplayedFragments(prev => {
            // 同じIDが既に存在する場合は追加しない
            if (prev.some(f => f.id === newFragmentId)) {
              console.log(
                `[DEBUG-FRAGMENT] 重複フラグメント: ${newFragmentId} - 追加をスキップ`,
              );
              return prev;
            }

            // 新しいフラグメントを追加
            const updatedFragments = [
              ...prev,
              {
                ...msg.fragment,
                id: newFragmentId,
                isTyping: true,
                isComplete: false,
              },
            ];
            displayedFragmentsRef.current = updatedFragments;
            return updatedFragments;
          });
        } else {
          // 未検証のフラグメントは保留リストに追加
          console.log(
            `[DEBUG-FRAGMENT] 未検証フラグメント: ${newFragmentId} - 保留リストに追加`,
          );
          setPendingFragments(prev => [
            ...prev,
            { ...msg.fragment, id: newFragmentId },
          ]);
          // 次のメッセージ処理を続行
          processMessageQueue();
        }
      } else {
        // 既存フラグメントの更新処理
        console.log(`[DEBUG-FRAGMENT] フラグメント更新: ID=${fragmentId}`);

        // タスク進捗の更新（progressフィールドがある場合）
        if (msg.progress !== undefined) {
          console.log(`[DEBUG-FRAGMENT] タスク進捗更新: ${msg.progress}%`);
          setTask(prev => ({
            ...prev,
            progress: Math.min(100, Math.max(0, msg.progress)),
            status: prev.status === 'queued' ? 'processing' : prev.status,
          }));
        }

        // fragments配列を更新
        setFragments(prev => {
          return prev.map(f =>
            f.id === fragmentId ? { ...f, ...msg.fragment, id: fragmentId } : f,
          );
        });

        // 表示中のフラグメントも更新
        setDisplayedFragments(prev => {
          const existingIndex = prev.findIndex(f => f.id === fragmentId);
          if (existingIndex >= 0) {
            const updatedFragments = [...prev];
            updatedFragments[existingIndex] = {
              ...updatedFragments[existingIndex],
              ...msg.fragment,
              id: fragmentId,
              isUpdating: true,
            };
            displayedFragmentsRef.current = updatedFragments;
            return updatedFragments;
          }
          return prev;
        });

        // アップデート効果を一時的に表示
        setTimeout(() => {
          setDisplayedFragments(prev => {
            return prev.map(f =>
              f.id === fragmentId ? { ...f, isUpdating: false } : f,
            );
          });
        }, 500);

        // 次のメッセージを処理
        processMessageQueue();
      }
    },
    [processMessageQueue],
  );

  /** タスク完了メッセージ */
  const handleCompleteMessage = useCallback(
    data => {
      console.log('[DEBUG-WS] タスク完了:', data);

      // タスク状態の更新
      setTask(prev => ({
        ...prev,
        status: 'completed',
        progress: 100,
        completed_at: data.result_summary?.completion_time || null,
      }));

      // スケルトン表示を確実に停止
      setIsInitialLoading(false);
      setIsLoading(false);
      // スケルトンカードを強制的に非表示
      setForceHideSkeleton(true);

      setShowControls(true);

      // アニメーションのソースを'complete'に設定
      animationSourceRef.current = 'complete';

      // 統計情報を更新
      if (data.stats && Object.keys(data.stats).length > 0) {
        console.log('[DEBUG-WS] バックエンドから受信した統計情報:', data.stats);

        // バックエンドからの統計情報が有効か確認
        const hasValidStats =
          data.stats.verified !== undefined &&
          data.stats.accuracy !== undefined &&
          data.stats.verified > 0; // verified > 0 の条件を追加

        if (hasValidStats) {
          // 統計情報を直接設定
          setStats(data.stats);
          setAnimatedStats(data.stats);
          console.log('[DEBUG-WS] バックエンドの統計情報を使用します');
        } else {
          // 無効な場合はフロントエンドで計算
          console.log(
            '[DEBUG-WS] バックエンドの統計情報が無効です。フロントエンドで計算します',
          );
          const calculatedStats = calculateStats(fragments);
          setStats(calculatedStats);
          setAnimatedStats(calculatedStats);
          console.log(
            '[DEBUG-WS] フロントエンドで計算した統計情報:',
            calculatedStats,
          );
        }
      } else {
        // バックエンドから統計情報が来ない場合、フロントで計算
        console.log(
          '[DEBUG-WS] 統計情報がありません。フロントエンドで計算します',
        );

        const calculatedStats = calculateStats(fragments);
        setStats(calculatedStats);
        setAnimatedStats(calculatedStats);

        console.log(
          '[DEBUG-WS] フロントエンドで計算した統計情報:',
          calculatedStats,
        );
      }

      // 重要な変更：完了時には検証済み(factuality !== undefined)のフラグメントのみを表示
      setDisplayedFragments(prev => {
        // 現在表示されているフラグメントがない場合は、fragmentsから検証済みのみ表示
        if (prev.length === 0 && fragments.length > 0) {
          return fragments
            .filter(f => f.factuality !== undefined && f.factuality !== null)
            .map(f => ({
              ...f,
              isTyping: false,
              isComplete: true,
              isInteractive: true,
            }));
        }

        return prev
          .filter(f => {
            // fragmentsから対応するフラグメントを探す
            const matchingFragment = fragments.find(
              fragment => fragment.id === f.id,
            );
            // factualityが設定されているもののみ表示
            return (
              matchingFragment &&
              matchingFragment.factuality !== undefined &&
              matchingFragment.factuality !== null
            );
          })
          .map(f => ({
            ...f,
            isTyping: false,
            isComplete: true,
            isInteractive: true,
          }));
      });

      // 残りのフラグメントをクリア
      setRemainingFragments([]);

      // フラグメントキューをクリア
      setFragmentQueue([]);

      message.success('ファクトチェックが完了しました', 3);
    },
    [fragments, calculateStats],
  );

  /** エラーメッセージ */
  const handleErrorMessage = useCallback(data => {
    console.log('[DEBUG-WS] エラー発生:', data);
    setTask(prev => ({
      ...prev,
      status: 'failed',
      error_message: data.message,
    }));
    setShowControls(true);

    message.error({
      content: data.message || '検証エラー',
      duration: 5,
    });
    if (data.details?.should_refund) {
      message.info('チケットは自動的に返還されます');
    }
  }, []);

  // WebSocketメッセージディスパッチャーの修正
  const safeDispatchWebSocketMessage = useCallback(
    msg => {
      console.log(`[DEBUG-DISPATCH] メッセージ処理: type=${msg.type}`);

      try {
        // メッセージタイプに応じた処理
        switch (msg.type) {
          case 'fact_check_start':
            handleStartMessage(msg);
            setTimeout(processMessageQueue, 50);
            break;
          case 'create_fragments':
            handleFragmentsCreate(msg);
            setTimeout(processMessageQueue, 50);
            break;
          case 'new_fragment':
            // 新しいフラグメントの処理
            handleFragmentUpdateInternal(undefined, msg);
            break;
          case 'update_fragment': {
            const fragmentId = `${msg.fragment.task_id}-${msg.fragment.order_index}`;
            handleFragmentUpdateInternal(fragmentId, msg);
            break;
          }
          case 'fact_check_complete':
            handleCompleteMessage(msg);
            setTimeout(processMessageQueue, 50);
            break;
          case 'fact_check_error':
            handleErrorMessage(msg);
            setTimeout(processMessageQueue, 50);
            break;
          case 'delete_fragment':
            // フラグメント削除処理
            console.log(
              `[DEBUG-DISPATCH] フラグメント削除: ${msg.fragment_id}`,
            );
            // 現在タイピング中なら完了させる
            if (isTypingInProgressRef.current) {
              isTypingInProgressRef.current = false;
              setIsTypingInProgress(false);
            }
            // 削除処理の実行
            setDisplayedFragments(prev => {
              const filtered = prev.filter(f => f.id !== msg.fragment_id);
              displayedFragmentsRef.current = filtered;
              return filtered;
            });
            // 次のメッセージ処理
            setTimeout(processMessageQueue, 50);
            break;
          default:
            console.log(`[DEBUG-DISPATCH] 未処理メッセージタイプ: ${msg.type}`);
            // 次のメッセージを処理
            setTimeout(processMessageQueue, 50);
            break;
        }
      } catch (error) {
        console.error('[ERROR] メッセージ処理中にエラー:', error);
        // エラーが発生してもキュー処理を継続
        isTypingInProgressRef.current = false;
        setIsTypingInProgress(false);
        setTimeout(processMessageQueue, 100);
      }
    },
    [
      handleFragmentUpdateInternal,
      processMessageQueue,
      handleStartMessage,
      handleFragmentsCreate,
      handleCompleteMessage,
      handleErrorMessage,
    ],
  );

  // 参照を更新
  useEffect(() => {
    safeDispatchWebSocketMessageRef.current = safeDispatchWebSocketMessage;
  }, [safeDispatchWebSocketMessage]);

  // WebSocketメッセージハンドラの改善
  const handleWebSocketMessage = useCallback(
    data => {
      if (!data || data.task_id !== task_id) return;

      // デバッグ情報
      if (data.type === 'new_fragment') {
        const fragmentId = `${data.fragment.task_id}-${data.fragment.order_index}`;
        console.log(
          `[WS受信] 新フラグメント: ID=${fragmentId}, タイプ中=${isTypingInProgressRef.current}, 長さ=${data.fragment.fragment_text?.length || 0}文字`,
        );

        // すでに表示されているか確認
        const isAlreadyDisplayed = displayedFragmentsRef.current.some(
          f => f.id === fragmentId,
        );
        if (isAlreadyDisplayed) {
          console.log(
            `[WS受信] フラグメント${fragmentId}は既に表示済み - スキップ`,
          );
          return; // 重複は無視
        }
      } else {
        console.log(`[WS受信] メッセージ: type=${data.type}`);
      }

      // メッセージキューに追加
      messageQueueRef.current.push(data);

      // キュー処理を開始/続行
      if (!isTypingInProgressRef.current) {
        console.log(
          `[WS受信] タイプ中でないため即時処理: キュー長=${messageQueueRef.current.length}`,
        );
        // タイプ中でなければすぐに処理
        const nextMessage = messageQueueRef.current.shift();
        if (nextMessage && safeDispatchWebSocketMessageRef.current) {
          safeDispatchWebSocketMessageRef.current(nextMessage);
        }
      } else {
        // タイプ中ならキューに追加するだけ
        console.log(
          `[WS受信] タイプ中のためキューに追加: キュー長=${messageQueueRef.current.length}`,
        );
      }
    },
    [task_id],
  );

  // タイプライター完了ハンドラを修正
  const onTypingComplete = useCallback(
    fragmentId => {
      console.log(`[DEBUG-TYPING] タイピング完了: ID=${fragmentId}`);

      // 不正なフラグメントIDのチェック
      if (!fragmentId) {
        console.error('[ERROR] 不正なフラグメントID:', fragmentId);
        isTypingInProgressRef.current = false;
        setIsTypingInProgress(false);

        // 安全ディレイの後にキュー処理を再開
        setTimeout(() => {
          console.log(
            '[DEBUG-TYPING] タイピング状態リセット後にキュー処理再開',
          );
          processMessageQueue();
        }, 50);
        return;
      }

      // フラグメント状態の更新
      setDisplayedFragments(prev => {
        // フラグメントを探す
        const targetIndex = prev.findIndex(f => f.id === fragmentId);
        if (targetIndex === -1) {
          console.error(
            `[ERROR] 完了フラグメントが見つかりません: ${fragmentId}`,
          );
          return prev; // 変更なし
        }

        // 該当フラグメントを更新
        const updatedFragments = [...prev];
        updatedFragments[targetIndex] = {
          ...updatedFragments[targetIndex],
          isTyping: false,
          isComplete: true,
          isInteractive: true,
        };
        displayedFragmentsRef.current = updatedFragments;
        return updatedFragments;
      });

      // タイピング状態の解除
      isTypingInProgressRef.current = false;
      setIsTypingInProgress(false);

      // 少し遅延してキュー処理を再開（レンダリング完了を待つ）
      setTimeout(() => {
        console.log(
          '[DEBUG-TYPING] キュー処理再開: キュー長=' +
            messageQueueRef.current.length,
        );
        processMessageQueue();
      }, 50);
    },
    [processMessageQueue],
  );

  // processNextFragmentInQueue関数を再実装
  const processNextFragmentInQueue = useCallback(() => {
    setFragmentQueue(queue => {
      if (queue.length === 0) return queue;

      // キュー先頭のフラグメントを取得
      const [nextFragment, ...rest] = queue;

      // タイプライター表示を開始
      setIsTypingInProgress(true);
      setDisplayedFragments(prev => {
        // すでに表示されていないことを確認
        if (!prev.find(f => f.id === nextFragment.id)) {
          return [
            ...prev,
            {
              ...nextFragment,
              isTyping: true,
              isComplete: false,
              isInteractive: false,
            },
          ].sort((a, b) => a.order_index - b.order_index);
        }
        return prev;
      });

      return rest; // 残りのキューを返す
    });
  }, []);

  const debugFragment = useCallback((fragment, label = 'Fragment') => {
    console.log(
      `[DEBUG] ${label}: id=${fragment.id}, order=${fragment.order_index}, factuality=${fragment.factuality}, type=${typeof fragment.factuality}`,
    );
  }, []);

  // WebSocket接続の設定
  useEffect(() => {
    let wsConnectInterval;

    // WebSocket接続状態の確認とリトライ
    const checkAndConnectWs = () => {
      if (
        !window.factCheckWebSocket ||
        window.factCheckWebSocket.readyState !== WebSocket.OPEN
      ) {
        console.log('[DEBUG-WS] WebSocket接続確認・再接続');
        // WebSocketが未接続または閉じている場合、接続イベントをディスパッチ
        const event = new CustomEvent('reconnectFactCheckWebSocket', {
          detail: { userId: userProfile?.user_id },
        });
        window.dispatchEvent(event);
      }
    };

    // WebSocketメッセージハンドラー
    const wsMessageHandler = event => {
      try {
        const message = JSON.parse(event.data);
        if (message && message.task_id === task_id) {
          console.log('[DEBUG-WS] WebSocketメッセージ受信:', message);
          handleWebSocketMessage(message);
        }
      } catch (error) {
        console.error('WebSocketメッセージのパースエラー:', error);
      }
    };

    // カスタムイベントハンドラー（ページリロード後のメッセージ受信用）
    const customEventHandler = e => {
      if (e.detail && e.detail.task_id === task_id) {
        handleWebSocketMessage(e.detail);
      }
    };

    // WebSocketに接続がある場合はリスナーを登録
    if (window.factCheckWebSocket) {
      window.factCheckWebSocket.addEventListener('message', wsMessageHandler);
    }

    // カスタムイベントリスナーを追加
    window.addEventListener('factCheckWebSocketMessage', customEventHandler);

    // グローバルハンドラも設定
    const originalHandler = window.factCheckWebSocketMessage;
    window.factCheckWebSocketMessage = msg => {
      if (originalHandler) {
        originalHandler(msg);
      }
      handleWebSocketMessage(msg);
    };

    // 定期的にWebSocket接続を確認（リロード直後など未接続時の対応）
    wsConnectInterval = setInterval(checkAndConnectWs, 5000);

    // コンポーネント初期化時にも一度チェック
    checkAndConnectWs();

    return () => {
      // クリーンアップ
      if (window.factCheckWebSocket) {
        window.factCheckWebSocket.removeEventListener(
          'message',
          wsMessageHandler,
        );
      }
      window.removeEventListener(
        'factCheckWebSocketMessage',
        customEventHandler,
      );
      window.factCheckWebSocketMessage = originalHandler;
      clearInterval(wsConnectInterval);
    };
  }, [handleWebSocketMessage, task_id, userProfile]);

  // ドロワーを閉じる処理
  const handleDrawerClose = useCallback(() => {
    setDrawerState(prev => ({
      visible: false,
      fragment: null,
    }));

    // 全てのハイライトを削除
    document.querySelectorAll('.fragment-card.selected').forEach(el => {
      el.classList.remove('selected');
    });
    document.querySelectorAll('.block-highlight').forEach(el => {
      el.classList.remove('block-highlight');
    });
    document.querySelectorAll('.sentence-highlight').forEach(el => {
      el.classList.remove('sentence-highlight');
    });
  }, []);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      // プロフィール
      const profile = await getUserProfile();
      setUserProfile(profile);

      // タスク情報
      const resp = await axios.get(
        `${process.env.REACT_APP_API_URL}/fact-check/tasks/${task_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      const taskData = resp.data;
      setTask(taskData);

      // Editorjs JSON
      if (taskData.fact_check_text) {
        try {
          const content = JSON.parse(taskData.fact_check_text);
          setEditorContent(content);
        } catch (e) {
          console.error('Failed to parse editor content:', e);
          message.error('コンテンツの解析に失敗しました');
        }
      }

      // フラグメント
      if (Array.isArray(taskData.fragments)) {
        const fragsWithId = taskData.fragments.map(f => ({
          ...f,
          id: f.task_id + '-' + f.order_index,
        }));

        console.log(`[DEBUG] 全フラグメント数: ${fragsWithId.length}`);
        fragsWithId.forEach(frag => {
          debugFragment(frag, 'API取得フラグメント');
        });

        setFragments(fragsWithId);

        // タスク進行中のフラグメント表示ロジックを改善
        if (isTaskInProgress(taskData)) {
          // 進行中の場合、共通関数で検証済みのフラグメントのみを表示
          const verifiedFragments = fragsWithId.filter(frag =>
            isFragmentVerified(frag, taskData, fragsWithId.length),
          );

          console.log(
            `[DEBUG] 検証済みフラグメント数: ${verifiedFragments.length}`,
          );
          setDisplayedFragments(
            verifiedFragments.map(frag => ({
              ...frag,
              isTyping: false,
              isComplete: true,
              isInteractive: true,
            })),
          );
        } else {
          // 完了済みの場合も検証済みのフラグメントのみを表示
          const verifiedFragments = fragsWithId.filter(frag =>
            isFragmentVerified(frag, taskData, fragsWithId.length),
          );

          console.log(
            `[DEBUG] 検証済みフラグメント数(完了時): ${verifiedFragments.length}`,
          );
          setDisplayedFragments(
            verifiedFragments.map(frag => ({
              ...frag,
              isTyping: false,
              isComplete: true,
              isInteractive: true,
            })),
          );
        }
      }
    } catch (error) {
      console.error('Failed to fetch task data:', error);
      setError('データの取得に失敗しました');
    } finally {
      setIsLoading(false);
    }
  }, [task_id, isTaskInProgress, debugFragment]);

  // 初期データ取得
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getBestReferenceLink = references => {
    // console.log('Checking references:', references);
    if (!references || !references.length) {
      // console.log('No references found');
      return null;
    }

    // 関連性と信頼性でフィルタリング
    const highPriorityRefs = references.filter(ref => {
      // console.log(
      //   'Checking ref:',
      //   ref,
      //   'relevance_score:',
      //   ref.relevance_score,
      //   'domain_score:',
      //   ref.domain_score,
      // );
      return ref.relevance_score === 5 && ref.domain_score >= 3;
    });
    // console.log('High priority refs:', highPriorityRefs);

    const mediumPriorityRefs = references.filter(ref => {
      return ref.relevance_score === 4 && ref.domain_score >= 4;
    });
    // console.log('Medium priority refs:', mediumPriorityRefs);

    // 優先度の高いリンクから選択
    let candidates =
      highPriorityRefs.length > 0 ? highPriorityRefs : mediumPriorityRefs;
    if (!candidates.length) {
      // console.log('No candidates found');
      return null;
    }

    // 信頼性が最も高いものを選択
    const bestRef = candidates.reduce((best, current) =>
      current.domain_score > best.domain_score ? current : best,
    );
    // console.log('Selected best reference:', bestRef);
    return bestRef;
  };

  const getFilteredAndSortedFragments = useCallback(() => {
    // まず未検証フラグメントを除外し、displayedFragmentsにあるものだけを対象にする
    let result = fragments.filter(
      f =>
        displayedFragments.some(d => d.id === f.id) &&
        // 共通関数を使った検証判定
        isFragmentVerified(f, task, fragments.length),
    );

    // 未完了のみ表示フィルター
    if (showIncomplete) {
      result = result.filter(f => !f.result);
    }

    // 並び替え
    switch (sortOrder) {
      case 'low':
        result.sort((a, b) => (a.factuality || 0) - (b.factuality || 0));
        break;
      case 'high':
        result.sort((a, b) => (b.factuality || 0) - (a.factuality || 0));
        break;
      default:
        result.sort((a, b) => a.order_index - b.order_index);
    }

    return result;
  }, [fragments, displayedFragments, showIncomplete, sortOrder, task]);

  // エディタの準備完了時の処理
  const handleEditorReady = useCallback(() => {
    const editor = window._editor;
    if (!editor) {
      console.log('No editor instance available in window._editor');
      return;
    }
    editorRef.current = editor;
    setEditorReady(true);
    setEditorInstance(editor);

    // Affixの位置を取得して保存
    setTimeout(() => {
      const affixElement = document.querySelector('.results-panel-affix');
      if (affixElement) {
        const affixPosition =
          affixElement.getBoundingClientRect().top + window.pageYOffset - 24;
        setMinScrollPosition(affixPosition);
      }
    }, 100);
  }, []);

  const handleOriginalSave = async () => {
    if (!editorInstance || !task) return;

    try {
      setIsSaving(true);
      const savedData = await editorInstance.save();

      // 文字数を計算
      const charCount = savedData.blocks.reduce((total, block) => {
        return total + (block.data?.text ? block.data.text.trim().length : 0);
      }, 0);

      // チケット枚数を計算
      const calculatedTicketCost = 1 + Math.ceil(charCount / 2500);

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/fact-check/tasks/${task.id}`,
        {
          fact_check_text: JSON.stringify(savedData),
          frontend_ticket_cost: calculatedTicketCost, // フロントエンドで計算したチケット枚数を追加
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );

      if (response.data) {
        message.success('原稿を保存しました');
      }
    } catch (error) {
      console.error('Failed to save content:', error);
      message.error('原稿の保存に失敗しました');
    } finally {
      setIsSaving(false);
    }
  };

  const handleMarkAsCompleted = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/fact-check/tasks/${task_id}`,
        {
          status: task.status === 'finished' ? 'completed' : 'finished',
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );

      if (response.data) {
        // ステータスが「finished」になる場合、スケルトンカードを非表示に
        const newStatus = task.status === 'finished' ? 'completed' : 'finished';
        if (newStatus === 'finished') {
          setForceHideSkeleton(true);
        }

        setTask(prev => ({
          ...prev,
          status: newStatus,
        }));

        message.success(
          task.status === 'finished'
            ? '完了状態を解除しました'
            : '完了済みに設定しました',
        );
      }
    } catch (error) {
      console.error('Failed to update task status:', error);
      message.error('ステータスの更新に失敗しました');
    }
  };

  // Word出力機能
  const handleWordExport = async () => {
    if (!editorInstance || !task) return;

    try {
      setIsExporting(true);
      const editorData = await editorInstance.save();

      const timestamp = moment().format('YYYYMMDDHHmm');
      const filename = `${timestamp}_factcheck.docx`;

      // フラグメント情報をコメント形式に変換
      // ※各フラグメントについて、以下の情報を送信する：
      //   - fragment_text → 主張文（text）
      //   - fragment.factuality（例： "80%" ）を追加して表示する
      //   - fragment.reason を {reason} として別段落に表示する
      //   - reference_info の各要素（ref.keyQuote, ref.url）を Source リプとして送信
      //   - deep_check_status が 'completed' の場合、deep_check_result と deep_check_sources を DeepCheck リプとして送信
      const commentData = fragments.map(fragment => ({
        blockIds: fragment.block_position?.ids || [],
        comments: [
          {
            author: 'magicss.ai',
            text: fragment.fragment_text,
            // 追加する情報
            factuality: fragment.factuality * 10, // 例："80%"
            reason: fragment.reason, // 補足理由（存在する場合）
            replies: [
              // Source リプ（参考ソース）
              ...fragment.reference_info.map(ref => ({
                author: 'Source',
                text: `${ref.keyQuote}\n${ref.url}`,
              })),
              // DeepCheck リプ： deep_check_result と deep_check_sources を送信
              fragment.deep_check_status === 'completed'
                ? {
                    reply: fragment.deep_check_result,
                    author: 'Sonar検証',
                    verified: true,
                    sources: fragment.deep_check_sources || [],
                  }
                : null,
            ].filter(Boolean),
          },
        ],
      }));

      console.log('commentData', commentData);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/fact-check/export/word`,
        {
          content: editorData,
          comments: commentData,
          filename,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          responseType: 'blob',
        },
      );

      // ダウンロード処理
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);

      message.success('Wordファイルをダウンロードしました');
    } catch (error) {
      console.error('Failed to export Word file:', error);
      message.error('Word出力に失敗しました');
    } finally {
      setIsExporting(false);
    }
  };

  // HTMLコピー機能
  const handleHtmlCopy = async () => {
    if (!editorInstance) return;

    try {
      const editorData = await editorInstance.save();
      const blocks = editorData.blocks;

      const htmlContent = blocks
        .map(block => {
          switch (block.type) {
            case 'header': {
              return `<h${block.data.level}>${block.data.text}</h${block.data.level}>`;
            }
            case 'paragraph': {
              return `<p>${block.data.text}</p>`;
            }
            case 'list': {
              const tag = block.data.style === 'ordered' ? 'ol' : 'ul';
              const items = block.data.items
                .map(item => `<li>${item}</li>`)
                .join('');
              return `<${tag}>${items}</${tag}>`;
            }
            case 'table': {
              const rows = block.data.content
                .map(row => {
                  const cells = row.map(cell => `<td>${cell}</td>`).join('');
                  return `<tr>${cells}</tr>`;
                })
                .join('');
              return `<table border="1">${rows}</table>`;
            }
            default:
              return '';
          }
        })
        .join('\n');

      await navigator.clipboard.writeText(htmlContent);
      message.success('HTMLをコピーしました');
    } catch (error) {
      console.error('Failed to copy HTML:', error);
      message.error('HTMLのコピーに失敗しました');
    }
  };

  // ブロック番号の更新関数
  const updateBlockNumbers = useCallback(() => {
    if (!editorRef.current || !fragments.length) return;

    // 既存の番号表示をクリア
    document.querySelectorAll('.block-numbers').forEach(el => el.remove());

    // ブロックごとのフラグメント番号をマッピング
    const blockFragments = {};
    fragments.forEach(fragment => {
      if (fragment.block_position?.ids) {
        fragment.block_position.ids.forEach(blockId => {
          if (!blockFragments[blockId]) {
            blockFragments[blockId] = [];
          }
          blockFragments[blockId].push({
            number: fragment.order_index + 1,
            factuality: fragment.factuality || 0,
            result: fragment.result || false,
            deep_check_status: fragment.deep_check_status,
            // フラグメント全体の情報を保持
            fragment: fragment,
          });
        });
      }
    });

    // 各ブロックに番号を表示
    Object.entries(blockFragments).forEach(([blockId, fragmentsInfo]) => {
      const block = editorRef.current?.blocks.getById(blockId);
      if (block) {
        const holder = block.holder;
        if (!holder) return;

        const contentElement = holder.querySelector('.ce-block__content');
        if (!contentElement) return;

        const numbersContainer = document.createElement('div');
        numbersContainer.className = 'block-numbers';

        fragmentsInfo
          .sort((a, b) => a.number - b.number)
          .forEach(fragmentInfo => {
            if (showIncomplete && fragmentInfo.result) {
              return;
            }

            const badge = document.createElement('div');
            badge.className = `block-number`;
            badge.textContent = fragmentInfo.number;

            // 判定：factuality が設定されている＝検証済みかどうか
            // タスクが進行中の場合は、タスクの進捗率とフラグメントの順番も考慮する
            const totalFragments =
              task && task.fragments ? task.fragments.length : 0;

            // 共通関数を使った検証判定
            const isVerified = isFragmentVerified(
              fragmentInfo.fragment,
              task,
              totalFragments,
            );

            // リロード時も未検証状態を維持
            badge.style.backgroundColor = '#d9d9d9';
            badge.style.color = '#888888';
            badge.style.cursor = 'default';

            if (isVerified) {
              // 検証済みの場合のスタイル適用
              if (fragmentInfo.deep_check_status === 'completed') {
                badge.style.background =
                  'linear-gradient(45deg, #722ed1, #eb2f96)';
              } else {
                const reliability = fragmentInfo.factuality * 100;
                const reliabilityColor = getAccuracyColor(reliability);
                badge.style.backgroundColor = reliabilityColor;
              }
              badge.style.color = 'white';

              // 検証済みの場合のみ、イベントリスナーを登録
              badge.style.cursor = 'pointer';
              badge.addEventListener('click', e => {
                e.stopPropagation();
                handleFragmentSelect(fragmentInfo.fragment);
              });
              badge.addEventListener('mouseenter', () =>
                handleHeaderHover(
                  true,
                  fragmentInfo.fragment.block_position?.ids,
                  false,
                ),
              );
              badge.addEventListener('mouseleave', () =>
                handleHeaderHover(false),
              );
            }

            if (!showIncomplete && fragmentInfo.result) {
              badge.style.opacity = '0.3';
            }

            if (fragmentInfo.result) {
              const checkIcon = document.createElement('div');
              checkIcon.className = 'block-number-check';
              checkIcon.innerHTML = `<svg viewBox="64 64 896 896" focusable="false" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true" stroke="currentColor" stroke-width="50"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg>`;
              badge.appendChild(checkIcon);
            }

            numbersContainer.appendChild(badge);
          });

        contentElement.style.position = 'relative';
        contentElement.appendChild(numbersContainer);
      }
    });
  }, [
    fragments,
    editorReady,
    showIncomplete,
    handleFragmentSelect,
    handleHeaderHover,
    task,
    isTaskInProgress,
  ]);

  // フラグメントの変更を監視してブロック番号を更新
  useEffect(() => {
    if (editorReady && fragments.length > 0) {
      updateBlockNumbers();
    }
  }, [fragments, editorReady, updateBlockNumbers]);

  // FilterSectionコンポーネント
  const FilterSection = () => {
    // 見出しは常に表示
    return (
      <>
        <div
          style={{
            marginTop: '16px',
          }}
        >
          <Row align="middle" justify="space-between">
            <Col>
              <div
                style={{
                  fontSize: '16px',
                  fontWeight: '600',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  marginBottom: '16px',
                  borderLeft: '3px solid #00d4ff',
                  paddingLeft: '10px',
                }}
              >
                検証項目一覧
                <Tooltip title="情報を表示">
                  <InfoCircleOutlined
                    style={{
                      color: '#1890ff',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      Modal.info({
                        title: 'ファクトチェックについて',
                        width: 900,
                        maskClosable: true,
                        content: (
                          <div
                            style={{
                              marginTop: '16px',
                              maxHeight: 'calc(80vh - 100px)',
                              overflow: 'auto',
                              scrollbarWidth: 'thin',
                              scrollbarColor: '#e6e6e6 transparent',
                              msOverflowStyle: 'none',
                              '&::-webkit-scrollbar': {
                                width: '6px',
                                height: '6px',
                              },
                              '&::-webkit-scrollbar-track': {
                                background: 'transparent',
                              },
                              '&::-webkit-scrollbar-thumb': {
                                background: '#e6e6e6',
                                borderRadius: '3px',
                              },
                              '&::-webkit-scrollbar-thumb:hover': {
                                background: '#d9d9d9',
                              },
                            }}
                          >
                            <Tabs defaultActiveKey="1">
                              <Tabs.TabPane tab="検証方法" key="1">
                                <div style={{ padding: '8px 0' }}>
                                  <p>
                                    AIが原稿を分析し、事実確認が必要な記述を自動的に抽出します。
                                    抽出される項目数は原稿の長さに応じて調整されます。
                                  </p>

                                  <div
                                    style={{
                                      background: '#f9f9f9',
                                      padding: '12px',
                                      borderRadius: '8px',
                                      marginTop: '12px',
                                      marginBottom: '16px',
                                      border: '1px solid #f0f0f0',
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginBottom: '8px',
                                        fontWeight: '500',
                                      }}
                                    >
                                      抽出の優先順位：
                                    </div>
                                    <ol
                                      style={{
                                        paddingLeft: '20px',
                                        margin: '0',
                                      }}
                                    >
                                      <li>
                                        誤情報になった場合に重大なリスクがある主張
                                      </li>
                                      <li>
                                        商品やサービスの機能や特徴、統計など一次情報が必要な主張
                                      </li>
                                      <li>
                                        専門的な情報で、信頼性の高い裏付けが必要な主張
                                      </li>
                                      <li>
                                        情報の根拠が併記されていない、または根拠が薄い主張
                                      </li>
                                    </ol>
                                  </div>

                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      marginBottom: '16px',
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: '40px',
                                        height: '40px',
                                        borderRadius: '50%',
                                        background: '#f0f0f0',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginRight: '12px',
                                        fontSize: '20px',
                                        color: '#1890ff',
                                      }}
                                    >
                                      <FileSearchOutlined />
                                    </div>
                                    <div>
                                      <div
                                        style={{
                                          fontWeight: '500',
                                          marginBottom: '4px',
                                        }}
                                      >
                                        文字数に応じた検証項目数
                                      </div>

                                      <div
                                        style={{
                                          fontSize: '13px',
                                          lineHeight: '1.5',
                                        }}
                                      >
                                        〜2000文字：最大25項目
                                        <br />
                                        〜3500文字：最大40項目
                                        <br />
                                        〜5000文字：最大55項目
                                        <br />
                                        〜6500文字：最大70項目
                                        <br />
                                        〜8000文字：最大85項目
                                        <br />
                                        〜9500文字：最大100項目
                                        <br />
                                        〜11000文字：最大115項目
                                      </div>
                                    </div>
                                  </div>

                                  <Alert
                                    message={
                                      <span
                                        style={{
                                          fontSize: '14px',
                                          fontWeight: '700',
                                        }}
                                      >
                                        検証項目は原稿の内容に基づいて抽出されます
                                      </span>
                                    }
                                    description="原稿の内容によっては、抽出される項目数が上記の目安と異なる場合があります。事実検証すべき内容が少ない原稿では、抽出項目が少なくなります。"
                                    type="info"
                                    showIcon
                                  />
                                </div>
                              </Tabs.TabPane>

                              <Tabs.TabPane tab="事実性スコア" key="2">
                                <div style={{ padding: '8px 0' }}>
                                  <p>
                                    事実性スコアは、記述の事実的正確性を0.0〜1.0の数値で表します。
                                    スコアが高いほど、信頼できる情報源によって裏付けられていることを意味します。
                                  </p>

                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      marginTop: '20px',
                                      marginBottom: '24px',
                                    }}
                                  >
                                    <div style={{ textAlign: 'center' }}>
                                      <Progress
                                        type="circle"
                                        percent={95}
                                        width={60}
                                        strokeColor="#1890ff"
                                      />
                                      <div
                                        style={{
                                          marginTop: '8px',
                                          fontWeight: '500',
                                        }}
                                      >
                                        高い信頼性
                                      </div>
                                      <div
                                        style={{
                                          fontSize: '12px',
                                          color: 'rgba(0, 0, 0, 0.45)',
                                        }}
                                      >
                                        0.9〜1.0
                                      </div>
                                    </div>
                                    <div style={{ textAlign: 'center' }}>
                                      <Progress
                                        type="circle"
                                        percent={75}
                                        width={60}
                                        strokeColor="#52c41a"
                                      />
                                      <div
                                        style={{
                                          marginTop: '8px',
                                          fontWeight: '500',
                                        }}
                                      >
                                        一定の信頼性
                                      </div>
                                      <div
                                        style={{
                                          fontSize: '12px',
                                          color: 'rgba(0, 0, 0, 0.45)',
                                        }}
                                      >
                                        0.7〜0.89
                                      </div>
                                    </div>
                                    <div style={{ textAlign: 'center' }}>
                                      <Progress
                                        type="circle"
                                        percent={55}
                                        width={60}
                                        strokeColor="#faad14"
                                      />
                                      <div
                                        style={{
                                          marginTop: '8px',
                                          fontWeight: '500',
                                        }}
                                      >
                                        確認が必要
                                      </div>
                                      <div
                                        style={{
                                          fontSize: '12px',
                                          color: 'rgba(0, 0, 0, 0.45)',
                                        }}
                                      >
                                        0.5〜0.69
                                      </div>
                                    </div>
                                    <div style={{ textAlign: 'center' }}>
                                      <Progress
                                        type="circle"
                                        percent={35}
                                        width={60}
                                        strokeColor="#ff4d4f"
                                      />
                                      <div
                                        style={{
                                          marginTop: '8px',
                                          fontWeight: '500',
                                        }}
                                      >
                                        低い信頼性
                                      </div>
                                      <div
                                        style={{
                                          fontSize: '12px',
                                          color: 'rgba(0, 0, 0, 0.45)',
                                        }}
                                      >
                                        0.1〜0.49
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      background: '#f9f9f9',
                                      padding: '12px',
                                      borderRadius: '8px',
                                      marginBottom: '16px',
                                      border: '1px solid #f0f0f0',
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginBottom: '8px',
                                        fontWeight: '500',
                                      }}
                                    >
                                      スコア計算の主な要素：
                                    </div>
                                    <ul
                                      style={{
                                        paddingLeft: '20px',
                                        margin: '0',
                                      }}
                                    >
                                      <li>
                                        <strong>情報源の信頼性</strong>
                                        ：政府機関や学術機関のサイトは高評価
                                      </li>
                                      <li>
                                        <strong>情報の関連性</strong>
                                        ：検証項目と引用情報の直接的な関連性
                                      </li>
                                      <li>
                                        <strong>複数ソースの一致</strong>
                                        ：複数の情報源による裏付け
                                      </li>
                                      <li>
                                        <strong>情報の新しさ</strong>
                                        ：最新の情報ほど高評価
                                      </li>
                                    </ul>
                                  </div>

                                  <Alert
                                    message={
                                      <span
                                        style={{
                                          fontSize: '14px',
                                          fontWeight: '700',
                                        }}
                                      >
                                        低スコアの項目は要注意
                                      </span>
                                    }
                                    description="スコアが低い項目は、情報の裏付けが不十分か、矛盾する情報が見つかった可能性があります。Sonar検証を実行するか、手動で情報を確認することをお勧めします。"
                                    type="warning"
                                    showIcon
                                  />
                                </div>
                              </Tabs.TabPane>

                              <Tabs.TabPane tab="使い方のヒント" key="4">
                                <div style={{ padding: '8px 0' }}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'flex-start',
                                      marginBottom: '16px',
                                      padding: '12px',
                                      background: '#f9f9f9',
                                      borderRadius: '8px',
                                      border: '1px solid #f0f0f0',
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: '32px',
                                        height: '32px',
                                        borderRadius: '50%',
                                        background: '#e6f7ff',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginRight: '12px',
                                        fontSize: '16px',
                                        color: '#1890ff',
                                        flexShrink: 0,
                                        marginTop: '4px',
                                      }}
                                    >
                                      <FilterOutlined />
                                    </div>
                                    <div>
                                      <div
                                        style={{
                                          fontWeight: '500',
                                          marginBottom: '4px',
                                        }}
                                      >
                                        フィルタリング機能
                                      </div>
                                      <div
                                        style={{
                                          fontSize: '13px',
                                          marginBottom: '8px',
                                        }}
                                      >
                                        「未完了のみを表示」チェックボックスを使うと、まだ確認していない項目だけを表示できます。
                                      </div>
                                      <div
                                        style={{
                                          border: '1px solid #d9d9d9',
                                          borderRadius: '4px',
                                          padding: '8px',
                                          background: 'white',
                                          fontSize: '13px',
                                        }}
                                      >
                                        <Checkbox checked={true}>
                                          未完了のみを表示
                                        </Checkbox>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'flex-start',
                                      marginBottom: '16px',
                                      padding: '12px',
                                      background: '#f9f9f9',
                                      borderRadius: '8px',
                                      border: '1px solid #f0f0f0',
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: '32px',
                                        height: '32px',
                                        borderRadius: '50%',
                                        background: '#e6f7ff',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginRight: '12px',
                                        fontSize: '16px',
                                        color: '#1890ff',
                                        flexShrink: 0,
                                        marginTop: '4px',
                                      }}
                                    >
                                      <SortAscendingOutlined />
                                    </div>
                                    <div>
                                      <div
                                        style={{
                                          fontWeight: '500',
                                          marginBottom: '4px',
                                        }}
                                      >
                                        ソート機能
                                      </div>
                                      <div
                                        style={{
                                          fontSize: '13px',
                                          marginBottom: '8px',
                                        }}
                                      >
                                        ドロップダウンメニューで項目の表示順を変更できます。スコアの低い項目から確認すると効率的です。
                                      </div>
                                      <div
                                        style={{
                                          border: '1px solid #d9d9d9',
                                          borderRadius: '4px',
                                          padding: '8px',
                                          background: 'white',
                                          fontSize: '13px',
                                        }}
                                      >
                                        <Select
                                          defaultValue="low"
                                          style={{ width: 150 }}
                                          options={[
                                            {
                                              value: 'default',
                                              label: 'デフォルト',
                                            },
                                            {
                                              value: 'low',
                                              label: 'スコアの低い順',
                                            },
                                            {
                                              value: 'high',
                                              label: 'スコアの高い順',
                                            },
                                          ]}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'flex-start',
                                      marginBottom: '16px',
                                      padding: '12px',
                                      background: '#f9f9f9',
                                      borderRadius: '8px',
                                      border: '1px solid #f0f0f0',
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: '32px',
                                        height: '32px',
                                        borderRadius: '50%',
                                        background: '#e6f7ff',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginRight: '12px',
                                        fontSize: '16px',
                                        color: '#1890ff',
                                        flexShrink: 0,
                                        marginTop: '4px',
                                      }}
                                    >
                                      <CheckSquareOutlined />
                                    </div>
                                    <div>
                                      <div
                                        style={{
                                          fontWeight: '500',
                                          marginBottom: '4px',
                                        }}
                                      >
                                        完了/未完了の管理
                                      </div>
                                      <div
                                        style={{
                                          fontSize: '13px',
                                          marginBottom: '8px',
                                        }}
                                      >
                                        各項目のスイッチをオンにすると、その項目を確認済みとしてマークできます。
                                        すべての項目を確認したら、「完了済みにする」ボタンをクリックしてください。
                                      </div>
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'space-between',
                                          border: '1px solid #d9d9d9',
                                          borderRadius: '4px',
                                          padding: '8px 12px',
                                          background: 'white',
                                          fontSize: '13px',
                                        }}
                                      >
                                        <span>検証項目の例</span>
                                        <Switch size="small" defaultChecked />
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'flex-start',
                                      marginBottom: '16px',
                                      padding: '12px',
                                      background: '#f9f9f9',
                                      borderRadius: '8px',
                                      border: '1px solid #f0f0f0',
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: '32px',
                                        height: '32px',
                                        borderRadius: '50%',
                                        background: '#e6f7ff',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginRight: '12px',
                                        fontSize: '16px',
                                        color: '#1890ff',
                                        flexShrink: 0,
                                        marginTop: '4px',
                                      }}
                                    >
                                      <ExportOutlined />
                                    </div>
                                    <div>
                                      <div
                                        style={{
                                          fontWeight: '500',
                                          marginBottom: '4px',
                                        }}
                                      >
                                        出力オプション
                                      </div>
                                      <div
                                        style={{
                                          fontSize: '13px',
                                          marginBottom: '8px',
                                        }}
                                      >
                                        検証が完了した原稿は、Word形式でダウンロードしたり、HTMLコードとしてコピーしたりできます。
                                      </div>
                                      <div
                                        style={{
                                          display: 'flex',
                                          gap: '8px',
                                        }}
                                      >
                                        <Button
                                          size="small"
                                          icon={<FileWordOutlined />}
                                        >
                                          Word出力
                                        </Button>
                                        <Button
                                          size="small"
                                          icon={<CopyOutlined />}
                                        >
                                          HTMLコピー
                                        </Button>
                                      </div>
                                    </div>
                                  </div>

                                  <Alert
                                    message={
                                      <span
                                        style={{
                                          fontSize: '14px',
                                          fontWeight: '700',
                                        }}
                                      >
                                        原稿の編集と保存
                                      </span>
                                    }
                                    description="左側のエディタで原稿を編集できます。編集内容は自動的には保存されないため、「原稿を保存」ボタンをクリックして明示的に保存してください。"
                                    type="info"
                                    showIcon
                                  />
                                </div>
                              </Tabs.TabPane>
                            </Tabs>
                          </div>
                        ),
                        okText: '閉じる',
                      });
                    }}
                  />
                </Tooltip>
              </div>
            </Col>
            {/* 検証完了状態の場合のみ自動スクロールスイッチを表示 */}
            {!isTaskInProgress(task) && (
              <Col>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '16px',
                  }}
                >
                  <Tooltip title="検証項目にマウスを乗せた時に、原稿の対応する箇所へ自動スクロールします">
                    <Switch
                      checked={autoScroll}
                      onChange={checked => setAutoScroll(checked)}
                      checkedChildren="自動スクロール"
                      unCheckedChildren="自動スクロール"
                    />
                  </Tooltip>
                </div>
              </Col>
            )}
          </Row>

          {/* フィルタリング部分は処理中は非表示 */}
          {!isTaskInProgress(task) && (
            <Row
              align="middle"
              justify="space-between"
              style={{
                padding: '8px 16px',
                backgroundColor: '#fafafa',
                marginBottom: '8px',
                flexDirection: screens.sm ? 'row' : 'column',
                alignItems: screens.sm ? 'center' : 'flex-start',
                gap: screens.sm ? 0 : '12px',
                width: '100%',
              }}
            >
              <Col
                xs={24}
                sm={12}
                style={{ marginBottom: screens.sm ? 0 : '8px' }}
              >
                <Tooltip title="未完了の検証項目のみを表示します。完了済みの項目は非表示になります。">
                  <Checkbox
                    checked={showIncomplete}
                    onChange={e => setShowIncomplete(e.target.checked)}
                  >
                    未完了のみを表示
                  </Checkbox>
                </Tooltip>
              </Col>
              <Col
                xs={24}
                sm={12}
                style={{
                  textAlign: screens.sm ? 'right' : 'left',
                  width: '100%',
                }}
              >
                <Tooltip title="検証項目の表示順序を変更します。スコアの低い項目から確認すると効率的です。">
                  <Select
                    value={sortOrder}
                    onChange={value => setSortOrder(value)}
                    style={{ width: screens.sm ? 150 : '100%' }}
                    options={[
                      { value: 'default', label: 'デフォルト' },
                      { value: 'low', label: 'スコアの低い順' },
                      { value: 'high', label: 'スコアの高い順' },
                    ]}
                  />
                </Tooltip>
              </Col>
            </Row>
          )}
        </div>
      </>
    );
  };

  const SkeletonProgress = () => {
    return (
      <div
        style={{
          width: '24px',
          height: '24px',
          borderRadius: '50%',
          background:
            'linear-gradient(90deg, #f0f0f0 25%, #fafafa 50%, #f0f0f0 75%)',
          backgroundSize: '200% 100%',
          animation: 'shimmer 1.5s infinite linear',
        }}
      />
    );
  };

  // アニメーション用のスタイルをhead内に追加
  const shimmerStyle = document.createElement('style');
  shimmerStyle.textContent = `
    @keyframes shimmer {
      0% { background-position: 200% 0; }
      100% { background-position: -200% 0; }
    }
  `;
  document.head.appendChild(shimmerStyle);

  // FragmentCardコンポーネントの修正（完成形デザインでタイプライター表示）
  const FragmentCard = React.memo(
    ({
      fragment,
      onTypingComplete,
      handleFragmentSelect,
      handleHeaderHover = () => {},
    }) => {
      // 状態の計算
      const isTyping = !!fragment.isTyping;

      // タスクが進行中かどうか
      const isTaskProcessing = isTaskInProgress(task);

      // 検証済みかどうかの判定 - 厳密に判定
      const isVerified = isFragmentVerified(fragment, task, fragments.length);

      console.log(
        `[DEBUG-CARD] フラグメント ${fragment.id}: isVerified=${isVerified}, factuality=${fragment.factuality}, type=${typeof fragment.factuality}, isTyping=${isTyping}`,
      );

      // 未検証かつタイピング中でなければ表示しない
      if (!isVerified && !isTyping) {
        console.log(`[DEBUG-CARD] フラグメント非表示: ${fragment.id}`);
        return null;
      }

      // タスクが完了している場合は最後のスケルトンカードを非表示
      if (task?.status === 'completed' && !isVerified && !isTyping) {
        console.log(
          `[DEBUG-CARD] タスク完了時のスケルトンカード非表示: ${fragment.id}`,
        );
        return null;
      }

      // インタラクティブな状態（タイプライター表示中は非インタラクティブ）
      const isInteractive = fragment.isInteractive && !isTyping;

      // factuality (0～1) を百分率に変換
      const reliabilityValue = isVerified
        ? (fragment.factuality || 0) * 100
        : 0;
      const reliabilityColor = getAccuracyColor(reliabilityValue);

      // イベントハンドラ
      const handleCardClick = () => {
        if (!isInteractive) return;
        handleFragmentSelect(fragment.id);
      };

      const handleMouseEnter = e => {
        e.stopPropagation();
        if (!isInteractive) return;
        handleHeaderHover(true, fragment.block_position?.ids, true);
      };

      const handleMouseLeave = e => {
        e.stopPropagation();
        if (!isInteractive) return;
        handleHeaderHover(false);
      };

      // クラス名の設定
      const cardClasses = [
        'fragment-card',
        isTyping ? 'typing' : '',
        fragment.isUpdating ? 'isUpdating' : '',
        isVerified ? 'complete' : '',
        isInteractive ? 'interactive' : '',
        fragment.result ? 'completed' : '',
      ]
        .filter(Boolean)
        .join(' ');

      // フラグメントテキストがない場合のフォールバック
      const displayText =
        fragment.fragment_text || `検証項目 #${fragment.order_index + 1}`;

      return (
        <div
          className={cardClasses}
          data-completed={fragment.result}
          data-fragment-id={fragment.id}
          onClick={handleCardClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{
            cursor: isInteractive ? 'pointer' : 'default',
            opacity: 1, // 常に不透明で表示（半透明状態をなくす）
          }}
        >
          <div
            className="fragment-number-badge"
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              color: '#777',
              width: '18px',
              height: '18px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '11px',
              zIndex: 10,
            }}
          >
            #{fragment.order_index + 1}
          </div>
          <div
            className="fragment-header"
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              padding: '12px 16px',
            }}
          >
            {!isTaskProcessing && (
              <>
                <Tooltip title="検証が完了したらチェックを入れてください。完了済みの項目は一覧でフィルタリングできます。">
                  <div onClick={e => e.stopPropagation()}>
                    <Checkbox
                      checked={fragment.result}
                      onChange={e => {
                        e.stopPropagation();

                        // 処理中なら何もしない
                        if (switchLoading && updatingFragmentId === fragment.id)
                          return;

                        // ここでは状態を変更せず、APIコール処理のみを呼び出す
                        handleToggleComplete(fragment);
                      }}
                      onClick={e => e.stopPropagation()}
                      style={{ marginRight: '8px', marginLeft: '4px' }}
                      disabled={
                        switchLoading && updatingFragmentId === fragment.id
                      }
                    />
                  </div>
                </Tooltip>
                {switchLoading && updatingFragmentId === fragment.id ? (
                  <Spin
                    size="small"
                    style={{ position: 'absolute', left: '24px' }}
                  />
                ) : null}
              </>
            )}
            <Progress
              type="circle"
              percent={Math.round(reliabilityValue)}
              size={24}
              strokeColor={
                fragment.deep_check_status === 'completed'
                  ? 'url(#deepCheckGradient)'
                  : getAccuracyColor(
                      Math.round((fragment.factuality || 0) * 100),
                    )
              }
              format={() => {
                const reliabilityValue = Math.round(
                  (fragment.factuality || 0) * 100,
                );
                const reliabilityColor = getAccuracyColor(reliabilityValue);
                const isDeepChecked =
                  fragment.deep_check_status === 'completed';
                const completedColor = fragment.result ? '#8f8f8f' : undefined;
                if (reliabilityValue === 100) {
                  return (
                    <CheckOutlined
                      style={{
                        fontSize: '11px',
                        color:
                          completedColor ||
                          (isDeepChecked ? undefined : reliabilityColor),
                        background:
                          !completedColor && isDeepChecked
                            ? 'linear-gradient(45deg, #722ed1, #eb2f96)'
                            : undefined,
                        WebkitBackgroundClip:
                          !completedColor && isDeepChecked ? 'text' : undefined,
                        WebkitTextFillColor:
                          !completedColor && isDeepChecked
                            ? 'transparent'
                            : undefined,
                      }}
                    />
                  );
                }
                if (reliabilityValue === 0) {
                  return (
                    <span
                      style={{
                        fontSize: '11px',
                        color: completedColor || '#F5222D', // 完了済みならグレー、それ以外は赤色
                        fontWeight: 'bold',
                        background:
                          !completedColor && isDeepChecked
                            ? 'linear-gradient(45deg, #722ed1, #eb2f96)'
                            : undefined,
                        WebkitBackgroundClip:
                          !completedColor && isDeepChecked ? 'text' : undefined,
                        WebkitTextFillColor:
                          !completedColor && isDeepChecked
                            ? 'transparent'
                            : undefined,
                      }}
                    >
                      ?
                    </span>
                  );
                }
                if (isDeepChecked) {
                  return (
                    <span
                      style={{
                        background: completedColor
                          ? undefined
                          : 'linear-gradient(45deg, #722ed1, #eb2f96)',
                        WebkitBackgroundClip: completedColor
                          ? undefined
                          : 'text',
                        WebkitTextFillColor: completedColor
                          ? undefined
                          : 'transparent',
                        fontSize: '11px',
                        fontWeight: 'bold',
                        color: completedColor,
                      }}
                    >
                      {reliabilityValue}
                    </span>
                  );
                }
                return (
                  <span
                    style={{
                      color: completedColor || reliabilityColor,
                      fontWeight: 'bold',
                    }}
                  >
                    {reliabilityValue}
                  </span>
                );
              }}
            />
            <div
              className="fragment-text"
              style={{
                flex: 1,
                fontWeight: '500',
                opacity: 1, // 常に不透明で表示
              }}
            >
              {isTyping ? (
                <>
                  {console.log(
                    `[DEBUG-TYPEWRITER] FragmentCard[${fragment.id}]タイプライター表示開始: "${displayText}"`,
                  )}
                  <TypewriterText
                    text={displayText}
                    onComplete={() => {
                      console.log(
                        `[DEBUG-TYPEWRITER] タイプライター完了コールバック: ${fragment.id}`,
                      );
                      onTypingComplete(fragment.id);
                    }}
                    speed={10}
                  />
                </>
              ) : (
                displayText
              )}
            </div>
            <RightOutlined
              style={{
                fontSize: '12px',
                color: '#8c8c8c',
                opacity: isInteractive ? 1 : 0, // インタラクティブな場合のみ表示
                transition: 'opacity 0.5s ease-in-out',
              }}
            />
          </div>
          <svg style={{ width: 0, height: 0, position: 'absolute' }}>
            <defs>
              <linearGradient
                id="deepCheckGradient"
                x1="0%"
                y1="0%"
                x2="100%"
                y2="100%"
              >
                <stop offset="0%" stopColor="#722ed1" />
                <stop offset="100%" stopColor="#eb2f96" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      );
    },
  );

  // 完了状態の切り替え
  const handleToggleComplete = useCallback(
    async fragment => {
      if (!fragment) return;

      // 新しい状態値を計算 (すぐに使えるようにローカル変数に保存)
      const newCompletedValue = !fragment.result;

      // 楽観的UIアップデート - APIレスポンス待ちなしで即時UIを更新
      setUpdatingFragmentId(fragment.id);
      setSwitchLoading(true);

      // すぐにUIを更新（APIレスポンスを待たない）
      setFragments(prev =>
        prev.map(f =>
          f.id === fragment.id ? { ...f, result: newCompletedValue } : f,
        ),
      );

      // ドロワーが開いていて、同じフラグメントを表示中なら、その状態も更新
      if (
        drawerState.visible &&
        drawerState.fragment &&
        drawerState.fragment.id === fragment.id
      ) {
        setDrawerState(prev => ({
          ...prev,
          fragment: {
            ...prev.fragment,
            result: newCompletedValue,
          },
        }));
      }

      // フラグメント番号のUI更新も即時実行
      updateFragmentNumberStyle(fragment);

      try {
        // APIコール（バックグラウンドで実行）
        const response = await axios.patch(
          `${process.env.REACT_APP_API_URL}/fact-check/fragments/${task_id}/${fragment.order_index + 1}`,
          {
            result: newCompletedValue,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        );

        // APIが成功したらメッセージ表示だけ
        if (response.data) {
          // 成功メッセージ表示
          message.success(
            newCompletedValue
              ? '完了済みとしてマークしました'
              : '完了済みマークを外しました',
          );
        }
      } catch (error) {
        console.error('フラグメント完了状態の更新に失敗:', error);

        // エラー時は元の状態に戻す
        setFragments(prev =>
          prev.map(f =>
            f.id === fragment.id ? { ...f, result: !newCompletedValue } : f,
          ),
        );

        // ドロワーの状態も戻す
        if (
          drawerState.visible &&
          drawerState.fragment &&
          drawerState.fragment.id === fragment.id
        ) {
          setDrawerState(prev => ({
            ...prev,
            fragment: {
              ...prev.fragment,
              result: !newCompletedValue,
            },
          }));
        }

        // エラー通知
        notification.error({
          message: 'エラー',
          description: 'フラグメント完了状態の更新に失敗しました',
        });
      } finally {
        setSwitchLoading(false);
        setUpdatingFragmentId(null);
      }
    },
    [drawerState, task_id, updateFragmentNumberStyle],
  );

  const getTaskStatusTitle = useCallback(task => {
    if (!task) return '検証タスク';

    switch (task.status) {
      case 'queued':
        return '検証予約中';
      case 'processing':
        return '検証しています...';
      case 'completed':
      case 'finished':
        return '検証結果';
      case 'failed':
        return '検証失敗';
      default:
        return '検証タスク';
    }
  }, []);

  // Drawerコンポーネント
  const FragmentDrawer = useCallback(() => {
    const { fragment, visible } = drawerState;
    if (!fragment) return null;

    const bestRef = getBestReferenceLink(fragment.reference_info);

    // [n]形式の参照をリンク化する関数
    const formatReasonWithLinks = useCallback(text => {
      if (!text) return '';

      // [n]形式をクリック可能なリンクに変換
      const linkedText = text.replace(
        /\[(\d+)\]/g,
        '<span class="reference-link-number" data-ref-number="$1" style="color: #1890ff; cursor: pointer; font-weight: 500;" onclick="window.openReferenceUrl(\'$1\')">[<span style="text-decoration: underline;">$1</span>]</span>',
      );

      // URLをリンクに変換
      return linkedText.replace(
        /(https?:\/\/[^\s]+)/g,
        '<a href="$1" target="_blank" rel="noopener noreferrer" style="color: #1890ff; text-decoration: underline;">$1</a>',
      );
    }, []);

    // リンク番号クリック処理をグローバルに公開
    useEffect(() => {
      window.openReferenceUrl = refNumber => {
        if (!fragment || !fragment.reference_info) return;

        const idx = parseInt(refNumber, 10) - 1;
        if (idx >= 0 && idx < fragment.reference_info.length) {
          const refUrl = fragment.reference_info[idx].url;
          if (refUrl) {
            window.open(refUrl, '_blank', 'noopener,noreferrer');

            // ハイライト効果も適用
            const refElement = document.getElementById(`reference-item-${idx}`);
            if (refElement) {
              refElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              });
              refElement.classList.add('reference-highlight');
              setTimeout(() => {
                refElement.classList.remove('reference-highlight');
              }, 2000);
            }
          }
        }
      };

      return () => {
        // コンポーネントのアンマウント時に関数を削除
        delete window.openReferenceUrl;
      };
    }, [fragment]);

    return (
      <Drawer
        id="fragment-drawer"
        title={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '12px',
              width: '100%',
            }}
          >
            <span
              style={{
                flex: 1,
                fontSize: screens.xs ? '14px' : '16px',
                lineHeight: screens.xs ? '1.4' : '1.5',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {fragment.fragment_text}
            </span>
            {fragment.factuality !== null ? (
              <Progress
                type="circle"
                percent={Math.round(fragment.factuality * 100)}
                width={24}
                strokeWidth={12}
                strokeColor={
                  fragment.result
                    ? '#8f8f8f'
                    : fragment.deep_check_status === 'completed'
                      ? 'url(#deepCheckGradient)'
                      : getAccuracyColor(Math.round(fragment.factuality * 100))
                }
                format={percent => {
                  const reliabilityValue = percent;
                  const isDeepChecked =
                    fragment.deep_check_status === 'completed';
                  const completedColor = fragment.result
                    ? '#8f8f8f'
                    : undefined;

                  if (reliabilityValue === 100) {
                    return (
                      <CheckOutlined
                        style={{
                          fontSize: '11px',
                          color:
                            completedColor ||
                            (isDeepChecked
                              ? undefined
                              : getAccuracyColor(reliabilityValue)),
                          background:
                            !completedColor && isDeepChecked
                              ? 'linear-gradient(45deg, #722ed1, #eb2f96)'
                              : undefined,
                          WebkitBackgroundClip:
                            !completedColor && isDeepChecked
                              ? 'text'
                              : undefined,
                          WebkitTextFillColor:
                            !completedColor && isDeepChecked
                              ? 'transparent'
                              : undefined,
                        }}
                      />
                    );
                  }
                  if (reliabilityValue === 0) {
                    return (
                      <span
                        style={{
                          fontSize: '11px',
                          color: '#F5222D', // 赤色に固定
                          fontWeight: 'bold',
                          background:
                            !completedColor && isDeepChecked
                              ? 'linear-gradient(45deg, #722ed1, #eb2f96)'
                              : undefined,
                          WebkitBackgroundClip:
                            !completedColor && isDeepChecked
                              ? 'text'
                              : undefined,
                          WebkitTextFillColor:
                            !completedColor && isDeepChecked
                              ? 'transparent'
                              : undefined,
                        }}
                      >
                        ?
                      </span>
                    );
                  }
                  if (isDeepChecked) {
                    return (
                      <span
                        style={{
                          background: completedColor
                            ? undefined
                            : 'linear-gradient(45deg, #722ed1, #eb2f96)',
                          WebkitBackgroundClip: completedColor
                            ? undefined
                            : 'text',
                          WebkitTextFillColor: completedColor
                            ? undefined
                            : 'transparent',
                          fontSize: '11px',
                          fontWeight: 'bold',
                          color: completedColor,
                        }}
                      >
                        {reliabilityValue}
                      </span>
                    );
                  }
                  return (
                    <span
                      style={{
                        color:
                          completedColor || getAccuracyColor(reliabilityValue),
                        fontWeight: 'bold',
                        fontSize: '11px',
                      }}
                    >
                      {reliabilityValue}
                    </span>
                  );
                }}
              />
            ) : (
              <Skeleton.Avatar
                active
                shape="circle"
                style={{ width: 24, height: 24 }}
              />
            )}
          </div>
        }
        placement="right"
        onClose={handleDrawerClose}
        open={visible}
        width={screens.md ? 450 : '85%'}
        className="fragment-drawer"
        mask={true}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
        styles={{
          header: {
            paddingRight: '48px',
          },
          body: {
            padding: '24px',
            paddingTop: 0,
          },
          mask: {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
          },
          wrapper: {
            backgroundColor: 'transparent',
          },
          content: {
            background: 'rgba(255, 255, 255, 0.95)',
          },
        }}
      >
        <svg style={{ width: 0, height: 0, position: 'absolute' }}>
          <defs>
            <linearGradient
              id="deepCheckGradient"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="100%"
            >
              <stop offset="0%" stopColor="#722ed1" />
              <stop offset="100%" stopColor="#eb2f96" />
            </linearGradient>
          </defs>
        </svg>
        <div
          className="fragment-number"
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            padding: '4px 8px',
            background: '#f0f0f0',
            borderRadius: '4px',
            fontSize: '12px',
            fontWeight: '500',
            color: '#666',
            zIndex: 1,
          }}
        >
          #{fragment.order_index + 1}
        </div>

        <div style={{ marginTop: '16px' }}>
          {!isTaskInProgress(task) && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
                marginBottom: '16px',
                justifyContent: 'space-between',
              }}
            >
              <div onClick={e => e.stopPropagation()}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    // switchLoadingフラグの確認
                    if (switchLoading && updatingFragmentId === fragment.id)
                      return;

                    // 完了状態を切り替える
                    handleToggleComplete(fragment);
                  }}
                >
                  <Checkbox
                    checked={fragment.result}
                    onChange={e => {
                      e.stopPropagation();

                      // 処理中なら何もしない
                      if (switchLoading && updatingFragmentId === fragment.id)
                        return;

                      // 親要素のクリックイベントで処理するので、ここでは何もしない
                    }}
                    style={{ marginRight: '8px' }}
                    disabled={
                      switchLoading && updatingFragmentId === fragment.id
                    }
                  />
                  {switchLoading && updatingFragmentId === fragment.id ? (
                    <Spin size="small" style={{ marginRight: '8px' }} />
                  ) : null}
                  <span>
                    {fragment.result
                      ? '完了済みマークを外す'
                      : '完了済みとしてマークする'}
                  </span>
                </div>
              </div>
            </div>
          )}

          {fragment.reason && (
            <div
              className="fragment-reason"
              style={{
                marginBottom: '16px',
                color: 'rgba(0, 0, 0, 0.85)',
                background:
                  'linear-gradient(to right, rgba(114, 46, 209, 0.05), rgba(235, 47, 150, 0.05))',
                padding: '16px',
                borderRadius: '8px',
                fontSize: '14px',
                lineHeight: '1.6',
                textAlign: 'justify',
                boxShadow: 'inset 0 0 0 1px rgba(0, 0, 0, 0.06)',
              }}
              dangerouslySetInnerHTML={{
                __html: formatReasonWithLinks(fragment.reason),
              }}
            />
          )}

          {fragment.reference_info?.length > 0 && (
            <>
              <Divider orientation="left">
                <span style={{ fontSize: '14px', fontWeight: 'normal' }}>
                  参照ソース
                </span>
                <Tooltip
                  title={
                    <div style={{ maxWidth: '300px' }}>
                      <div style={{ marginBottom: '12px' }}>
                        <div
                          style={{
                            fontWeight: 'bold',
                            marginBottom: '8px',
                            color: 'rgba(255, 255, 255, 0.85)', // 見出し色を白に変更
                          }}
                        >
                          参照ソースについて
                        </div>
                        <div style={{ fontSize: '12px', lineHeight: '1.5' }}>
                          主張の事実確認のために使用した情報源を表示しています。本文中の
                          <span
                            style={{
                              display: 'inline-flex',
                              alignItems: 'center',
                              background:
                                'linear-gradient(to right, rgba(114, 46, 209, 0.1), rgba(235, 47, 150, 0.1))',
                              padding: '0 4px',
                              borderRadius: '4px',
                              margin: '0 2px',
                              fontWeight: '500',
                            }}
                          >
                            [1]
                          </span>
                          などの番号と対応しています。クリックすると元の情報源ページにアクセスできます。
                        </div>
                      </div>
                      <div style={{ marginBottom: '12px' }}>
                        <div
                          style={{
                            fontWeight: 'bold',
                            marginBottom: '8px',
                            color: 'rgba(255, 255, 255, 0.85)', // 見出し色を白に変更
                          }}
                        >
                          信頼性バーについて
                        </div>
                        <div
                          style={{
                            fontSize: '12px',
                            lineHeight: '1.5',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                          }}
                        >
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Progress
                              percent={85}
                              size="small"
                              showInfo={false}
                              strokeWidth={4}
                              style={{ width: '40px' }}
                              strokeColor={{
                                '0%': '#722ed1',
                                '100%': '#eb2f96',
                              }}
                            />
                            <span
                              style={{
                                fontSize: '10px',
                                color: '#eb2f96',
                                fontWeight: '500',
                                marginLeft: '4px',
                              }}
                            >
                              85%
                            </span>
                          </div>
                          <span>
                            情報源の信頼度を表しています。公式や政府機関情報は高くなります。
                          </span>
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            fontWeight: 'bold',
                            marginBottom: '8px',
                            color: 'rgba(255, 255, 255, 0.85)', // 見出し色を白に変更
                          }}
                        >
                          情報ソースの種類
                        </div>
                        <div
                          style={{
                            fontSize: '12px',
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '4px',
                          }}
                        >
                          <Tag
                            color="#333333"
                            style={{
                              margin: 0,
                              padding: '0 4px',
                              fontSize: '10px',
                              lineHeight: '16px',
                              color: '#FFFFFF',
                            }}
                          >
                            公式
                          </Tag>
                          <Tag
                            color="#1677ff"
                            style={{
                              margin: 0,
                              padding: '0 4px',
                              fontSize: '10px',
                              lineHeight: '16px',
                            }}
                          >
                            政府機関
                          </Tag>
                          <Tag
                            color="#eb2f96"
                            style={{
                              margin: 0,
                              padding: '0 4px',
                              fontSize: '10px',
                              lineHeight: '16px',
                            }}
                          >
                            企業
                          </Tag>
                          <Tag
                            color="#52c41a"
                            style={{
                              margin: 0,
                              padding: '0 4px',
                              fontSize: '10px',
                              lineHeight: '16px',
                            }}
                          >
                            ニュース
                          </Tag>
                          <Tag
                            color="#fa8c16"
                            style={{
                              margin: 0,
                              padding: '0 4px',
                              fontSize: '10px',
                              lineHeight: '16px',
                            }}
                          >
                            学術
                          </Tag>
                          <Tag
                            color="#722ed1"
                            style={{
                              margin: 0,
                              padding: '0 4px',
                              fontSize: '10px',
                              lineHeight: '16px',
                            }}
                          >
                            コミュニティ
                          </Tag>
                        </div>
                      </div>
                    </div>
                  }
                  placement="topRight"
                  overlayStyle={{ maxWidth: '350px' }}
                >
                  <InfoCircleOutlined
                    style={{
                      marginLeft: '8px',
                      fontSize: '14px',
                      color: 'rgba(0, 0, 0, 0.45)',
                    }}
                  />
                </Tooltip>
              </Divider>
              <List
                id="reference-list"
                dataSource={fragment.reference_info}
                renderItem={(ref, index) => (
                  <List.Item
                    key={index}
                    id={`reference-item-${index}`}
                    style={{
                      border: 'none',
                      padding: '0',
                      borderBottom:
                        index < fragment.reference_info.length - 1
                          ? '1px solid #f0f0f0'
                          : 'none',
                    }}
                  >
                    <a
                      href={ref.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="reference-link"
                      style={{
                        width: '100%',
                        display: 'block',
                        padding: '8px 8px 8px 0',
                        borderRadius: '4px',
                        transition: 'background-color 0.3s ease',
                      }}
                      onMouseEnter={e => {
                        e.currentTarget.style.backgroundColor =
                          'rgba(0, 0, 0, 0.03)';
                      }}
                      onMouseLeave={e => {
                        e.currentTarget.style.backgroundColor = 'transparent';
                      }}
                    >
                      <div style={{ width: '100%' }}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            gap: '8px',
                            marginBottom: '4px',
                          }}
                        >
                          <div
                            style={{
                              minWidth: '24px',
                              textAlign: 'center',
                              color: 'rgba(0, 0, 0, 0.65)',
                              fontWeight: '500',
                              background:
                                'linear-gradient(to right, rgba(114, 46, 209, 0.1), rgba(235, 47, 150, 0.1))',
                              borderRadius: '4px',
                              padding: '0 4px',
                              marginTop: '-8px',
                            }}
                          >
                            {index + 1}
                          </div>
                          <div style={{ flex: 1 }}>
                            {(ref.keyQuote || ref.source_text) && (
                              <div>
                                <div
                                  className="reference-quote-text"
                                  style={{
                                    padding: '8px',
                                    borderRadius: '4px',
                                    fontSize: '13px',
                                    lineHeight: '1.5',
                                    color: 'rgba(0, 0, 0, 0.75)',
                                    marginBottom: '8px',
                                    position: 'relative',
                                    // *で囲まれている場合の透明度は削除（リスト項目全体を透明にするため）
                                    opacity: 1,
                                    // 通常テキストの場合、引用スタイルを適用
                                    backgroundColor: 'transparent',
                                    paddingLeft:
                                      (ref.source_text &&
                                        ref.source_text.startsWith('*') &&
                                        ref.source_text.endsWith('*')) ||
                                      (ref.keyQuote &&
                                        ref.keyQuote.startsWith('*') &&
                                        ref.keyQuote.endsWith('*'))
                                        ? '8px'
                                        : '20px',
                                  }}
                                >
                                  {/* 通常テキストの場合は引用アイコンを追加 */}
                                  {!(
                                    (ref.source_text &&
                                      ref.source_text.startsWith('*') &&
                                      ref.source_text.endsWith('*')) ||
                                    (ref.keyQuote &&
                                      ref.keyQuote.startsWith('*') &&
                                      ref.keyQuote.endsWith('*'))
                                  ) && (
                                    <div
                                      style={{
                                        position: 'absolute',
                                        top: '-11px',
                                        left: '-8px',
                                        color: 'rgba(114, 46, 209, 0.1)',
                                        fontSize: '64px',
                                        fontFamily: 'serif',
                                      }}
                                    >
                                      "
                                    </div>
                                  )}
                                  {/* *がある場合は除去して表示 */}
                                  {ref.source_text
                                    ? ref.source_text.startsWith('*') &&
                                      ref.source_text.endsWith('*')
                                      ? ref.source_text.substring(
                                          1,
                                          ref.source_text.length - 1,
                                        )
                                      : ref.source_text
                                    : ref.keyQuote &&
                                        ref.keyQuote.startsWith('*') &&
                                        ref.keyQuote.endsWith('*')
                                      ? ref.keyQuote.substring(
                                          1,
                                          ref.keyQuote.length - 1,
                                        )
                                      : ref.keyQuote}
                                </div>
                              </div>
                            )}

                            {/* タイトルと日付の行 */}
                            {(ref.page_title ||
                              (ref.date &&
                                !isNaN(new Date(ref.date).getTime()))) && (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  marginBottom: '4px',
                                  fontSize: '11px',
                                  color: 'rgba(0, 0, 0, 0.45)',
                                }}
                              >
                                <div
                                  style={{
                                    flex: '1',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    marginRight: '8px',
                                  }}
                                >
                                  {ref.page_title && ref.page_title.length > 26
                                    ? `${ref.page_title.substring(0, 26)}...`
                                    : ref.page_title}
                                </div>
                                <div
                                  style={{
                                    flexShrink: '0',
                                    textAlign: 'right',
                                  }}
                                >
                                  {ref.date &&
                                    !isNaN(new Date(ref.date).getTime()) &&
                                    (() => {
                                      try {
                                        const date = new Date(ref.date);
                                        return `${date.getFullYear()}.${String(date.getMonth() + 1).padStart(2, '0')}.${String(date.getDate()).padStart(2, '0')}`;
                                      } catch (e) {
                                        return null;
                                      }
                                    })()}
                                </div>
                              </div>
                            )}

                            <div
                              className="reference-meta"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                              }}
                            >
                              <img
                                src={`https://www.google.com/s2/favicons?domain=${new URL(ref.url).hostname}`}
                                alt=""
                                className="reference-favicon"
                              />
                              <div style={{ flex: 1 }}>
                                <div className="reference-title">
                                  {new URL(ref.url).hostname}
                                </div>
                              </div>
                              {ref.type &&
                                [
                                  'official',
                                  'government',
                                  'corporation',
                                  'news',
                                  'academic',
                                  'community',
                                ].includes(ref.type) && (
                                  <Tag
                                    color={
                                      ref.type === 'official'
                                        ? '#333333' // 濃い黒
                                        : ref.type === 'government'
                                          ? '#E6F4FF' // 薄い青
                                          : ref.type === 'corporation'
                                            ? '#FFF0F6' // 薄いピンク
                                            : ref.type === 'news'
                                              ? '#F6FFED' // 薄い緑
                                              : ref.type === 'academic'
                                                ? '#FFF7E6' // 薄いオレンジ
                                                : ref.type === 'community'
                                                  ? '#F0E6FF' // 薄い紫
                                                  : undefined
                                    }
                                    style={{
                                      margin: 0,
                                      padding: '0 4px',
                                      fontSize: '10px',
                                      lineHeight: '16px',
                                      border: 'none',
                                      color:
                                        ref.type === 'official'
                                          ? '#FFFFFF' // 白
                                          : ref.type === 'government'
                                            ? '#1677FF' // 濃い青
                                            : ref.type === 'corporation'
                                              ? '#EB2F96' // 濃いピンク
                                              : ref.type === 'news'
                                                ? '#52C41A' // 濃い緑
                                                : ref.type === 'academic'
                                                  ? '#FA8C16' // 濃いオレンジ
                                                  : ref.type === 'community'
                                                    ? '#722ED1' // 濃い紫
                                                    : undefined,
                                    }}
                                  >
                                    {(() => {
                                      const typeMap = {
                                        'official': '公式',
                                        'government': '政府機関',
                                        'corporation': '企業',
                                        'news': 'ニュース',
                                        'academic': '学術',
                                        'community': 'コミュニティ',
                                      };
                                      return typeMap[ref.type] || '';
                                    })()}
                                  </Tag>
                                )}
                              {ref.trustness && (
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '4px',
                                  }}
                                >
                                  <Progress
                                    percent={Math.round(ref.trustness * 100)}
                                    size="small"
                                    showInfo={false}
                                    strokeWidth={4}
                                    style={{ width: '40px' }}
                                    strokeColor={{
                                      '0%': '#722ed1',
                                      '100%': '#eb2f96',
                                    }}
                                  />
                                  <span
                                    style={{
                                      fontSize: '10px',
                                      color: '#eb2f96',
                                      fontWeight: '500',
                                    }}
                                  >
                                    {Math.round(ref.trustness * 100)}%
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </List.Item>
                )}
              />
            </>
          )}
        </div>
      </Drawer>
    );
  }, [
    drawerState,
    task,
    handleDrawerClose,
    handleToggleComplete,
    getBestReferenceLink,
    isTaskInProgress,
    switchLoading,
    screens,
    getFactualityColor,
    updatingFragmentId,
    formatReasonWithLinks,
  ]);

  // Stats Sectionコンポーネント
  const StatsSection = () => (
    <Row gutter={[16, 16]} align="middle" wrap>
      <Col xs={12} sm={6} md={6} lg={6}>
        <div
          style={{
            textAlign: 'center',
            height: screens.xs ? '60px' : '76px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              fontSize: screens.xs ? '12px' : '13px',
              color: 'rgba(0, 0, 0, 0.45)',
              height: screens.xs ? '18px' : '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            文字数
            <Tooltip title="検証対象の原稿の総文字数です。">
              <QuestionCircleOutlined
                style={{
                  marginLeft: '4px',
                  fontSize: '12px',
                  color: 'rgba(0, 0, 0, 0.45)',
                }}
              />
            </Tooltip>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: screens.xs ? '36px' : '46px',
            }}
          >
            <div
              style={{
                fontSize: screens.xs ? '18px' : '24px',
                fontWeight: '500',
                lineHeight: '1',
                display: 'flex',
                alignItems: 'flex-end',
              }}
            >
              <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>
                {animationSourceRef.current === 'none' ? (
                  calculateCharacterCount(editorContent).toLocaleString() || '0'
                ) : (
                  <AnimatedNumber
                    value={calculateCharacterCount(editorContent) || 0}
                    formatter={val => val.toLocaleString()}
                  />
                )}
              </span>
              <span
                style={{
                  fontSize: screens.xs ? '12px' : '14px',
                  marginLeft: '2px',
                  color: 'rgba(0, 0, 0, 0.45)',
                  lineHeight: '1',
                }}
              >
                文字
              </span>
            </div>
          </div>
        </div>
      </Col>
      <Col xs={12} sm={6} md={6} lg={6}>
        <div
          style={{
            textAlign: 'center',
            height: screens.xs ? '60px' : '76px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              fontSize: screens.xs ? '12px' : '13px',
              color: 'rgba(0, 0, 0, 0.45)',
              height: screens.xs ? '18px' : '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            検証項目
            <Tooltip title="AIが検出した検証すべき事実的な記述の数です。">
              <QuestionCircleOutlined
                style={{
                  marginLeft: '4px',
                  fontSize: '12px',
                  color: 'rgba(0, 0, 0, 0.45)',
                }}
              />
            </Tooltip>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: screens.xs ? '36px' : '46px',
            }}
          >
            <div
              style={{
                fontSize: screens.xs ? '18px' : '24px',
                fontWeight: '500',
                lineHeight: '1',
                display: 'flex',
                alignItems: 'flex-end',
              }}
            >
              <span style={{ color: '#8f8f8f', marginRight: '2px' }}>
                {animationSourceRef.current === 'none' ? (
                  stats.completed || 0
                ) : (
                  <AnimatedNumber value={animatedStats.completed || 0} />
                )}
              </span>
              <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>
                {' '}
                /{' '}
                {animationSourceRef.current === 'none' ? (
                  stats.total
                ) : (
                  <AnimatedNumber value={animatedStats.total} />
                )}
              </span>
              <span
                style={{
                  fontSize: screens.xs ? '12px' : '14px',
                  marginLeft: '2px',
                  color: 'rgba(0, 0, 0, 0.45)',
                  lineHeight: '1',
                }}
              >
                件
              </span>
            </div>
          </div>
        </div>
      </Col>
      <Col xs={12} sm={6} md={6} lg={6}>
        <div
          style={{
            textAlign: 'center',
            height: screens.xs ? '60px' : '76px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              fontSize: screens.xs ? '12px' : '13px',
              color: 'rgba(0, 0, 0, 0.45)',
              height: screens.xs ? '18px' : '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            検出ソース
            <Tooltip title="検証に使用された情報源の数です。信頼性の高いソースほど優先的に使用されます。">
              <QuestionCircleOutlined
                style={{
                  marginLeft: '4px',
                  fontSize: '12px',
                  color: 'rgba(0, 0, 0, 0.45)',
                }}
              />
            </Tooltip>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: screens.xs ? '36px' : '46px',
            }}
          >
            <div
              style={{
                fontSize: screens.xs ? '18px' : '24px',
                fontWeight: '500',
                lineHeight: '1',
                display: 'flex',
                alignItems: 'flex-end',
              }}
            >
              <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>
                {animationSourceRef.current === 'none' ? (
                  stats.sourceCount
                ) : (
                  <AnimatedNumber value={animatedStats.sourceCount} />
                )}
              </span>
              <span
                style={{
                  fontSize: screens.xs ? '12px' : '14px',
                  marginLeft: '2px',
                  color: 'rgba(0, 0, 0, 0.45)',
                  lineHeight: '1',
                }}
              >
                件
              </span>
            </div>
          </div>
        </div>
      </Col>
      <Col xs={12} sm={6} md={6} lg={6}>
        <div
          style={{
            textAlign: 'center',
            height: screens.xs ? '60px' : '76px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              fontSize: screens.xs ? '12px' : '13px',
              color: 'rgba(0, 0, 0, 0.45)',
              height: screens.xs ? '18px' : '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            正確性
            <Tooltip title="検証された項目の正確性スコアの平均値です。100に近いほど原稿の事実的な正確性が高いことを示します。">
              <QuestionCircleOutlined
                style={{
                  marginLeft: '4px',
                  fontSize: '12px',
                  color: 'rgba(0, 0, 0, 0.45)',
                }}
              />
            </Tooltip>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Progress
              type="circle"
              percent={
                animationSourceRef.current === 'none'
                  ? stats.accuracy
                  : animatedStats.accuracy
              }
              size={screens.xs ? 36 : 46}
              strokeWidth={screens.xs ? 6 : 8}
              strokeColor={getAccuracyColor(
                animationSourceRef.current === 'none'
                  ? stats.accuracy
                  : animatedStats.accuracy,
              )}
              format={percent => (
                <span
                  style={{
                    fontSize: screens.xs ? '12px' : '15px',
                    fontWeight: 'bold',
                    color: getAccuracyColor(percent),
                  }}
                >
                  {percent}
                </span>
              )}
            />
          </div>
        </div>
      </Col>
    </Row>
  );

  // メインのレンダリング部分を修正
  if (isLoading) {
    return (
      <div className="loading-container">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <Alert
          message="エラーが発生しました"
          description={error}
          type="error"
          showIcon
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>検証結果 | ファクトチェックモード - magicss</title>
        <meta name="robots" content="noindex" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>

      <Layout
        style={{
          minHeight: '100vh',
          background: '#fff',
          padding: screens.md ? '0 24px' : 0,
        }}
      >
        <div className="fact-check-header">
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: '8px' }}
          >
            <Col xs={24} lg={18}>
              <Breadcrumb style={{ marginBottom: 0 }}>
                <Breadcrumb.Item>
                  <Link to="/fact-check">ファクトチェック</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/fact-check/tasks">タスク一覧</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>検証結果</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col
              xs={24}
              lg={6}
              style={{
                display: 'flex',
                justifyContent: screens.lg ? 'flex-end' : 'flex-start',
                gap: '8px',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: screens.lg ? '-24px' : '8px',
                marginBottom: screens.lg ? 0 : '8px',
                ...(screens.lg
                  ? {}
                  : { width: '100%', justifyContent: 'space-between' }),
              }}
            >
              {!isTaskInProgress(task) && (
                <Tooltip
                  title={
                    task?.status === 'finished'
                      ? 'タスクの完了状態を解除します'
                      : 'すべての検証が完了したらクリックしてタスクを完了済みにします'
                  }
                >
                  <Button
                    type={task?.status === 'finished' ? 'primary' : 'default'}
                    onClick={() => handleMarkAsCompleted()}
                    style={{
                      background:
                        task?.status === 'finished' ? '#00d4ff' : undefined,
                      fontSize: '12px',
                      padding: '0 10px',
                      height: '24px',
                      ...(screens.lg
                        ? {}
                        : { flex: '1', justifyContent: 'center' }),
                    }}
                    icon={
                      task?.status === 'finished' ? (
                        <CheckOutlined />
                      ) : undefined
                    }
                    size="small"
                  >
                    {task?.status === 'finished'
                      ? '完了済み'
                      : '完了済みにする'}
                  </Button>
                </Tooltip>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row
                justify="space-between"
                align="middle"
                gutter={[0, { xs: 24, sm: 0 }]}
              >
                <Col xs={24} md={12}>
                  <Title
                    level={2}
                    style={{ margin: 0 }}
                    className="gradient-text"
                  >
                    {getTaskStatusTitle(task)}
                  </Title>
                  <div
                    style={{
                      fontSize: '12px',
                      color: 'rgba(0, 0, 0, 0.45)',
                      marginTop: '16px',
                    }}
                  >
                    <Space wrap>
                      <span>
                        実行日時：
                        {formatDateTime(task?.created_at)}
                      </span>
                      <Divider
                        type="vertical"
                        style={{
                          display: screens.xs ? 'none' : 'inline-block',
                        }}
                      />
                      <span>
                        最終更新：
                        {formatDateTime(task?.updated_at)}
                      </span>
                    </Space>
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <StatsSection />
                </Col>
              </Row>

              {/* 進捗バーの表示条件を修正 */}
              {isTaskInProgress(task) && (
                <Progress
                  id="progress"
                  percent={task?.progress || 0}
                  status="active"
                  style={{ marginTop: 16 }}
                  format={percent =>
                    task?.status === 'queued' ? (
                      <span style={{ fontSize: '14px', color: '#108ee9' }}>
                        検証予約中
                      </span>
                    ) : (
                      `${percent}%`
                    )
                  }
                />
              )}
            </Col>
          </Row>
        </div>

        <Divider style={{ margin: '24px 0' }} />

        <div className="fact-check-content">
          <PanelGroup
            direction={screens.md ? 'horizontal' : 'vertical'}
            autoSaveId="fact-check-panels"
            defaultSizes={[60, 40]}
          >
            <Panel
              defaultSize={60}
              minSize={40}
              style={{
                overflow: screens.md ? 'hidden' : 'visible',
                ...(screens.md ? {} : { minHeight: '400px', flex: 'none' }),
              }}
            >
              <div className="editor-panel">
                <Editor
                  initialData={editorContent}
                  onReady={handleEditorReady}
                  readOnly={isTaskInProgress(task) || !screens.md}
                  config={{
                    holder: 'fact-check-editor',
                    minHeight: screens.md ? 0 : 350,
                    placeholder: '本文を入力',
                    inlineToolbar: screens.md ? ['link'] : [],
                    tools: screens.md ? undefined : {},
                  }}
                />
              </div>
            </Panel>

            <PanelResizeHandle className="react-resizable-panels-handle" />

            <Panel
              defaultSize={40}
              minSize={30}
              style={{
                ...(screens.md ? {} : { minHeight: '350px', flex: 'none' }),
              }}
            >
              <div className="results-panel">
                <FilterSection />

                <Affix
                  offsetTop={24}
                  className="results-panel-affix"
                  style={{
                    display: screens.md ? 'block' : 'none',
                  }}
                >
                  <Card
                    bordered={false}
                    bodyStyle={{
                      padding: 0,
                      maxHeight: screens.md ? cardMaxHeight : 'calc(50vh)',
                      minHeight: screens.md ? 'auto' : '250px',
                      overflow: 'auto',
                      transition: 'max-height 0.1s linear', // より速くリニアな変化に
                    }}
                  >
                    <div className="fragments-list">
                      {getFilteredAndSortedFragments().map(fragment => {
                        // displayedFragmentsに存在するものだけを表示
                        const displayFragment = displayedFragments.find(
                          d => d.id === fragment.id,
                        );
                        if (!displayFragment) return null; // まだ表示順が来ていないものは表示しない

                        // 重要: 検証済みかチェック
                        const isVerified =
                          fragment.factuality !== undefined &&
                          fragment.factuality !== null;

                        // 未検証なら表示しない（タイピング中は例外）
                        if (!isVerified && !displayFragment.isTyping)
                          return null;

                        return (
                          <FragmentCard
                            key={fragment.id}
                            fragment={{
                              ...fragment,
                              isTyping: displayFragment.isTyping || false,
                              isComplete: displayFragment.isComplete || false,
                              isInteractive:
                                displayFragment.isInteractive || false,
                            }}
                            onTypingComplete={onTypingComplete}
                            handleFragmentSelect={handleFragmentSelect}
                            handleHeaderHover={handleHeaderHover}
                          />
                        );
                      })}
                      {/* デバッグ情報を追加 */}
                      {console.log(
                        `[DEBUG-SKELETON] isInitialLoading: ${isInitialLoading}, isTaskInProgress: ${isTaskInProgress(task)}, task?.status: ${task?.status}, forceHideSkeleton: ${forceHideSkeleton}, task詳細:`,
                        task,
                      )}
                      {!forceHideSkeleton &&
                        (isInitialLoading || isTaskInProgress(task)) &&
                        task?.status !== 'completed' &&
                        task?.status !== 'finished' && (
                          <>
                            {console.log(
                              '[DEBUG-SKELETON] スケルトンカードを表示します. 条件判定結果:',
                              {
                                isInitialLoading,
                                taskInProgress: isTaskInProgress(task),
                                notCompleted: task?.status !== 'completed',
                                notFinished: task?.status !== 'finished',
                                notForceHide: !forceHideSkeleton,
                                taskStatus: task?.status,
                              },
                            )}
                            <FragmentSkeleton count={1} />
                          </>
                        )}
                    </div>
                  </Card>
                  <Row
                    gutter={[8, 8]}
                    style={{
                      marginTop: '16px',
                      marginBottom: screens.md ? 0 : '80px',
                    }}
                  >
                    {/* タスクが進行中（実行中や予約中）でない場合のみ表示 */}
                    {!isTaskInProgress(task) && task?.status !== 'queued' && (
                      <>
                        <Col xs={24} sm={8}>
                          <Tooltip title="現在の原稿内容をサーバーに保存します。定期的に保存することをお勧めします。">
                            <Button
                              type="primary"
                              icon={<SaveOutlined />}
                              onClick={handleOriginalSave}
                              loading={isSaving}
                              style={{
                                width: '100%',
                                marginBottom: screens.sm ? 0 : 8,
                              }}
                            >
                              原稿を保存
                            </Button>
                          </Tooltip>
                        </Col>
                        <Col xs={24} sm={8}>
                          <Tooltip title="検証結果を含めたWordファイルをダウンロードします。コメント形式で検証結果が記載されます。">
                            <Button
                              icon={<FileWordOutlined />}
                              onClick={handleWordExport}
                              loading={isExporting}
                              style={{
                                width: '100%',
                                marginBottom: screens.sm ? 0 : 8,
                              }}
                            >
                              Word出力
                            </Button>
                          </Tooltip>
                        </Col>
                        <Col xs={24} sm={8}>
                          <Tooltip title="原稿のHTMLコードをクリップボードにコピーします。Webサイトへの掲載時に便利です。">
                            <Button
                              icon={<CopyOutlined />}
                              onClick={handleHtmlCopy}
                              style={{ width: '100%' }}
                            >
                              HTMLコピー
                            </Button>
                          </Tooltip>
                        </Col>
                      </>
                    )}
                    {/* タスクが進行中や予約中の場合に表示するメッセージ */}
                    {(isTaskInProgress(task) || task?.status === 'queued') && (
                      <Col xs={24}>
                        <Alert
                          type="info"
                          message="検証完了後に原稿の保存やエクスポートが可能になります"
                          banner
                          style={{ marginBottom: '16px' }}
                        />
                      </Col>
                    )}
                  </Row>
                </Affix>

                {/* ワンカラム表示時のみ表示する通常カード */}
                {!screens.md && (
                  <Card
                    bordered={false}
                    bodyStyle={{
                      padding: 0,
                      maxHeight: 'calc(50vh)',
                      minHeight: '250px',
                      overflow: 'auto',
                    }}
                  >
                    <div className="fragments-list">
                      {getFilteredAndSortedFragments().map(fragment => {
                        // displayedFragmentsに存在するものだけを表示
                        const displayFragment = displayedFragments.find(
                          d => d.id === fragment.id,
                        );
                        if (!displayFragment) return null; // まだ表示順が来ていないものは表示しない
                        return (
                          <FragmentCard
                            key={fragment.id}
                            fragment={{
                              ...fragment,
                              isUpdating: updatingFragments.includes(
                                fragment.id,
                              ),
                            }}
                            task={task}
                            isInteractive={!isTaskInProgress(task)}
                            onCardClick={() => handleFragmentClick(fragment)}
                            onMouseEnter={
                              fragment.block_position?.ids
                                ? () =>
                                    handleHeaderHover(
                                      true,
                                      fragment.block_position.ids,
                                      false,
                                    )
                                : undefined
                            }
                            onMouseLeave={
                              fragment.block_position?.ids
                                ? () => handleHeaderHover(false)
                                : undefined
                            }
                          />
                        );
                      })}
                    </div>
                  </Card>
                )}
              </div>
            </Panel>
          </PanelGroup>
        </div>
      </Layout>
      <FragmentDrawer />
    </>
  );
};

export default FactCheckDetail;
