export const calculateCharacterCount = content => {
  if (!content || !content.blocks) return 0;

  return content.blocks.reduce((total, block) => {
    switch (block.type) {
      case 'paragraph':
      case 'header': {
        // HTMLタグを除去して純粋なテキストの長さをカウント
        const text = block.data.text.replace(/<[^>]*>/g, '');
        return total + text.length;
      }
      case 'list': {
        // リストアイテムのテキストを合計
        const listLength = block.data.items.reduce((sum, item) => {
          const itemText =
            typeof item === 'object'
              ? (item.content || '').replace(/<[^>]*>/g, '')
              : (item || '').replace(/<[^>]*>/g, '');
          return sum + itemText.length;
        }, 0);
        return total + listLength;
      }
      case 'table': {
        // テーブルセルのテキストを合計
        const tableLength = block.data.content.reduce((sum, row) => {
          return (
            sum +
            row.reduce((cellSum, cell) => {
              const cellText = (cell || '').replace(/<[^>]*>/g, '');
              return cellSum + cellText.length;
            }, 0)
          );
        }, 0);
        return total + tableLength;
      }
      default:
        return total;
    }
  }, 0);
};

// HTMLコンテンツの文字数をカウントする関数
export const calculateHtmlCharacterCount = htmlContent => {
  if (!htmlContent) return 0;

  // 1. テーブルの処理 - テーブルタグを一時的に置換して保護
  let processedContent = htmlContent;
  const tableRegex = /<table[^>]*>[\s\S]*?<\/table>/gi;
  const tables = [];
  processedContent = processedContent.replace(tableRegex, match => {
    tables.push(match);
    return `__TABLE_${tables.length - 1}__`;
  });

  // 2. リストの処理 - リストタグを一時的に置換して保護
  const listRegex = /<(ul|ol)[^>]*>[\s\S]*?<\/(ul|ol)>/gi;
  const lists = [];
  processedContent = processedContent.replace(listRegex, match => {
    lists.push(match);
    return `__LIST_${lists.length - 1}__`;
  });

  // 3. 空の段落や連続した改行を削除
  processedContent = processedContent.replace(/<p>\s*<\/p>/gi, '');
  processedContent = processedContent.replace(/(\r\n|\n|\r)+/gm, '\n');

  // 4. テーブルを処理
  let tableCharCount = 0;
  tables.forEach(table => {
    const cellRegex = /<td[^>]*>([\s\S]*?)<\/td>/gi;
    let match;
    while ((match = cellRegex.exec(table)) !== null) {
      const cellContent = match[1].replace(/<[^>]*>/g, '').trim();
      tableCharCount += cellContent.length;
    }
  });

  // 5. リストを処理
  let listCharCount = 0;
  lists.forEach(list => {
    const itemRegex = /<li[^>]*>([\s\S]*?)<\/li>/gi;
    let match;
    while ((match = itemRegex.exec(list)) !== null) {
      const itemContent = match[1].replace(/<[^>]*>/g, '').trim();
      listCharCount += itemContent.length;
    }
  });

  // 6. テーブルとリストのプレースホルダーを空に置換
  processedContent = processedContent.replace(/__TABLE_\d+__/g, '');
  processedContent = processedContent.replace(/__LIST_\d+__/g, '');

  // 7. 残りのHTMLタグを削除
  const textWithoutTags = processedContent.replace(/<[^>]*>/g, '');

  // 8. 連続した空白を1つに置換
  const cleanText = textWithoutTags.replace(/\s+/g, ' ').trim();

  // 9. 合計文字数を計算
  return cleanText.length + tableCharCount + listCharCount;
};
