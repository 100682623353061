import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://stg.magicss.ai';

export const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }
};

export const getPlans = async () => {
  try {
    const response = await api.get('/plans/');
    return response.data;
  } catch (error) {
    console.error('Failed to get plans:', error);
    throw error;
  }
};

export const createCheckoutSession = async (planId, isYearly, discountedPrice) => {
  try {
    const response = await api.post('/subscriptions/create-checkout-session', { 
      plan_id: planId, 
      is_yearly: isYearly,
      discounted_price: discountedPrice
    });
    return response.data;
  } catch (error) {
    console.error('Failed to create checkout session:', error);
    throw error;
  }
};

export const cancelSubscription = async () => {
  try {
    const response = await api.post('/subscriptions/cancel-subscription');
    return response.data;
  } catch (error) {
    console.error('Failed to cancel subscription:', error);
    throw error;
  }
};

export const getCurrentSubscription = async () => {
  try {
    const response = await api.get('/subscriptions/current-subscription');
    return response.data;
  } catch (error) {
    console.error('Failed to get current subscription:', error);
    throw error;
  }
};

export const createPortalSession = async () => {
  try {
    const response = await api.post('/subscriptions/create-portal-session');
    return response.data;
  } catch (error) {
    console.error('Failed to create portal session:', error);
    throw error;
  }
};

export const calculateProratedAmount = async (currentPlanId, newPlanId, isYearly) => {
  try {
    const response = await api.post('/subscriptions/calculate-prorated-amount', {
      current_plan_id: currentPlanId,
      new_plan_id: newPlanId,
      is_yearly: isYearly
    });
    return response.data;
  } catch (error) {
    console.error('Failed to calculate prorated amount:', error);
    throw error;
  }
};

export const cancelPendingPlanChange = async () => {
  try {
    await api.post('/subscriptions/cancel-pending-plan-change');
  } catch (error) {
    console.error('Failed to cancel pending plan change:', error);
  }
};

