import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button, message, Typography, Tag, Tabs, Table, Spin, Select, InputNumber, DatePicker, Divider } from 'antd';
import { SearchOutlined, SyncOutlined, ExportOutlined } from '@ant-design/icons';
import './UserDetail.css';
import axios from 'axios';
import moment from 'moment-timezone';
import { getUserRole } from '../../auth';

const { Text } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;

const UserDetail = ({ visible, onCancel, userId, onUpdate }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [articles, setArticles] = useState([]);
  const [recommendedKeywords, setRecommendedKeywords] = useState({ count: 0, lastUpdated: null });
  const [currentUserRole, setCurrentUserRole] = useState(null);

  useEffect(() => {
    const fetchUserRole = async () => {
      const role = await getUserRole();
      setCurrentUserRole(role);
    };

    fetchUserRole();
  }, []);

  useEffect(() => {
    if (visible && userId) {
      fetchUserDetails();
      fetchUserArticles();
      fetchRecommendedKeywords();
    }
  }, [visible, userId]);

  const fetchUserDetails = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      setUserData(data);
      form.setFieldsValue({
        ...data,
        plan: data.plan?.name,
        next_update_date: data.next_update_date ? moment(data.next_update_date) : null,
      });
    } catch (error) {
      console.error('Failed to fetch user details:', error);
      message.error('Failed to fetch user details');
    } finally {
      setLoading(false);
    }
  };

  const fetchUserArticles = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`http://localhost:8000/seo-article/list?user_id=${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setArticles(response.data);
    } catch (error) {
      console.error('Failed to fetch user articles:', error);
      message.error('Failed to fetch user articles');
    }
  };

  const fetchRecommendedKeywords = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`http://localhost:8000/seo-article/keywords-recommend?user_id=${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRecommendedKeywords({
        count: response.data.keywords.length,
        lastUpdated: response.data.recommended_keywords_update_at,
      });
    } catch (error) {
      console.error('Failed to fetch recommended keywords:', error);
      message.error('Failed to fetch recommended keywords');
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const updatedValues = {
        ...values,
        next_update_date: values.next_update_date ? values.next_update_date.format('YYYY-MM-DD') : null,
      };
      await axios.put(`${process.env.REACT_APP_API_URL}/users/${userId}`, updatedValues, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      message.success('ユーザー情報を更新しました');
      onUpdate();
      fetchUserDetails();
    } catch (error) {
      console.error('Failed to update user details:', error);
      message.error('Failed to update user details');
    }
    setLoading(false);
  };

  const updateRecommendedKeywords = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`http://localhost:8000/seo-article/update-recommended-keywords/${userId}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      message.success('おすすめキーワードを更新しました');
      fetchRecommendedKeywords();
    } catch (error) {
      console.error('Failed to update recommended keywords:', error);
      message.error('Failed to update recommended keywords');
    }
  };

  const roleTags = {
    user: { color: 'blue', text: 'User' },
    parent: { color: 'green', text: 'Parent' },
    admin: { color: 'volcano', text: 'Admin' },
    master: { color: 'red', text: 'Master' },
  };

  const planTags = {
    free: { color: 'blue', text: 'フリープラン' },
    standard: { color: 'green', text: 'スタンダードプラン' },
    premium: { color: 'gold', text: 'プレミアムプラン' },
    custom: { color: 'purple', text: 'カスタムプラン' },
  };

  const isEditable = (field) => {
    if (currentUserRole === 'master') {
      return ['company_name', 'department_name', 'email', 'last_name', 'first_name', 'last_name_kana', 'first_name_kana', 'plan', 'ticket_count', 'next_update_date', 'stripe_id', 'note'].includes(field);
    }
    if (currentUserRole === 'admin' && field === 'note') return true;
    return false;
  };

  const getStatusColor = (status) => {
    if (status.includes('予約中') || status.includes('更新予約中')) return 'orange';
    if (status.includes('中') || status.includes('更新中')) return 'green';
    if (status === '生成済み') return 'blue';
    if (status === '生成失敗') return 'red';
    return 'default';
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
  });

  const articleColumns = [
    {
      title: 'ステータス',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: '予約中', value: '予約中' },
        { text: '更新予約中', value: '更新予約中' },
        { text: '生成中', value: '生成中' },
        { text: '更新中', value: '更新中' },
        { text: '生成済み', value: '生成済み' },
        { text: '生成失敗', value: '生成失敗' },
      ],
      onFilter: (value, record) => record.status.includes(value),
      render: (status, record) => (
        <>
          <Tag color={getStatusColor(status)}>
            {status.split('（')[0]}
          </Tag>
          {status.includes('（') && (
            <span style={{ marginLeft: '5px', fontSize: '12px', color: '#888' }}>
              {status.split('（')[1].replace('）', '')}
            </span>
          )}
        </>
      ),
    },
    {
      title: 'キーワード',
      dataIndex: 'keyword',
      key: 'keyword',
      ...getColumnSearchProps('keyword'),
    },
    {
      title: '生成実行日',
      dataIndex: 'execution_date',
      key: 'execution_date',
      sorter: (a, b) => new Date(a.execution_date) - new Date(b.execution_date),
      render: (text, record) => {
        if (record.status.includes('予約中')) {
          return record.estimated_wait_time != null ? `予想待ち時間: ${record.estimated_wait_time}分` : '予想待ち時間: 計算中';
        }
        return text ? moment.utc(text).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm') : '-';
      },
    },
    {
      title: '完了日',
      dataIndex: 'completed_at',
      key: 'completed_at',
      sorter: (a, b) => new Date(a.completed_at) - new Date(b.completed_at),
      render: (text, record) => {
        if (record.status === '生成済み' || record.status === '生成失敗') {
          return text ? moment.utc(text).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm') : '-';
        }
        return '-';
      },
    },
  ];

  const userInfoColumns = [
    {
      title: '項目',
      dataIndex: 'item',
      key: 'item',
      render: (text) => <Text style={{ fontSize: '12px' }}>{text}</Text>,
    },
    {
      title: '内容',
      dataIndex: 'value',
      key: 'value',
      render: (text, record) => record.value,
    },
  ];

  const userInfoData = [
    { 
      key: 'company_and_department', 
      item: '会社名/部署', 
      value: (
        <>
          <Form.Item name="company_name" style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px 0 0' }}>
            <Input disabled={!isEditable('company_name')} />
          </Form.Item>
          <Form.Item name="department_name" style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0' }}>
            <Input disabled={!isEditable('department_name')} />
          </Form.Item>
        </>
      )
    },
    { 
      key: 'name', 
      item: '氏名', 
      value: (
        <>
          <Form.Item name="last_name" style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px 0 0' }}>
            <Input disabled={!isEditable('last_name')} />
          </Form.Item>
          <Form.Item name="first_name" style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0' }}>
            <Input disabled={!isEditable('first_name')} />
          </Form.Item>
        </>
      )
    },
    { 
      key: 'name_kana', 
      item: '氏名(カタカナ)', 
      value: (
        <>
          <Form.Item name="last_name_kana" style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px 0 0' }}>
            <Input disabled={!isEditable('last_name_kana')} />
          </Form.Item>
          <Form.Item name="first_name_kana" style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0' }}>
            <Input disabled={!isEditable('first_name_kana')} />
          </Form.Item>
        </>
      )
    },
    { key: 'email', item: 'Email', value: <Form.Item name="email" style={{ margin: '0' }}><Input disabled={!isEditable('email')} /></Form.Item> },
    { 
      key: 'plan', 
      item: 'プラン', 
      value: (
        <Form.Item name="plan" style={{ margin: '0' }}>
          <Select disabled={!isEditable('plan')}>
            <Option value="free">フリープラン</Option>
            <Option value="standard">スタンダードプラン</Option>
            <Option value="premium">プレミアムプラン</Option>
            <Option value="custom">カスタムプラン</Option>
          </Select>
        </Form.Item>
      )
    },
    { 
      key: 'ticket_count', 
      item: 'チケット', 
      value: <Form.Item name="ticket_count" style={{ margin: '0' }}><InputNumber disabled={!isEditable('ticket_count')} /></Form.Item> 
    },
    { 
      key: 'next_update_date', 
      item: '次回更新日', 
      value: <Form.Item name="next_update_date" style={{ margin: '0' }}><DatePicker disabled={!isEditable('next_update_date')} /></Form.Item> 
    },
    { 
      key: 'stripe_id', 
      item: 'StripeID', 
      value: <Form.Item name="stripe_id" style={{ margin: '0' }}><Input disabled={!isEditable('stripe_id')} /></Form.Item> 
    },
    { key: 'registered_at', item: '登録日', value: userData?.registered_at ? moment.utc(userData.registered_at).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm') : '-' },
    { key: 'last_login_at', item: '最終ログイン', value: userData?.last_login_at ? moment.utc(userData.last_login_at).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm') : '-' },
    { 
      key: 'note', 
      item: '備考', 
      value: <Form.Item name="note" style={{ margin: '0' }}><TextArea rows={4} disabled={!isEditable('note')} /></Form.Item> 
    },
  ];

  if (!userData) {
    return null;
  }

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      centerd
      width={1000}
      className='u_detail_modal'
    >
      <Spin spinning={loading}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
          <div style={{ flex: 5 }}>
            <Text strong>{userData.company_name} {userData.department_name}</Text>
            <br />
            <Text>{userData.last_name} {userData.first_name}</Text>
            <Text type="secondary" style={{ fontSize: 12 }}> ({userData.last_name_kana} {userData.first_name_kana})</Text>
          </div>
          <div style={{ flex: 5 }}>
            <Text>{userData.email}</Text>
            <br />
            <Text type="secondary" style={{ fontSize: 12 }}>ID: {userData.user_id}</Text>
          </div>
          <div style={{ flex: 3 }}>
          <Tag color={roleTags[userData.role]?.color}>{roleTags[userData.role]?.text}</Tag>
            <Tag color={planTags[userData.plan?.name || 'free']?.color}>
              {planTags[userData.plan?.name || 'free']?.text}
            </Tag>
          </div>
        </div>

        <Divider />

        <Tabs 
          tabPosition="left" 
          tabBarStyle={{ background: 'none', width: '120px' }}
          style={{ height: 'auto' }}
        >
          <TabPane tab="基本情報" key="1">
            <Form form={form} onFinish={onFinish} layout="vertical" id="u_detail">
              <Table
                columns={userInfoColumns}
                dataSource={userInfoData}
                pagination={false}
                showHeader={false}
                size="small"
              />
              {(currentUserRole === 'master' || currentUserRole === 'admin') && (
                <Form.Item style={{ marginTop: '16px', textAlign: 'right' }}>
                  <Button type="primary" htmlType="submit">
                    更新
                  </Button>
                </Form.Item>
              )}
            </Form>
          </TabPane>
          <TabPane tab="分析" key="2">
            <p>分析データはまだ利用できません。</p>
          </TabPane>
          <TabPane tab="生成履歴" key="3">
            <Button
                icon={<ExportOutlined />} 
                onClick={() => window.open(`/seo-article/list/${userId}`, '_blank')}
                style={{marginBottom: '24px'}}
              >
                生成履歴ページを開く
            </Button>
            <Table
              columns={articleColumns}
              dataSource={articles}
              rowKey="id"
              pagination={{ 
                pageSize: 5,
                position: ['bottomLeft']
              }}
              onRow={(record) => ({
                onClick: () => {
                  if (record.status === '生成済み') {
                    window.open(`/seo-article/detail/${record.uuid}`, '_blank');
                  }
                },
                style: { cursor: record.status === '生成済み' ? 'pointer' : 'default' }
              })}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '16px' }}>
              <div>
                <Text>おすすめキーワード：{recommendedKeywords.count}件</Text>
                <Text style={{ marginLeft: '16px' }}>
                  （最終更新日時：{recommendedKeywords.lastUpdated ? moment(recommendedKeywords.lastUpdated).format('YYYY/MM/DD HH:mm') : '未更新'}）
                </Text>
                <Button 
                  icon={<SyncOutlined />} 
                  onClick={updateRecommendedKeywords}
                  style={{ marginLeft: '16px' }}
                >
                  更新する
                </Button>
              </div>
            </div>
          </TabPane>
          <TabPane tab="決済履歴" key="4">
            <p>決済履歴はまだ利用できません。</p>
          </TabPane>
        </Tabs>
      </Spin>
    </Modal>
  );
};

export default UserDetail;