import React, { useState, useRef, useEffect } from 'react';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Paragraph from '@editorjs/paragraph';
import Table from '@editorjs/table';
import Link from '@editorjs/link';
import Quote from '@editorjs/quote';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Undo from 'editorjs-undo';
import './editor-custom.css';

// parseHtml関数をエクスポート
export const parseHtml = (html, initialData) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const blocks = [];

  // SEO記事のタイトルがある場合、H1ブロックとして追加
  if (initialData?.article?.title) {
    blocks.push({
      type: 'header',
      data: {
        text: initialData.article.title,
        level: 1,
      },
    });
  }

  const processedNodes = new WeakSet();

  // インライン要素を処理する関数
  const processInlineElement = node => {
    if (!node) return '';
    if (node.nodeType === Node.TEXT_NODE) {
      return node.textContent.trim();
    }
    if (node.nodeType === Node.ELEMENT_NODE) {
      if (node.tagName === 'A') {
        const href = node.getAttribute('href');
        const text = node.textContent.trim();
        if (!text) return '';
        return `<a href="${href}">${text}</a>`;
      }
      if (['STRONG', 'EM', 'B', 'I', 'MARK', 'SPAN'].includes(node.tagName)) {
        const text = Array.from(node.childNodes)
          .map(child => processInlineElement(child))
          .join('')
          .trim();
        if (!text) return '';
        return text;
      }
      if (node.tagName === 'BR') return '\n';
    }
    return Array.from(node.childNodes)
      .map(child => processInlineElement(child))
      .join('')
      .trim();
  };

  // 親チェーンの取得
  const getParentChain = node => {
    const chain = [];
    let current = node.parentElement;
    while (current) {
      chain.push({
        tagName: current.tagName,
        className: current.className,
        isProcessed: processedNodes.has(current),
      });
      current = current.parentElement;
    }
    return chain;
  };

  // 要素が意味のあるコンテンツを持つか確認
  const hasContent = node => {
    const text = processInlineElement(node);
    return text.length > 0;
  };

  // 要素が処理対象のブロックタグかどうか確認
  const isTargetBlock = node => {
    if (node.nodeType !== Node.ELEMENT_NODE) return false;
    const blockTags = [
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'p',
      'ul',
      'ol',
      'table',
      'blockquote',
    ];
    return blockTags.includes(node.tagName.toLowerCase());
  };

  // 要素内に処理対象のブロック要素が含まれているか確認
  const containsTargetBlock = node => {
    if (!node.children) return false;
    return Array.from(node.children).some(
      child => isTargetBlock(child) || containsTargetBlock(child),
    );
  };

  // ブロック要素を処理する関数
  const processBlockElement = node => {
    if (!node || processedNodes.has(node)) return null;

    const tagName = node.tagName.toLowerCase();
    let block = null;

    // 見出しの処理
    if (/^h[1-6]$/.test(tagName)) {
      const text = processInlineElement(node);
      if (text) {
        block = {
          type: 'header',
          data: { text, level: parseInt(tagName[1]) },
        };
      }
    }
    // 段落の処理
    else if (tagName === 'p') {
      const text = processInlineElement(node);
      if (text) {
        block = {
          type: 'paragraph',
          data: { text },
        };
      }
    }
    // リストの処理
    else if (tagName === 'ul' || tagName === 'ol') {
      const items = Array.from(node.children)
        .filter(item => item.tagName === 'LI')
        .map(item => processInlineElement(item))
        .filter(text => text.trim());

      if (items.length > 0) {
        block = {
          type: 'list',
          data: {
            style: tagName === 'ul' ? 'unordered' : 'ordered',
            items,
          },
        };
      }
    }
    // テーブルの処理
    else if (tagName === 'table') {
      const rows = Array.from(node.rows).map(row =>
        Array.from(row.cells).map(cell => processInlineElement(cell)),
      );

      if (rows.length > 0 && rows[0].length > 0) {
        block = {
          type: 'table',
          data: {
            withHeadings: node.querySelector('thead') !== null,
            content: rows,
          },
        };
      }
    }
    // 引用の処理
    else if (tagName === 'blockquote') {
      const text = processInlineElement(node);
      if (text) {
        block = {
          type: 'quote',
          data: {
            text,
            caption: '',
            alignment: 'left',
          },
        };
      }
    }

    if (block) {
      processedNodes.add(node);
      blocks.push(block);
    }

    return block;
  };

  // メインの処理関数
  const processNode = node => {
    if (!node) {
      return;
    }

    if (processedNodes.has(node)) {
      return;
    }

    // 要素ノードでない場合はスキップ
    if (node.nodeType !== Node.ELEMENT_NODE) {
      return;
    }

    const tagName = node.tagName.toLowerCase();
    const blockTags = [
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'p',
      'ul',
      'ol',
      'table',
      'blockquote',
    ];

    // ブロック要素の処理
    if (blockTags.includes(tagName)) {
      const text = processInlineElement(node);
      if (text && !processedNodes.has(node)) {
        processBlockElement(node);
      }
    }

    // 子要素の処理
    Array.from(node.children).forEach(child => {
      if (!processedNodes.has(child)) {
        processNode(child);
      }
    });
  };

  // body以下の要素を処理
  Array.from(doc.body.children).forEach(node => processNode(node));

  return blocks;
};

const Editor = ({ onChange, initialData, onReady, config }) => {
  const editorInstance = useRef(null);
  const undoInstance = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const onChangeRef = useRef(onChange);
  const containerRef = useRef(null);

  // ResizeObserverの警告を抑制
  useEffect(() => {
    const originalError = console.error;
    console.error = (...args) => {
      if (args[0]?.includes?.('ResizeObserver')) {
        return;
      }
      originalError.apply(console, args);
    };

    return () => {
      console.error = originalError;
    };
  }, []);

  const processTableNode = node => {
    const processCell = cell => {
      // セル内のすべてのノードを処理
      const processContent = node => {
        if (node.nodeType === Node.TEXT_NODE) {
          return node.textContent;
        }

        if (node.nodeType === Node.ELEMENT_NODE) {
          if (node.tagName === 'A') {
            // リンクの属性をすべて保持
            const attrs = Array.from(node.attributes)
              .map(attr => `${attr.name}="${attr.value}"`)
              .join(' ');
            return `<a ${attrs}>${node.textContent}</a>`;
          }
          if (node.tagName === 'BR') {
            return '<br>';
          }
          // その他の要素は子ノードを再帰的に処理
          return Array.from(node.childNodes)
            .map(child => processContent(child))
            .join('');
        }

        return '';
      };

      // セル内のすべてのコンテンツを処理
      return Array.from(cell.childNodes)
        .map(node => processContent(node))
        .join('');
    };

    // テーブルの行を処理
    const rows = Array.from(node.rows).map(row => {
      // 各セルを処理
      return Array.from(row.cells).map(cell => processCell(cell));
    });

    return {
      type: 'table',
      data: {
        withHeadings: node.querySelector('thead') !== null,
        content: rows,
      },
    };
  };

  // エディタの初期化
  useEffect(() => {
    // ヘッダーレベルを推測する関数
    const inferHeaderLevel = text => {
      if (!text) return 2;
      if (text.length > 20 && !/^\d+\./.test(text)) return 1;
      if (/^\d+\./.test(text)) return 2;
      if (/^[・◆]/.test(text) || text.length < 15) return 3;
      return 2;
    };

    const processedData = {
      blocks:
        initialData?.blocks?.map(block => {
          if (block.type === 'header') {
            return {
              ...block,
              data: {
                ...block.data,
                level: block.data.level || inferHeaderLevel(block.data.text),
              },
            };
          }
          return block;
        }) || [],
    };

    const editor = new EditorJS({
      holder: config?.holder || 'editorjs',
      tools: {
        header: {
          class: Header,
          config: {
            levels: [1, 2, 3, 4],
            defaultLevel: 2,
            placeholder: '見出しを入力',
          },
          shortcut: 'CMD+SHIFT+H',
          inlineToolbar: true,
          toolbox: {
            icon: '<svg>...</svg>',
            title: '見出し',
          },
          save: blockContent => {
            const header = blockContent.querySelector('.ce-header');
            const level = header
              ? parseInt(header.getAttribute('data-level')) ||
                parseInt(header.tagName.replace('H', ''))
              : 2;
            const text = blockContent.textContent.trim();
            return {
              text,
              level: level || inferHeaderLevel(text),
            };
          },
          render: data => {
            const level = data.level || inferHeaderLevel(data.text);
            const wrapper = document.createElement('div');
            const header = document.createElement(`h${level}`);
            header.classList.add('ce-header');
            header.setAttribute('data-level', level.toString());
            header.contentEditable = true;
            header.innerHTML = data.text || '';
            wrapper.appendChild(header);
            return wrapper;
          },
        },
        paragraph: {
          class: Paragraph,
          config: {
            preserveBlank: true,
            placeholder: '本文を入力',
          },
          toolbox: {
            icon: '<svg>...</svg>',
            title: '段落',
          },
          convertTo: ['header', 'list'],
          sanitize: {
            text: {
              b: true,
              i: true,
              a: {
                href: true,
                target: true,
                rel: true,
              },
            },
          },
        },
        list: {
          class: List,
          config: {
            defaultStyle: 'unordered',
          },
          toolbox: {
            icon: '<svg>...</svg>',
            title: 'リスト',
          },
          convertTo: ['paragraph', 'header'],
          sanitize: {
            style: false,
            items: true,
          },
        },
        table: {
          class: Table,
          config: {
            rows: 2,
            cols: 3,
            withHeadings: true,
          },
          toolbox: {
            title: 'テーブル',
          },
        },
        quote: {
          class: Quote,
          config: {
            quotePlaceholder: '引用文を入力',
            captionPlaceholder: '引用元を入力',
          },
          toolbox: {
            title: '引用',
          },
        },
        linkTool: {
          class: Link,
          config: {
            endpoint: 'http://localhost:8008/fetchUrl',
          },
        },
      },
      data: processedData,
      onChange: async () => {
        try {
          if (editorInstance.current && editorInstance.current.save) {
            const savedData = await editorInstance.current.save();
            savedData.blocks = savedData.blocks.map(block => {
              if (block.type === 'header') {
                const element = document.querySelector(
                  `[data-id="${block.id}"] .ce-header`,
                );
                if (element) {
                  const level =
                    parseInt(element.getAttribute('data-level')) ||
                    parseInt(element.tagName.replace('H', '')) ||
                    inferHeaderLevel(block.data.text);
                  block.data.level = level;
                  element.setAttribute('data-level', level.toString());
                  if (element.tagName.toLowerCase() !== `h${level}`) {
                    const newHeader = document.createElement(`h${level}`);
                    newHeader.innerHTML = element.innerHTML;
                    newHeader.className = element.className;
                    newHeader.setAttribute('data-level', level.toString());
                    element.parentNode.replaceChild(newHeader, element);
                  }
                }
              }
              return block;
            });
            onChangeRef.current(savedData);
          }
        } catch (error) {
          // エラーは無視
        }
      },
      onReady: () => {
        editorInstance.current = editor;
        window._editor = editor;

        const setupHeaders = async () => {
          try {
            const savedData = await editor.save();
            const headerBlocks = savedData.blocks.filter(
              block => block.type === 'header',
            );

            const promises = headerBlocks.map(async block => {
              const element = document.querySelector(
                `[data-id="${block.id}"] .ce-header`,
              );
              if (element) {
                const level =
                  block.data.level || inferHeaderLevel(block.data.text);
                element.setAttribute('data-level', level.toString());
                if (element.tagName.toLowerCase() !== `h${level}`) {
                  const newHeader = document.createElement(`h${level}`);
                  newHeader.innerHTML = element.innerHTML;
                  newHeader.className = element.className;
                  newHeader.setAttribute('data-level', level.toString());
                  element.parentNode.replaceChild(newHeader, element);
                }
              }
            });

            await Promise.all(promises);
            const finalData = await editor.save();
            onChangeRef.current(finalData);
            setIsLoading(false);
            if (onReady) {
              onReady();
            }
          } catch (error) {
            setIsLoading(false);
            if (onReady) {
              onReady();
            }
          }
        };

        setupHeaders();

        setTimeout(async () => {
          try {
            if (!editorInstance.current) return;

            undoInstance.current = new Undo({
              editor,
              config: {
                maxLength: 50,
                onUpdate: async () => {
                  try {
                    if (editorInstance.current) {
                      const currentScroll = window.scrollY;
                      const savedData = await editorInstance.current.save();
                      onChangeRef.current(savedData);
                      window.scrollTo(0, currentScroll);
                    }
                  } catch (error) {
                    // エラーは無視
                  }
                },
                shortcuts: {
                  undo: 'CMD+Z',
                  redo: 'CMD+SHIFT+Z',
                },
              },
            });

            undoInstance.current.save();
          } catch (error) {
            // エラーは無視
          }
        }, 500);
      },
      i18n: {
        messages: {
          ui: {
            blockTunes: {
              toggler: {
                'Click to tune': 'クリックして編集',
                'or drag to move': 'またはドラッグで移動',
              },
            },
            inlineToolbar: {
              converter: {
                'Convert to': '変換',
              },
            },
            toolbar: {
              toolbox: {
                Add: '追加',
              },
            },
            popover: {
              Filter: '検索',
              'Nothing found': '見つかりません',
              'Convert to': '変換',
              'Convert to paragraph': '段落に変換',
              'Convert to heading': '見出しに変換',
              'Convert to list': 'リストに変換',
              'Convert to ordered': '番号付きリストに変換',
              'Convert to unordered': '箇条書きリストに変換',
            },
          },
          toolNames: {
            Text: 'テキスト',
            Heading: '見出し',
            List: 'リスト',
            Quote: '引用',
            Table: 'テーブル',
            Link: 'リンク',
          },
          tools: {
            list: {
              Unordered: '箇条書きリスト',
              Ordered: '番号付きリストに変換',
              Checklist: 'チェックリスト',
            },
            header: {
              'Heading 1': '見出し1',
              'Heading 2': '見出し2',
              'Heading 3': '見出し3',
              'Heading 4': '見出し4',
            },
            link: {
              'Add a link': 'リンクを追加',
            },
          },
          blockTunes: {
            delete: {
              Delete: '削除',
            },
            moveUp: {
              'Move up': '上に移動',
            },
            moveDown: {
              'Move down': '下に移動',
            },
          },
        },
      },
      minHeight: 0,
      placeholder: '本文を入力',
      defaultBlock: 'paragraph',
      inlineToolbar: ['link'],
      tunes: ['delete', 'moveUp', 'moveDown'],
    });

    return () => {
      if (editorInstance.current) {
        try {
          editorInstance.current.destroy();
          editorInstance.current = null;
          window._editor = null;
        } catch (error) {
          // エラーは無視
        }
      }
    };
  }, []);

  // onChangeの更新
  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  // データの更新
  useEffect(() => {
    if (!editorInstance.current || !initialData) return;

    const updateContent = async () => {
      try {
        await editorInstance.current.isReady;

        // 文字列の場合はパースする
        const newBlocks =
          typeof initialData === 'string'
            ? parseHtml(initialData, initialData)
            : initialData.blocks || [];

        // 現在のブロックを取得
        const currentData = await editorInstance.current.save();
        const currentBlocks = currentData.blocks || [];

        // ブロックの内容が実質的に同じ場合は更新しない
        const currentJson = JSON.stringify(
          currentBlocks.map(b => ({ ...b, id: undefined })),
        );
        const newJson = JSON.stringify(
          newBlocks.map(b => ({ ...b, id: undefined })),
        );

        if (currentJson === newJson) {
          return;
        }

        // 内容が異なる場合のみ更新
        await editorInstance.current.render({
          blocks: newBlocks,
          time: Date.now(),
        });

        // 新しいデータが設定された場合は履歴をリセット
        if (undoInstance.current) {
          undoInstance.current.clear();
          undoInstance.current.save();
        }
      } catch (error) {
        console.warn('Editor data update failed:', error);
      }
    };

    updateContent();
  }, [initialData]);

  useEffect(() => {
    const handleKeyDown = e => {
      if ((e.ctrlKey || e.metaKey) && e.key === 'z') {
        e.preventDefault();
        if (e.shiftKey) {
          undoInstance.current?.redo();
        } else {
          undoInstance.current?.undo();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <div style={{ position: 'relative' }} ref={containerRef}>
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <Spin indicator={antIcon} />
        </div>
      )}
      <div
        id={config?.holder || 'editorjs'}
        style={{
          minHeight: '200px',
          position: 'relative',
          width: '100%',
          height: '100%',
        }}
      />
    </div>
  );
};

export default Editor;
