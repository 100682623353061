import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  Button,
  message,
  Tag,
  Progress,
  Breadcrumb,
  Input,
  Typography,
  Divider,
  Modal,
  Tooltip,
  Spin,
  Space,
  Row,
  Col,
  Card,
  Select,
  Form,
  Badge,
  Tabs,
  List,
  Popconfirm,
} from 'antd';
import {
  SearchOutlined,
  UserOutlined,
  EyeOutlined,
  BankOutlined,
  MailOutlined,
  PlusOutlined,
  SettingOutlined,
  RightCircleFilled,
} from '@ant-design/icons';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { getUserRole, getUserProfile } from '../../auth';
import moment from 'moment-timezone';
import UserDetail from '../UserManagement/UserDetail';
import UserSelector from '../Common/UserSelector';

const { Title, Text } = Typography;
const { Search } = Input;

const ArticleList = () => {
  const [articles, setArticles] = useState([]);
  const [userRole, setUserRole] = useState('user');
  const [currentUser, setCurrentUser] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const [relatedKeywords, setRelatedKeywords] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [hasBaseKeyword, setHasBaseKeyword] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [selectedKeyword, setSelectedKeyword] = useState('');
  const [selectedArticleUuid, setSelectedArticleUuid] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { userId } = useParams();
  const location = useLocation();
  const [selectedUserId, setSelectedUserId] = useState(userId || null);
  const [userDetailModalVisible, setUserDetailModalVisible] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    showQuickJumper: true,
  });
  const [filters, setFilters] = useState({});
  const [sorter, setSorter] = useState({});
  const [dateRange, setDateRange] = useState([]);
  const [categoryTagModalVisible, setCategoryTagModalVisible] = useState(false);
  const [editingArticleIds, setEditingArticleIds] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [managementModalVisible, setManagementModalVisible] = useState(false);
  const [isUpdatingCategoryTag, setIsUpdatingCategoryTag] = useState(false);

  const fetchArticlesAndUsers = useCallback(
    async (
      page,
      pageSize,
      currentFilters,
      currentSorter,
      currentUserId,
      disableStatusUpdate = false,
    ) => {
      setLoading(true);
      console.log('fetchArticlesAndUsers called with:', {
        page,
        pageSize,
        currentFilters,
        currentSorter,
        currentUserId,
      });

      try {
        const params = {
          page,
          per_page: pageSize,
          keyword: currentFilters?.keyword?.[0] || '',
          status: currentFilters?.status?.[0] || '',
          user_id: currentUserId,
          category_id: currentFilters?.category?.[0] || null,
          tag_id: currentFilters?.tags?.[0] || null,
          sort_field: currentSorter.field || null,
          sort_order: currentSorter.order
            ? currentSorter.order === 'ascend'
              ? 'asc'
              : 'desc'
            : null,
        };

        console.log('API request params:', params);

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/seo-article/list`,
          {
            params,
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        );

        console.log('API response:', response.data);

        setArticles(response.data.articles);

        // pagination更新を分離
        const newPagination = {
          current: response.data.page,
          pageSize: response.data.per_page,
          total: response.data.total_count,
          showSizeChanger: true,
          showQuickJumper: true,
        };

        console.log('Setting new pagination:', newPagination);
        setPagination(newPagination);

        if (disableStatusUpdate) {
          await new Promise(resolve => setTimeout(resolve, 500)); // 短い遅延を追加
        }

        // 管理者用のユーザー情報取得
        if (
          (userRole === 'admin' || userRole === 'master') &&
          response.data.articles.length > 0
        ) {
          const userIds = [
            ...new Set(response.data.articles.map(article => article.user_id)),
          ];
          if (userIds.length > 0) {
            const userResponse = await axios.get(
              `${process.env.REACT_APP_API_URL}/users/`,
              {
                params: { user_ids: userIds.join(',') },
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
              },
            );

            const userDetailsMap = {};
            userResponse.data.forEach(user => {
              userDetailsMap[user.user_id] = user;
            });
            setUserDetails(userDetailsMap);
          }
        }
      } catch (error) {
        console.error('Failed to fetch articles and user details:', error);
        message.error('データの取得に失敗しました');
      } finally {
        setLoading(false);
      }
    },
    [userRole],
  );

  const fetchCategoriesAndTags = useCallback(async () => {
    try {
      const [categoriesResponse, tagsResponse] = await Promise.all([
        axios.get(`${process.env.REACT_APP_API_URL}/categories`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }),
        axios.get(`${process.env.REACT_APP_API_URL}/tags`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }),
      ]);

      setCategories(categoriesResponse.data);
      setTags(tagsResponse.data);
    } catch (error) {
      console.error('Failed to fetch categories and tags:', error);
      message.error('カテゴリーとタグの取得に失敗しました');
    }
  }, []);

  useEffect(() => {
    const initializeData = async () => {
      try {
        const role = await getUserRole();
        const profile = await getUserProfile();
        setUserRole(role);
        setCurrentUser(profile);

        if (userId && role !== 'admin' && role !== 'master') {
          navigate('/seo-article/list');
          return;
        }

        // 初期データ取得
        await fetchArticlesAndUsers(
          pagination.current,
          pagination.pageSize,
          filters,
          sorter,
          userId,
        );
      } catch (error) {
        console.error('Failed to initialize data:', error);
        message.error('データの初期化に失敗しました');
      }
    };

    initializeData();
  }, [userId]);

  useEffect(() => {
    fetchCategoriesAndTags();
  }, [fetchCategoriesAndTags]);

  const renderUserSelector = () => {
    if (userRole !== 'admin' && userRole !== 'master') return null;

    return (
      <UserSelector
        value={selectedUserId}
        onChange={value => {
          setSelectedUserId(value);
          if (value) {
            navigate(`/seo-article/list/${value}`);
          } else {
            navigate('/seo-article/list');
          }

          // ユーザーが変更されたら、最初のページから再取得
          fetchArticlesAndUsers(1, pagination.pageSize, filters, sorter, value);
        }}
        placeholder="ユーザーの記事を絞り込み"
        style={{ width: '100%' }}
      />
    );
  };

  const updateArticleStatuses = useCallback(async () => {
    // モーダル表示中、更新処理中は実行しない
    if (
      categoryTagModalVisible ||
      managementModalVisible ||
      isUpdatingCategoryTag
    ) {
      return;
    }

    const currentArticleIds = articles.map(article => article.id);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/seo-article/status-updates`,
        { article_ids: currentArticleIds },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      // 状態更新をバッチ処理
      setArticles(prevArticles =>
        prevArticles.map(article => {
          const update = response.data.updates.find(u => u.id === article.id);
          if (update) {
            return {
              ...article,
              ...update,
              completed_at: update.completed_at
                ? new Date(update.completed_at)
                : null,
            };
          }
          return article;
        }),
      );
    } catch (error) {
      console.error('Failed to update article statuses:', error);
    }
  }, [
    articles,
    categoryTagModalVisible,
    managementModalVisible,
    isUpdatingCategoryTag,
  ]);

  useEffect(() => {
    const intervalId = setInterval(updateArticleStatuses, 5000); // 5秒ごとに更新
    return () => clearInterval(intervalId);
  }, [updateArticleStatuses]);

  const rowSelection = {
    selectedRowKeys,
    onChange: selectedKeys => {
      setSelectedRowKeys(selectedKeys);
    },
    // 記事が20件以上選択されないようにする
    getCheckboxProps: record => ({
      disabled:
        selectedRowKeys.length >= 20 && !selectedRowKeys.includes(record.id),
    }),
  };

  const CategoryTagModal = ({
    visible,
    articleIds,
    articles,
    categories,
    tags,
    setTags,
    initialValues,
    onClose,
    onSave,
  }) => {
    const [form] = Form.useForm();
    const [newCategoryName, setNewCategoryName] = useState('');
    const [newTagName, setNewTagName] = useState('');
    const [localTags, setLocalTags] = useState([]);
    const [localTagIds, setLocalTagIds] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
      if (visible) {
        form.setFieldsValue(initialValues);
        setLocalTagIds(initialValues.tag_ids || []);
        setLocalTags(tags);
      }
    }, [visible, initialValues, form, tags]);

    const handleAddCategory = async () => {
      if (!newCategoryName.trim()) return;
      setIsUpdating(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/categories`,
          { name: newCategoryName },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        );
        const newCategory = response.data;
        form.setFieldsValue({ category_id: newCategory.id });
        setCategories(prevCategories => [...prevCategories, newCategory]);
        setNewCategoryName('');
      } catch (error) {
        if (error.response?.status === 400) {
          message.error(error.response.data.detail);
        } else {
          message.error('カテゴリーの作成に失敗しました');
        }
      } finally {
        setIsUpdating(false);
      }
    };

    const handleAddTag = async name => {
      if (!name.trim()) return;
      setIsUpdating(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/tags`,
          { name },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        );

        const newTag = response.data;
        setLocalTags(prev => [...prev, newTag]);

        // 新しいタグを選択状態に追加
        const newValue = [...localTagIds, newTag.id];
        setLocalTagIds(newValue);
        form.setFieldsValue({ tag_ids: newValue });

        setNewTagName('');
      } catch (error) {
        if (error.response?.status === 400) {
          message.error(error.response.data.detail);
        } else {
          message.error('タグの作成に失敗しました');
        }
      } finally {
        setIsUpdating(false);
      }
    };

    const handleSave = async () => {
      try {
        const values = await form.validateFields();
        setIsUpdating(true);
        await onSave({
          ...values,
          tag_ids: localTagIds,
        });
      } catch (error) {
        console.error('Validation failed:', error);
      } finally {
        setIsUpdating(false);
      }
    };

    const handleClose = () => {
      if (!isUpdating) {
        form.resetFields();
        setLocalTagIds([]);
        setNewCategoryName('');
        setNewTagName('');
        onClose();
      }
    };

    return (
      <Modal
        title={
          articleIds.length > 1
            ? `カテゴリー・タグの一括編集（${articleIds.length}件）`
            : `${articles.find(a => a.id === articleIds[0])?.keyword}`
        }
        visible={visible}
        onCancel={handleClose}
        onOk={handleSave}
        confirmLoading={isUpdating}
        maskClosable={false}
        destroyOnClose
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="category_id"
            label="カテゴリー"
            tooltip="1つのカテゴリーを選択できます"
          >
            <Select
              allowClear
              showSearch
              placeholder="カテゴリーを選択"
              value={form.getFieldValue('category_id')}
              onChange={value => {
                form.setFieldsValue({ category_id: value });
              }}
              dropdownRender={menu => (
                <>
                  {menu}
                  <Divider style={{ margin: '8px 0' }} />
                  <Space align="center" style={{ padding: '0 8px 4px' }}>
                    <Input
                      placeholder="新しいカテゴリー"
                      value={newCategoryName}
                      onChange={e => setNewCategoryName(e.target.value)}
                      maxLength={50}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          handleAddCategory();
                        }
                      }}
                    />
                    <Button
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={handleAddCategory}
                      disabled={isUpdating}
                    >
                      追加
                    </Button>
                  </Space>
                </>
              )}
            >
              {categories.map(category => (
                <Select.Option key={category.id} value={category.id}>
                  <Badge color={category.color} text={category.name} />
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="tag_ids"
            label="タグ"
            tooltip="最大3つのタグを設定できます"
          >
            <Select
              mode="multiple"
              allowClear
              showSearch
              placeholder="タグを選択または入力"
              maxTagCount={3}
              value={localTagIds}
              onChange={values => {
                // 3つを超えるタグの選択を防ぐ
                if (values && values.length > 3) {
                  message.warning('タグは最大3つまでしか選択できません');
                  // 最初の3つのみを保持
                  form.setFieldsValue({
                    tag_ids: values.slice(0, 3),
                  });
                } else {
                  setLocalTagIds(values || []);
                  form.setFieldsValue({ tag_ids: values });
                }
              }}
              dropdownRender={menu => (
                <>
                  {menu}
                  <Divider style={{ margin: '8px 0' }} />
                  <div style={{ padding: '0 8px 4px' }}>
                    <Typography.Text type="secondary">
                      最大3つまで選択できます
                    </Typography.Text>
                  </div>
                </>
              )}
              onSearch={value => setNewTagName(value)}
              onKeyDown={async e => {
                if (e.key === 'Enter' && newTagName) {
                  e.preventDefault();
                  await handleAddTag(newTagName);
                }
              }}
              maxTagTextLength={10}
            >
              {localTags.map(tag => (
                <Select.Option key={tag.id} value={tag.id}>
                  {tag.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const handleSaveCategoryTag = async values => {
    try {
      const requestData = {
        article_ids: editingArticleIds,
        category_id:
          values.category_id === undefined ? null : values.category_id,
        tag_ids: values.tag_ids || [],
      };

      await axios.put(
        `${process.env.REACT_APP_API_URL}/articles/bulk-categorization`,
        requestData,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      );

      // モーダルを閉じる
      setCategoryTagModalVisible(false);
      setEditingArticleIds([]);

      // データを並列で更新（記事一覧とカテゴリー・タグ情報）
      await Promise.all([
        fetchArticlesAndUsers(
          pagination.current,
          pagination.pageSize,
          filters,
          sorter,
          userId,
          true,
        ),
        fetchCategoriesAndTags(), // カテゴリー・タグ情報を更新
      ]);

      message.success('更新が完了しました');
    } catch (error) {
      console.error('Error response:', error.response?.data);
      message.error(error.response?.data?.detail || '更新に失敗しました');
    }
  };

  const handleUpdateCategory = async (id, name) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/categories/${id}`,
        { name },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      );
      message.success('カテゴリーを更新しました');
      setManagementModalVisible(false);

      // データ更新を遅延実行
      setTimeout(async () => {
        await Promise.all([
          fetchCategoriesAndTags(),
          fetchArticlesAndUsers(
            pagination.current,
            pagination.pageSize,
            filters,
            sorter,
            userId,
          ),
        ]);
      }, 100);
    } catch (error) {
      message.error('カテゴリーの更新に失敗しました');
      throw error; // CategoryTagManageModalでエラー処理できるように再スロー
    }
  };

  const handleDeleteCategory = async id => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/categories/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      message.success('カテゴリーを削除しました');
      setManagementModalVisible(false);

      // データ更新を遅延実行
      setTimeout(async () => {
        await Promise.all([
          fetchCategoriesAndTags(),
          fetchArticlesAndUsers(
            pagination.current,
            pagination.pageSize,
            filters,
            sorter,
            userId,
          ),
        ]);
      }, 100);
    } catch (error) {
      message.error('カテゴリーの削除に失敗しました');
      throw error;
    }
  };

  const handleUpdateTag = async (id, name) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/tags/${id}`,
        { name },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      );
      message.success('タグを更新しました');
      setManagementModalVisible(false);

      // データ更新を遅延実行
      setTimeout(async () => {
        await Promise.all([
          fetchCategoriesAndTags(),
          fetchArticlesAndUsers(
            pagination.current,
            pagination.pageSize,
            filters,
            sorter,
            userId,
          ),
        ]);
      }, 100);
    } catch (error) {
      message.error('タグの更新に失敗しました');
      throw error;
    }
  };

  const handleDeleteTag = async id => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/tags/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      message.success('タグを削除しました');
      setManagementModalVisible(false);

      // データ更新を遅延実行
      setTimeout(async () => {
        await Promise.all([
          fetchCategoriesAndTags(),
          fetchArticlesAndUsers(
            pagination.current,
            pagination.pageSize,
            filters,
            sorter,
            userId,
          ),
        ]);
      }, 100);
    } catch (error) {
      message.error('タグの削除に失敗しました');
      throw error;
    }
  };

  const handleTableChange = (newPagination, newFilters, newSorter) => {
    // リクエストパラメータのpageが常に1になってしまう問題の修正
    const isFirstChange =
      !filters.status && !filters.keyword && !filters.category && !filters.tags; // カテゴリーとタグも条件に追加

    const requestPage = isFirstChange ? newPagination.current : 1;

    // デバッグ用ログ
    console.log('Table change details:', {
      isFirstChange,
      requestPage,
      newPagination,
      newFilters,
      newSorter,
    });

    // nullのフィルター値も確実に処理
    const updatedFilters = {};
    for (const [key, value] of Object.entries(newFilters)) {
      updatedFilters[key] = value || null;
    }

    setFilters(updatedFilters);
    setSorter(newSorter || {});

    fetchArticlesAndUsers(
      newPagination.current, // currentを直接使用（既存コードを維持）
      newPagination.pageSize,
      updatedFilters,
      newSorter || {},
      selectedUserId,
    );
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();

    // 新しいフィルター状態を作成
    const newFilters = {
      ...filters,
      [dataIndex]: selectedKeys,
    };
    setFilters(newFilters);

    // ページネーションを1ページ目にリセット
    const newPagination = {
      ...pagination,
      current: 1,
    };
    setPagination(newPagination);

    // データを取得
    fetchArticlesAndUsers(
      1,
      pagination.pageSize,
      newFilters,
      sorter,
      dateRange,
      userId,
    );
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              // クリア時の処理も追加
              const newFilters = { ...filters };
              delete newFilters[dataIndex];
              setFilters(newFilters);
              fetchArticlesAndUsers(
                pagination.current,
                pagination.pageSize,
                newFilters,
                sorter,
                dateRange,
                userId,
              );
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      if (!value || !record || !record[dataIndex]) return false;

      const recordValue = String(record[dataIndex]); // 値を文字列に変換
      const searchValue = String(value); // 検索値を文字列に変換

      return recordValue.toLowerCase().includes(searchValue.toLowerCase());
    },
  });

  const getStatusColor = status => {
    if (status.includes('予約中') || status.includes('更新予約中'))
      return 'orange';
    if (status.includes('中') || status.includes('更新中')) return 'green';
    if (status === '生成済み') return 'blue';
    if (status === '生成失敗') return 'red';
    return 'default';
  };

  const showRegenModal = record => {
    Modal.confirm({
      title: 'タスクの再実行',
      content: `「${record.keyword}」のタスクを再実行しますか？ 10枚のチケットが消費されます。`,
      onOk: () => handleRegenerate(record),
    });
  };

  const handleRegenerate = async record => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/seo-article/regenerate/${record.uuid}`,
        {
          task_type: 'generate',
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      );

      if (response.status === 200 && response.data.message) {
        message.success(response.data.message);
        setArticles(prevArticles =>
          prevArticles.map(article =>
            article.uuid === record.uuid
              ? { ...article, status: '予約中' }
              : article,
          ),
        );
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error('Failed to regenerate task:', error);
      if (error.response && error.response.status === 200) {
        message.success('タスクの再実行が予約されました');
        await fetchArticlesAndUsers();
      } else {
        message.error(
          'タスクの再実行に失敗しました: ' +
            (error.response?.data?.detail || error.message),
        );
      }
    }
  };

  const showModal = useCallback(record => {
    setSelectedKeyword(record.keyword);
    setSelectedArticleUuid(record.uuid);
    setModalVisible(true);
    setModalLoading(true);
    fetchRelatedKeywords(record.uuid);
  }, []);

  const fetchRelatedKeywords = useCallback(async articleUuid => {
    setModalLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/related-keywords/article/${articleUuid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setRelatedKeywords(response.data.related_keywords || []);
      setLastUpdated(response.data.last_updated);
      setHasBaseKeyword(response.data.has_base_keyword);
    } catch (error) {
      console.error('Failed to fetch related keywords:', error);
      message.error('関連キーワードの取得に失敗しました');
      setRelatedKeywords([]);
    } finally {
      setModalLoading(false);
    }
  }, []);

  const handleFetchRelatedKeywords = useCallback(async () => {
    if (selectedArticleUuid) {
      setModalLoading(true);
      try {
        const token = localStorage.getItem('token');
        await axios.post(
          `${process.env.REACT_APP_API_URL}/seo-article/fetch-related-keywords/article/${selectedArticleUuid}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        await fetchRelatedKeywords(selectedArticleUuid);
      } catch (error) {
        console.error('Failed to fetch related keywords:', error);
        message.error('関連キーワードの取得に失敗しました');
      } finally {
        setModalLoading(false);
      }
    }
  }, [selectedArticleUuid, fetchRelatedKeywords]);

  const handleDelete = async record => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/seo-article/${record.uuid}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      );
      message.success('記事が削除されました');
      await fetchArticlesAndUsers();
    } catch (error) {
      console.error('Failed to delete article:', error);
      if (error.response && error.response.status === 404) {
        message.warning('この記事は既に削除されています');
        await fetchArticlesAndUsers(); // 404エラーの場合も記事リストを更新
      } else {
        message.error('記事の削除に失敗しました');
      }
    }
  };

  const showDeleteConfirm = record => {
    Modal.confirm({
      title: '記事の削除',
      content: '本当にこの記事を削除しますか？この操作は取り消せません。',
      okText: '削除',
      okType: 'danger',
      cancelText: 'キャンセル',
      onOk() {
        handleDelete(record);
      },
    });
  };

  const columns = [
    {
      title: 'ステータス',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: '予約中', value: '予約中' },
        { text: '更新予約中', value: '更新予約中' },
        { text: '生成中', value: '生成中' },
        { text: '更新中', value: '更新中' },
        { text: '生成済み', value: '生成済み' },
        { text: '本文完了', value: '本文完了' },
        { text: '生成失敗', value: '生成失敗' },
      ],
      filterMode: 'menu',
      filteredValue: filters.status || null,
      filterMultiple: false,
      render: (status, record) => {
        return (
          <>
            <Tag color={getStatusColor(status)}>{status.split('（')[0]}</Tag>
            {status.includes('（') && (
              <span
                style={{ marginLeft: '4px', fontSize: '12px', color: '#888' }}
              >
                {status.split('（')[1].replace('）', '')}
              </span>
            )}
            {status === '生成済み' && (
              <>{record.has_content && <Tag color="blue">本文</Tag>}</>
            )}
            {status === '生成中' && (
              <Progress
                id="progress"
                style={{
                  display: 'inline-block',
                  width: '100px',
                  marginLeft: '10px',
                }}
                percent={record.progress}
                size="small"
              />
            )}
            {status === '生成失敗' && !record.structured_headings && (
              <Button
                size="small"
                onClick={() => showRegenModal(record)}
                style={{ marginLeft: '0px' }}
              >
                再実行
              </Button>
            )}
          </>
        );
      },
      width: 140,
    },
    {
      title: 'キーワード',
      dataIndex: 'keyword',
      key: 'keyword',
      ...getColumnSearchProps('keyword'),
      filteredValue: filters.keyword || null,
      width: 300,
    },
    // {
    //   title: '結果',
    //   key: 'result',
    //   render: (_, record) => {
    //     if (record.status === '生成済み' || record.structured_headings) {
    //       return (
    //         <Link to={`/seo-article/detail/${record.uuid}`}>
    //           <Button
    //             type="primary"
    //             style={{
    //               background:
    //                 'linear-gradient(90deg, #0072ff 0%, #00d4ff 100%)',
    //               border: 'none',
    //               transition: 'all 0.3s',
    //             }}
    //             onMouseEnter={e => {
    //               e.currentTarget.style.opacity = '0.7';
    //             }}
    //             onMouseLeave={e => {
    //               e.currentTarget.style.opacity = '1.0';
    //             }}
    //           >
    //             結果
    //           </Button>
    //         </Link>
    //       );
    //     } else if (record.status === '生成失敗') {
    //       return (
    //         <Button type="danger" onClick={() => showDeleteConfirm(record)}>
    //           取消
    //         </Button>
    //       );
    //     }
    //     return null;
    //   },
    //   width: 100,
    // },
    {
      title: '生成実行日',
      dataIndex: 'execution_date',
      key: 'execution_date',
      sorter: true,
      render: (text, record) => {
        if (record.status.includes('予約中')) {
          if (record.estimated_wait_time === 0) {
            return '予想待ち時間: 計算中';
          } else if (
            record.estimated_wait_time !== null &&
            record.estimated_wait_time !== undefined
          ) {
            return `予想待ち時間: ${record.estimated_wait_time}分`;
          } else {
            return '予想待ち時間: 不明';
          }
        }
        return text
          ? moment.utc(text).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm')
          : '-';
      },
      width: 160,
    },
    {
      title: '完了日',
      dataIndex: 'completed_at',
      key: 'completed_at',
      sorter: true,
      render: (text, record) => {
        if (record.status === '生成済み' || record.status === '生成失敗') {
          return text
            ? moment.utc(text).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm')
            : '-';
        }
        return '-';
      },
      width: 160,
    },
    {
      title: 'カテゴリー',
      dataIndex: 'category',
      key: 'category',
      width: 140,
      ...(userRole === 'admin' || userRole === 'master'
        ? {
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div style={{ padding: 8 }}>
                <Select
                  showSearch
                  allowClear
                  style={{ width: 200, marginBottom: 8 }}
                  placeholder="カテゴリーを検索"
                  value={selectedKeys?.[0]}
                  onChange={value => {
                    setSelectedKeys(value ? [value] : []);
                    confirm();
                  }}
                  onClear={() => {
                    clearFilters();
                    const newFilters = { ...filters };
                    delete newFilters['category'];
                    setFilters(newFilters);
                    fetchArticlesAndUsers(
                      pagination.current,
                      pagination.pageSize,
                      newFilters,
                      sorter,
                      userId,
                    );
                  }}
                  filterOption={(input, option) => {
                    const category = categories.find(
                      c => c.id === option.value,
                    );
                    return category?.name
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {categories.map(c => (
                    <Select.Option key={c.id} value={c.id}>
                      <Badge color={c.color} text={c.name} />
                    </Select.Option>
                  ))}
                </Select>
              </div>
            ),
          }
        : {
            filters: categories.map(c => ({
              text: <Badge color={c.color} text={c.name} />,
              value: c.id,
            })),
            filterMode: 'tree',
          }),
      filteredValue: filters.category || null,
      filterMode: 'tree',
      render: (_, record) => {
        // 自分の記事かどうかのチェック（masterもadminも同じ条件）
        const hasPermission = record.user_id === currentUser?.user_id;

        const handleClick = e => {
          e.stopPropagation();
          if (!hasPermission) {
            message.error('自分が作成したコンテンツのみ編集できます');
            return;
          }
          setEditingArticleIds([record.id]);
          setCategoryTagModalVisible(true);
        };

        return (
          <div
            onClick={handleClick}
            style={{
              cursor: hasPermission ? 'pointer' : 'not-allowed',
              padding: '4px 8px',
              borderRadius: '4px',
              minHeight: '28px',
              border: '1px dashed #d9d9d9',
              display: 'inline-block',
            }}
          >
            {record.category ? (
              <Badge
                color={record.category.color}
                text={record.category.name}
                style={{
                  padding: '2px 8px',
                  borderRadius: '2px',
                }}
              />
            ) : // 自分の記事の場合のみ「設定する」を表示
            hasPermission ? (
              <Text type="secondary">設定する</Text>
            ) : (
              <Text type="secondary">未設定</Text>
            )}
          </div>
        );
      },
    },
    {
      title: 'タグ',
      dataIndex: 'tags',
      key: 'tags',
      width: 200,
      ...(userRole === 'admin' || userRole === 'master'
        ? {
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div style={{ padding: 8 }}>
                <Select
                  showSearch
                  allowClear
                  style={{ width: 200, marginBottom: 8 }}
                  placeholder="タグを検索"
                  value={selectedKeys?.[0]}
                  onChange={value => {
                    setSelectedKeys(value ? [value] : []);
                    confirm();
                  }}
                  onClear={() => {
                    clearFilters();
                    const newFilters = { ...filters };
                    delete newFilters['tags'];
                    setFilters(newFilters);
                    fetchArticlesAndUsers(
                      pagination.current,
                      pagination.pageSize,
                      newFilters,
                      sorter,
                      userId,
                    );
                  }}
                  filterOption={(input, option) => {
                    const tag = tags.find(t => t.id === option.value);
                    return tag?.name
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {tags.map(t => (
                    <Select.Option key={t.id} value={t.id}>
                      {t.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            ),
          }
        : {
            filters: tags.map(t => ({
              text: t.name,
              value: t.id,
            })),
            filterMode: 'tree',
          }),
      filteredValue: filters.tags || null,
      filterMode: 'tree',
      render: (_, record) => {
        const hasPermission = record.user_id === currentUser?.user_id;

        const handleClick = e => {
          e.stopPropagation();
          if (!hasPermission) {
            message.error('自分が作成したコンテンツのみ編集できます');
            return;
          }
          setEditingArticleIds([record.id]);
          setCategoryTagModalVisible(true);
        };

        return (
          <div
            onClick={handleClick}
            style={{
              cursor: hasPermission ? 'pointer' : 'not-allowed',
              padding: '4px 8px',
              borderRadius: '4px',
              minHeight: '28px',
              border: '1px dashed #d9d9d9',
              display: 'inline-block',
            }}
          >
            {record.tags?.length > 0 ? (
              <Space size={[0, 4]} wrap>
                {record.tags.map(tag => (
                  <Tag key={tag.id}>{tag.name}</Tag>
                ))}
              </Space>
            ) : // 自分の記事の場合のみ「設定する」を表示
            hasPermission ? (
              <Text type="secondary">設定する</Text>
            ) : (
              <Text type="secondary">未設定</Text>
            )}
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'user_id',
      key: 'user_id',
      width: 70,
      render: (_, record) => {
        const user = userDetails[record.user_id];
        return (
          <Tooltip
            title={
              user ? (
                <>
                  <div>{user.email}</div>
                  {user.company_name && <div>{user.company_name}</div>}
                  {user.department_name && <div>{user.department_name}</div>}
                  {(user.last_name || user.first_name) && (
                    <div>
                      {user.last_name} {user.first_name}
                    </div>
                  )}
                  {user.display_name && <div>({user.display_name})</div>}
                </>
              ) : (
                '読み込み中...'
              )
            }
          >
            <Button
              icon={<UserOutlined />}
              type="text"
              onClick={e => {
                e.stopPropagation();
                setSelectedUserId(record.user_id);
                setUserDetailModalVisible(true);
              }}
              style={{
                color: '#1890ff',
                transition: 'color 0.3s',
              }}
              onMouseEnter={e => {
                e.currentTarget.style.color = '#40a9ff';
              }}
              onMouseLeave={e => {
                e.currentTarget.style.color = '#1890ff';
              }}
            />
          </Tooltip>
        );
      },
    },
  ].filter(column => column.key !== 'result');

  const onRow = record => ({
    onClick: e => {
      // ユーザーアイコンと関連KWの列をクリックした場合は処理を中断
      const isUserIconClick =
        e.target.closest('.ant-btn') ||
        e.target.closest('[role="button"]') ||
        e.target.closest('.anticon-user');

      if (isUserIconClick) {
        e.stopPropagation();
        return;
      }

      if (record.status === '生成済み' || record.structured_headings) {
        // Commandキー(Mac)またはCtrlキー(Windows)が押されている場合は既存の動作を維持
        if (e.metaKey || e.ctrlKey) {
          window.open(`/seo-article/detail/${record.uuid}`, '_blank');
        } else {
          // 通常のクリックは新規タブで開く
          window.open(
            `/seo-article/detail/${record.uuid}`,
            '_blank',
            'noopener,noreferrer',
          );
        }
      }
    },
    style: {
      cursor:
        record.status === '生成済み' || record.structured_headings
          ? 'pointer'
          : 'default',
    },
  });

  // if (userRole === 'admin' || userRole === 'master') {
  //   columns.push({
  //     title: '関連KW',
  //     key: 'relatedKeywords',
  //     render: (_, record) => (
  //       <Button
  //         onClick={() => showModal(record)}
  //         icon={record.has_base_keyword ? <EyeOutlined /> : <SearchOutlined />}
  //       >
  //         {record.has_base_keyword ? '表示' : '取得'}
  //       </Button>
  //     ),
  //     width: 100,
  //   });
  // }

  const CategoryTagManageModal = ({
    visible,
    categories,
    tags,
    onClose,
    onUpdateCategory,
    onDeleteCategory,
    onUpdateTag,
    onDeleteTag,
  }) => {
    const [editingCategory, setEditingCategory] = useState(null);
    const [editingTag, setEditingTag] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleCategoryDelete = async id => {
      try {
        setIsProcessing(true);
        await onDeleteCategory(id);
      } finally {
        setIsProcessing(false);
      }
    };

    const handleTagDelete = async id => {
      try {
        setIsProcessing(true);
        await onDeleteTag(id);
      } finally {
        setIsProcessing(false);
      }
    };

    const handleUpdateCategorySubmit = async () => {
      try {
        setIsProcessing(true);
        await onUpdateCategory(editingCategory.id, editingCategory.name);
        setEditingCategory(null);
      } finally {
        setIsProcessing(false);
      }
    };

    const handleUpdateTagSubmit = async () => {
      try {
        setIsProcessing(true);
        await onUpdateTag(editingTag.id, editingTag.name);
        setEditingTag(null);
      } finally {
        setIsProcessing(false);
      }
    };

    return (
      <Modal
        title="カテゴリー・タグの管理"
        visible={visible}
        onCancel={isProcessing ? undefined : onClose}
        footer={null}
        width={600}
        maskClosable={!isProcessing}
      >
        <Tabs defaultActiveKey="categories">
          <Tabs.TabPane tab="カテゴリー" key="categories">
            <List
              dataSource={categories}
              renderItem={category => (
                <List.Item
                  actions={[
                    <Button
                      key="edit"
                      type="link"
                      onClick={() => setEditingCategory(category)}
                      disabled={isProcessing}
                    >
                      編集
                    </Button>,
                    <Popconfirm
                      key="delete"
                      title="このカテゴリーを削除しますか？"
                      onConfirm={() => handleCategoryDelete(category.id)}
                      disabled={isProcessing}
                    >
                      <Button type="link" danger disabled={isProcessing}>
                        削除
                      </Button>
                    </Popconfirm>,
                  ]}
                >
                  <Badge color={category.color} text={category.name} />
                </List.Item>
              )}
            />
            <Modal
              title="カテゴリー名の変更"
              visible={!!editingCategory}
              onCancel={() => !isProcessing && setEditingCategory(null)}
              onOk={handleUpdateCategorySubmit}
              confirmLoading={isProcessing}
              maskClosable={!isProcessing}
            >
              <Input
                value={editingCategory?.name}
                onChange={e =>
                  setEditingCategory({
                    ...editingCategory,
                    name: e.target.value,
                  })
                }
                maxLength={50}
                disabled={isProcessing}
              />
            </Modal>
          </Tabs.TabPane>
          <Tabs.TabPane tab="タグ" key="tags">
            <List
              dataSource={tags}
              renderItem={tag => (
                <List.Item
                  actions={[
                    <Button
                      key="edit"
                      type="link"
                      onClick={() => setEditingTag(tag)}
                      disabled={isProcessing}
                    >
                      編集
                    </Button>,
                    <Popconfirm
                      key="delete"
                      title="このタグを削除しますか？"
                      onConfirm={() => handleTagDelete(tag.id)}
                      disabled={isProcessing}
                    >
                      <Button type="link" danger disabled={isProcessing}>
                        削除
                      </Button>
                    </Popconfirm>,
                  ]}
                >
                  <Tag>{tag.name}</Tag>
                </List.Item>
              )}
            />
            <Modal
              title="タグ名の変更"
              visible={!!editingTag}
              onCancel={() => !isProcessing && setEditingTag(null)}
              onOk={handleUpdateTagSubmit}
              confirmLoading={isProcessing}
              maskClosable={!isProcessing}
            >
              <Input
                value={editingTag?.name}
                onChange={e =>
                  setEditingTag({
                    ...editingTag,
                    name: e.target.value,
                  })
                }
                maxLength={10}
                disabled={isProcessing}
              />
            </Modal>
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    );
  };

  const RelatedKeywordsModal = ({
    visible,
    onCancel,
    keyword,
    relatedKeywords,
    lastUpdated,
    loading,
    hasBaseKeyword,
    handleFetchRelatedKeywords,
  }) => {
    const columns = [
      {
        title: 'キーワード',
        dataIndex: 'keyword',
        key: 'keyword',
      },
      {
        title: '検索Vol',
        dataIndex: 'volume',
        key: 'volume',
        sorter: (a, b) => a.volume - b.volume,
      },
      {
        title: 'CPC',
        dataIndex: 'cpc',
        key: 'cpc',
        render: cpc => `$${cpc?.toFixed(2) || 'N/A'}`,
        sorter: (a, b) => (a.cpc || 0) - (b.cpc || 0),
      },
      {
        title: 'トレンド',
        dataIndex: 'trend',
        key: 'trend',
        render: (_, record) => {
          const data = record.monthly_volumes || [];
          const width = 80;
          const height = 24;
          const points = data
            .map(
              (d, i) =>
                `${(i / (data.length - 1)) * width},${height - (d.volume / Math.max(...data.map(d => d.volume))) * height}`,
            )
            .join(' ');

          return (
            <svg width={width} height={height}>
              <polyline
                fill="none"
                stroke="#00d4ff"
                strokeWidth="1"
                points={points}
              />
            </svg>
          );
        },
      },
      {
        title: '競合性',
        dataIndex: 'competition',
        key: 'competition',
        render: competition => {
          const value =
            competition !== null && competition !== undefined
              ? Math.min(Math.round(competition * 10), 10)
              : 0;
          return (
            <Tooltip
              title={`競合性: ${value}/10 (${(competition * 100).toFixed(2)}%)`}
            >
              <Progress
                percent={value * 10}
                steps={10}
                size="small"
                showInfo={false}
                strokeColor="#1890ff"
              />
            </Tooltip>
          );
        },
        sorter: (a, b) => (a.competition || 0) - (b.competition || 0),
      },
    ];

    return (
      <Modal
        title={
          <div style={{ fontSize: '16px' }}>
            <span style={{ fontSize: '20px', color: '#00d4ff' }}>
              「{keyword}」
            </span>
            の関連キーワード
          </div>
        }
        visible={visible}
        centered
        onCancel={onCancel}
        footer={[
          <Button
            key="fetch"
            onClick={handleFetchRelatedKeywords}
            loading={loading}
          >
            関連キーワードを更新
          </Button>,
          <Button key="close" onClick={onCancel}>
            閉じる
          </Button>,
        ]}
        width="80vw"
      >
        {lastUpdated && (
          <Text style={{ fontSize: '12px' }}>
            取得日時:{' '}
            {moment
              .utc(lastUpdated)
              .tz('Asia/Tokyo')
              .format('YYYY/MM/DD HH:mm')}
          </Text>
        )}
        <Spin spinning={loading}>
          {relatedKeywords && relatedKeywords.length > 0 ? (
            <Table
              dataSource={relatedKeywords}
              columns={columns}
              pagination={{ pageSize: 10 }}
              scroll={{ y: 500 }}
              style={{ marginTop: '24px' }}
            />
          ) : (
            <p style={{ marginTop: '24px' }}>
              関連キーワードが見つかりません。
            </p>
          )}
        </Spin>
      </Modal>
    );
  };

  return (
    <>
      <Helmet>
        <title>生成結果一覧 | SEO記事生成モード - magicss</title>
        <meta name="robots" content="noindex" />
        <meta
          property="og:title"
          content={`生成結果一覧 | SEO記事生成モード - magicss`}
        />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/ogp.png`}
        />
        <meta
          property="og:description"
          content={`コンテンツ生成AIツール「magicss」のSEO記事生成結果の一覧ページです。`}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Row gutter={24}>
        <Col xs={24} lg={14}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>
              <Link to="/seo-article">SEO記事生成</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>生成結果一覧</Breadcrumb.Item>
          </Breadcrumb>
          <Title
            level={1}
            className="gradient-text"
            style={{ fontSize: '24px' }}
          >
            生成結果一覧
          </Title>
          <Text style={{ color: '#6d8eb7' }}>
            テーブルの項目行にあるアイコンをクリックすると、絞り込みや検索、データの並び替えができます。
          </Text>
        </Col>
      </Row>
      <Divider style={{ margin: '24px 0' }} />

      {(userRole === 'admin' || userRole === 'master') && (
        <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
          <Col xs={24} sm={24}>
            {renderUserSelector()}
          </Col>
        </Row>
      )}

      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={articles}
        rowKey="id"
        loading={loading}
        onChange={handleTableChange}
        onRow={onRow}
        pagination={{
          position: ['bottomLeft'],
          ...pagination,
          showTotal: (total, range) => (
            <Row
              justify="space-between"
              style={{ width: '100%', padding: '0 16px' }}
            >
              <Col>{`${range[0]}-${range[1]} / ${total}件`}</Col>
              <Col>
                {selectedRowKeys.length > 0 && (
                  <Space>
                    <span>{`${selectedRowKeys.length}件選択中`}</span>
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => {
                        if (selectedRowKeys.length > 20) {
                          message.warning(
                            '一括編集は20件までに制限されています',
                          );
                          return;
                        }
                        setEditingArticleIds(selectedRowKeys);
                        setCategoryTagModalVisible(true);
                      }}
                      disabled={selectedRowKeys.length > 20}
                    >
                      一括編集
                    </Button>
                    <Button size="small" onClick={() => setSelectedRowKeys([])}>
                      選択解除
                    </Button>
                  </Space>
                )}
              </Col>
            </Row>
          ),
        }}
        scroll={{ x: 'max-content' }}
      />
      <Row gutter={[24, 24]} justify={'space-between'}>
        <Col></Col>
        <Col>
          <Button
            onClick={() => setManagementModalVisible(true)}
            icon={<SettingOutlined />}
          >
            カテゴリー・タグ管理
          </Button>
        </Col>
      </Row>
      <CategoryTagModal
        visible={categoryTagModalVisible}
        articleIds={editingArticleIds}
        articles={articles}
        categories={categories}
        tags={tags}
        setTags={setTags}
        onClose={() => {
          setCategoryTagModalVisible(false);
          setEditingArticleIds([]);
        }}
        onSave={handleSaveCategoryTag}
        initialValues={{
          category_id:
            editingArticleIds.length === 1
              ? articles.find(a => a.id === editingArticleIds[0])?.category?.id
              : undefined,
          tag_ids:
            editingArticleIds.length === 1
              ? articles
                  .find(a => a.id === editingArticleIds[0])
                  ?.tags?.map(t => t.id)
              : [],
        }}
      />
      <CategoryTagManageModal
        visible={managementModalVisible}
        categories={categories}
        tags={tags}
        onClose={() => setManagementModalVisible(false)}
        onUpdateCategory={handleUpdateCategory}
        onDeleteCategory={handleDeleteCategory}
        onUpdateTag={handleUpdateTag}
        onDeleteTag={handleDeleteTag}
      />
      <UserDetail
        visible={userDetailModalVisible}
        onCancel={() => setUserDetailModalVisible(false)}
        userId={selectedUserId}
        onUpdate={() => {
          setUserDetailModalVisible(false);
          fetchArticlesAndUsers(
            pagination.current,
            pagination.pageSize,
            filters,
            sorter,
            selectedUserId, // ユーザーIDの状態を維持
          );
        }}
      />
      <RelatedKeywordsModal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        keyword={selectedKeyword}
        relatedKeywords={relatedKeywords}
        lastUpdated={lastUpdated}
        loading={modalLoading}
        hasBaseKeyword={hasBaseKeyword}
        handleFetchRelatedKeywords={handleFetchRelatedKeywords}
      />
    </>
  );
};

export default ArticleList;
