import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Typography, Tabs, Table, message, Checkbox, Button, Breadcrumb, Tooltip, Progress,Segmented, Switch, Affix, Modal, Collapse, Divider, Spin, Skeleton, Space, Input, Dropdown, Menu, Row, Col } from 'antd';
// import { Tooltip as Tooltip } from 'antd';
import { BulbOutlined, RadarChartOutlined, ProductOutlined, BarsOutlined,ContactsOutlined,CopyOutlined,CheckOutlined,EditOutlined,ExclamationCircleOutlined,InfoCircleOutlined, RightOutlined, SettingOutlined, LockOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Radar } from 'react-chartjs-2';
import './ArticleDetail.css';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip as ChartTooltip, Legend } from 'chart.js';
import StructuredHeadings from './StructuredHeadings';
import 'react-json-pretty/themes/monikai.css';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, ChartTooltip, Legend);

const { Title, Paragraph, Text } = Typography;
const { TextArea } = Input;
const { TabPane } = Tabs;
const { confirm } = Modal;
const { Panel } = Collapse;

const ArticleDetail = () => {
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [topTopics, setTopTopics] = useState([]);
  const [uniqueTopics, setUniqueTopics] = useState([]);
  const [relatedKeywords, setRelatedKeywords] = useState([]);
  const [queryInsightsSummaries, setQueryInsightsSummaries] = useState({});
  const [topicCheckStatus, setTopicCheckStatus] = useState('idle');
  const [contentGenerationStatus, setContentGenerationStatus] = useState('idle');
  const { uuid } = useParams();
  const [products, setProducts] = useState([]);
  const [selectedTab, setSelectedTab] = useState('relatedKeywords');
  const [showAllTopics, setShowAllTopics] = useState(false);
  const [lastJobStatus, setLastJobStatus] = useState({
    topicCheck: null,
    contentGeneration: null
  });
  const [editableTitle, setEditableTitle] = useState('');
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [shareStatus, setShareStatus] = useState('private');
  const [shareUrl, setShareUrl] = useState('');
  const [sharePassword, setSharePassword] = useState('');
  const [userPlan, setUserPlan] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [updatingTopics, setUpdatingTopics] = useState({
    relatedKeywords: false,
    topTopics: false,
    uniqueTopics: false
  });
  
  const showConfirm = (action, isRetry = false) => {
    let title = '';
    let content = '';
    let confirmAction = null;
    if (action === 'generateStructure') {
      title = '構成案の生成';
      content = '構成案を生成します。よろしいですか？';
      confirmAction = handleGenerateStructure;
    } else if (action === 'checkTopics') {
      title = isRetry ? 'トピックチェックの再実行' : 'トピックのチェック';
      content = 'トピックチェックは一度のみ実行できます。よろしいですか？';
      confirmAction = () => handleTopicCheck(isRetry);
    } else if (action === 'generateArticleContent') {
      title = isRetry ? '本文生成の再実行' : '本文の生成';
      content = '本文生成は一度のみ実行できます。よろしいですか？';
      confirmAction = () => handleContentGeneration(isRetry);
    }
    confirm({
      title: title,
      icon: <ExclamationCircleOutlined />,
      content: content,
      onOk() {
        if (confirmAction) {
          confirmAction();
        }
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    const fetchArticleAndStatus = async () => {
      try {
        const token = localStorage.getItem('token');
        console.log('Token:', token);
        const articleResponse = await axios.get(`${process.env.REACT_APP_API_URL}/seo-article/detail/${uuid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log('**Article Response:', articleResponse.data);
        setArticle(articleResponse.data);
        setLoading(false);
  
        if (!isEditingTitle) {
          setEditableTitle(articleResponse.data.title);
        }
  
        console.log('**Share Status:', articleResponse.data.share_status);
        setShareStatus(prevStatus => articleResponse.data.share_status || prevStatus || 'private');
        setShareUrl(prevUrl => {
          if (articleResponse.data.share_status === 'private') {
            return '';
          }
          return articleResponse.data.share_url || prevUrl || '';
        });

        if (articleResponse.data.top_topics) {
          const parsedTopTopics = typeof articleResponse.data.top_topics === 'string'
            ? JSON.parse(articleResponse.data.top_topics)
            : articleResponse.data.top_topics;
          setTopTopics(parsedTopTopics.filter(topic => topic[0] && !topic[0].startsWith('トピック')));
        }
        
        if (articleResponse.data.unique_topics) {
          const parsedUniqueTopics = typeof articleResponse.data.unique_topics === 'string'
            ? JSON.parse(articleResponse.data.unique_topics)
            : articleResponse.data.unique_topics;
          setUniqueTopics(parsedUniqueTopics.filter(topic => topic[0] && !topic[0].startsWith('トピック')));
        }
        
        if (articleResponse.data.related_keywords) {
          const parsedRelatedKeywords = typeof articleResponse.data.related_keywords === 'string'
            ? JSON.parse(articleResponse.data.related_keywords)
            : articleResponse.data.related_keywords;
          setRelatedKeywords(parsedRelatedKeywords);
        }
  
        if (articleResponse.data.query_insights_summaries) {
          setQueryInsightsSummaries(JSON.parse(articleResponse.data.query_insights_summaries));
        }
    
        const statusResponse = await axios.get(`${process.env.REACT_APP_API_URL}/seo-article/status/${uuid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log('Status Response:', statusResponse);
        setTopicCheckStatus(statusResponse.data.topic_check_status);
        setContentGenerationStatus(statusResponse.data.content_generation_status);
        setLastJobStatus({
          topicCheck: statusResponse.data.last_topic_check_status,
          contentGeneration: statusResponse.data.last_content_generation_status
        });
      } catch (error) {
        console.error('Failed to fetch article or status:', error);
        message.error('記事情報の取得に失敗しました');
        setLoading(false);
      }
    };
  
    const fetchProducts = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/seo-article/products/${uuid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProducts(response.data);
      } catch (error) {
        // message.error('Failed to fetch products');
      }
    };
  
    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('${process.env.REACT_APP_API_URL}/users/me', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUserPlan(response.data.plan?.name);
        setUserRole(response.data.role);
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    };
  
    fetchArticleAndStatus();
    fetchProducts();
    fetchUserInfo();
  
    const intervalId = setInterval(() => {
      fetchArticleAndStatus();
      fetchProducts();
    }, 5000);
  
    return () => {
      clearInterval(intervalId);
    };
  
  }, [uuid, isEditingTitle, shareStatus]);

  const handleTopicCheck = async (isRetry = false) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${process.env.REACT_APP_API_URL}/seo-article/detail/${uuid}/check_topics`, { is_retry: isRetry }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTopicCheckStatus('queued');
      // message.success(isRetry ? 'トピックチェックの再実行を開始しました' : 'トピックチェックを開始しました');
    } catch (error) {
      console.error('Failed to start topic check:', error);
      message.error('トピックチェックの開始に失敗しました');
    }
  };

  const handleContentGeneration = async (isRetry = false) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${process.env.REACT_APP_API_URL}/seo-article/generate_article_content/${uuid}`, { is_retry: isRetry }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setContentGenerationStatus('queued');
      // message.success(isRetry ? '本文生成の再実行を開始しました' : '本文生成を開始しました');
    } catch (error) {
      console.error('Failed to start content generation:', error);
      message.error('本文生成の開始に失敗しました');
    }
  };

  const renderTopicCheckButton = () => {
    const baseButtonStyle = {
      border: 'none',
      transition: 'all 0.3s',
      width: '100%',
      fontWeight: 'bold',
    };
  
    const activeButtonStyle = {
      ...baseButtonStyle,
      background: 'linear-gradient(90deg, #0072ff 0%, #00d4ff 100%)',
    };
  
    const disabledButtonStyle = {
      ...baseButtonStyle,
      background: '#f5f5f5',
      color: 'rgba(0, 0, 0, 0.25)',
    };
  
    if (topicCheckStatus === 'queued' || topicCheckStatus === 'processing') {
      return (
        <Button 
          disabled 
          block
          size="large"
          style={disabledButtonStyle}
          icon={topicCheckStatus === 'processing' ? <Spin /> : null}
        >
          {topicCheckStatus === 'queued' ? 'トピックチェック予約中' : 'トピックチェック実行中'}
        </Button>
      );
    }
  
    const isRetry = lastJobStatus.topicCheck === 'failed';
    const handleClick = () => {
      if (userPlan === 'free') {
        message.warning('含有トピックの自動チェック機能は、スタンダード以上のプランでご利用になれます。');
      } else {
        showConfirm('checkTopics', isRetry);
      }
    };
  
    const buttonStyle = userPlan === 'free' ? disabledButtonStyle : activeButtonStyle;
  
    return (
      <Tooltip title={userPlan === 'free' ? "含有トピックの自動チェック機能は、スタンダード以上のプランでご利用になれます。" : ""}>
        <Button 
          onClick={handleClick}
          type="primary"
          size="large"
          style={buttonStyle}
          disabled={userPlan === 'free'}
          icon={userPlan === 'free' ? <LockOutlined /> : null}
          onMouseEnter={(e) => {
            if (userPlan !== 'free') {
              e.currentTarget.style.opacity = '0.7';
            }
          }}
          onMouseLeave={(e) => {
            if (userPlan !== 'free') {
              e.currentTarget.style.opacity = '1.0';
            }
          }}
        >
          {isRetry ? 'トピックチェックを再実行する' : '見出し含有トピックをチェックする'}
          {userPlan !== 'free' && <RightOutlined style={{marginLeft: 'auto', right: '10px', position: 'absolute', top: '50%', transform: 'translate(0, -50%)', fontSize: '14px' }} />}
        </Button>
      </Tooltip>
    );
  };

  const renderContentGenerationButton = () => {
    const buttonStyle = {
      background: 'linear-gradient(90deg, #0072ff 0%, #00d4ff 100%)',
      border: 'none',
      transition: 'all 0.3s',
      width: '100%',
      fontWeight: 'bold',
    };

    if (contentGenerationStatus === 'queued' || contentGenerationStatus === 'processing') {
      return (
        <Button 
          disabled 
          block
          size="large"
          style={{ fontWeight: 'bold' }}
          icon={contentGenerationStatus === 'processing' ? <Spin /> : null}
        >
          {contentGenerationStatus === 'queued' ? '本文生成予約中' : '本文生成実行中'}
        </Button>
      );
    }
  
    const isRetry = lastJobStatus.contentGeneration === 'failed';
    return (
      <Button 
        onClick={() => showConfirm('generateArticleContent', isRetry)} 
        style={buttonStyle}
        type="primary"
        size="large"
        onMouseEnter={(e) => {
          e.currentTarget.style.opacity = '0.7';
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.opacity = '1.0';
        }}
      >
        {isRetry ? '本文生成を再実行する' : '記事の本文を生成する'}
        <RightOutlined style={{marginLeft: 'auto', right: '10px', position: 'absolute', top: '50%', transform: 'translate(0, -50%)', fontSize: '14px' }} />
      </Button>
    );
  };

  const fetchArticleDetails = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/seo-article/detail/${uuid}`);
      setArticle(response.data);
      setShareStatus(response.data.share_status || 'private');
      setShareUrl(response.data.share_url || '');
    } catch (error) {
      console.error('Failed to fetch article details:', error);
    }
  };

  const handleShareStatusChange = async (status) => {
    try {
      const data = { status };
      if (status === 'password') {
        data.password = sharePassword;
      }
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/seo-article/share/${uuid}`, data, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      
      // APIレスポンスに基づいて状態を更新
      setShareStatus(response.data.status);
      setShareUrl(response.data.status === 'private' ? '' : response.data.url || '');
  
      message.success('共有設定を更新しました');
    } catch (error) {
      console.error('Failed to change share status:', error);
      message.error('共有設定の更新に失敗しました');
    }
  };

  const copyShareUrl = () => {
    navigator.clipboard.writeText(shareUrl);
    message.success('URLをコピーしました');
  };

  const getShareMenuItems = () => {
    const items = [
      {
        key: 'private',
        label: (
          <span>
            {shareStatus === 'private' && <CheckOutlined style={{ marginRight: 8 }} />}
            非公開
          </span>
        ),
        onClick: () => handleShareStatusChange('private'),
      },
      {
        key: 'public',
        label: (
          <span>
            {shareStatus === 'public' && <CheckOutlined style={{ marginRight: 8 }} />}
            URLを公開
          </span>
        ),
        onClick: () => handleShareStatusChange('public'),
      },
    ];
  
    if (userRole === 'admin' || userRole === 'master' || ['premium', 'custom'].includes(userPlan)) {
      items.push({
        key: 'password',
        label: (
          <span>
            {shareStatus === 'password' && <CheckOutlined style={{ marginRight: 8 }} />}
            パスワード付きで公開
          </span>
        ),
        onClick: () => {
          Modal.confirm({
            title: 'パスワードを設定',
            content: (
              <Input.Password
                placeholder="パスワードを入力"
                style={{
                  width: '100%',
                  padding: '7px 16px',
                  backgroundColor: '#f5f5f5',
                  border: '2px solid #f5f5f5',
                  transition: 'all 0.3s'
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = '#eee';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = '#f5f5f5';
                }}
                onFocus={(e) => {
                  e.currentTarget.style.border = '2px solid #0072ff';
                }}
                onBlur={(e) => {
                  e.currentTarget.style.border = '2px solid #f5f5f5';
                }}
                onChange={(e) => setSharePassword(e.target.value)}
              />
            ),
            onOk: () => handleShareStatusChange('password'),
          });
        },
      });
    } else {
      items.push({
        key: 'password',
        label: (
          <Tooltip title="パスワード付きの公開はプレミアム以上のプランでご利用になれます。">
            <span style={{ color: '#d9d9d9' }}>
              パスワード付きで公開
            </span>
          </Tooltip>
        ),
        disabled: true,
      });
    }
  
    return items;
  };
  
  const shareMenu = {
    items: getShareMenuItems(),
  };
  
  const getShareButtonText = (status) => {
    switch (status) {
      case 'public': return 'URLを公開中';
      case 'password': return 'パスワード付きで公開中';
      default: return '非公開';
    }
  };

  const getShareTooltip = (status) => {
    switch (status) {
      case 'public': return 'この生成結果ページは公開されています。クリックして設定を変更できます。';
      case 'password': return 'この生成結果ページはパスワード付きで公開されています。クリックして設定を変更できます。';
      default: return 'この生成結果ページは非公開です。クリックして共有設定を変更できます。';
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!article) {
    return <Typography.Title level={3}>記事が見つかりません</Typography.Title>;
  }

  const searchIntents = article.search_intents ? JSON.parse(article.search_intents) : [];
  const readerGoals = article.reader_goals ? JSON.parse(article.reader_goals) : { content_goal: "", best_result: "" };
  const searchPersona = article.search_persona || "";

  const topTopicsData = article && article.top_topics ? JSON.parse(article.top_topics).map(([topic, frequency, checked], index) => ({
    key: index,
    topic,
    frequency: parseInt(frequency, 10),
    checked: checked !== 0,
  })) : [];
  
  const uniqueTopicsData = article && article.unique_topics ? JSON.parse(article.unique_topics).map(([topic, uniqueness, checked], index) => ({
    key: index,
    topic,
    uniqueness: parseInt(uniqueness, 10),
    checked: checked !== 0,
  })) : [];

  const queryInsightsData = {
    labels: ['専門性', 'コンバージョン', '緊急性', 'ローカリゼーション', 'ニーズの多様性', 'ビジュアライゼーション', '公式度と信頼性', '情報の最新性', 'UGCやレビュー・体験談'],
    datasets: [
      {
        label: 'Query Insights',
        data: [
          article.expertise_and_depth,
          article.purchase_motivation_and_conversion,
          article.urgency_and_cta,
          article.localization_and_regional_adaptation,
          article.diversity_of_user_needs,
          article.visualization,
          article.source_formality_and_reliability,
          article.freshness_of_information,
          article.ugc_utilization_and_impact,
        ],
        backgroundColor: 'rgba(0,212,255,0.1)',
        borderColor: 'rgb(0 212 255)',
        borderWidth: 2,
      },
    ],
  };

  const queryInsightsDescriptions = [
    { category: '専門性', description: '上位サイトがどの程度専門的で深い情報を提供しているか' },
    { category: 'コンバージョン', description: '上位サイトがどの程度購買意欲を喚起し、コンバージョンを促進しているか' },
    { category: '緊急性', description: '検索者が緊急を要し、また上位サイトが即座の行動を促しているか' },
    { category: 'ローカリゼーション', description: '国や地域に根付いたローカルな情報である度合い' },
    { category: 'ニーズの多様性', description: 'どの程度、多様なユーザーニーズを内容したキーワードであるか' },
    { category: 'ビジュアライゼーション', description: '上位サイトがどの程度ビジュアルコンテンツを提供しているか' },
    { category: '公式度と信頼性', description: '上位サイトの情報源がどの程度公式で信頼できるか' },
    { category: '情報の最新性', description: '上位サイトがどの程度最新の情報を提供しているか' },
    { category: 'UGCやレビュー・体験談', description: '上位サイトがどの程度ユーザー生成コンテンツを活用し、影響力があるか' },
  ];

  const categoryColors = {
    '企業/ブランド': { backgroundColor: '#e6f7ff', color: '#1890ff' },
    '商品/サービス': { backgroundColor: '#fff2e8', color: '#fa8c16' },
    '機能/プラン': { backgroundColor: '#f9f0ff', color: '#7b61ff' },
    'ウェブサイト': { backgroundColor: '#f8f9cd', color: '#b5b01f' },
    '店舗/施設': { backgroundColor: '#f0fff1', color: '#33b65e' },
    '書籍/コンテンツ': { backgroundColor: '#f3e4ee', color: '#c76fbf' },
    '公的機関': { backgroundColor: '#fffbe6', color: '#faad14' },
    'その他': { backgroundColor: '#f0f0f0', color: '#8c8c8c' },
  };

  const productsColumns = [
    {
      title: '関連スコア',
      dataIndex: 'article_data',
      key: 'relevance',
      render: (article_data, record) => {
        const relevance = article_data[article.id]?.relevance || 0;
        return (
          <Progress
            percent={relevance * 20}  // relevanceは1-5のスケールなので、100%に合わせるために20を掛ける
            steps={5}
            size={7}
            strokeColor={{
              '0%': '#108ee9',
              '100%': '#87d068',
            }}
            format={() => null}
          />
        );
      },
    },
    {
      title: 'プロダクト名',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'カテゴリー',
      dataIndex: 'category',
      key: 'category',
      render: (category) => (
        <span
          style={{
            display: 'inline-block',
            padding: '2px 8px',
            borderRadius: '4px',
            backgroundColor: categoryColors[category]?.backgroundColor || '#f0f0f0',
            color: categoryColors[category]?.color || '#000',
            fontSize: '12px',
            width:'100px',
            textAlign:'center '
          }}
        >
          {category}
        </span>
      ),
    },
    {
      title: '説明文',
      dataIndex: 'article_data',
      key: 'description',
      render: (article_data, record) => article_data[article.id]?.description || '',
    },
    {
      title: '出現数 / 公式サイト数',
      key: 'count',
      render: (_, record) => {
        const articleData = record.article_data[article.id] || {};
        return (
          <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
            {articleData.count || 0} / {articleData.official_count || 0}
          </span>
        );
      },
    },
  ];

  const handleRelatedKeywordCheck = async (keyword) => {
    // 即時にUIを更新
    const updatedRelatedKeywords = relatedKeywords.map((item) => {
      if (item[0] === keyword) {
        return [item[0], item[1], item[2] === 0 ? 5 : 0];
      }
      return item;
    });
    setRelatedKeywords(updatedRelatedKeywords);
  
    // 短いローディング状態を表示
    setUpdatingTopics(prev => ({ ...prev, relatedKeywords: true }));
    
    // 少し遅延してローディングを消す（例: 500ms後）
    setTimeout(() => {
      setUpdatingTopics(prev => ({ ...prev, relatedKeywords: false }));
    }, 500);
  
    // バックグラウンドで更新処理を継続
    updateArticle({ related_keywords: updatedRelatedKeywords })
      .then(() => {
        // 成功時の処理（静かに状態を同期）
        setRelatedKeywords(updatedRelatedKeywords);
      })
      .catch((error) => {
        console.error('Failed to update related keywords:', error);
        // エラー時は元の状態に戻す
        setRelatedKeywords(relatedKeywords);
        message.error('関連キーワードの更新に失敗しました');
      });
  };
  
  const handleTopTopicCheck = async (topic) => {
    // 即時にUIを更新
    const updatedTopTopics = topTopics.map((item) => {
      if (item[0] === topic) {
        return [item[0], item[1], item[2] === 0 ? 5 : 0];
      }
      return item;
    });
    setTopTopics(updatedTopTopics);
  
    // 短いローディング状態を表示
    setUpdatingTopics(prev => ({ ...prev, topTopics: true }));
    
    // 少し遅延してローディングを消す（例: 500ms後）
    setTimeout(() => {
      setUpdatingTopics(prev => ({ ...prev, topTopics: false }));
    }, 500);
  
    // バックグラウンドで更新処理を継続
    updateArticle({ top_topics: updatedTopTopics })
      .then(() => {
        // 成功時の処理（静かに状態を同期）
        setTopTopics(updatedTopTopics);
      })
      .catch((error) => {
        console.error('Failed to update top topics:', error);
        // エラー時は元の状態に戻す
        setTopTopics(topTopics);
        message.error('主要トピックの更新に失敗しました');
      });
  };
  
  const handleUniqueTopicCheck = async (topic) => {
    // 即時にUIを更新
    const updatedUniqueTopics = uniqueTopics.map((item) => {
      if (item[0] === topic) {
        return [item[0], item[1], item[2] === 0 ? 5 : 0];
      }
      return item;
    });
    setUniqueTopics(updatedUniqueTopics);
  
    // 短いローディング状態を表示
    setUpdatingTopics(prev => ({ ...prev, uniqueTopics: true }));
    
    // 少し遅延してローディングを消す（例: 500ms後）
    setTimeout(() => {
      setUpdatingTopics(prev => ({ ...prev, uniqueTopics: false }));
    }, 500);
  
    // バックグラウンドで更新処理を継続
    updateArticle({ unique_topics: updatedUniqueTopics })
      .then(() => {
        // 成功時の処理（静かに状態を同期）
        setUniqueTopics(updatedUniqueTopics);
      })
      .catch((error) => {
        console.error('Failed to update unique topics:', error);
        // エラー時は元の状態に戻す
        setUniqueTopics(uniqueTopics);
        message.error('独自トピックの更新に失敗しました');
      });
  };

  const UpdatingTableWrapper = ({ updating, children }) => (
    <div style={{ position: 'relative' }}>
      {children}
      {updating && (
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000
        }}>
          <Spin size="small" />
        </div>
      )}
    </div>
  );

  const updateArticle = async (data) => {
    try {
      if (userPlan === 'free') {
        if (data.title || data.structured_headings) {
          message.warning('編集機能はスタンダード以上のプランでご利用になれます。');
          return;
        }
      }
      
      const token = localStorage.getItem('token');
      const updatedData = { ...data };
      ['top_topics', 'unique_topics', 'related_keywords'].forEach(key => {
        if (updatedData[key]) {
          if (typeof updatedData[key] === 'string') {
            try {
              updatedData[key] = JSON.parse(updatedData[key]);
            } catch (error) {
              console.error(`Failed to parse ${key}:`, error);
              message.error(`${key}の形式が正しくありません`);
              return;
            }
          }
        }
      });
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/seo-article/detail/${article.uuid}`, updatedData, {
        headers: { 
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
      if (response.status === 200) {
        message.success('データを更新しました');
      } else {
        throw new Error('Update failed');
      }
    } catch (error) {
      console.error('Failed to update article:', error);
      throw error;
    }
  };

  const handleGenerateStructure = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${process.env.REACT_APP_API_URL}/seo-article/detail/${article.uuid}`, { status: '更新中' }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      await axios.post(`${process.env.REACT_APP_API_URL}/seo-article/generate_structure/${article.uuid}`, null, {
        headers: { Authorization: `Bearer ${token}` },
      });
      await axios.put(`${process.env.REACT_APP_API_URL}/seo-article/detail/${article.uuid}`, { status: '生成完了' }, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } catch (error) {
      console.error('Failed to generate structure:', error);
      message.error('構造の生成に失敗しました');
    }
  };

  const updateArticleStructure = async (structuredHeadings) => {
    if (userPlan === 'free') {
      message.warning('構成案の編集機能はスタンダード以上のプランでご利用になれます。');
      return;
    }
  
    if (!Array.isArray(structuredHeadings)) {
      console.error('structuredHeadings is not an array:', structuredHeadings);
      return;
    }
  
    const markdownStructure = structuredHeadings.map((item) => {
      const headingText = `${item.type === 'h2' ? '$$' : '###'} ${item.text}`;
      if (item.children) {
        const childrenText = item.children.map((child) => `### ${child.text}`).join('\n');
        return `${headingText}\n${childrenText}`;
      }
      return headingText;
    }).join('\n');    
  
    if (markdownStructure === article.structured_headings) {
      return;
    }
  
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${process.env.REACT_APP_API_URL}/seo-article/detail/${article.uuid}`, {
        structured_headings: markdownStructure,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      message.success('構成案を更新しました');
    } catch (error) {
      console.error('Failed to update article structure:', error);
      message.error('構成案の更新に失敗しました');
    }
  };

  const handleCopyStructure = () => {
    if (article.structured_headings) {
      const formattedStructure = article.structured_headings
        .split('\n')
        .map(line => {
          if (line.startsWith('$$')) {
            return `<h2>${line.slice(2).trim()}</h2>`;
          } else if (line.startsWith('###')) {
            return `<h3>${line.slice(3).trim()}</h3>`;
          }
          return line;
        })
        .join('\n');
  
      navigator.clipboard.writeText(formattedStructure)
        .then(() => {
          message.success('構成案をコピーしました！');
        })
        .catch((error) => {
          console.error('Failed to copy structure:', error);
          message.error('構成案のコピーに失敗しました');
        });
    }
  };

  const renderStructuredHeadings = (headings) => {
    if (!headings) return null;
    const lines = headings.split('\n');
    let h2Count = 0;
    let h3Count = 0;
    
    return lines.map((line, index) => {
      if (line.startsWith('$$')) {
        h2Count++;
        h3Count = 0;
        const headingText = line.slice(2).trim();
        const headingId = `content-h2-${h2Count}`;
        return (
          <h2 key={index} onClick={() => scrollToHeading(headingId)}>
            {headingText}
          </h2>
        );
      } else if (line.startsWith('###')) {
        h3Count++;
        const headingText = line.slice(3).trim();
        const headingId = `content-h2-${h2Count}-h3-${h3Count}`;
        return (
          <h3 key={index} onClick={() => scrollToHeading(headingId)}>
            {headingText}
          </h3>
        );
      }
      return null;
    });
  };

  const scrollToHeading = (headingId) => {
    const element = document.getElementById(headingId);
    if (element) {
      const offset = 0; // ヘッダーの高さなどに応じて調整
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  const ArticleContent = ({ content }) => {
    if(!content) return null;
    const copyHtml = () => {
      const formattedHtml = content.replace(/></g, '>\n<');
      navigator.clipboard.writeText(formattedHtml)
        .then(() => {
          message.success('HTMLをコピーしました');
        })
        .catch((error) => {
          message.error('HTMLのコピーに失敗しました');
          console.error('Failed to copy HTML: ', error);
        });
    };
  
    const copyText = () => {
      const escapedContent = content
        .replace(/<h2>/g, '\n\n\n[h2]')
        .replace(/<\/h2>/g, '[/h2]\n')
        .replace(/<h3>/g, '\n\n\n[h3]')
        .replace(/<\/h3>/g, '[/h3]\n')
        .replace(/<h4>/g, '\n\n[h4]')
        .replace(/<\/h4>/g, '[/h4]\n')
        .replace(/<ul>/g, '\n\n')
        .replace(/<\/ul>/g, '\n')
        .replace(/<li>/g, '・')
        .replace(/<\/li>/g, '')
        .replace(/<table>/g, '\n\n')
        .replace(/<\/table>/g, '\n')
        .replace(/<tr>/g, '')
        .replace(/<\/tr>/g, '')
        .replace(/<td>/g, '|')
        .replace(/<\/td>/g, '')
        .replace(/<th>/g, '|')
        .replace(/<\/th>/g, '')
        .replace(/<p>/g, '\n')
        .replace(/<\/p>/g, '\n');
      navigator.clipboard.writeText(escapedContent.trim())
        .then(() => {
          message.success('テキストをコピーしました');
        })
        .catch((error) => {
          message.error('テキストのコピーに失敗しました');
          console.error('Failed to copy text: ', error);
        });
    };

    const addIdsToHeadings = (htmlContent) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, 'text/html');
      
      let h2Count = 0;
      let h3Count = 0;
      
      doc.querySelectorAll('h2, h3, h4').forEach((heading) => {
        if (heading.tagName === 'H2') {
          h2Count++;
          h3Count = 0;
          heading.id = `content-h2-${h2Count}`;
        } else if (heading.tagName === 'H3') {
          h3Count++;
          heading.id = `content-h2-${h2Count}-h3-${h3Count}`;
        } else if (heading.tagName === 'H4') {
          heading.id = `content-h2-${h2Count}-h3-${h3Count}-h4-${heading.textContent.replace(/\s+/g, '-').toLowerCase()}`;
        }
      });
    
      return doc.body.innerHTML;
    };
  
    return (
      <div className="article-content">
        <div className="copy-buttons">
          <Button onClick={copyHtml} icon={<CopyOutlined />}>HTMLをコピー</Button>
          <Button onClick={copyText} icon={<CopyOutlined />}>テキストをコピー</Button>
        </div>
        <div 
          className="html-content" 
          dangerouslySetInnerHTML={{ __html: addIdsToHeadings(content) }} 
        />
      </div>
    );
  };

  const fixedColumn30 = {
    width: 30,
    paddingLeft: 8,
    paddingRight: 8,
  };
  const fixedColumn50 = {
    width: 50,
    paddingLeft: 8,
    paddingRight: 8,
  };
  const fixedColumn240 = {
    width: 240,
    paddingLeft: 16,
    paddingRight: 16,
  };

  return (
    <div id="a_detail">
      <Row gutter={24}>
        <Col span={14}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>
              <Link to="/seo-article">SEO記事生成</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/seo-article/list">生成結果一覧</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>「{article?.keyword}」の生成結果</Breadcrumb.Item>
          </Breadcrumb>
          <Title level={1} className='gradient-text' style={{fontSize:'24px'}}>「{article.keyword}」の生成結果</Title>
          <Text type="secondary" style={{ display: 'block', marginBottom: '24px' }}>
            生成完了日: {new Date(article.completed_at).toLocaleString()}
          </Text>
        </Col>
        <Col span={10} style={{ textAlign: 'right' }}>
          <Space direction="vertical" align="end" size="small">
            <div style={{ display: 'flex', alignItems: 'center', marginBottom:'8px' }}>
              <Text type="secondary">
                共有設定：
              </Text>
              <Tooltip title={getShareTooltip(shareStatus)}>
                <Dropdown menu={{ items: getShareMenuItems() }} trigger={['click']}>
                  <Button>
                    {getShareButtonText(shareStatus)}
                    <Divider type="vertical" />
                    <SettingOutlined />
                  </Button>
                </Dropdown>
              </Tooltip>
            </div>
            {shareStatus !== 'private' && (
              <Input.Group compact style={{ display: 'flex' }}>
                <Input
                  style={{ flex: 1, minWidth: '300px' }}
                  value={shareUrl}
                  readOnly
                />
                <Button icon={<CopyOutlined />} onClick={copyShareUrl}>
                  コピー
                </Button>
              </Input.Group>
            )}
          </Space>
        </Col>
      </Row>
      <Divider style={{ margin: '24px 0' }} />
      <Tabs 
        defaultActiveKey="1"
        type="card"
        size="middle"
        style={{width: '100%'}}
      >
        <TabPane tab={<span><RadarChartOutlined style={{fontSize:'16px'}} />&nbsp;クエリインサイト360</span>} key="1">
          <div style={{ display: 'flex' }}>
            <div style={{ width: '50%', height: '500px', marginTop: '40px' }}>
              <Radar
                data={{
                  labels: queryInsightsData.labels,
                  datasets: queryInsightsData.datasets,
                }}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  scales: {
                    r: {
                      min: 0,
                      max: 5,
                      ticks: {
                        stepSize: 1,
                        font: {
                          size: 12,
                        },
                      },
                      pointLabels: {
                        font: {
                          size: 12,
                        },
                      },
                    },
                  },
                }}
              />
            </div>
            <div style={{ width: '45%', marginLeft:'5%' }}>
              <Title level={3} style={{fontSize:'14px',color:'rgb(0 212 255)'}}>
                クエリインサイト360
                <Tooltip title="上位サイトの特徴を分析し、9つの観点からAIがスコア化した独自の指標です。">
                  <InfoCircleOutlined style={{marginLeft:'6px', color: 'rgb(0 212 255)'}} />
                </Tooltip>
                ：
              </Title>
              <Collapse 
                defaultActiveKey={['0']}
                accordion
                expandIconPosition="left"
                bordered={false}
              >
                {queryInsightsDescriptions.map((item, index) => (
                  <Panel 
                    header={
                      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <span>{item.category}</span>
                        <Tooltip title={item.description}>
                          <InfoCircleOutlined style={{color: 'rgba(0, 0, 0, 0.45)'}} />
                        </Tooltip>
                      </div>
                    }
                    key={index}
                    style={{
                      borderBottom: index === queryInsightsDescriptions.length - 1 ? 'none' : '1px solid #f0f0f0',
                      transition: 'background-color 0.3s',
                    }}
                  >
                    <p>{queryInsightsSummaries[item.category] || ''}</p>
                  </Panel>
                ))}
              </Collapse>
            </div>
          </div>
        </TabPane>
        <TabPane tab={<span><ContactsOutlined style={{fontSize:'16px'}} />&nbsp;検索者の分析</span>} key="2">
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1, marginRight: '36px' }}>
              <Title level={3} style={{fontSize:'14px',color:'rgb(0 212 255)'}}>
                検索意図
                <Tooltip title="キーワードに内包される検索者の知りたいことをAIが分析したデータです。">
                  <InfoCircleOutlined style={{marginLeft:'6px', color: 'rgb(0 212 255)'}} />
                </Tooltip>
                ：
              </Title>
              {article && article.search_intents && (
                <Table
                  bordered
                  dataSource={searchIntents.map((intent, index) => ({ key: index, index: index + 1, intent: intent.replace(/"/g, '') }))}
                  columns={[
                    {
                      dataIndex: 'index',
                      key: 'index',
                      render: (text, record, index) => ({
                        children: text,
                        props: {
                          style: { backgroundColor: '#fafafa', padding: '16px', fontWeight: 'bold' },
                        },
                      }),
                    },
                    {
                      dataIndex: 'intent',
                      key: 'intent',
                    },
                  ]}
                  showHeader={false}
                  pagination={false}
                />
              )}
              <Title level={3} style={{fontSize:'14px', marginTop: '36px', color:'rgb(0 212 255)'}}>
                読者のゴール
                <Tooltip title="検索行動の後で、読者がどのような状態になりたいかをAIが分析したデータです。">
                  <InfoCircleOutlined style={{marginLeft:'6px', color: 'rgb(0 212 255)'}} />
                </Tooltip>
                ：
              </Title>
              {article && article.reader_goals && (
                <Table
                  bordered
                  dataSource={[
                    { key: 'content_goal', label: 'コンテンツのゴール', value: readerGoals.content_goal },
                    { key: 'best_result', label: '最高の結果', value: readerGoals.best_result },
                  ]}
                  columns={[
                    {
                      dataIndex: 'label',
                      key: 'label',
                      render: (text, record, index) => ({
                        children: text,
                        props: {
                          style: { backgroundColor: '#fafafa', padding: '16px', fontWeight: 'bold' },
                        },
                      }),
                    },
                    {
                      dataIndex: 'value',
                      key: 'value',
                    },
                  ]}
                  showHeader={false}
                  pagination={false}
                />
              )}
            </div>
            <div style={{ flex: 1 }}>
              <Title level={3} style={{fontSize:'14px', color:'rgb(0 212 255)'}}>
                検索者のペルソナ
                <Tooltip title="AIが多角的に分析した、中央値的な検索者のペルソナです。">
                  <InfoCircleOutlined style={{marginLeft:'6px', color: 'rgb(0 212 255)'}} />
                </Tooltip>
                ：
              </Title>
              {article && article.search_persona && (
                <Table
                  bordered
                  dataSource={searchPersona.split('\n').map((line, index) => {
                    const [key, value] = line.split(':');
                    return key && value ? { key: index, label: key.trim(), value: value.trim() } : null;
                  }).filter(Boolean)}
                  columns={[
                    {
                      dataIndex: 'label',
                      key: 'label',
                      width: 160,
                      render: (text, record, index) => ({
                        children: text,
                        props: {
                          style: { backgroundColor: '#fafafa', padding: '16px', fontWeight: 'bold' },
                        },
                      }),
                    },
                    {
                      dataIndex: 'value',
                      key: 'value',
                    },
                  ]}
                  showHeader={false}
                  pagination={false}
                />
              )}
            </div>
          </div>
        </TabPane>
        <TabPane tab={<span><BulbOutlined style={{fontSize:'16px'}} />&nbsp;トピック分析</span>} key="3">
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1, marginRight: '24px' }}>
              <Title level={3} style={{fontSize:'14px', color:'rgb(0 212 255)'}}>
                重要語句
                <Tooltip title="上位サイトで頻出している重要な語句です。一般的に用いられるサジェストワードやLSIと比べ、ある意味ではよりリアルなデータと言えます。">
                  <InfoCircleOutlined style={{marginLeft:'6px', color: 'rgb(0 212 255)'}} />
                </Tooltip>
                ：
              </Title>
              <UpdatingTableWrapper updating={updatingTopics.relatedKeywords}>
                <Table
                  columns={[
                    {
                      title: '',
                      dataIndex: 'checked',
                      key: 'check',
                      render: (_, record) => {
                        const keyword = record[0];
                        const checkState = record[2];
                        const checkboxColor = checkState === 1 ? 'pink' : checkState === 2 ? 'red' : checkState === 3 ? 'orange' : 'default';
                        return (
                          <Checkbox
                            checked={checkState !== 0}
                            onChange={() => handleRelatedKeywordCheck(keyword)}
                            className={`custom-checkbox ${checkboxColor}`}
                          />
                        );
                      },
                      ...fixedColumn30,
                    },
                    {
                      title: 'キーワード',
                      dataIndex: 'keyword',
                      key: 'keyword',
                      render: (_, record) => (
                        <span style={{ color: record[2] ? '#ccc' : 'inherit' }}>
                          {record[0]}
                        </span>
                      ),
                    },
                    {
                      title: '出現割合',
                      dataIndex: 'count',
                      key: 'count',
                      render: (_, record) => (
                        <Progress
                          percent={record[1] * 20}
                          steps={5}
                          size={7}
                          strokeColor={{
                            '0%': '#108ee9',
                            '100%': '#87d068',
                          }}
                          format={() => null}
                        />
                      ),
                      ...fixedColumn50,
                    },
                  ]}
                  dataSource={relatedKeywords}
                  className="topic-table"
                  pagination={false}
                />
              </UpdatingTableWrapper>
            </div>
            <div style={{ flex: 1, marginRight: '24px' }}>
              <Title level={3} style={{fontSize:'14px', color:'rgb(0 212 255)'}}>
                上位サイトの主要トピック
                <Tooltip title="上位サイトのコンテンツをAIが分析し、頻出しているトピックを整理したデータです。">
                  <InfoCircleOutlined style={{marginLeft:'6px', color: 'rgb(0 212 255)'}} />
                </Tooltip>
                ：
              </Title>
              <UpdatingTableWrapper updating={updatingTopics.topTopics}>
                <Table
                  columns={[
                    {
                      title: '',
                      dataIndex: 'checked',
                      key: 'check',
                      render: (checked, record) => {
                        const topic = record.topic;
                        const checkState = topTopics.find(([t]) => t === topic)[2];
                        const checkboxColor = checkState === 1 ? 'pink' : checkState === 2 ? 'red' : checkState === 3 ? 'orange' : 'default';
                        return (
                          <Checkbox
                            checked={checked}
                            onChange={() => handleTopTopicCheck(topic)}
                            className={`custom-checkbox ${checkboxColor}`}
                          />
                        );
                      },
                      ...fixedColumn30,
                    },
                    {
                      title: 'トピック',
                      dataIndex: 'topic',
                      key: 'topic',
                      render: (topic, record) => (
                        <span style={{ color: record.checked ? '#ccc' : 'inherit' }}>
                          {topic}
                        </span>
                      ),
                    },
                    {
                      title: '頻出度',
                      dataIndex: 'frequency',
                      key: 'frequency',
                      render: (frequency) => (
                        <Progress
                          percent={frequency * 20}
                          steps={5}
                          size={7}
                          strokeColor={{
                            '0%': '#108ee9',
                            '100%': '#87d068',
                          }}
                          format={() => null}
                        />
                      ),
                      ...fixedColumn50,
                    },
                  ]}
                  dataSource={topTopicsData}
                  className="topic-table"
                  pagination={false}
                />
              </UpdatingTableWrapper>
            </div>
            <div style={{ flex: 1 }}>
              <Title level={3} style={{fontSize:'14px', color:'rgb(0 212 255)'}}>
                独自性を出しやすいトピック
                <Tooltip title="上位サイトが扱っていないが、一定のニーズがある可能性あるトピックのアイデアです。">
                  <InfoCircleOutlined style={{marginLeft:'6px', color: 'rgb(0 212 255)'}} />
                </Tooltip>
                ：
              </Title>
              <UpdatingTableWrapper updating={updatingTopics.uniqueTopics}>
                <Table
                  columns={[
                    {
                      title: '',
                      dataIndex: 'checked',
                      key: 'check',
                      render: (checked, record) => {
                        const topic = record.topic;
                        const checkState = uniqueTopics.find(([t]) => t === topic)[2];
                        const checkboxColor = checkState === 1 ? 'pink' : checkState === 2 ? 'red' : checkState === 3 ? 'orange' : 'default';
                        return (
                          <Checkbox
                            checked={checked}
                            onChange={() => handleUniqueTopicCheck(topic)}
                            className={`custom-checkbox ${checkboxColor}`}
                          />
                        );
                      },
                      ...fixedColumn30,
                    },
                    {
                      title: 'トピック',
                      dataIndex: 'topic',
                      key: 'topic',
                      render: (topic, record) => (
                        <span style={{ color: record.checked ? '#ccc' : 'inherit' }}>
                          {topic}
                        </span>
                      ),
                    },
                    {
                      title: '独自性',
                      dataIndex: 'uniqueness',
                      key: 'uniqueness',
                      render: (uniqueness) => (
                        <Progress
                          percent={uniqueness * 20}
                          steps={5}
                          size={7}
                          strokeColor={{
                            '0%': '#108ee9',
                            '100%': '#87d068',
                          }}
                          format={() => null}
                        />
                      ),
                      ...fixedColumn50,
                    },
                  ]}
                  dataSource={uniqueTopicsData}
                  className="topic-table"
                  pagination={false}
                />
              </UpdatingTableWrapper>
            </div>
          </div>
        </TabPane>
        <TabPane tab={<span><ProductOutlined style={{fontSize:'16px'}} />&nbsp;プロダクト分析</span>} key="4">
          <Title level={3} style={{fontSize:'14px',color:'rgb(0 212 255)'}}>
            上位サイトで紹介されているプロダクト
            <Tooltip title="上位サイトで紹介されている商品やサービス、機関などをAIが分析したデータです。">
              <InfoCircleOutlined style={{marginLeft:'6px', color: 'rgb(0 212 255)'}} />
            </Tooltip>
            ：
          </Title>
          {products && products.length > 0 &&(
            <Table
              dataSource={products.sort((a, b) => (b.article_data[article.id]?.presence || 0) - (a.article_data[article.id]?.presence || 0))}
              columns={productsColumns}
              pagination={false}
            />
          )}
        </TabPane>
        <TabPane tab={<span><BarsOutlined style={{fontSize:'16px'}} />&nbsp;構成案の生成</span>} key="5">
          <div style={{ display: 'flex' }}>
            <div style={{ flex: '0 0 60%' }}>
              <Title level={3} style={{fontSize:'14px', color:'rgb(0 212 255)'}}>AIタイトル
                <Tooltip title="分析したあらゆる情報を元に、AIが生成した記事のタイトル候補です。">
                  <InfoCircleOutlined style={{marginLeft:'6px', color: 'rgb(0 212 255)'}} />
                </Tooltip>
                ：
              </Title>
              <Title level={2} style={{margin:'8px 0 36px'}}>
              <TextArea
                value={editableTitle}
                onChange={(e) => setEditableTitle(e.target.value)}
                onFocus={() => setIsEditingTitle(true)}
                onBlur={async () => {
                  setIsEditingTitle(false);
                  if (editableTitle !== article.title) {
                    try {
                      await updateArticle({ title: editableTitle });
                      setArticle({ ...article, title: editableTitle });
                    } catch (error) {
                      setEditableTitle(article.title);
                    }
                  }
                }}
                autoSize={{ minRows: 1, maxRows: 3 }}
                style={{
                  fontSize: '24px',
                  fontWeight: 'bold',
                  border: 'none',
                  padding: '0',
                  margin: '0',
                  width: '100%',
                  resize: 'none',
                }}
              />
              </Title>

              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom:'4px' }}>
                <Title level={3} style={{fontSize:'14px', color:'rgb(0 212 255)'}}>
                  AI構成案
                  <Tooltip title="あらゆる情報を元にAIが生成した記事の構成案です。並び替えや編集が可能です。">
                    <InfoCircleOutlined style={{marginLeft:'6px', color: 'rgb(0 212 255)'}} />
                  </Tooltip>
                  ：
                </Title>
                <Button onClick={handleCopyStructure} style={{color:'#999',fontSize:'12px',color:'#108ee9'}}><CopyOutlined style={{fontSize:'16px'}} />&nbsp;コピー</Button>
              </div>
              {article.structured_headings && (
                <StructuredHeadings
                  headings={article.structured_headings}
                  onChange={updateArticleStructure}
                  isContentGenerated={article.content !== null && article.content !== ''}
                />
              )}
            </div>
            <div style={{ flex: '0 0 35%', marginLeft: '5%' }}>
              <Affix offsetTop={24}>
                <div className="content-check-table-wrap" style={{ marginBottom: '16px' }}>
                  <div style={{ marginBottom: '16px',textAlign:'right' }}>
                    <Switch
                      checked={showAllTopics}
                      onChange={(checked) => setShowAllTopics(checked)}
                      size="small"
                    />
                    <span style={{fontSize:'12px',marginLeft:'4px'}}>すべて表示</span>
                  </div>
                  <Segmented
                    options={[
                      {
                        label: '重要語句',
                        value: 'relatedKeywords',
                      },
                      {
                        label: '主要トピック',
                        value: 'topTopics',
                      },
                      {
                        label: '独自トピック',
                        value: 'uniqueTopics',
                      },
                    ]}
                    defaultValue="relatedKeywords"
                    onChange={(value) => {
                      setSelectedTab(value);
                    }}
                    style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom:'4px' }}
                    block
                  />
                  {selectedTab === 'relatedKeywords' && (
                    <Table
                      columns={[
                        {
                          title: 'チェック',
                          dataIndex: 'checked',
                          key: 'check',
                          width: '10%',
                          render: (_, record) => {
                            const keyword = record[0];
                            const checkState = record[2];
                            const checkboxColor = checkState === 1 ? 'pink' : checkState === 2 ? 'red' : checkState === 3 ? 'orange' : 'default';
                            return (
                              <Checkbox
                                checked={checkState !== 0}
                                onChange={() => handleRelatedKeywordCheck(keyword)}
                                className={`custom-checkbox ${checkboxColor}`}
                              />
                            );
                          },
                          ...fixedColumn50,
                        },
                        {
                          title: 'キーワード',
                          dataIndex: 'keyword',
                          key: 'keyword',
                          width: '75%',
                          render: (_, [keyword, frequency, checked]) => (
                            <span style={{ color: checked === 0 ? 'inherit' : '#ccc' }}>{keyword}</span>
                          ),
                        },
                        {
                          title: '重要度',
                          dataIndex: 'importance',
                          key: 'importance',
                          width: '15%',
                          render: (_, [keyword, frequency]) => (
                            <Progress
                              percent={frequency * 20}
                              steps={5}
                              size={7}
                              strokeColor={{
                                '0%': '#108ee9',
                                '100%': '#87d068',
                              }}
                              format={() => null}
                            />
                          ),
                        },
                      ]}
                      dataSource={showAllTopics ? relatedKeywords : relatedKeywords.filter(([_, __, checked]) => checked === 0 || checked === 5)}
                      pagination={false}
                      showHeader={false}
                      size="small"
                      className="content-check-table"
                    />
                  )}
                  {selectedTab === 'topTopics' && (
                    <Table
                      columns={[
                        {
                          title: 'チェック',
                          dataIndex: 'checked',
                          key: 'check',
                          width: '10%',
                          render: (_, record) => {
                            const topic = record[0];
                            const checkState = record[2];
                            const checkboxColor = checkState === 1 ? 'pink' : checkState === 2 ? 'red' : checkState === 3 ? 'orange' : 'default';
                            return (
                              <Checkbox
                                checked={checkState !== 0}
                                onChange={() => handleTopTopicCheck(topic)}
                                className={`custom-checkbox ${checkboxColor}`}
                              />
                            );
                          },
                          ...fixedColumn50,
                        },
                        {
                          title: 'トピック',
                          dataIndex: 'topic',
                          key: 'topic',
                          width: '75%',
                          render: (_, [keyword, frequency, checked]) => (
                            <span style={{ color: checked === 0 ? 'inherit' : '#ccc' }}>{keyword}</span>
                          ),
                        },
                        {
                          title: '重要度',
                          dataIndex: 'importance',
                          key: 'importance',
                          width: '15%',
                          render: (_, [keyword, frequency]) => (
                            <Progress
                              percent={frequency * 20}
                              steps={5}
                              size={7}
                              strokeColor={{
                                '0%': '#108ee9',
                                '100%': '#87d068',
                              }}
                              format={() => null}
                            />
                          ),
                        },
                      ]}
                      dataSource={showAllTopics ? topTopics : topTopics.filter(([_, __, checked]) => checked === 0 || checked === 5)}
                      pagination={false}
                      showHeader={false}
                      size="small"
                      className="content-check-table"
                    />
                  )}
                  {selectedTab === 'uniqueTopics' && (
                    <Table
                      columns={[
                        {
                          title: 'チェック',
                          dataIndex: 'checked',
                          key: 'check',
                          width: '10%',
                          render: (_, record) => {
                            const topic = record[0];
                            const checkState = record[2];
                            const checkboxColor = checkState === 1 ? 'pink' : checkState === 2 ? 'red' : checkState === 3 ? 'orange' : 'default';
                            return (
                              <Checkbox
                                checked={checkState !== 0}
                                onChange={() => handleUniqueTopicCheck(topic)}
                                className={`custom-checkbox ${checkboxColor}`}
                              />
                            );
                          },
                          ...fixedColumn50,
                        },
                        {
                          title: 'トピック',
                          dataIndex: 'topic',
                          key: 'topic',
                          width: '75%',
                          render: (_, [keyword, uniqueness, checked]) => (
                            <span style={{ color: checked === 0 ? 'inherit' : '#ccc' }}>{keyword}</span>
                          ),
                        },
                        {
                          title: '独自性',
                          dataIndex: 'uniqueness',
                          key: 'uniqueness',
                          width: '15%',
                          render: (_, [keyword, uniqueness]) => (
                            <Progress
                              percent={uniqueness * 20}
                              steps={5}
                              size={7}
                              strokeColor={{
                                '0%': '#108ee9',
                                '100%': '#87d068',
                              }}
                              format={() => null}
                            />
                          ),
                        },
                      ]}
                      dataSource={showAllTopics ? uniqueTopics : uniqueTopics.filter(([_, __, checked]) => checked === 0 || checked === 5)}
                      pagination={false}
                      showHeader={false}
                      size="small"
                      className="content-check-table"
                    />
                  )}
                </div>
                {article.structured_headings && (
                  renderTopicCheckButton()
                )}
              </Affix>
            </div>
          </div>
        </TabPane>
        <TabPane tab={<span><EditOutlined style={{fontSize:'16px'}} />&nbsp;記事の生成</span>} key="6">
          <div style={{ display: 'flex', justifyContent:'center' }}>
            <div style={{ flex: '0 0 60%', maxWidth: '720px' }}>
              {article.content ? (
                <>
                  <Title level={2} style={{margin:'8px 0 36px'}}>{article.title}</Title>
                  {article.eyecatch_image_url && (
                    <img 
                      src={`${process.env.REACT_APP_API_URL}${article.eyecatch_image_url}`} 
                      alt="Article eyecatch" 
                      style={{ maxWidth: '100%', height: 'auto', marginBottom: '20px' }}
                    />
                  )}
                  <div>
                    <ArticleContent content={article.content} />
                  </div>
                  {/* {article.t_subheading_outlines && (
                    <div>
                      <h3>Subheading Outlines:</h3>
                      <JSONPretty data={article.t_subheading_outlines} />
                    </div>
                  )}
                  {article.t_section_leads && (
                    <div>
                      <h3>Section Leads:</h3>
                      <JSONPretty data={article.t_section_leads} />
                    </div>
                  )}
                  {article.t_section_content && (
                    <div>
                      <h3>Section Content:</h3>
                      <JSONPretty data={article.t_section_content} />
                    </div>
                  )}
                  {article.t_refined_section_content && (
                    <div>
                      <h3>Refined Section Content:</h3>
                      <JSONPretty data={article.t_refined_section_content} />
                    </div>
                  )}
                  {article.t_article_lead && (
                    <div>
                      <h3>Article Lead:</h3>
                      <JSONPretty data={article.t_article_lead} />
                    </div>
                  )} */}
                </>
              ) : (
                <>
                  <Title level={2} style={{margin:'8px 0 36px'}}>{article.title}</Title>
                  <Skeleton 
                    loading={true} 
                    active 
                    paragraph={{ rows: 4, width: ['100%', '100%', '100%', '100%'] }}
                    title={{ width: '20%' }}
                    style={{ marginBottom: '36px' }}
                  >
                    <div style={{ marginBottom: '20px' }}>
                      <Skeleton.Paragraph style={{ width: 200 }} active size="small" />
                      <br />
                      <Skeleton.Paragraph style={{ width: 150, marginTop: 16 }} active size="small" />
                    </div>
                  </Skeleton>
                  {article && article.structured_headings ? (
                    <Title level={3} style={{fontSize:'14px', color:'rgb(0 212 255)', marginBottom: '16px'}}>
                      想定文字数：
                      <span style={{fontSize:'24px', fontWeight:'bold'}}>
                        {(article.structured_headings.split('###').length - 1) * 400}
                      </span>文字
                    </Title>
                  ) : (
                    <Title level={3} style={{fontSize:'14px', color:'rgb(0 212 255)', marginBottom: '16px'}}>
                      想定文字数：計算不可
                    </Title>
                  )}
                  <Paragraph>
                    <ul style={{ lineHeight: 2.0 }}>
                      <li>構成案に沿ってAIが本文を生成します。</li>
                      <li>文字数は目安となります。</li>
                      <li>本文生成のやり直しはできません。</li>
                    </ul>
                  </Paragraph>
                  {renderContentGenerationButton()}
                </>
              )}
            </div>
            <div style={{ flex: '0 0 35%', marginLeft: '5%', position: 'relative', height: '100vh' }}>
              <Affix offsetTop={24} style={{ position: 'absolute', top: 0, width: '100%' }}>
                <div className="content-structure-wrap" style={{ marginBottom: '16px', maxHeight: 'calc(100vh - 48px)', overflowY: 'auto' }}>
                  {article.content ? (
                    <div className="structured-headings-readonly can-scroll">
                      {article && article.structured_headings && renderStructuredHeadings(article.structured_headings)}
                    </div>
                  ) : (
                    <div className="structured-headings-readonly">
                      {article && article.structured_headings && renderStructuredHeadings(article.structured_headings)}
                    </div>
                  )}
                </div>
              </Affix>
            </div>
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ArticleDetail;