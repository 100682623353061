import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Input, Button, message, Row, Col, Typography, Alert, Checkbox, Steps, Divider } from 'antd';
import { registerProfile } from '../../auth';
import styles from './Auth.module.css';
import { api } from '../../api';

const { Title, Text } = Typography;

const RegisterProfile = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const { token } = useParams();
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);

  const customFields = [
    { question: 'カスタム項目1', answer1: '回答1-1', answer2: '回答1-2', answer3: '回答1-3' },
    { question: 'カスタム項目2', answer1: '回答2-1', answer2: '回答2-2', answer3: '回答2-3' },
    { question: 'カスタム項目3', answer1: '回答3-1', answer2: '回答3-2', answer3: '回答3-3' },
  ];

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        const response = await api.get(`/auth/confirm-email/${token}`);
        setEmailConfirmed(true);
        setEmail(response.data.email);
        message.success('メールアドレスの認証が完了しました。');
      } catch (error) {
        if (error.response && error.response.status === 400) {
          setEmailConfirmed(false);
          setTokenExpired(true);
        } else {
          message.error('無効なトークンです。再度登録をお試しください。');
          navigate('/register-email');
        }
      }
    };
    confirmEmail();
  }, [token, navigate]);

  const handleRegisterProfile = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('email', email);
      formData.append('last_name', values.full_name.last_name);
      formData.append('first_name', values.full_name.first_name);
      formData.append('last_name_kana', values.full_name_kana.last_name_kana);
      formData.append('first_name_kana', values.full_name_kana.first_name_kana);
      formData.append('company_name', values.company_name);
      formData.append('department_name', values.department_name);
      formData.append('password', values.password);
      formData.append('confirm_password', values.confirm_password);
      formData.append('custom_field_1', values.custom_field_1 ? values.custom_field_1.join(',') : '');
      formData.append('custom_field_2', values.custom_field_2 ? values.custom_field_2.join(',') : '');
      formData.append('custom_field_3', values.custom_field_3 ? values.custom_field_3.join(',') : '');
      formData.append('token', token);
  
      console.log('Sending form data:', Object.fromEntries(formData.entries()));
  
      await registerProfile(formData);
      message.success('会員登録が完了しました。ログインしてください。');
      navigate('/login');
    } catch (error) {
      console.error('Registration error:', error);
      if (error.response && error.response.data && error.response.data.detail) {
        message.error(`会員登録に失敗しました: ${error.response.data.detail}`);
      } else {
        message.error('会員登録に失敗しました');
      }
    }
    setLoading(false);
  };

  const steps = [
    {
      title: '認証URLの送信',
    },
    {
      title: 'メール確認/認証',
    },
    {
      title: '情報入力(2分)',
    },
  ];

  return (
    <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
      <Col xs={22} sm={20} md={18} lg={10}>
        <div
          className={styles.authContainer}
          style={{ boxShadow: '0 0 50px 0 rgba(0, 0, 0, 0.1)' }}
        >
          <div className='logo outside'>
            magicss
          </div>
          <Title
            level={1}
            style={{
              fontSize: '20px',
              margin: '0 auto 24px',
              display: 'inline-block',
            }}
          >
            新規無料ユーザー登録
          </Title>
          <Steps 
            current={2}
            items={steps} 
            labelPlacement='vertical' 
            size='small' 
            style={{ marginBottom: '28px' }}
          />
          {tokenExpired ? (
            <Alert
              message="無効なトークン"
              description="トークンの有効期限が切れています。再度登録をお試しください。"
              type="error"
              showIcon
              action={
                <Button type="primary" onClick={() => navigate('/register-email')}>
                  新規登録
                </Button>
              }
            />
          ) : emailConfirmed ? (
            <>
              <Text style={{ marginBottom: '24px', textAlign: 'center' }}>
                <span style={{ color: '#ff0000' }}>
                  ※※※まだ登録は完了していません※※※
                </span>
                <br />
                以下のフォームに必要事項を入力し、15分以内に会員登録を完了してください。
              </Text>
              <Divider style={{ margin: '0 0 24px', borderColor: '#f5f5f5' }} />
              <Form onFinish={handleRegisterProfile} layout="vertical" autoComplete="on">
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="company_name"
                      label={<span className={styles.label}>会社名</span>}
                    >
                      <Input
                        placeholder="会社名"
                        size="large"
                        style={{
                          border: '2px solid #f5f5f5',
                          backgroundColor: '#f5f5f5',
                          padding: '8px',
                          transition: 'all 0.3s',
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor = '#eee';
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor = '#f5f5f5';
                        }}
                        onFocus={(e) => {
                          e.currentTarget.style.border = '2px solid #0072ff';
                        }}
                        onBlur={(e) => {
                          e.currentTarget.style.border = '2px solid #f5f5f5';
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="department_name"
                      label={<span className={styles.label}>部署名</span>}
                    >
                      <Input
                        placeholder="部署名"
                        size="large"
                        style={{
                          border: '2px solid #f5f5f5',
                          backgroundColor: '#f5f5f5',
                          padding: '8px',
                          transition: 'all 0.3s',
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor = '#eee';
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor = '#f5f5f5';
                        }}
                        onFocus={(e) => {
                          e.currentTarget.style.border = '2px solid #0072ff';
                        }}
                        onBlur={(e) => {
                          e.currentTarget.style.border = '2px solid #f5f5f5';
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name={['full_name', 'last_name']}
                      label={<span className={styles.label}>姓</span>}
                      rules={[{ required: true, message: '姓を入力してください' }]}
                    >
                      <Input
                        placeholder="姓"
                        size="large"
                        style={{
                          border: '2px solid #f5f5f5',
                          backgroundColor: '#f5f5f5',
                          padding: '8px',
                          transition: 'all 0.3s',
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor = '#eee';
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor = '#f5f5f5';
                        }}
                        onFocus={(e) => {
                          e.currentTarget.style.border = '2px solid #0072ff';
                        }}
                        onBlur={(e) => {
                          e.currentTarget.style.border = '2px solid #f5f5f5';
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={['full_name', 'first_name']}
                      label={<span className={styles.label}>名</span>}
                      rules={[{ required: true, message: '名を入力してください' }]}
                    >
                      <Input
                        placeholder="名"
                        size="large"
                        style={{
                          border: '2px solid #f5f5f5',
                          backgroundColor: '#f5f5f5',
                          padding: '8px',
                          transition: 'all 0.3s',
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor = '#eee';
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor = '#f5f5f5';
                        }}
                        onFocus={(e) => {
                          e.currentTarget.style.border = '2px solid #0072ff';
                        }}
                        onBlur={(e) => {
                          e.currentTarget.style.border = '2px solid #f5f5f5';
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name={['full_name_kana', 'last_name_kana']}
                      label={<span className={styles.label}>姓（カタカナ）</span>}
                      rules={[
                        { required: true, message: '姓（カタカナ）を入力してください' },
                      ]}
                    >
                      <Input
                        placeholder="姓（カタカナ）"
                        size="large"
                        style={{
                          border: '2px solid #f5f5f5',
                          backgroundColor: '#f5f5f5',
                          padding: '8px',
                          transition: 'all 0.3s',
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor = '#eee';
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor = '#f5f5f5';
                        }}
                        onFocus={(e) => {
                          e.currentTarget.style.border = '2px solid #0072ff';
                        }}
                        onBlur={(e) => {
                          e.currentTarget.style.border = '2px solid #f5f5f5';
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={['full_name_kana', 'first_name_kana']}
                      label={<span className={styles.label}>名（カタカナ）</span>}
                      rules={[
                        { required: true, message: '名（カタカナ）を入力してください' },
                      ]}
                    >
                      <Input
                        placeholder="名（カタカナ）"
                        size="large"
                        style={{
                          border: '2px solid #f5f5f5',
                          backgroundColor: '#f5f5f5',
                          padding: '8px',
                          transition: 'all 0.3s',
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor = '#eee';
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor = '#f5f5f5';
                        }}
                        onFocus={(e) => {
                          e.currentTarget.style.border = '2px solid #0072ff';
                        }}
                        onBlur={(e) => {
                          e.currentTarget.style.border = '2px solid #f5f5f5';
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider style={{ margin: '0 0 24px', borderColor: '#f5f5f5' }} />
                {customFields.map((field, index) => (
                  <div key={index}>
                    <h4>{field.question}</h4>
                    <Form.Item name={`custom_field_${index + 1}`}>
                      <Checkbox.Group className={styles.checkboxGroup}>
                        <Row>
                          <Col span={8}>
                            <Checkbox value="answer1">{field.answer1}</Checkbox>
                          </Col>
                          <Col span={8}>
                            <Checkbox value="answer2">{field.answer2}</Checkbox>
                          </Col>
                          <Col span={8}>
                            <Checkbox value="answer3">{field.answer3}</Checkbox>
                          </Col>
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                  </div>
                ))}
                <Divider style={{ margin: '0 0 24px', borderColor: '#f5f5f5' }} />
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="password"
                      label={<span className={styles.label}>パスワード</span>}
                      rules={[
                        { required: true, message: 'パスワードを入力してください' },
                        { min: 8, message: 'パスワードは8文字以上で入力してください' },
                        {
                          validator: (_, value) => {
                            if (
                              value &&
                              !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
                                value
                              )
                            ) {
                              return Promise.reject(
                                new Error(
                                  'パスワードは大文字、小文字、数字、記号を含む8文字以上で入力してください'
                                )
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <div
                        style={{
                          border: '2px solid #f5f5f5',
                          backgroundColor: '#f5f5f5',
                          transition: 'all 0.3s',
                          borderRadius: '8px', // 角丸を追加
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor = '#eee';
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor = '#f5f5f5';
                        }}
                        onFocus={(e) => {
                          e.currentTarget.style.border = '2px solid #0072ff';
                        }}
                        onBlur={(e) => {
                          e.currentTarget.style.border = '2px solid #f5f5f5';
                        }}
                      >
                        <Input.Password
                          placeholder="パスワード"
                          autoComplete="new-password"
                          size="large"
                          style={{
                            border: 'none',
                            backgroundColor: 'transparent',
                            outline: 'none',
                            padding: '8px',
                          }}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="confirm_password"
                      label={<span className={styles.label}>パスワード（確認用）</span>}
                      dependencies={['password']}
                      rules={[
                        { required: true, message: 'パスワード（確認用）を入力してください' },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('パスワードが一致しません'));
                          },
                        }),
                      ]}
                    >
                      <div
                        style={{
                          border: '2px solid #f5f5f5',
                          backgroundColor: '#f5f5f5',
                          transition: 'all 0.3s',
                          borderRadius: '8px', // 角丸を追加
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor = '#eee';
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor = '#f5f5f5';
                        }}
                        onFocus={(e) => {
                          e.currentTarget.style.border = '2px solid #0072ff';
                        }}
                        onBlur={(e) => {
                          e.currentTarget.style.border = '2px solid #f5f5f5';
                        }}
                      >
                        <Input.Password
                          placeholder="パスワード（確認用）"
                          autoComplete="new-password"
                          size="large"
                          style={{
                            border: 'none',
                            backgroundColor: 'transparent',
                            outline: 'none',
                            padding: '8px',
                          }}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
                
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    block
                    size="large"
                    style={{
                      background: 'linear-gradient(90deg, #0072ff 0%, #00d4ff 100%)',
                      border: 'none',
                      transition: 'all 0.3s',
                      width: '100%',
                      fontWeight: 'bold',
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.opacity = '0.7';
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.opacity = '1.0';
                    }}
                  >
                    無料登録を完了する
                  </Button>
                </Form.Item>
              </Form>
            </>
          ) : (
            <Alert
              message="メールアドレスの確認中"
              description="メールアドレスの確認中です。しばらくお待ちください。"
              type="info"
              showIcon
            />
          )}
        </div>
      </Col>
    </Row>
  );
};

export default RegisterProfile;