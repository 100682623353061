import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Table,
  Button,
  message,
  Tag,
  Progress,
  Breadcrumb,
  Typography,
  Divider,
  Row,
  Col,
  Space,
  Tooltip,
  Modal,
  Grid,
  Input,
  ConfigProvider,
} from 'antd';
import {
  UserOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
  DeleteOutlined,
  ExportOutlined,
  CheckOutlined,
  MinusOutlined,
} from '@ant-design/icons';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { getUserRole, getUserProfile } from '../../auth';
import moment from 'moment';
import UserDetail from '../UserManagement/UserDetail';
import { calculateCharacterCount } from '../../utils/calculateCharacterCount';
import { formatDateTime } from '../../utils/dateUtils';
import UserSelector from '../Common/UserSelector';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const FactCheckList = () => {
  const [tasks, setTasks] = useState([]);
  const [userRole, setUserRole] = useState('user');
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    showQuickJumper: true,
  });
  const [filters, setFilters] = useState({});
  const [sorter, setSorter] = useState({
    field: 'created_at',
    order: 'descend',
  });
  const [selectedUser, setSelectedUser] = useState(null);
  const [userDetailVisible, setUserDetailVisible] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [userDetailModalVisible, setUserDetailModalVisible] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const resizeObserverBlockerRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();
  const screens = useBreakpoint();

  useEffect(() => {
    // 元のエラーハンドラを保存
    const originalError = window.console.error;

    // ResizeObserverエラーをフィルタリングするエラーハンドラ
    window.console.error = (...args) => {
      // 文字列パターンとエラーオブジェクトの両方を処理
      const errorText = args[0]?.message
        ? args[0].message
        : String(args[0] || '');
      if (errorText.includes && errorText.includes('ResizeObserver loop')) {
        // ResizeObserverエラーは無視
        return;
      }
      // その他のエラーは通常通り処理
      originalError.apply(console, args);
    };

    // ResizeObserverループ完了エラーを防ぐためのグローバルハンドラを追加
    const blocker = e => {
      if (
        e.target.className &&
        typeof e.target.className === 'string' &&
        e.target.className.includes('ant-table')
      ) {
        // テーブル関連のResize Observerエラーの場合、エラーの伝播を停止
        e.stopImmediatePropagation();
      }
    };

    document.addEventListener('error', blocker, true);
    resizeObserverBlockerRef.current = blocker;

    // コンポーネントのアンマウント時に元のエラーハンドラとイベントリスナーを復元
    return () => {
      window.console.error = originalError;
      if (resizeObserverBlockerRef.current) {
        document.removeEventListener(
          'error',
          resizeObserverBlockerRef.current,
          true,
        );
      }
    };
  }, []);

  const tableStyle = {
    tableLayout: 'fixed', // テーブルレイアウトを固定
  };

  const columnStyle = {
    wordWrap: 'break-word', // 長いテキストの折り返し
    whiteSpace: 'normal', // 通常の改行を許可
  };

  useEffect(() => {
    // URLパスからユーザーIDを抽出 (/fact-check/tasks/:userId)
    const pathParts = location.pathname.split('/');
    const userIdFromUrl = pathParts.length >= 4 ? pathParts[3] : null;

    if (userIdFromUrl && userIdFromUrl !== selectedUserId) {
      setSelectedUserId(userIdFromUrl);
    }
  }, [location.pathname]);

  const fetchTasksAndUsers = useCallback(
    async (page, pageSize, currentFilters, currentSorter, userId = null) => {
      setLoading(true);
      try {
        const params = {
          skip: (page - 1) * pageSize,
          limit: pageSize,
          status: currentFilters?.status?.[0] || '',
          title: currentFilters?.title?.[0] || '',
          type: currentFilters?.type?.[0] || '',
          sort_field: currentSorter?.field || 'created_at',
          sort_order: currentSorter?.order
            ? currentSorter.order === 'ascend'
              ? 'asc'
              : 'desc'
            : 'desc',
          user_id: userId,
        };

        if (userId) {
          params.user_id = userId;
        }

        const [tasksResponse, usersResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/fact-check/tasks`, {
            params,
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }),
          userRole === 'admin' || userRole === 'master'
            ? axios.get(`${process.env.REACT_APP_API_URL}/users/`, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
              })
            : Promise.resolve({ data: [] }),
        ]);

        if (tasksResponse.data && tasksResponse.data.tasks) {
          // レスポンスデータの整形
          const processedTasks = tasksResponse.data.tasks.map(task => {
            // タイトルだけを抽出し、文字数計算を行わない
            let title = '';

            try {
              if (task.fact_check_text) {
                const content = JSON.parse(task.fact_check_text);
                const titleBlock = content.blocks?.find(
                  block => block.type === 'header' && block.data?.level === 1,
                );
                title = titleBlock?.data?.text || '';
              }
            } catch (e) {
              // エラーを無視
              console.warn('Failed to parse task content');
            }

            return {
              ...task,
              title,
            };
          });

          setTasks(processedTasks);
          setPagination(prev => ({
            ...prev,
            current: page,
            pageSize: pageSize,
            total: tasksResponse.data.total_count,
            showSizeChanger: true,
            showQuickJumper: true,
          }));

          // ユーザー情報の設定
          if (userRole === 'admin' || userRole === 'master') {
            const userDetailsMap = {};
            usersResponse.data.forEach(user => {
              userDetailsMap[user.user_id] = user;
            });
            setUserDetails(userDetailsMap);
          }
        }
      } catch (error) {
        console.error('Failed to fetch tasks:', error);
        message.error('タスクの取得に失敗しました');
      } finally {
        setLoading(false);
      }
    },
    [userRole],
  );

  useEffect(() => {
    const initializeData = async () => {
      try {
        const role = await getUserRole();
        const profile = await getUserProfile();
        setUserRole(role);
        setCurrentUser(profile);

        await fetchTasksAndUsers(
          pagination.current,
          pagination.pageSize,
          filters,
          sorter,
          selectedUserId,
        );
      } catch (error) {
        console.error('Failed to initialize data:', error);
        message.error('データの初期化に失敗しました');
      }
    };

    initializeData();
  }, [selectedUserId]);

  const renderUserSelector = () => {
    if (userRole !== 'admin' && userRole !== 'master') return null;

    return (
      <UserSelector
        value={selectedUserId}
        onChange={value => {
          // URLを更新
          if (value) {
            navigate(`/fact-check/tasks/${value}`);
          } else {
            navigate('/fact-check/tasks');
          }
          // ステートを更新してフェッチを開始
          setSelectedUserId(value);
        }}
        placeholder="ユーザーのタスクを絞り込み"
        style={{ width: '100%' }}
      />
    );
  };

  const handleDelete = async record => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/fact-check/tasks/${record.id}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      );
      message.success('タスクが削除されました');
      await fetchTasksAndUsers(
        pagination.current,
        pagination.pageSize,
        filters,
        sorter,
      );
    } catch (error) {
      console.error('Failed to delete task:', error);
      message.error('タスクの削除に失敗しました');
    }
  };

  const showDeleteConfirm = record => {
    Modal.confirm({
      title: 'タスクの削除',
      content: '本当にこのタスクを削除しますか？この操作は取り消せません。',
      okText: '削除',
      okType: 'danger',
      cancelText: 'キャンセル',
      onOk() {
        handleDelete(record);
      },
    });
  };

  const updateTaskStatuses = useCallback(async () => {
    if (!tasks || !tasks.length) return;

    const taskIds = tasks.map(t => t.id);
    if (!taskIds.length) return;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/fact-check/status-updates`,
        { task_ids: taskIds },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      );

      if (response.data && response.data.updated_tasks) {
        const updates = response.data.updated_tasks;
        console.log('[DEBUG] status-updates result:', updates);

        // stateにマージ
        setTasks(prevTasks => {
          // deep copy
          const newTasks = prevTasks.map(t => {
            const matchedUpdate = updates.find(u => u.id === t.id);
            if (matchedUpdate) {
              // "status", "progress" 等を上書き
              return {
                ...t,
                status: matchedUpdate.status,
                progress: matchedUpdate.progress, // e.g. 30
              };
            }
            return t;
          });
          return newTasks;
        });
      }
    } catch (error) {
      console.error('Failed to update task statuses:', error);
      // optional: message.warn('ステータス更新に失敗');
    }
  }, [tasks]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateTaskStatuses();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [updateTaskStatuses]);

  const getStatusColor = status => {
    switch (status) {
      case 'draft':
        return 'default';
      case 'queued':
        return 'orange';
      case 'processing':
        return 'green';
      case 'completed':
        return 'blue';
      case 'finished':
        return 'purple';
      case 'failed':
        return 'red';
      default:
        return 'default';
    }
  };

  const getAccuracyColor = accuracy => {
    if (accuracy >= 90) return '#0072FF';
    if (accuracy >= 79) return '#2E8CFF';
    if (accuracy >= 70) return '#52C41A';
    if (accuracy >= 60) return '#73D13D';
    if (accuracy >= 50) return '#FFA940';
    if (accuracy >= 40) return '#FA8C16';
    if (accuracy >= 30) return '#FA541C';
    return '#F5222D';
  };

  const getStatusText = status => {
    const statusMap = {
      draft: '下書き',
      queued: '予約中',
      processing: '実行中',
      completed: '実行済み',
      finished: '完了',
      failed: '失敗',
    };
    return statusMap[status] || status;
  };

  const getTypeColor = type => {
    const typeColors = {
      'from-seo': 'purple',
      'from-url': 'blue',
      'from-file': 'cyan',
      'direct-input': 'green',
      'other': 'gold',
    };
    return typeColors[type] || 'gold';
  };

  const getTypeText = type => {
    const typeTexts = {
      'from-seo': 'SEO記事',
      'from-url': 'URL',
      'from-file': 'ファイル',
      'direct-input': '直接入力',
      'other': 'その他',
    };
    return typeTexts[type] || 'その他';
  };

  const getCharCountColor = (count, maxCount = 2000) => {
    // if (count > maxCount) return '#00d4ff';
    return '#666666';
  };

  const handleTableChange = (newPagination, newFilters, newSorter) => {
    const isFirstChange = !filters.status && !filters.title;
    const requestPage = isFirstChange ? newPagination.current : 1;

    const updatedFilters = {};
    for (const [key, value] of Object.entries(newFilters)) {
      updatedFilters[key] = value || null;
    }

    setFilters(updatedFilters);
    setSorter(newSorter || {});

    fetchTasksAndUsers(
      requestPage,
      newPagination.pageSize,
      updatedFilters,
      newSorter || {},
      selectedUserId,
    );
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const newFilters = {
      ...filters,
      [dataIndex]: selectedKeys,
    };
    setFilters(newFilters);

    const newPagination = {
      ...pagination,
      current: 1,
    };
    setPagination(newPagination);

    fetchTasksAndUsers(1, pagination.pageSize, newFilters, sorter);
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              const newFilters = { ...filters };
              delete newFilters[dataIndex];
              setFilters(newFilters);
              fetchTasksAndUsers(
                pagination.current,
                pagination.pageSize,
                newFilters,
                sorter,
              );
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      if (!value || !record || !record[dataIndex]) return false;
      const recordValue = String(record[dataIndex]);
      const searchValue = String(value);
      return recordValue.toLowerCase().includes(searchValue.toLowerCase());
    },
  });

  const columns = [
    {
      title: 'ステータス',
      dataIndex: 'status',
      key: 'status',
      width: 140,
      filters: [
        { text: '下書き', value: 'draft' },
        { text: '予約中', value: 'queued' },
        { text: '実行中', value: 'processing' },
        { text: '実行済み', value: 'completed' },
        { text: '完了', value: 'finished' },
        { text: '失敗', value: 'failed' },
      ],
      filterMode: 'menu',
      filteredValue: filters.status || null,
      filterMultiple: false,
      render: (value, record) => (
        <>
          <Tag color={getStatusColor(value)}>{getStatusText(value)}</Tag>
          {value === 'processing' && (
            <span style={{ marginLeft: '8px', fontSize: '12px' }}>
              {record.progress || 0}%
            </span>
          )}
          {(value === 'draft' || value === 'failed') && (
            <Button
              type="text"
              danger
              size="small"
              icon={<DeleteOutlined />}
              onClick={e => {
                e.stopPropagation();
                showDeleteConfirm(record);
              }}
              style={{
                border: '1px solid #ff4d4f',
                borderRadius: '4px',
                padding: '0 4px',
                height: '22px',
                marginLeft: '8px',
              }}
            />
          )}
          {value === 'failed' && record.error_message && (
            <Tooltip title={record.error_message}>
              <ExclamationCircleOutlined
                style={{ marginLeft: 8, color: '#ff4d4f' }}
              />
            </Tooltip>
          )}
        </>
      ),
    },
    {
      title: 'タイトル',
      dataIndex: 'title',
      key: 'title',
      ...getColumnSearchProps('title'),
      filteredValue: filters.title || null,
      width: 300,
    },
    {
      title: '文字数',
      dataIndex: 'character_count',
      key: 'character_count',
      width: 100,
      render: (_, record) => {
        // 文字数を計算
        let charCount = 0;
        try {
          if (record.fact_check_text) {
            const content = JSON.parse(record.fact_check_text);
            charCount = calculateCharacterCount(content);
          }
        } catch (e) {
          console.warn('Failed to parse content for character count');
        }

        return (
          <span style={{ color: getCharCountColor(charCount) }}>
            {charCount.toLocaleString()}
          </span>
        );
      },
      sorter: (a, b) => {
        // ソート用の文字数計算
        const getCharCount = record => {
          try {
            if (record.fact_check_text) {
              const content = JSON.parse(record.fact_check_text);
              return calculateCharacterCount(content);
            }
          } catch (e) {
            // エラーの場合は0
          }
          return 0;
        };

        return getCharCount(a) - getCharCount(b);
      },
    },
    {
      title: '正確性',
      dataIndex: 'factuality',
      key: 'factuality',
      width: 100,
      render: (_, record) => {
        // フラグメントから平均正確性を計算
        const fragments = record.fragments || [];
        const validFragments = fragments.filter(
          f => typeof f.factuality === 'number',
        );
        const avgFactuality = validFragments.length
          ? (validFragments.reduce((sum, f) => sum + f.factuality, 0) /
              validFragments.length) *
            100
          : 0;

        // 完了済みアイテム数（result=trueのフラグメント）
        const completed = fragments.filter(f => f.result === true).length;
        const total = fragments.length;

        // ソース数の合計
        const sourceCount = fragments.reduce((count, frag) => {
          // reference_infoのソースをカウント
          const refCount = Array.isArray(frag.reference_info)
            ? frag.reference_info.length
            : 0;

          // deep_check_sourcesのソースをカウント（存在する場合）
          const deepCheckCount = Array.isArray(frag.deep_check_sources)
            ? frag.deep_check_sources.length
            : 0;

          return count + refCount + deepCheckCount;
        }, 0);

        // ホバー情報を含むツールチップ付きの数値表示
        return (
          <Tooltip
            title={
              <>
                <div>
                  検証項目数：{completed}/{total}件
                </div>
                <div>検出ソース数：{sourceCount}件</div>
              </>
            }
          >
            <span
              style={{
                fontWeight: 'bold',
                color: getAccuracyColor(Math.round(avgFactuality)),
              }}
            >
              {Math.round(avgFactuality) || '-'}
            </span>
          </Tooltip>
        );
      },
      sorter: (a, b) => {
        const getAvgFactuality = record => {
          const fragments = record.fragments || [];
          const validFragments = fragments.filter(
            f => typeof f.factuality === 'number',
          );
          return validFragments.length
            ? validFragments.reduce((sum, f) => sum + f.factuality, 0) /
                validFragments.length
            : 0;
        };
        return getAvgFactuality(a) - getAvgFactuality(b);
      },
    },
    {
      title: '最終更新日時',
      dataIndex: 'updated_at',
      key: 'updated_at',
      sorter: true,
      render: (text, record) => {
        return formatDateTime(text || record.updated_at);
      },
      width: 160,
    },
  ];

  // 管理者用のユーザー列は維持
  if (userRole === 'admin' || userRole === 'master') {
    columns.push({
      title: 'ユーザー',
      dataIndex: 'user_id',
      key: 'user_id',
      width: 70,
      render: (userId, record) => {
        return (
          <Button
            icon={<UserOutlined />}
            type="text"
            onClick={e => {
              e.stopPropagation();
              setSelectedUserId(userId);
              setUserDetailModalVisible(true);
            }}
            style={{
              color: '#1890ff',
            }}
          />
        );
      },
    });
  }

  const onRow = record => ({
    onClick: event => {
      if (
        event.target.closest('.user-icon-column') ||
        event.target.closest('.type-link-column')
      ) {
        return;
      }

      if (record.status === 'draft') {
        window.open(`/fact-check/new/${record.id}`, '_blank');
      } else {
        window.open(`/fact-check/result/${record.id}`, '_blank');
      }
    },
    style: {
      cursor: 'pointer',
    },
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: selectedKeys => {
      setSelectedRowKeys(selectedKeys);
    },
    // オプションで選択数に制限を設けることも可能
    getCheckboxProps: record => ({
      disabled:
        selectedRowKeys.length >= 20 && !selectedRowKeys.includes(record.id),
    }),
  };

  const renderTable = useCallback(() => {
    return (
      <ConfigProvider
        table={{
          expandable: {
            animation: false, // アニメーションを無効化
          },
        }}
      >
        <div style={{ overflow: 'auto', width: '100%' }}>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={tasks}
            rowKey="id"
            loading={loading}
            onChange={handleTableChange}
            onRow={onRow}
            pagination={{
              position: ['bottomLeft'],
              ...pagination,
              showTotal: (total, range) => (
                <Row
                  justify="space-between"
                  style={{ width: '100%', padding: '0 16px' }}
                >
                  <Col>{`${range[0]}-${range[1]} / ${total}件`}</Col>
                  <Col>
                    {selectedRowKeys.length > 0 && (
                      <Space>
                        <span>{`${selectedRowKeys.length}件選択中`}</span>
                        <Button
                          type="primary"
                          size="small"
                          onClick={() => {
                            // 選択した項目に対する処理をここに実装
                            message.info(
                              `${selectedRowKeys.length}件選択されています`,
                            );
                          }}
                        >
                          一括編集
                        </Button>
                        <Button
                          size="small"
                          onClick={() => setSelectedRowKeys([])}
                        >
                          選択解除
                        </Button>
                      </Space>
                    )}
                  </Col>
                </Row>
              ),
            }}
            scroll={{ x: 'max-content' }}
            locale={{
              filterConfirm: '適用',
              filterReset: 'リセット',
              emptyText: 'データがありません',
            }}
            style={{ width: '100%' }} // 追加: 幅を100%に設定
          />
        </div>
      </ConfigProvider>
    );
  }, [
    tasks,
    loading,
    pagination,
    columns,
    onRow,
    handleTableChange,
    rowSelection,
    selectedRowKeys,
  ]);

  return (
    <ConfigProvider
      // テーブルのデフォルト設定をオーバーライド
      table={{
        expandable: {
          animation: false, // アニメーションを無効化
        },
      }}
    >
      <Helmet>
        <title>検証タスク一覧 | ファクトチェックモード - magicss</title>
        <meta name="robots" content="noindex" />
        <meta
          property="og:title"
          content="検証タスク一覧 | ファクトチェックモード - magicss"
        />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/ogp.png`}
        />
        <meta
          property="og:description"
          content="コンテンツ生成AIツール「magicss」のファクトチェックタスク一覧ページです。"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <Row>
        <Col span={24}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>
              <Link to="/fact-check">ファクトチェック</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>検証タスク一覧</Breadcrumb.Item>
          </Breadcrumb>
          <Title
            level={1}
            style={{ fontSize: '24px' }}
            className="gradient-text"
          >
            検証タスク一覧
          </Title>
          <Text style={{ color: '#6d8eb7' }}>
            テーブルの項目行にあるアイコンをクリックすると、絞り込みや検索、データの並び替えができます。
          </Text>
          <Divider style={{ margin: '24px 0' }} />
        </Col>
      </Row>

      {(userRole === 'admin' || userRole === 'master') && (
        <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
          <Col xs={24} sm={24}>
            {renderUserSelector()}
          </Col>
        </Row>
      )}

      {React.useMemo(() => renderTable(), [renderTable])}

      <UserDetail
        visible={userDetailModalVisible}
        onCancel={() => setUserDetailModalVisible(false)}
        userId={selectedUserId}
        onUpdate={() => {
          setUserDetailModalVisible(false);
          fetchTasksAndUsers(
            pagination.current,
            pagination.pageSize,
            filters,
            sorter,
            selectedUserId,
          );
        }}
      />

      <style>
        {`
          .type-link {
            display: inline-block;
            transition: opacity 0.3s ease;
          }
          .type-link:hover {
            opacity: 0.7;
            text-decoration: none;
          }
          .type-link:hover .ant-tag {
            opacity: 0.7;
          }
        `}
      </style>
    </ConfigProvider>
  );
};

export default FactCheckList;
