import { api, setAuthToken } from './api';

export const login = async (email, password) => {
  try {
    console.log('Sending login request with:', { email, password });
    const response = await api.post('/auth/token', {
      username: email,
      password: password,
    }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    console.log('Login response:', response);
    const { access_token } = response.data;
    localStorage.setItem('token', access_token);
    setAuthToken(access_token);
    return response.data;
  } catch (error) {
    console.error('Login failed:', error);
    throw error;
  }
};

export const logout = () => {
  localStorage.removeItem('token');
  setAuthToken(null);
};

export const register = async (userData) => {
  try {
    const response = await api.post('/users/', userData);
    return response.data;
  } catch (error) {
    console.error('Registration failed:', error);
    throw error;
  }
};

export const getUserProfile = async () => {
  try {
    const response = await api.get('/users/me');
    return response.data;
  } catch (error) {
    console.error('Failed to get user profile:', error);
    return null;
  }
};

export const updateUserProfile = async (userData) => {
  try {
    let response;
    if (userData.password && userData.current_password) { // パスワード変更の場合
      console.log("Sending password update request:", userData);
      response = await api.put('/users/me/password', userData); // パスワード変更API
    } else { // 基本情報変更の場合
      console.log("Sending profile update request:", userData);
      response = await api.put('/users/me', userData); // 基本情報変更API
    }
    return response.data;
  } catch (error) {
    console.error('Failed to update user profile:', error.response.data.detail);
    throw error;
  }
};

export const registerEmail = async (email) => {
    try {
      console.log("Sending registration request with email:", email);  // デバッグコード
      const response = await api.post('/auth/register-email', { email });
      console.log("Registration response:", response);  // デバッグコード
      return response.data;
    } catch (error) {
      console.error('Email registration failed:', error);
      throw error;
    }
};

export const registerProfile = async (formData) => {
  try {
    const response = await api.post('/auth/register-profile', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  } catch (error) {
    console.error('Profile registration failed:', error);
    throw error;
  }
};
  
export const resetPassword = async (email) => {
  try {
    const response = await api.post('/auth/reset-password', { email });
    return response.data;
  } catch (error) {
    console.error('Password reset failed:', error);
    throw error;
  }
};

export const resetPasswordConfirm = async (token, newPassword) => {
  try {
    const response = await api.post(`/auth/reset-password-confirm/${token}`, { new_password: newPassword });
    return response.data;
  } catch (error) {
    console.error('Password reset confirmation failed:', error);
    throw error;
  }
};

export const getAuthToken = () => {
  return localStorage.getItem('token');
};

export const getUserRole = async () => {
  try {
    const response = await api.get('/users/me');
    console.log('User role response:', response);
    return response.data.role;
  } catch (error) {
    console.error('Failed to get user role:', error);
    if (error.response && error.response.status === 401) {
      // トークンの有効期限切れの場合、ローカルストレージからトークンを削除
      localStorage.removeItem('token');
      return null;
    }
    throw error;
  }
};