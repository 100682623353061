import React, { useState, useEffect } from 'react';
import Nestable from 'react-nestable';
import 'react-nestable/dist/styles/index.css';
import {
  AiOutlineHolder,
  AiFillCaretRight,
  AiFillCaretDown,
  AiOutlineDelete,
  AiOutlinePlus,
} from 'react-icons/ai';
import { message } from 'antd';
import './ArticleDetail.css';

const parseHeadings = markdownHeadings => {
  if (!markdownHeadings || typeof markdownHeadings !== 'string') {
    return [];
  }

  const parsedHeadings = [];
  let currentH2 = null;

  // 各行を処理
  markdownHeadings.split('\n').forEach(line => {
    const trimmedLine = line.trim();

    // $$で始まる行のみを大見出しとして処理
    if (trimmedLine.startsWith('$$')) {
      const headingText = trimmedLine.slice(2).trim();
      currentH2 = {
        id: `h2-${parsedHeadings.length}`,
        text: headingText,
        type: 'h2',
        children: [],
      };
      parsedHeadings.push(currentH2);
    }
    // ###で始まる行のみを中見出しとして処理（かつ現在の大見出しが存在する場合）
    else if (trimmedLine.startsWith('###') && currentH2) {
      const headingText = trimmedLine.slice(3).trim();
      currentH2.children.push({
        id: `h3-${currentH2.id}-${currentH2.children.length}`,
        text: headingText,
        type: 'h3',
      });
    }
    // その他の行は無視
  });

  return parsedHeadings;
};

const StructuredHeadings = ({ headings, onChange, isContentGenerated }) => {
  const [structuredHeadings, setStructuredHeadings] = useState(
    parseHeadings(headings),
  );

  useEffect(() => {
    setStructuredHeadings(parseHeadings(headings));
  }, [headings]);

  const handleDelete = item => {
    // 見出しが1つしかない場合は削除を防止（大見出し・中見出し問わず）
    const totalHeadings = structuredHeadings.reduce((count, h2) => {
      return count + 1 + (h2.children ? h2.children.length : 0);
    }, 0);

    if (totalHeadings <= 1) {
      message.warning('最低1つの見出しが必要です');
      return;
    }

    const deleteItem = (headings, targetId) => {
      return headings
        .map(heading => {
          if (heading.id === targetId) {
            return null;
          }
          if (heading.children) {
            return {
              ...heading,
              children: heading.children.filter(child => child.id !== targetId),
            };
          }
          return heading;
        })
        .filter(Boolean);
    };

    const newStructuredHeadings = deleteItem(structuredHeadings, item.id);

    setStructuredHeadings(newStructuredHeadings);

    // マークダウン形式に変換してonChangeを呼び出し
    const markdownStructure = newStructuredHeadings
      .map(heading => {
        const headingText = `$$ ${heading.text}`;
        if (heading.children && heading.children.length > 0) {
          const childrenText = heading.children
            .map(child => `### ${child.text}`)
            .join('\n');
          return `${headingText}\n${childrenText}`;
        }
        return headingText;
      })
      .join('\n');

    onChange(markdownStructure);
  };

  const renderItem = ({ item, collapseIcon, handler }) => {
    return (
      <div className="dd-item" key={item.id}>
        <div className="dd-handle">
          {!isContentGenerated && handler}
          <div className="dd-content">
            <div className={`heading-label heading-label-${item.type}`}>
              {item.type.toUpperCase()}
            </div>
            {collapseIcon}
            <div
              className="text"
              contentEditable={!isContentGenerated}
              suppressContentEditableWarning={true}
              onBlur={e => {
                if (!isContentGenerated && e.target.textContent !== item.text) {
                  const newStructuredHeadings = structuredHeadings.map(
                    heading => {
                      if (heading.id === item.id) {
                        return { ...heading, text: e.target.textContent };
                      } else if (heading.children) {
                        return {
                          ...heading,
                          children: heading.children.map(child => {
                            if (child.id === item.id) {
                              return { ...child, text: e.target.textContent };
                            }
                            return child;
                          }),
                        };
                      }
                      return heading;
                    },
                  );

                  setStructuredHeadings(newStructuredHeadings);

                  // マークダウン形式に変換
                  const markdownStructure = newStructuredHeadings
                    .map(heading => {
                      const headingText = `$$ ${heading.text}`;
                      if (heading.children && heading.children.length > 0) {
                        const childrenText = heading.children
                          .map(child => `### ${child.text}`)
                          .join('\n');
                        return `${headingText}\n${childrenText}`;
                      }
                      return headingText;
                    })
                    .join('\n');

                  onChange(markdownStructure); // 文字列としてのマークダウンを渡す
                }
              }}
            >
              {item.text}
            </div>
            {!isContentGenerated && (
              <button
                className="delete-button"
                onClick={() => handleDelete(item)}
              >
                <AiOutlineDelete />
              </button>
            )}
          </div>
        </div>
        {!isContentGenerated && item.type === 'h2' && (
          <div className="add-heading-button-wrapper">
            <AddHeadingButton item={item} onAdd={addHeading} />
          </div>
        )}
      </div>
    );
  };

  const Handler = () => (
    <div className="drag-handle">
      <AiOutlineHolder />
    </div>
  );

  const Collapser = ({ isCollapsed }) => (
    <div className="collapse-icon">
      {isCollapsed ? <AiFillCaretRight /> : <AiFillCaretDown />}
    </div>
  );

  const AddHeadingButton = ({ item, onAdd }) => (
    <button className="add-heading-button" onClick={() => onAdd(item)}>
      <AiOutlinePlus />
    </button>
  );

  const addHeading = item => {
    const totalHeadings = structuredHeadings.reduce(
      (count, h2) => count + 1 + (h2.children ? h2.children.length : 0),
      0,
    );
    const h2Count = structuredHeadings.length;
    const h3Count = item.children ? item.children.length : 0;

    if (totalHeadings >= 35) {
      alert(
        '大見出しと中見出しの合計個数が上限に達しました。これ以上追加できません。',
      );
      return;
    }

    if (item.type === 'h2' && h2Count >= 8) {
      alert('大見出しの個数が上限に達しました。これ以上追加できません。');
      return;
    }

    if (item.type === 'h2' && h3Count >= 12) {
      alert(
        'この大見出し内の中見出しの個数が上限に達しました。これ以上追加できません。',
      );
      return;
    }

    const newHeading = {
      id: `${item.type}-${item.id}-${item.children ? item.children.length : 0}`,
      text: '新しい見出し',
      type: item.type === 'h2' ? 'h3' : 'h2',
    };

    const insertHeading = (headings, parentId, heading) => {
      return headings.map(h => {
        if (h.id === parentId) {
          return {
            ...h,
            children: [...(h.children || []), heading],
          };
        } else if (h.children) {
          return {
            ...h,
            children: insertHeading(h.children, parentId, heading),
          };
        }
        return h;
      });
    };

    const newStructuredHeadings = insertHeading(
      structuredHeadings,
      item.id,
      newHeading,
    );
    setStructuredHeadings(newStructuredHeadings);
    onChange(newStructuredHeadings);
  };

  return (
    <Nestable
      items={structuredHeadings}
      renderItem={renderItem}
      handler={!isContentGenerated ? <Handler /> : null}
      renderCollapseIcon={({ isCollapsed }) => (
        <Collapser isCollapsed={isCollapsed} />
      )}
      onChange={items => {
        if (!isContentGenerated) {
          // Nestableから受け取ったデータの正規化
          const normalizedItems = Array.isArray(items)
            ? items
            : items.items
              ? items.items
              : [items];

          // 各アイテムの型を適切に設定
          const processedItems = normalizedItems.map(item => {
            // 親レベルのアイテムはすべてh2として扱う
            const newItem = {
              ...item,
              type: 'h2',
            };

            // 子要素がある場合は、それらをすべてh3として扱う
            if (item.children) {
              newItem.children = item.children.map(child => ({
                ...child,
                type: 'h3',
              }));
            }

            return newItem;
          });

          setStructuredHeadings(processedItems);

          // マークダウン形式に変換
          const markdownStructure = processedItems
            .map(heading => {
              // 大見出しを生成
              const h2Text = `$$ ${heading.text}`;

              // 子要素（中見出し）があれば追加
              if (heading.children && heading.children.length > 0) {
                const h3Texts = heading.children
                  .map(child => `### ${child.text}`)
                  .join('\n');
                return `${h2Text}\n${h3Texts}`;
              }

              return h2Text;
            })
            .join('\n');

          // マークダウン文字列を親コンポーネントに渡す
          if (onChange) {
            onChange(markdownStructure);
          }
        }
      }}
      maxDepth={2}
      disabled={isContentGenerated}
    />
  );
};

export default StructuredHeadings;
