import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, Row, Col, Typography, Divider, Modal } from 'antd';
import { getUserProfile, updateUserProfile, getAuthToken } from '../auth';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

const { Title, Text } = Typography;

const UserSettings = () => {
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const [emailVerificationToken, setEmailVerificationToken] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userProfile = await getUserProfile();
        form1.setFieldsValue({
          company_name: userProfile.company_name,
          department_name: userProfile.department_name,
          full_name: {
            last_name: userProfile.last_name,
            first_name: userProfile.first_name,
          },
          full_name_kana: {
            last_name_kana: userProfile.last_name_kana,
            first_name_kana: userProfile.first_name_kana,
          },
        });
        form2.setFieldsValue({
          email: userProfile.email,
        });
      } catch (error) {
        message.error('ユーザー情報の取得に失敗しました');
      }
    };

    fetchUserProfile();
  }, [form1, form2]);

  const handleSubmitProfile = async (values) => {
    setLoading(true);
    try {
      const updatedValues = {
        company_name: values.company_name,
        department_name: values.department_name,
        last_name: values.full_name.last_name,
        first_name: values.full_name.first_name,
        last_name_kana: values.full_name_kana.last_name_kana,
        first_name_kana: values.full_name_kana.first_name_kana,
        email: form2.getFieldValue('email'), // email を追加
      };
      console.log('Sending update request with:', updatedValues);
      await updateUserProfile(updatedValues); // current_password を削除
      message.success('ユーザー情報を更新しました');
    } catch (error) {
      message.error('ユーザー情報の更新に失敗しました');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordChange = async (values) => {
    setLoading(true);
    try {
      console.log('Sending password change request with:', values); // ログ出力修正
      await updateUserProfile({ password: values.new_password, current_password: values.current_password }); // 引数修正
      message.success('パスワードを更新しました');
      setPasswordModalVisible(false);
      form2.resetFields(['current_password', 'new_password', 'confirm_new_password']);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        message.error('現在のパスワードが正しくありません');
      } else {
        message.error('パスワードの更新に失敗しました');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChangeRequest = async (email) => {
    setLoading(true);
    try {
      console.log('Sending email change request with:', email);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/change-email-request`,
        { email },
        {
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
          },
        }
      );
      setEmailVerificationToken(response.data.token);
      setEmailModalVisible(false);
      message.success('確認メールを送信しました。');
    } catch (error) {
      message.error('メールアドレス変更リクエストの送信に失敗しました');
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChangeConfirm = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/change-email-confirm/${emailVerificationToken}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
          },
        }
      );
      message.success('メールアドレスの変更が完了しました');
      navigate('/settings', { state: { message: 'メールアドレスの変更が完了しました。' } });
    } catch (error) {
      message.error('メールアドレスの変更に失敗しました');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.state && location.state.message) {
      message.success(location.state.message);
    }
  }, [location.state]);

  return (
    <div style={{ maxWidth: '800px' }}>
      <Text style={{ margin: '16px 0' }}>ユーザー設定</Text>
      <Title level={1} className="gradient-text" style={{ fontSize: '24px' }}>
        登録情報の確認・変更
      </Title>
      <Divider style={{ margin: '24px 0' }} />

      {/* 基本情報フォーム */}
      <Form onFinish={handleSubmitProfile} layout="vertical" form={form1}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="company_name" label={<span>会社名</span>}>
              <Input
                placeholder="会社名"
                size="large"
                style={{
                  border: '2px solid #f5f5f5',
                  backgroundColor: '#f5f5f5',
                  padding: '8px',
                  transition: 'all 0.3s',
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = '#eee';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = '#f5f5f5';
                }}
                onFocus={(e) => {
                  e.currentTarget.style.border = '2px solid #0072ff';
                }}
                onBlur={(e) => {
                  e.currentTarget.style.border = '2px solid #f5f5f5';
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="department_name" label={<span>部署名</span>}>
              <Input
                placeholder="部署名"
                size="large"
                style={{
                  border: '2px solid #f5f5f5',
                  backgroundColor: '#f5f5f5',
                  padding: '8px',
                  transition: 'all 0.3s',
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = '#eee';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = '#f5f5f5';
                }}
                onFocus={(e) => {
                  e.currentTarget.style.border = '2px solid #0072ff';
                }}
                onBlur={(e) => {
                  e.currentTarget.style.border = '2px solid #f5f5f5';
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name={['full_name', 'last_name']}
              label={<span>姓</span>}
              rules={[{ required: true, message: '姓を入力してください' }]}
            >
              <Input
                placeholder="姓"
                size="large"
                style={{
                  border: '2px solid #f5f5f5',
                  backgroundColor: '#f5f5f5',
                  padding: '8px',
                  transition: 'all 0.3s',
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = '#eee';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = '#f5f5f5';
                }}
                onFocus={(e) => {
                  e.currentTarget.style.border = '2px solid #0072ff';
                }}
                onBlur={(e) => {
                  e.currentTarget.style.border = '2px solid #f5f5f5';
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={['full_name', 'first_name']}
              label={<span>名</span>}
              rules={[{ required: true, message: '名を入力してください' }]}
            >
              <Input
                placeholder="名"
                size="large"
                style={{
                  border: '2px solid #f5f5f5',
                  backgroundColor: '#f5f5f5',
                  padding: '8px',
                  transition: 'all 0.3s',
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = '#eee';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = '#f5f5f5';
                }}
                onFocus={(e) => {
                  e.currentTarget.style.border = '2px solid #0072ff';
                }}
                onBlur={(e) => {
                  e.currentTarget.style.border = '2px solid #f5f5f5';
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name={['full_name_kana', 'last_name_kana']}
              label={<span>姓（カタカナ）</span>}
              rules={[{ required: true, message: '姓（カタカナ）を入力してください' }]}
            >
              <Input
                placeholder="姓（カタカナ）"
                size="large"
                style={{
                  border: '2px solid #f5f5f5',
                  backgroundColor: '#f5f5f5',
                  padding: '8px',
                  transition: 'all 0.3s',
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = '#eee';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = '#f5f5f5';
                }}
                onFocus={(e) => {
                  e.currentTarget.style.border = '2px solid #0072ff';
                }}
                onBlur={(e) => {
                  e.currentTarget.style.border = '2px solid #f5f5f5';
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={['full_name_kana', 'first_name_kana']}
              label={<span>名（カタカナ）</span>}
              rules={[{ required: true, message: '名（カタカナ）を入力してください' }]}
            >
              <Input
                placeholder="名（カタカナ）"
                size="large"
                style={{
                  border: '2px solid #f5f5f5',
                  backgroundColor: '#f5f5f5',
                  padding: '8px',
                  transition: 'all 0.3s',
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = '#eee';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = '#f5f5f5';
                }}
                onFocus={(e) => {
                  e.currentTarget.style.border = '2px solid #0072ff';
                }}
                onBlur={(e) => {
                  e.currentTarget.style.border = '2px solid #f5f5f5';
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            block
            size="large"
            style={{
              background: 'linear-gradient(90deg, #0072ff 0%, #00d4ff 100%)',
              border: 'none',
              transition: 'all 0.3s',
              width: '100%',
              fontWeight: 'bold',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.opacity = '0.7';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.opacity = '1.0';
            }}
          >
            変更を保存する
          </Button>
        </Form.Item>
      </Form>

      <Divider style={{ margin: '48px 0px', borderColor: '#f5f5f5' }} />

      {/* ログイン情報フォーム */}
      <Form onFinish={() => {}} layout="vertical" form={form2} style={{ maxWidth: '800px' }}>
        <Row gutter={16}>
          <Col span={16}>
            <Title level={5}>ログイン情報</Title>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'メールアドレスを入力してください' }]}
            >
              <Input
                placeholder="メールアドレス"
                size="large"
                disabled
                id="current-email"
                style={{
                  border: '2px solid #f5f5f5',
                  backgroundColor: '#f5f5f5',
                  padding: '8px',
                  transition: 'all 0.3s',
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = '#eee';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = '#f5f5f5';
                }}
                onFocus={(e) => {
                  e.currentTarget.style.border = '2px solid #0072ff';
                }}
                onBlur={(e) => {
                  e.currentTarget.style.border = '2px solid #f5f5f5';
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Button block onClick={() => setEmailModalVisible(true)}>
              メールアドレスを変更
            </Button>
          </Col>
          <Col span={8}>
            <Button block onClick={() => setPasswordModalVisible(true)}>
              パスワードを変更
            </Button>
          </Col>
        </Row>
      </Form>

      {/* パスワード変更モーダル */}
      <Modal
        title="パスワードの変更"
        visible={passwordModalVisible}
        onCancel={() => setPasswordModalVisible(false)}
        footer={null}
      >
        <Form
          onFinish={handlePasswordChange}
          layout="vertical"
          style={{ marginTop: '24px' }}
        >
          <Form.Item
            name="current_password"
            label={<span>現在のパスワード</span>}
            rules={[{ required: true, message: '現在のパスワードを入力してください' }]}
          >
            <div
              style={{
                border: '2px solid #f5f5f5',
                backgroundColor: '#f5f5f5',
                transition: 'all 0.3s',
                borderRadius: '8px', // 角丸を追加
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = '#eee';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = '#f5f5f5';
              }}
              onFocus={(e) => {
                e.currentTarget.style.border = '2px solid #0072ff';
              }}
              onBlur={(e) => {
                e.currentTarget.style.border = '2px solid #f5f5f5';
              }}
            >
              <Input.Password
                placeholder="現在のパスワード"
                size="large"
                style={{
                  border: 'none',
                  backgroundColor: 'transparent',
                  outline: 'none',
                  padding: '8px',
                }}
              />
            </div>
          </Form.Item>
          <Form.Item
            name="new_password"
            label={<span>新しいパスワード</span>}
            rules={[
              { required: true, message: '新しいパスワードを入力してください' },
              { min: 8, message: 'パスワードは8文字以上で入力してください' },
              {
                validator: (_, value) => {
                  if (
                    value &&
                    !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
                      value
                    )
                  ) {
                    return Promise.reject(
                      new Error(
                        'パスワードは大文字、小文字、数字、記号を含む8文字以上で入力してください'
                      )
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <div
              style={{
                border: '2px solid #f5f5f5',
                backgroundColor: '#f5f5f5',
                transition: 'all 0.3s',
                borderRadius: '8px', // 角丸を追加
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = '#eee';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = '#f5f5f5';
              }}
              onFocus={(e) => {
                e.currentTarget.style.border = '2px solid #0072ff';
              }}
              onBlur={(e) => {
                e.currentTarget.style.border = '2px solid #f5f5f5';
              }}
            >
              <Input.Password
                placeholder="新しいパスワード"
                size="large"
                style={{
                  border: 'none',
                  backgroundColor: 'transparent',
                  outline: 'none',
                  padding: '8px',
                }}
              />
            </div>
          </Form.Item>
          <Form.Item
            name="confirm_new_password"
            label={<span>新しいパスワード（確認用）</span>}
            dependencies={['new_password']}
            rules={[
              {
                required: true,
                message: '新しいパスワード（確認用）を入力してください',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new_password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('パスワードが一致しません'));
                },
              }),
            ]}
          >
            <div
              style={{
                border: '2px solid #f5f5f5',
                backgroundColor: '#f5f5f5',
                transition: 'all 0.3s',
                borderRadius: '8px', // 角丸を追加
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = '#eee';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = '#f5f5f5';
              }}
              onFocus={(e) => {
                e.currentTarget.style.border = '2px solid #0072ff';
              }}
              onBlur={(e) => {
                e.currentTarget.style.border = '2px solid #f5f5f5';
              }}
            >
              <Input.Password
                placeholder="新しいパスワード（確認用）"
                size="large"
                style={{
                  border: 'none',
                  backgroundColor: 'transparent',
                  outline: 'none',
                  padding: '8px',
                }}
              />
            </div>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              block
              size="large"
              style={{
                background: 'linear-gradient(90deg, #0072ff 0%, #00d4ff 100%)',
                border: 'none',
                transition: 'all 0.3s',
                fontWeight: 'bold',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.opacity = '0.7';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.opacity = '1.0';
              }}
            >
              パスワードを変更する
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* メールアドレス変更モーダル */}
      <Modal
        title="メールアドレスの変更"
        visible={emailModalVisible}
        onCancel={() => setEmailModalVisible(false)}
        footer={null}
      >
        <Form
          onFinish={(values) => handleEmailChangeRequest(values.email)}
          layout="vertical"
          style={{ marginTop: '24px' }}
        >
          <Form.Item
            name="email"
            label={<span>新しいメールアドレス</span>}
            rules={[{ required: true, message: '新しいメールアドレスを入力してください' }]}
          >
            <Input
              placeholder="新しいメールアドレス"
              size="large"
              style={{
                border: '2px solid #f5f5f5',
                backgroundColor: '#f5f5f5',
                padding: '8px',
                transition: 'all 0.3s',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = '#eee';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = '#f5f5f5';
              }}
              onFocus={(e) => {
                e.currentTarget.style.border = '2px solid #0072ff';
              }}
              onBlur={(e) => {
                e.currentTarget.style.border = '2px solid #f5f5f5';
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              block
              size="large"
              style={{
                background: 'linear-gradient(90deg, #0072ff 0%, #00d4ff 100%)',
                border: 'none',
                transition: 'all 0.3s',
                fontWeight: 'bold',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.opacity = '0.7';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.opacity = '1.0';
              }}
            >
              認証URLを送信する
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UserSettings;