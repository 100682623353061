import React, { useState, useEffect } from 'react';
import { Typography, Row, Col, Button, Card, Space, Divider, Modal, Switch, message, Tag, Checkbox } from 'antd';
import { CheckCircleFilled, CloseCircleFilled, FireOutlined, RightOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { getUserProfile } from '../auth';
import { api, createCheckoutSession, getCurrentSubscription, cancelPendingPlanChange } from '../api';
import moment from 'moment';

const { Title, Text, Paragraph } = Typography;

const PlanSettings = () => {
  const [plans, setPlans] = useState([]);
  const [userPlan, setUserPlan] = useState(null);
  const [isYearly, setIsYearly] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [proratedAmount, setProratedAmount] = useState(null);
  const [nextRenewalDate, setNextRenewalDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [newPlanPrice, setNewPlanPrice] = useState(0);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [ticketChange, setTicketChange] = useState(0);
  const [discountedTickets, setDiscountedTickets] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      await fetchPlans();
      fetchUserProfile();
      fetchCurrentSubscription();
    };
    fetchData();
  }, []);

  const fetchPlans = async () => {
    try {
      const response = await api.get('/plans/');
      const fetchedPlans = response.data.sort((a, b) => a.priority - b.priority);
      
      // カスタムプランを追加
      const customPlan = {
        id: 'custom',
        name: 'custom',
        display_name: 'カスタムプラン',
        type: 'custom',
        monthly_tickets: '????',
        description: {
          seo_can: [
            'すべての機能が利用可能',
            'カスタマイズされた機能',
            '専任のサポート担当者'
          ],
          seo_cant: []
        }
      };
      
      setPlans([...fetchedPlans, customPlan]);

    } catch (error) {
      console.error('Failed to fetch plans:', error);
      message.error('プランの取得に失敗しました');
    }
  };

  const fetchUserProfile = async () => {
    try {

      const profile = await getUserProfile();
      setUserProfile(profile);
      setUserPlan(profile.plan);
    } catch (error) {
      console.error('Failed to fetch user profile:', error);
      message.error('ユーザー情報の取得に失敗しました');
    }
  };

  const fetchCurrentSubscription = async () => {
    try {
      const subscription = await getCurrentSubscription();
      setCurrentSubscription(subscription);
    } catch (error) {
      console.error('Failed to fetch current subscription:', error);
      message.error('現在のサブスクリプション情報の取得に失敗しました');
    }
  };

  const handlePlanChange = async (planId) => {
    setIsLoading(true);
    const plan = plans.find(p => p.id === planId);
    setSelectedPlan(plan);
    
    try {
      const response = await api.post('/subscriptions/calculate-prorated-amount', {
        new_plan_id: planId,
        is_yearly: isYearly
      });
  
      console.log('Prorated amount calculation:', response.data);
  
      setProratedAmount(response.data.discount_amount);
      setNewPlanPrice(response.data.new_plan_price);
      setPaymentAmount(response.data.payment_amount);
      setNextRenewalDate(moment(response.data.next_renewal_date));
      setTicketChange(response.data.ticket_change);
      setDiscountedTickets(response.data.discounted_tickets);
      setConfirmModalVisible(true);
    } catch (error) {
      console.error('Failed to fetch prorated amount:', error);
      message.error('プラン変更の計算に失敗しました');
    } finally {
      setIsLoading(false);
    }
  };
  
  const confirmPlanChange = async () => {
    setIsLoading(true);
    try {
      const response = await api.post('/subscriptions/change-plan', {
        new_plan_id: selectedPlan.id,
        is_yearly: isYearly
      });
  
      if (response.data.status === 'checkout') {
        window.location.href = response.data.checkout_url;
      } else if (response.data.status === 'success') {
        message.success(response.data.message);
        if (selectedPlan.type === 'free') {
          setUserProfile(prevProfile => ({
            ...prevProfile,
            plan_id: response.data.new_plan_id,
            ticket_count: response.data.ticket_count
          }));
        } else {
          await fetchUserProfile();
        }
        await fetchCurrentSubscription();
        setConfirmModalVisible(false);
      }
    } catch (error) {
      console.error('Failed to change plan:', error);
      message.error('プラン変更に失敗しました: ' + (error.response?.data?.detail || error.message));
    } finally {
      setIsLoading(false);
    }
  };

  const handleCustomPlanInquiry = () => {
    navigate('/contact');
  };

  const planTags = {
    free: { color: 'blue', text: 'フリープラン' },
    standard: { color: 'green', text: 'スタンダードプラン' },
    premium: { color: 'gold', text: 'プレミアムプラン' },
    custom: { color: 'purple', text: 'カスタムプラン' },
  };

  const renderPlanCard = (plan) => {
    const yearlyPrice = plan.yearly_price;
    const monthlyPrice = plan.monthly_price;
    const displayPrice = isYearly ? yearlyPrice / 12 : monthlyPrice;
    const isCurrentPlan = userPlan && userPlan.id === plan.id;
  
    return (
      <Col xs={24} sm={12} md={6} key={plan.id}>
        <Card title={plan.display_name} style={{textAlign: 'center'}}>
          <div style={{ textAlign: 'center', marginBottom: '16px', height: '80px' }}>
            {plan.type !== 'free' && plan.type !== 'custom' && (
              <>
                <Text type="secondary" style={{ fontSize: '16px' }}>¥</Text>
                <Text strong style={{ fontSize: '24px', margin: '0 4px' }}>
                  {Math.floor(displayPrice * 1.1).toLocaleString()}
                </Text>
                <Text type="secondary" style={{ fontSize: '12px' }}>
                  (税込) / 月あたり
                </Text>
                <br />
                {isYearly && (
                  <>
                    <Text type="secondary" style={{ fontSize: '12px', textDecoration: 'line-through' }}>
                      ¥{Math.floor(monthlyPrice * 1.1).toLocaleString()} / 月
                    </Text>
                    <br />
                    <Text type="secondary" style={{ fontSize: '12px' }}>
                      年間: ¥{Math.floor(yearlyPrice * 1.1).toLocaleString()}
                    </Text>
                  </>
                )}
              </>
            )}
            {plan.type === 'free' && (
              <>
                <Text type="secondary" style={{ fontSize: '16px' }}>¥</Text>
                <Text strong style={{ fontSize: '24px' }}>0</Text>
              </>
            )}
            {plan.type === 'custom' && (
              <Text strong style={{ fontSize: '24px' }}>ASK</Text>
            )}
          </div>
          <Space direction="vertical" size="middle" style={{ width: '100%', textAlign: 'center' }}>
            <Text>
              <FireOutlined style={{ color: '#0072ff', marginRight: '4px', fontSize: '18px' }} />
              毎月のチケット上限数：<span style={{fontWeight: 'bold', fontSize: '18px', color: '#0072ff'}}>{plan.monthly_tickets} </span>枚
            </Text>
          </Space>
          <Divider style={{ margin: '16px 0' }} />
          <Space direction="vertical" size="middle" style={{ width: '100%', textAlign: 'left' }}>
            <Text style={{fontWeight: 'bold', color: '#00d4ff'}}>SEO記事生成モード：</Text>
            {plan.description.seo_can && plan.description.seo_can.map((data, index) => (
              <Text key={index}>
                <CheckCircleFilled style={{ color: '#00d4ff', marginRight: '8px' }} />
                {data}
              </Text>
            ))}
            {plan.description.seo_cant && plan.description.seo_cant.map((data, index) => (
              <Text key={index} type="secondary">
                <CloseCircleFilled style={{ color: '#ff4d4f', marginRight: '8px' }} />
                {data}
              </Text>
            ))}
          </Space>
          <Button
            loading={isLoading}
            type="primary"
            size="large"
            block
            style={{
              marginTop: '24px',
              ...(isCurrentPlan
                ? {
                    backgroundColor: '#f5f5f5',
                    color: '#999',
                    cursor: 'not-allowed',
                    fontWeight: 'bold',
                    border: 'none',
                  }
                : {
                    background: 'linear-gradient(90deg, #0072ff 0%, #00d4ff 100%)',
                    border: 'none',
                    transition: 'all 0.3s',
                    fontWeight: 'bold',
                  }),
            }}
            onMouseEnter={(e) => {
              if (!isCurrentPlan) {
                e.currentTarget.style.opacity = '0.7';
              }
            }}
            onMouseLeave={(e) => {
              if (!isCurrentPlan) {
                e.currentTarget.style.opacity = '1.0';
              }
            }}
            onClick={() => plan.type === 'custom' ? handleCustomPlanInquiry() : handlePlanChange(plan.id)}
            disabled={isCurrentPlan || isLoading}
          >
            {isCurrentPlan ? '現在のプラン' : (plan.type === 'custom' ? 'お問い合わせ' : 'このプランに変更する')}
            <RightOutlined style={{marginLeft: 'auto', right: '10px', position: 'absolute', top: '50%', transform: 'translate(0, -50%)', fontSize: '14px' }} />
          </Button>
        </Card>
      </Col>
    );
  };

  return (
    <div>
      <Text style={{ margin: '16px 0' }}>ユーザー設定</Text>
      <Title level={1} className='gradient-text' style={{fontSize:'24px'}}>ご利用プランの変更</Title>
      <Divider style={{ margin: '24px 0' }} />
      <div style={{ marginBottom: '24px' }}>
        <Switch
          checked={isYearly}
          onChange={(checked) => setIsYearly(checked)}
          checkedChildren="年払い"
          unCheckedChildren="月払い"
        />
        {isYearly && <Text style={{ marginLeft: '10px' }}>（10%割引）</Text>}
      </div>
      <Row gutter={[16, 16]}>
        {plans.map(renderPlanCard)}
      </Row>
      <Modal
        title={<Title level={3}>プラン変更の確認</Title>}
        visible={confirmModalVisible}
        onOk={confirmPlanChange}
        onCancel={() => setConfirmModalVisible(false)}
        okText="変更を確定"
        cancelText="キャンセル"
        okButtonProps={{ disabled: !agreedToTerms }}
        width={800}
      >
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Card>
            <Row gutter={16}>
              <Col span={8}>
                <Text strong>現在のプラン:</Text>
                <br />
                <Tag color={userPlan ? planTags[userPlan.type].color : 'default'}>
                  {userPlan ? userPlan.display_name : 'プラン未設定'}
                </Tag>
              </Col>
              <Col span={8}>
                <Text strong>新しいプラン:</Text>
                <br />
                <Tag color={selectedPlan ? planTags[selectedPlan.type].color : 'default'}>
                  {selectedPlan ? selectedPlan.display_name : 'プラン未選択'}
                </Tag>
              </Col>
              <Col span={8}>
                <Text strong>次回更新日:</Text>
                <br />
                <Text>{nextRenewalDate && nextRenewalDate.isValid() ? nextRenewalDate.format('YYYY年MM月DD日') : '未定'}</Text>
              </Col>
            </Row>
          </Card>
          <Card>
            <Row gutter={16}>
              <Col span={12}>
                <Text strong>チケット変更:</Text>
                <br />
                <Text>
                  {selectedPlan && selectedPlan.type === 'free'
                    ? `${userProfile ? userProfile.ticket_count : 0} (変更なし)`
                    : `${userProfile ? userProfile.ticket_count : 0} → ${userProfile ? userProfile.ticket_count + ticketChange : 0}`
                  }
                </Text>
              </Col>
              <Col span={12}>
                <Text strong>毎月の上限チケット数:</Text>
                <br />
                <Text>{userPlan ? userPlan.monthly_tickets : 0} → {selectedPlan ? selectedPlan.monthly_tickets : 0}</Text>
              </Col>
            </Row>
          </Card>
          {selectedPlan && selectedPlan.type !== 'free' && (
            <Card>
              <Space direction="vertical" size="small" style={{ width: '100%' }}>
                <Row justify="space-between">
                  <Col>
                    <Text strong>今回のお支払い:</Text>
                  </Col>
                  <Col>
                    <Text strong style={{ fontSize: '18px', color: '#1890ff' }}>
                      ¥{paymentAmount.toLocaleString()}
                    </Text>
                    </Col>
                </Row>
                {proratedAmount > 0 && (
                  <Text type="secondary">
                    割引額: ¥{proratedAmount.toLocaleString()}
                    （残存チケット分: {discountedTickets}枚、¥{(discountedTickets * 150).toLocaleString()}相当）
                  </Text>
                )}
                <Text type="secondary">
                  次回更新日からの{isYearly ? '年額' : '月額'}料金: 
                  ¥{((isYearly ? selectedPlan.yearly_price : selectedPlan.monthly_price) * 1.1).toLocaleString()}（税込）
                </Text>
              </Space>
            </Card>
          )}
          {selectedPlan && selectedPlan.type === 'free' && (
            <Card>
              <Text>フリープランに変更すると、次回更新日以降は料金が発生しません。</Text>
              <br />
              <Text>現在の保有チケットは次回更新まで使用できますが、更新されなくなります。</Text>
            </Card>
          )}
        </Space>
        <Divider />
        <Paragraph>
          <InfoCircleOutlined style={{ marginRight: '8px', color: '#1890ff' }} />
          プラン変更に関する注意事項:
        </Paragraph>
        <ul>
          <li>フリープランへの変更を除き、チケット枚数やプランは即時反映されます。</li>
          <li>フリープランへの変更時のみ、次回更新日まで保有チケットとプランをご利用いただけます。</li>
          <li>年払いの場合でも、毎月一回チケット枚数が上限値にリセットされます。</li>
          <li>更新時の自動更新失敗時は強制的にフリープランに戻り、チケット枚数もリセットされます。</li>
          <li>カスタムプランをご希望の場合は、お問い合わせください。</li>
        </ul>
        <Divider />
        <Checkbox onChange={(e) => setAgreedToTerms(e.target.checked)}>
          <a href="/terms" target="_blank" rel="noopener noreferrer">利用規約</a>に同意します。
        </Checkbox>
      </Modal>
    </div>
  );
};

export default PlanSettings;