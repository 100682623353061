import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Typography, Skeleton, Alert } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './TableOfContents.css';

const { Text } = Typography;

const TableOfContents = ({ editorData, loading }) => {
  const [headings, setHeadings] = useState([]);
  const [error, setError] = useState(null);
  const [activeId, setActiveId] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const observerRef = useRef(null);
  const initTimeoutRef = useRef(null);

  // スクロールハンドラの定義
  const handleScroll = useCallback(() => {
    if (!initialized || headings.length === 0) return;

    const editorElement = document.querySelector('.codex-editor');
    if (!editorElement) return;

    // スクロール位置を取得
    const scrollPosition = window.scrollY;
    const viewportHeight = window.innerHeight;
    const OFFSET = 80; // 画面上部からのオフセット

    // ヘッダー要素の位置情報を取得
    const headingPositions = headings
      .map(heading => {
        const element = editorElement.querySelector(
          `[data-id="${heading.id}"]`,
        );
        if (!element) return null;
        const rect = element.getBoundingClientRect();
        return {
          id: heading.id,
          top: rect.top + scrollPosition,
          bottom: rect.bottom + scrollPosition,
          level: heading.level,
        };
      })
      .filter(Boolean);

    if (headingPositions.length === 0) return;

    // 現在のビューポート範囲
    const viewportTop = scrollPosition;
    const viewportBottom = scrollPosition + viewportHeight;

    // 表示範囲内のヘッダーを特定
    const visibleHeadings = headingPositions.filter(pos => {
      // 見出しの位置が画面上部から80px付近にある場合、または
      // 見出しが画面上部よりも上にある場合（スクロールで通り過ぎた場合）のみを対象とする
      const elementTop = pos.top - viewportTop;
      return elementTop <= OFFSET + 20 && elementTop >= -50;
    });

    if (visibleHeadings.length > 0) {
      // 最適なヘッダーを選択（画面上部から80pxの位置を基準に）
      const bestHeading = visibleHeadings.reduce((best, current) => {
        const bestTop = best.top - viewportTop;
        const currentTop = current.top - viewportTop;

        // 画面上部からの距離を比較
        if (Math.abs(bestTop - OFFSET) > Math.abs(currentTop - OFFSET))
          return current;
        if (
          Math.abs(bestTop - OFFSET) === Math.abs(currentTop - OFFSET) &&
          current.level < best.level
        )
          return current;
        return best;
      });

      if (bestHeading.id !== activeId) {
        setActiveId(bestHeading.id);
      }
    } else {
      // 該当する見出しがない場合、最後に通過した見出しを探す
      const lastPassedHeading = headingPositions
        .filter(pos => pos.top - viewportTop < -50)
        .reduce((best, current) => {
          if (!best) return current;
          return current.top > best.top ? current : best;
        }, null);

      if (lastPassedHeading && lastPassedHeading.id !== activeId) {
        setActiveId(lastPassedHeading.id);
      }
    }
  }, [initialized, headings, activeId]);

  // 初期化を試みる関数
  const attemptInitialization = async () => {
    try {
      // エディタインスタンスの確認
      if (!window._editor) return false;

      // エディタの準備が完了するまで待機
      await new Promise(resolve => {
        const checkReady = () => {
          if (window._editor && document.querySelector('.codex-editor')) {
            resolve();
          } else {
            setTimeout(checkReady, 100);
          }
        };
        checkReady();
      });

      // 現在のエディタの状態を取得
      const editorData = await window._editor.save();

      // ヘッダー情報を取得（H2とH3のみ）
      const headers = editorData.blocks
        .filter(
          block =>
            block.type === 'header' &&
            (block.data.level === 2 || block.data.level === 3),
        )
        .map(block => ({
          id: block.id,
          text: block.data.text.trim(),
          level: block.data.level,
        }));

      if (!headers || headers.length === 0) {
        setHeadings([]);
        setInitialized(true);
        setIsReady(true);
        return true;
      }

      // DOM要素にレベル情報を設定
      const editorElement = document.querySelector('.codex-editor');
      if (editorElement) {
        headers.forEach(header => {
          const element = editorElement.querySelector(
            `[data-id="${header.id}"] .ce-header`,
          );
          if (element) {
            element.setAttribute('data-level', header.level.toString());
            element.dataset.level = header.level.toString();
          }
        });
      }

      // 状態を更新（最初のH2を選択）
      const firstH2 = headers.find(h => h.level === 2);
      setHeadings(headers);
      setActiveId(firstH2 ? firstH2.id : headers[0].id);
      setInitialized(true);
      setIsReady(true);

      return true;
    } catch (error) {
      setError('目次の初期化に失敗しました');
      setIsReady(true);
      return false;
    }
  };

  // 初期化の実行
  useEffect(() => {
    if (!editorData || !('blocks' in editorData)) {
      setHeadings([]);
      setInitialized(true);
      setIsReady(true);
      return;
    }
    attemptInitialization();
  }, [editorData]);

  // スクロール監視の設定
  useEffect(() => {
    if (!initialized || headings.length === 0) return;

    let isScrolling = false;
    let scrollTimeout;

    const handleScrollStart = () => {
      isScrolling = true;
    };

    const handleScrollEnd = () => {
      isScrolling = false;
      handleScroll();
    };

    const throttledScroll = () => {
      if (!isScrolling) {
        handleScrollStart();
      }

      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }

      if (!window.requestAnimationFrame) {
        handleScroll();
      } else {
        window.requestAnimationFrame(handleScroll);
      }

      scrollTimeout = setTimeout(handleScrollEnd, 150);
    };

    window.addEventListener('scroll', throttledScroll, { passive: true });
    handleScroll();

    return () => {
      window.removeEventListener('scroll', throttledScroll);
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
    };
  }, [initialized, headings, handleScroll]);

  const scrollToHeading = headingId => {
    const element = document.querySelector(`[data-id="${headingId}"]`);
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: elementPosition - 80,
        behavior: 'smooth',
      });
    }
  };

  if (loading || !isReady) {
    return (
      <div className="table-of-contents">
        <div className="toc-title">
          目次
          <LoadingOutlined style={{ marginLeft: '8px', fontSize: '14px' }} />
        </div>
        <div className="toc-items">
          <Text
            type="secondary"
            style={{ display: 'block', marginBottom: '12px' }}
          >
            目次を解析中です...
          </Text>
          <Skeleton
            active
            paragraph={{
              rows: 6,
              width: ['80%', '70%', '90%', '60%', '85%', '75%'],
            }}
            title={false}
          />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="table-of-contents">
        <div className="toc-title">目次</div>
        <Alert
          message={error}
          type="error"
          showIcon
          style={{ marginTop: '12px' }}
        />
      </div>
    );
  }

  if (!editorData || headings.length === 0) {
    return (
      <div className="table-of-contents">
        <div className="toc-title">目次</div>
        <Text type="secondary">見出しがありません</Text>
      </div>
    );
  }

  return (
    <div className="table-of-contents">
      <div className="toc-title">目次</div>
      <div className="toc-items">
        {headings.map((heading, index) => (
          <div
            key={index}
            className={`toc-item level-${heading.level} ${
              activeId === heading.id ? 'active' : ''
            }`}
            onClick={() => scrollToHeading(heading.id)}
          >
            {heading.text}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TableOfContents;
