import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Form, Input, Button, message, Breadcrumb, Modal, Typography, Divider, Row, Col, Table, Segmented, Badge, Card, Tag, AutoComplete } from 'antd';
import { Tooltip as AntTooltip } from 'antd';
import { FireOutlined, InfoCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { getUserProfile } from '../../auth';
import moment from 'moment-timezone';
import { debounce } from 'lodash';


const { Title, Paragraph, Text } = Typography;

const SEOArticleGenerator = () => {
  const [form] = Form.useForm();
  const [queueData, setQueueData] = useState({
    running: [],
    queued: [],
    completed: []
  });
  const [activeTab, setActiveTab] = useState('queued');
  const [selectedQueue, setSelectedQueue] = useState(null);
  const location = useLocation();
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [recommendedKeywords, setRecommendedKeywords] = useState([]);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);

  useEffect(() => {
    fetchQueueData();
    fetchRecommendedKeywords();
    const timer = setInterval(fetchQueueData, 10000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (location.state && location.state.keyword) {
      form.setFieldsValue({ keyword: location.state.keyword });
    }
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [location.state, form]);

  const fetchQueueData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/seo-article/user-queue`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log('User Queue Data:', response.data);
      setQueueData(response.data);
    } catch (error) {
      console.error('Failed to fetch queue data:', error);
      setQueueData({ running: [], queued: [], completed: [] });
    }
  };

  const fetchRecommendedKeywords = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/seo-article/keywords-recommend`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setRecommendedKeywords(response.data.keywords || []); // 空の配列をデフォルト値として設定
    } catch (error) {
      console.error('Failed to fetch recommended keywords:', error);
      setRecommendedKeywords([]); // エラー時は空の配列を設定
    }
  };

  const fetchAutoCompleteKeywords = useCallback(
    debounce(async (searchText) => {
      if (searchText.length < 1) {
        setAutoCompleteOptions([]);
        return;
      }
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/seo-article/autocomplete-keywords?query=${encodeURIComponent(searchText)}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setAutoCompleteOptions(response.data.map(keyword => ({ value: keyword })));
      } catch (error) {
        console.error('Failed to fetch autocomplete keywords:', error);
        setAutoCompleteOptions([]);
      }
    }, 300),
    []
  );

  const onFinish = async (values) => {
    try {
      const token = localStorage.getItem('token');
      const userProfile = await getUserProfile();
  
      if (!userProfile.user_id) {
        message.error('ユーザー情報の取得に失敗しました');
        return;
      }
  
      if (userProfile.ticket_count < 10) {
        message.error('チケットが不足しています。');
        return;
      }
  
      Modal.confirm({
        title: '新規生成の確認',
        content: (
          <div>
            <p>この操作では各種データと構成案まで生成します。</p>
            <p>
              <strong style={{ fontSize: '1.5em', fontWeight: 'bold', background: 'linear-gradient(90deg, #0072ff 0%, #00d4ff 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>10</strong>枚のチケットを消費しますがよろしいですか？
            </p>
          </div>
        ),
        onOk: async () => {
          try {
            values.keyword = values.keyword.replace(/　/g, ' ').replace(/\s+$/g, '');
  
            console.log('Sending generate request with:', values);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/seo-article/generate`, { ...values, user_id: userProfile.user_id }, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            console.log('Generate response:', response);
  
            form.resetFields();
            fetchQueueData();
          } catch (error) {
            console.error('Failed to generate article:', error);
            if (error.response && error.response.data && error.response.data.detail) {
              message.error(`生成リクエストの送信に失敗しました: ${error.response.data.detail}`);
            } else {
              message.error('生成リクエストの送信に失敗しました: ' + error.message);
            }
            // チケットの返還処理
            try {
              await axios.put(`${process.env.REACT_APP_API_URL}/users/me/refund_tickets`, { count: 10 }, {
                headers: { Authorization: `Bearer ${token}` },
              });
              message.success('チケットが返還されました');
            } catch (refundError) {
              console.error('Failed to refund tickets:', refundError);
              message.error('チケットの返還に失敗しました');
            }
          }
        },
        onCancel: () => {},
      });
    } catch (error) {
      console.error('Failed to get user profile or ticket count:', error);
      message.error('ユーザー情報の取得に失敗しました');
    }
  };

  const handleRecommendedKeywordClick = (keyword) => {
    form.setFieldsValue({ keyword });
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const getBadgeColor = (type) => {
    switch (type) {
      case 'queued': return '#faad14';
      case 'running': return '#1890ff';
      case 'completed': return '#00d4ff';
      case 'failed': return '#f5222d';
      default: return '#d9d9d9';
    }
  };

  const handleRegenerate = async (record) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/seo-article/regenerate/${record.uuid}`, {
        task_type: record.type,
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      
      if (response.status === 200 && response.data.message) {
        message.success(response.data.message);
        await fetchQueueData();
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error('Failed to regenerate task:', error);
      if (error.response && error.response.status === 200) {
        message.success('タスクの再実行が予約されました');
        await fetchQueueData();
      } else {
        message.error('タスクの再実行に失敗しました: ' + (error.response?.data?.detail || error.message));
      }
    }
  };
  
  const renderBadge = (count, type) => {
    if (count === 0) return null;
    return (
      <Badge
        count={count}
        style={{
          backgroundColor: getBadgeColor(type),
          marginLeft: '1px',
          marginTop: '-10px',
          transform: 'scale(0.8)',
        }}
      />
    );
  };

  const handleCancelQueue = async () => {
    if (!selectedQueue || !selectedQueue.id) {
      message.error('キャンセルする予約が選択されていません');
      return;
    }
  
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/seo-article/cancel-queue/${selectedQueue.id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      message.success(response.data.message);
      setSelectedQueue(null);
      await fetchQueueData();
    } catch (error) {
      console.error('Failed to cancel queue:', error);
      message.error('キャンセルに失敗しました: ' + (error.response?.data?.detail || error.message));
    }
  };

  const showRegenModal = (record) => {
    Modal.confirm({
      title: 'タスクの再実行',
      content: `「${record.keyword}」のタスクを再実行しますか？ ${getTicketCost(record.type)}枚のチケットが消費されます。`,
      onOk: () => handleRegenerate(record),
    });
  };
  
  const getTicketCost = (type) => {
    switch (type) {
      case 'generate':
        return 10;
      default:
        return 0;
    }
  };

  const columns = [
    {
      title: activeTab === 'queued' ? '予想待ち時間' : (activeTab === 'running' ? '開始時間' : '完了時間'),
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => {
        if (activeTab === 'queued') {
          return record.estimated_wait_time != null ? `${record.estimated_wait_time}分後` : '計算中';
        } else if (activeTab === 'completed' && record.status === 'failed') {
          return (
            <span>
              失敗
              <Button 
                size="small" 
                onClick={(e) => {
                  e.stopPropagation();
                  showRegenModal(record);
                }}
                style={{ marginLeft: '8px' }}
              >
                再実行
              </Button>
            </span>
          );
        } else {
          return text ? moment.utc(text).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm') : '-';
        }
      },
    },
    {
      title: 'キーワード',
      dataIndex: 'keyword',
      key: 'keyword',
      render: (text, record) => (
        activeTab === 'completed' && record.status !== 'failed' ? (
          <Link to={`/seo-article/detail/${record.uuid}`}>{text}</Link>
        ) : (
          text
        )
      ),
    },
    {
      title: '内容',
      dataIndex: 'type',
      key: 'type',
      render: (text) => {
        let content = '';
        switch (text) {
          case 'generate':
            content = '記事生成';
            break;
          case 'check_topics':
            content = 'トピックチェック';
            break;
          case 'generate_content':
            content = '本文生成';
            break;
          default:
            content = text;
        }
        return <Tag>{content}</Tag>;
      },
    },
  ];

  const getCompletedCount = () => queueData.completed.length;
  const getFailedCount = () => queueData.completed.filter(job => job.status === 'failed').length;

  const completedCount = getCompletedCount();
  const failedCount = getFailedCount();

  return (
    <>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>SEO記事生成</Breadcrumb.Item>
      </Breadcrumb>
      <Title level={1} className='gradient-text' style={{fontSize:'24px'}}>新規生成を予約する</Title>
      <Divider style={{ margin: '24px 0' }} />

      <Row gutter={40}>
        <Col span={14}>
          <Form form={form} style={{padding:'24px 0'}} name="keyword-form" onFinish={onFinish} layout="vertical">
            <Row gutter={0}>
              <Col span={16}>
                <Form.Item
                  name="keyword"
                  rules={[{ required: true, message: 'キーワードを入力してください' }]}
                >
                  <AutoComplete
                    options={autoCompleteOptions}
                    onSearch={fetchAutoCompleteKeywords}
                    onSelect={(value) => form.setFieldsValue({ keyword: value })}
                  >
                    <Input
                      ref={inputRef}
                      placeholder="キーワードを入力してください"
                      style={{
                        width: '100%',
                        padding: '7px 16px',
                        backgroundColor: '#f5f5f5',
                        border: '2px solid #f5f5f5',
                        transition: 'all 0.3s',
                        borderRadius: '50px 0 0 50px',
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = '#eee';
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = '#f5f5f5';
                      }}
                      onFocus={(e) => {
                        e.currentTarget.style.border = '2px solid #0072ff';
                      }}
                      onBlur={(e) => {
                        e.currentTarget.style.border = '2px solid #f5f5f5';
                      }}
                    />
                  </AutoComplete>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      background: 'linear-gradient(90deg, #0072ff 0%, #00d4ff 100%)',
                      border: 'none',
                      transition: 'all 0.3s',
                      width: '100%',
                      fontWeight: 'bold',
                      borderRadius: '0 50px 50px 0',
                    }}
                    size="large"
                    onMouseEnter={(e) => {
                      e.currentTarget.style.opacity = '0.7';
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.opacity = '1.0';
                    }}
                  >
                    <FireOutlined style={{marginLeft: 'auto', left: '16px', position: 'absolute', top: '50%', transform: 'translate(0, -50%)', fontSize: '18px' }} />
                    新規生成を予約する
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          {/* {recommendedKeywords.length > 0 && (
            <Row style={{ marginTop: '00px' }}>
              <Col span={24}>
                <p style={{ marginRight: '10px' }}>おすすめ：</p>
                {recommendedKeywords.map((keyword, index) => (
                  <Button
                    key={index}
                    style={{ marginRight: '6px', marginBottom: '6px', padding: '4px 8px', fontSize: '12px' }}
                    onClick={() => handleRecommendedKeywordClick(keyword.keyword)}
                  >
                    {keyword.keyword}
                  </Button>
                ))}
              </Col>
            </Row>
          )} */}
          <Divider style={{ margin: '24px 0' }} />
          <div style={{ marginTop: '24px' }}>
            <Paragraph>
              <ul style={{ lineHeight: 2.0 }}>
                <li>各種分析と構成案を自動生成します。本文はそのあとで生成実行できます。</li>
                <li>生成は予約され、優先順に自動実行されます。</li>
                <li>膨大な分析/生成処理を行うため、実行～完了までに10分前後の時間を要します。</li>
                <li>画面を開いて待っていただく必要はありません。</li>
                <li>何らかの理由で処理が失敗した場合、チケットは返還されます。</li>
                <li>キーワードの全角/半角スペースは同一と見なされます。</li>
              </ul>
            </Paragraph>
          </div>
        </Col>
        <Col span={10}>
          <Card
            title="あなたの生成状況"
            extra={
              <AntTooltip title="ここにあなたの生成予約状況や実行状況がリアルタイム表示されます。">
                <InfoCircleOutlined style={{marginLeft:'6px', color: 'rgb(0 212 255)'}} />
              </AntTooltip>
            }
          >
            <Segmented
              options={[
                { label: <span>予約中 {renderBadge(queueData.queued.length, 'queued')}</span>, value: 'queued' },
                { label: <span>実行中 {renderBadge(queueData.running.length, 'running')}</span>, value: 'running' },
                { label: (
                  <span>
                    完了
                    {renderBadge(completedCount - failedCount, 'completed')}
                    {renderBadge(failedCount, 'failed')}
                  </span>
                ), value: 'completed' },
              ]}
              value={activeTab}
              onChange={setActiveTab}
              style={{ marginBottom: '16px', width: '100%' }}
              block
            />
            <Table
              dataSource={queueData[activeTab]}
              columns={columns}
              pagination={false}
              size="small"
              onRow={(record) => ({
                onClick: () => {
                  if (activeTab === 'queued') {
                    console.log('Selected queue:', record);
                    setSelectedQueue(record);
                  }
                },
              })}
              rowKey={(record) => record.id}
            />
            <Modal
              title="キューのキャンセル"
              visible={!!selectedQueue}
              onOk={handleCancelQueue}
              onCancel={() => setSelectedQueue(null)}
              okText="キャンセル"
              cancelText="閉じる"
              okButtonProps={{ danger: true }}
            >
              <p>「{selectedQueue?.keyword}」の生成をキャンセルしますか？</p>
              <p>キャンセルすると、消費したチケットが返還されます。</p>
            </Modal>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SEOArticleGenerator;