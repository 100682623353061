import React, { useState } from 'react';
import { Form, Input, Button, message, Typography } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { resetPasswordConfirm } from '../../auth';
import styles from '../auth/Auth.module.css';

const { Title } = Typography;

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await resetPasswordConfirm(token, values.password);
      message.success('パスワードが正常に更新されました');
      navigate('/login');
    } catch (error) {
      message.error('パスワードの更新に失敗しました');
    }
    setLoading(false);
  };

  return (
    <div className={styles.authContainer}>
      <Title level={2} style={{ marginBottom: '24px', textAlign: 'center' }}>パスワードの再設定</Title>
      <Form onFinish={onFinish} layout="vertical">
        <Form.Item
          name="password"
          rules={[
            { required: true, message: '新しいパスワードを入力してください' },
            { min: 8, message: 'パスワードは8文字以上で入力してください' },
          ]}
        >
          <Input.Password
            placeholder="新しいパスワード"
            size="large"
            style={{
              border: '2px solid #f5f5f5',
              backgroundColor: '#f5f5f5',
              padding: '8px',
              transition: 'all 0.3s',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = '#eee';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = '#f5f5f5';
            }}
            onFocus={(e) => {
              e.currentTarget.style.border = '2px solid #0072ff';
            }}
            onBlur={(e) => {
              e.currentTarget.style.border = '2px solid #f5f5f5';
            }}
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          dependencies={['password']}
          rules={[
            { required: true, message: 'パスワードを再入力してください' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('パスワードが一致しません'));
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="パスワードの確認"
            size="large"
            style={{
              border: '2px solid #f5f5f5',
              backgroundColor: '#f5f5f5',
              padding: '8px',
              transition: 'all 0.3s',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = '#eee';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = '#f5f5f5';
            }}
            onFocus={(e) => {
              e.currentTarget.style.border = '2px solid #0072ff';
            }}
            onBlur={(e) => {
              e.currentTarget.style.border = '2px solid #f5f5f5';
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            block
            size="large"
            style={{
              background: 'linear-gradient(90deg, #0072ff 0%, #00d4ff 100%)',
              border: 'none',
              transition: 'all 0.3s',
              fontWeight: 'bold',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.opacity = '0.7';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.opacity = '1.0';
            }}
          >
            パスワードを更新
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ResetPassword;