import React, { useEffect } from 'react';
import { Result, Button } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import AuthenticatedRoute from './AuthenticatedRoute';

const SubscriptionSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // ここでバックエンドAPIを呼び出して、セッションIDを検証し、
    // 必要な場合はユーザーの情報を更新します
  }, []);

  return (
    <AuthenticatedRoute>
      <Result
        status="success"
        title="プランの変更が完了しました！"
        subTitle="新しいプランでのご利用をお楽しみください。"
        extra={[
          <Button key="console" onClick={() => navigate('/dashboard')}>
            ダッシュボードへ
          </Button>,
        ]}
      />
    </AuthenticatedRoute>
  );
};

export default SubscriptionSuccess;