import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Card, Segmented, Table, Row, Col, Badge, Tooltip, Typography, Breadcrumb } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { api } from '../../api';
import moment from 'moment-timezone';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';

const { Title } = Typography;

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState({
    running: [],
    queued: [],
    failed: [],
    user_details: {},
    article_keywords: {},
    chart_data: []
  });
  const [activeTab, setActiveTab] = useState({
    running: 'article',
    queued: 'article',
    failed: 'article',
  });
  const chartRef = useRef(null);
  
  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await api.get('/seo-article/dashboard');
        setDashboardData(response.data);
      } catch (error) {
        console.error('Failed to fetch dashboard data:', error);
      }
    };
  
    fetchDashboardData();
    const timer = setInterval(fetchDashboardData, 5000);
  
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (dashboardData.chart_data.length > 0 && chartRef.current && !chartRef.current.chart) {
      const ctx = chartRef.current.getContext('2d');
      
      const processData = (type) => {
        return dashboardData.chart_data
          .filter((item) => item.type === type)
          .map((item) => ({
            x: moment(item.date).toDate(),
            y: item.value,
          }));
      };
      
      const datasets = [
        { label: '新規記事実行数', data: processData('新規記事実行数'), borderColor: '#00d4ff' },
        { label: '新規記事失敗数', data: processData('新規記事失敗数'), borderColor: '#00d4ff', borderDash: [5, 5] },
        { label: 'トピックチェック実行数', data: processData('トピックチェック数'), borderColor: '#edac4c' },
        { label: 'トピックチェック失敗数', data: processData('トピックチェック失敗数'), borderColor: '#edac4c', borderDash: [5, 5] },
        { label: '本文生成実行数', data: processData('本文生成数'), borderColor: '#d77cd0' },
        { label: '本文生成失敗数', data: processData('本文生成失敗数'), borderColor: '#d77cd0', borderDash: [5, 5] },
      ];
      
      chartRef.current.chart = new Chart(ctx, {
        type: 'line',
        data: { datasets },
        options: {
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'day',
                tooltipFormat: 'YYYY/MM/DD',
                displayFormats: {
                  day: 'YYYY/MM/DD',
                },
              },
            },
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              position: 'top',
            },
          },
          maintainAspectRatio: false,
        },
      });
    }
  }, [dashboardData.chart_data]);

  const fixedColumn50 = {
    width: 50,
  };
  const fixedColumn80 = {
    width: 80,
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text) => moment.utc(text).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm'),
    },
    {
      title: 'Article ID',
      dataIndex: 'article_id',
      key: 'article_id',
      render: (text, record) => (
        <Tooltip title={dashboardData.article_keywords[text]}>
          <Link to={`/seo-article/detail/${record.uuid || ''}`} target="_blank">
            {text}
          </Link>
        </Tooltip>
      ),
      ...fixedColumn80,
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: (text) => (
        <Tooltip title={
          <>
            {dashboardData.user_details[text]?.email}<br/>
            {dashboardData.user_details[text]?.company_name}<br/>
            {dashboardData.user_details[text]?.department_name}<br/>
            {dashboardData.user_details[text]?.full_name}
          </>
        }>
          <UserOutlined />
        </Tooltip>
      ),
      ...fixedColumn50,
    },
  ];

  const renderSegmentedContent = (data, category) => {
    const articleCount = data.filter(item => item.type === 'generate').length;
    const topicCount = data.filter(item => item.type === 'check_topics').length;
    const contentCount = data.filter(item => item.type === 'generate_content').length;

    return (
      <>
        <Segmented
          options={[
            { label: (
              <span>
                新規記事
                {articleCount > 0 && (
                  <Badge 
                    count={articleCount} 
                    style={{ 
                      backgroundColor: getBadgeColor(category),
                      marginLeft: '5px',
                      marginTop: '-10px',
                      transform: 'scale(0.8)',
                    }} 
                  />
                )}
              </span>
            ), value: 'article' },
            { label: (
              <span>
                トピック
                {topicCount > 0 && (
                  <Badge 
                    count={topicCount} 
                    style={{ 
                      backgroundColor: getBadgeColor(category),
                      marginLeft: '5px',
                      marginTop: '-10px',
                      transform: 'scale(0.8)',
                    }} 
                  />
                )}
              </span>
            ), value: 'topic' },
            { label: (
              <span>
                本文
                {contentCount > 0 && (
                  <Badge 
                    count={contentCount} 
                    style={{ 
                      backgroundColor: getBadgeColor(category),
                      marginLeft: '5px',
                      marginTop: '-10px',
                      transform: 'scale(0.8)',
                    }} 
                  />
                )}
              </span>
            ), value: 'content' },
          ]}
          value={activeTab[category]}
          onChange={(value) => setActiveTab({ ...activeTab, [category]: value })}
          style={{ marginBottom: '16px', width: '100%' }}
          block
        />
        <Table
          dataSource={data.filter(item => {
            if (activeTab[category] === 'article') return item.type === 'generate';
            if (activeTab[category] === 'topic') return item.type === 'check_topics';
            if (activeTab[category] === 'content') return item.type === 'generate_content';
            return false;
          })}
          columns={columns}
          pagination={false}
          scroll={{ y: 200 }}
          size="small"
        />
      </>
    );
  };

  const getBadgeColor = (category) => {
    switch (category) {
      case 'running': return '#1890ff';
      case 'queued': return '#faad14';
      case 'failed': return '#f5222d';
      default: return '#1890ff';
    }
  };

  return (
    <>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <Link to="/seo-article/">SEO記事生成</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>タスク監視</Breadcrumb.Item>
      </Breadcrumb>
      <Row gutter={24}>
        <Col span={8}>
          <Card 
            title="実行中のタスク" 
            style={{ 
              marginBottom: 36, 
              height: '392px',
            }}
          >
            {renderSegmentedContent(dashboardData.running, 'running')}
          </Card>
        </Col>
        <Col span={8}>
          <Card 
            title="予約中のタスク" 
            style={{ 
              marginBottom: 36, 
              height: '392px',
            }}
          >
            {renderSegmentedContent(dashboardData.queued, 'queued')}
          </Card>
        </Col>
        <Col span={8}>
          <Card 
            title="失敗したタスク" 
            style={{ 
              marginBottom: 36, 
              height: '392px',
            }}
          >
            {renderSegmentedContent(dashboardData.failed, 'failed')}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card title="タスク実行統計">
            <canvas ref={chartRef} style={{ height: '350px' }} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;