import React, { useState, useEffect, useCallback } from 'react';
import { Table, Button, message, Tag, Progress, Breadcrumb, Input, Typography, Divider, Modal, Tooltip, Spin, Avatar, Row, Col, Card } from 'antd';
import { SearchOutlined, UserOutlined, EyeOutlined, BankOutlined, MailOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { getUserRole, getUserProfile } from '../../auth';
import moment from 'moment-timezone';
import debounce from 'lodash/debounce';
import UserDetail from '../UserManagement/UserDetail';

const { Title, Text } = Typography;
const { Search } = Input;

const ArticleList = () => {
  const [articles, setArticles] = useState([]);
  const [userRole, setUserRole] = useState('user');
  const [currentUser, setCurrentUser] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const [relatedKeywords, setRelatedKeywords] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [hasBaseKeyword, setHasBaseKeyword] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [selectedKeyword, setSelectedKeyword] = useState('');
  const [selectedArticleUuid, setSelectedArticleUuid] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchOptions, setSearchOptions] = useState([]);
  const navigate = useNavigate();
  const { userId } = useParams();
  const location = useLocation();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [userDetailModalVisible, setUserDetailModalVisible] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      const userRole = await getUserRole();
      const userProfile = await getUserProfile();
      setUserRole(userRole);
      setCurrentUser(userProfile);

      // パラメータ付きの場合、adminとmaster以外はリダイレクト
      if (userId && userRole !== 'admin' && userRole !== 'master') {
        navigate('/seo-article/list');
      } else {
        fetchArticles(userId);
      }
    };
    fetchUserData();
  }, [userId, navigate]);

  const fetchUserDetails = useCallback(async () => {
    if (userRole === 'admin' || userRole === 'master') {
      try {
        const token = localStorage.getItem('token');
        const userDetailsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/users/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const userDetailsMap = {};
        userDetailsResponse.data.forEach(user => {
          userDetailsMap[user.user_id] = user;
        });
        setUserDetails(userDetailsMap);
      } catch (error) {
        console.error('Failed to fetch user details:', error);
      }
    }
  }, [userRole]);

  const fetchArticles = useCallback(async (targetUserId = null) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');

      let url = `${process.env.REACT_APP_API_URL}/seo-article/list`;
      
      if (targetUserId) {
        // 特定ユーザーの結果を取得
        url += `?user_id=${targetUserId}`;
      } else if (userRole !== 'admin' && userRole !== 'master') {
        // admin、master以外は自分の結果のみ取得
        url += `?user_id=${currentUser.user_id}`;
      }

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setArticles(response.data);

      if (userRole === 'admin' || userRole === 'master') {
        await fetchUserDetails();
      }
    } catch (error) {
      // console.error('Failed to fetch articles:', error);
      // message.error('記事の取得に失敗しました');
    } finally {
      setLoading(false);
    }
  }, [userRole, currentUser, fetchUserDetails]);

useEffect(() => {
  const params = new URLSearchParams(location.search);
  const userIdFromParams = params.get('user_id');
  fetchArticles(userIdFromParams || userId);
}, [location.search, fetchArticles, userId]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
  });

  const getStatusColor = (status) => {
    if (status.includes('予約中') || status.includes('更新予約中')) return 'orange';
    if (status.includes('中') || status.includes('更新中')) return 'green';
    if (status === '生成済み') return 'blue';
    if (status === '生成失敗') return 'red';
    return 'default';
  };

  const showRegenModal = (record) => {
    Modal.confirm({
      title: 'タスクの再実行',
      content: `「${record.keyword}」のタスクを再実行しますか？ 10枚のチケットが消費されます。`,
      onOk: () => handleRegenerate(record),
    });
  };

  const handleRegenerate = async (record) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/seo-article/regenerate/${record.uuid}`, {
        task_type: 'generate',
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      
      if (response.status === 200 && response.data.message) {
        message.success(response.data.message);
        setArticles(prevArticles => prevArticles.map(article => 
          article.uuid === record.uuid ? { ...article, status: '予約中' } : article
        ));
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error('Failed to regenerate task:', error);
      if (error.response && error.response.status === 200) {
        message.success('タスクの再実行が予約されました');
        await fetchArticles();
      } else {
        message.error('タスクの再実行に失敗しました: ' + (error.response?.data?.detail || error.message));
      }
    }
  };

  const isTopicCheckCompleted = (record) => {
    const checkTopics = (topics) => {
      if (!topics) return false;
      const parsedTopics = JSON.parse(topics);
      return parsedTopics.some(topic => [1, 2, 3].includes(topic[2]));
    };
  
    return checkTopics(record.top_topics) || checkTopics(record.unique_topics) || checkTopics(record.related_keywords);
  };

  const showModal = useCallback((record) => {
    setSelectedKeyword(record.keyword);
    setSelectedArticleUuid(record.uuid);
    setModalVisible(true);
    setModalLoading(true);
    fetchRelatedKeywords(record.uuid);
  }, []);

  const fetchRelatedKeywords = useCallback(async (articleUuid) => {
    setModalLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/related-keywords/article/${articleUuid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRelatedKeywords(response.data.related_keywords || []);
      setLastUpdated(response.data.last_updated);
      setHasBaseKeyword(response.data.has_base_keyword);
    } catch (error) {
      console.error('Failed to fetch related keywords:', error);
      message.error('関連キーワードの取得に失敗しました');
      setRelatedKeywords([]);
    } finally {
      setModalLoading(false);
    }
  }, []);

  const handleFetchRelatedKeywords = useCallback(async () => {
    if (selectedArticleUuid) {
      setModalLoading(true);
      try {
        const token = localStorage.getItem('token');
        await axios.post(`${process.env.REACT_APP_API_URL}/seo-article/fetch-related-keywords/article/${selectedArticleUuid}`, {}, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        await fetchRelatedKeywords(selectedArticleUuid);
      } catch (error) {
        console.error('Failed to fetch related keywords:', error);
        message.error('関連キーワードの取得に失敗しました');
      } finally {
        setModalLoading(false);
      }
    }
  }, [selectedArticleUuid, fetchRelatedKeywords]);

  const handleDelete = async (record) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/seo-article/${record.uuid}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      message.success('記事が削除されました');
      fetchArticles();
    } catch (error) {
      console.error('Failed to delete article:', error);
      message.error('記事の削除に失敗しました');
    }
  };

  const showDeleteConfirm = (record) => {
    Modal.confirm({
      title: '記事の削除',
      content: '本当にこの記事を削除しますか？この操作は取り消せません。',
      okText: '削除',
      okType: 'danger',
      cancelText: 'キャンセル',
      onOk() {
        handleDelete(record);
      },
    });
  };

  const columns = [
    {
      title: 'ステータス',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: '予約中', value: '予約中' },
        { text: '更新予約中', value: '更新予約中' },
        { text: '生成中', value: '生成中' },
        { text: '更新中', value: '更新中' },
        { text: '生成済み', value: '生成済み' },
        { text: '生成失敗', value: '生成失敗' },
      ],
      onFilter: (value, record) => record.status.includes(value),
      render: (status, record) => (
        <>
          <Tag color={status === '生成済み' ? 'blue' : getStatusColor(status)}>
            {status.split('（')[0]}
          </Tag>
          {status.includes('（') && (
            <span style={{ marginLeft: '5px', fontSize: '12px', color: '#888' }}>
              {status.split('（')[1].replace('）', '')}
            </span>
          )}
          {status === '生成済み' && (
            <>
              {isTopicCheckCompleted(record) && <Tag color="blue">構成CH</Tag>}
              {record.content && <Tag color="blue">本文</Tag>}
            </>
          )}
          {status === '生成中' && (
            <Progress id="progress" style={{display: 'inline-block',width: '100px',marginLeft: '10px'}} percent={record.progress} size="small" />
          )}
          {status === '生成失敗' && (
            <Button size="small" onClick={() => showRegenModal(record)} style={{ marginLeft: '10px' }}>再実行</Button>
          )}
        </>
      ),
    },
    {
      title: 'キーワード',
      dataIndex: 'keyword',
      key: 'keyword',
      ...getColumnSearchProps('keyword'),
    },
    {
      title: '生成実行日',
      dataIndex: 'execution_date',
      key: 'execution_date',
      sorter: (a, b) => new Date(a.execution_date) - new Date(b.execution_date),
      render: (text, record) => {
        if (record.status.includes('予約中')) {
          if (record.estimated_wait_time === 0) {
            return '予想待ち時間: 計算中';
          } else if (record.estimated_wait_time !== null && record.estimated_wait_time !== undefined) {
            return `予想待ち時間: ${record.estimated_wait_time}分`;
          } else {
            return '予想待ち時間: 不明';
          }
        }
        return text ? moment.utc(text).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm') : '-';
      },
    },
    {
      title: '完了日',
      dataIndex: 'completed_at',
      key: 'completed_at',
      sorter: (a, b) => new Date(a.completed_at) - new Date(b.completed_at),
      render: (text, record) => {
        if (record.status === '生成済み' || record.status === '生成失敗') {
          return text ? moment.utc(text).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm') : '-';
        }
        return '-';
      },
    },
    {
      title: '結果',
      key: 'result',
      render: (_, record) => {
        if (record.status === '生成済み' || record.structured_headings) {
          return (
            <Link to={`/seo-article/detail/${record.uuid}`}>
              <Button 
                type="primary"
                style={{
                  background: 'linear-gradient(90deg, #0072ff 0%, #00d4ff 100%)',
                  border: 'none',
                  transition: 'all 0.3s',
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.opacity = '0.7';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.opacity = '1.0';
                }}
              >
                結果
              </Button>
            </Link>
          );
        } else if (record.status === '生成失敗') {
          return (
            <Button type="danger" onClick={() => showDeleteConfirm(record)}>
              取消
            </Button>
          );
        }
        return null;
      },
    },
  ];

  if (userRole === 'admin' || userRole === 'master') {
    columns.push({
      title: '',
      key: 'user',
      render: (_, record) => (
        <>
          <Tooltip title={
            <>
              {userDetails[record.user_id]?.email}<br/>
              {userDetails[record.user_id]?.company_name}<br/>
              {userDetails[record.user_id]?.department_name}<br/>
              {userDetails[record.user_id]?.last_name} {userDetails[record.user_id]?.first_name}
            </>
          }>
            <UserOutlined 
              style={{ 
                cursor: 'pointer',
                transition: 'color 0.3s'
              }}
              onMouseEnter={(e) => e.currentTarget.style.color = '#1890ff'}
              onMouseLeave={(e) => e.currentTarget.style.color = 'inherit'}
              onClick={() => {
                setSelectedUserId(record.user_id);
                setUserDetailModalVisible(true);
              }}
            />
          </Tooltip>
        </>
      ),
      width: 50,
    });
    columns.push({
      title: '関連KW',
      key: 'relatedKeywords',
      render: (_, record) => (
        <Button
          onClick={() => showModal(record)}
          icon={record.has_base_keyword ? <EyeOutlined /> : <SearchOutlined />}
        >
          {record.has_base_keyword ? '表示' : '取得'}
        </Button>
      ),
      width: 50,
    });
  }

  const RelatedKeywordsModal = ({ visible, onCancel, keyword, relatedKeywords, lastUpdated, loading, hasBaseKeyword, handleFetchRelatedKeywords }) => {
    const columns = [
      {
        title: 'キーワード',
        dataIndex: 'keyword',
        key: 'keyword',
      },
      {
        title: '検索Vol',
        dataIndex: 'volume',
        key: 'volume',
        sorter: (a, b) => a.volume - b.volume,
      },
      {
        title: 'CPC',
        dataIndex: 'cpc',
        key: 'cpc',
        render: (cpc) => `$${cpc?.toFixed(2) || 'N/A'}`,
        sorter: (a, b) => (a.cpc || 0) - (b.cpc || 0),
      },
      {
        title: 'トレンド',
        dataIndex: 'trend',
        key: 'trend',
        render: (_, record) => {
          const data = record.monthly_volumes || [];
          const width = 80;
          const height = 24;
          const points = data.map((d, i) => `${(i / (data.length - 1)) * width},${height - (d.volume / Math.max(...data.map(d => d.volume))) * height}`).join(' ');
      
          return (
            <svg width={width} height={height}>
              <polyline
                fill="none"
                stroke="#00d4ff"
                strokeWidth="1"
                points={points}
              />
            </svg>
          );
        },
      },
      {
        title: '競合性',
        dataIndex: 'competition',
        key: 'competition',
        render: (competition) => {
          const value = competition !== null && competition !== undefined ? Math.min(Math.round(competition * 10), 10) : 0;
          return (
            <Tooltip title={`競合性: ${value}/10 (${(competition * 100).toFixed(2)}%)`}>
              <Progress
                percent={value * 10}
                steps={10}
                size="small"
                showInfo={false}
                strokeColor="#1890ff"
              />
            </Tooltip>
          );
        },
        sorter: (a, b) => (a.competition || 0) - (b.competition || 0),
      },
    ];

    return (
      <Modal
        title={(
          <div style={{fontSize:"16px"}}><span style={{fontSize:"20px",color:"#00d4ff"}}>「{keyword}」</span>の関連キーワード</div>
        )}
        visible={visible}
        centered
        onCancel={onCancel}
        footer={[
          <Button key="fetch" onClick={handleFetchRelatedKeywords} loading={loading}>
            関連キーワードを更新
          </Button>,
          <Button key="close" onClick={onCancel}>
            閉じる
          </Button>,
        ]}
        width="80vw"
      >
        {lastUpdated && (
          <Text style={{fontSize:"12px"}}>取得日時: {moment(lastUpdated).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm')}</Text>
        )}
        <Spin spinning={loading}>
          {relatedKeywords && relatedKeywords.length > 0 ? (
            <Table
              dataSource={relatedKeywords}
              columns={columns}
              pagination={{ pageSize: 10 }}
              scroll={{ y: 500 }}
              style={{marginTop:"24px"}}
            />
          ) : (
            <p style={{marginTop:"24px"}}>関連キーワードが見つかりません。</p>
          )}
        </Spin>
      </Modal>
    );
  };

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>
              <Link to="/seo-article">SEO記事生成</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>生成結果一覧</Breadcrumb.Item>
          </Breadcrumb>
          <Title level={1} className='gradient-text' style={{fontSize:'24px'}}>生成結果一覧</Title>
        </Col>
        <Col span={12}>
          {userId && (userRole === 'admin' || userRole === 'master') && (
            <Card 
              className="by_user"
              style={{ 
                marginBottom: '20px', 
                backgroundColor: '#fffde4',
              }}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <div style={{ marginBottom: '8px' }}>
                    <Tag>{userDetails[userId]?.plan.name || 'User'}</Tag>
                  </div>
                  <div>
                    <MailOutlined style={{ marginRight: '8px' }} />
                    <Text>{userDetails[userId]?.email}</Text>
                  </div>
                </Col>
                <Col span={12}>
                  <div style={{ marginBottom: '8px' }}>
                    <BankOutlined style={{ marginRight: '8px' }} />
                    <Text>{userDetails[userId]?.company_name}  {userDetails[userId]?.department_name}</Text>
                  </div>
                  <Text>
                    {userDetails[userId]?.last_name} {userDetails[userId]?.first_name}
                  </Text>
                  <Text type="secondary" style={{ fontSize: '12px' }}>
                    （{userDetails[userId]?.last_name_kana} {userDetails[userId]?.first_name_kana}）
                  </Text>
                </Col>
              </Row>
            </Card>
          )}
        </Col>
      </Row>
      <Divider style={{ margin: '24px 0' }} />
      <Table 
        columns={columns} 
        dataSource={articles} 
        rowKey="id" 
        loading={loading}
        pagination={{
          position: ['bottomCenter'],
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
          showTotal: (total, range) => `${range[0]}-${range[1]} / ${total}件`,
        }}
      />
      <UserDetail
        visible={userDetailModalVisible}
        onCancel={() => setUserDetailModalVisible(false)}
        userId={selectedUserId}
        onUpdate={() => {
          setUserDetailModalVisible(false);
          fetchArticles();
        }}
      />
      <RelatedKeywordsModal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        keyword={selectedKeyword}
        relatedKeywords={relatedKeywords}
        lastUpdated={lastUpdated}
        loading={modalLoading}
        hasBaseKeyword={hasBaseKeyword}
        handleFetchRelatedKeywords={handleFetchRelatedKeywords}
      />
    </>
  );
};

export default ArticleList;