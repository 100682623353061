import React, { useState, useEffect } from 'react';
import { Table, Input, Button, Space, Breadcrumb, Tag } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Link } from 'react-router-dom';
import UserDetail from './UserDetail';

const UserList = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [userDetailVisible, setUserDetailVisible] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem('token');
      console.log('Token:', token);  // デバッグ出力
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(response.data);
    } catch (error) {
      console.error('Failed to fetch users:', error);
    }
  };

  const showUserDetail = (userId) => {
    setSelectedUserId(userId);
    setUserDetailVisible(true);
  };

  const handleUserDetailCancel = () => {
    setSelectedUserId(null);
    setUserDetailVisible(false);
  };

  const handleUserUpdate = () => {
    fetchUsers();
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
  });

  const planTags = {
    free: { color: 'blue', text: 'フリープラン' },
    standard: { color: 'green', text: 'スタンダードプラン' },
    premium: { color: 'gold', text: 'プレミアムプラン' },
    custom: { color: 'purple', text: 'カスタムプラン' },
  };

  const columns = [
    {
      title: '会社名/部署名',
      key: 'company',
      render: (_, record) => (
        <>
          <div>{record.company_name}</div>
          <div style={{ fontSize: '12px', color: '#888' }}>{record.department_name}</div>
        </>
      ),
      ...getColumnSearchProps('company_name'),
    },
    {
      title: '氏名',
      key: 'name',
      render: (_, record) => (
        <>
          <div>{record.last_name} {record.first_name}</div>
          <div style={{ fontSize: '12px', color: '#888' }}>
            {record.last_name_kana} {record.first_name_kana}
          </div>
        </>
      ),
      ...getColumnSearchProps('last_name'),
    },
    {
      title: 'プラン',
      dataIndex: 'plan',
      key: 'plan',
      render: (plan) => {
        const planInfo = planTags[plan?.name || 'free'];
        return (
          <Tag color={planInfo.color}>
            {planInfo.text}
          </Tag>
        );
      },
      filters: [
        { text: 'フリープラン', value: 'free' },
        { text: 'スタンダードプラン', value: 'standard' },
        { text: 'プレミアムプラン', value: 'premium' },
        { text: 'カスタムプラン', value: 'custom' },
      ],
      onFilter: (value, record) => record.plan?.name === value,
    },
    {
      title: 'チケット枚数',
      dataIndex: 'ticket_count',
      key: 'ticket_count',
      sorter: (a, b) => a.ticket_count - b.ticket_count,
    },
    {
      title: '登録日',
      dataIndex: 'registered_at',
      key: 'registered_at',
      render: (text) => new Date(text).toLocaleString(),
      sorter: (a, b) => new Date(a.registered_at) - new Date(b.registered_at),
    },
    {
      title: '最終ログイン',
      dataIndex: 'last_login_at',
      key: 'last_login_at',
      render: (text) => (text ? new Date(text).toLocaleString() : '-'),
      sorter: (a, b) => new Date(a.last_login_at || 0) - new Date(b.last_login_at || 0),
    },
  ];

  return (
    <>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <Link to="/seo-article">magikSEO</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>ユーザー一覧</Breadcrumb.Item>
      </Breadcrumb>
      <Table 
        columns={columns} 
        dataSource={data} 
        rowKey="id" 
        onRow={(record) => ({
          onClick: () => showUserDetail(record.user_id),
          style: {cursor: 'pointer'}
        })}
      />
      <UserDetail
        visible={userDetailVisible}
        onCancel={handleUserDetailCancel}
        userId={selectedUserId}
        onUpdate={handleUserUpdate}
      />
    </>
  );
};

export default UserList;